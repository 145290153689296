import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Immutable from "immutable";

import { Container } from "ui-library/atoms";
import { SubPageWrapper } from "ui-library/layouts";
import { Sidemenu } from "ui-library/modules";
import travelImage from "ui-library/assets/images/travel-pozadie.svg";

import i18n from "core/i18n";
import localized from "core/localized";
import router from "core/router";
import userContracts from "core/userContracts";
import { ContractHeader, ProductDetailPageLayout } from "containers/contract";
import { AppSidemenuLink } from "containers/link";
import { PageBoundary } from "containers/PageBoundary";
import { Roles } from "enums";
import { Tabs } from "routeConstants";

import { getActualPaymentScheduler } from "./selectors";

export const DetailTravelPage: FC = () => {
    const { t } = i18n.useTranslation();
    const isPageLoading = useSelector(router.getActivePageIsLoading);
    const policy = userContracts.useGetContractByUrlParam();
    const actualScheduler = useSelector(getActualPaymentScheduler);
    // @ts-ignore
    const scheduler = Immutable.Iterable.isIterable(actualScheduler) ? actualScheduler.first() : actualScheduler;
    const formattedSchedulerPayment = useSelector(localized.getLocalizedSchedulerPaymentFullLegacy(scheduler, false, true));

    const lobData = {
        label: t("insurance.insuranceCost"),
        value: formattedSchedulerPayment,
    };

    return (
        <ProductDetailPageLayout>
            <Container>
                <ContractHeader backgroundImage={travelImage} policy={policy} lobData={lobData} loading={isPageLoading} />

                <PageBoundary>
                    <SubPageWrapper>
                        <Sidemenu>
                            <AppSidemenuLink tab={Tabs.OVERVIEW} title={t("menu.tab.overview")} enabledFor={[Roles.AUTHENTICATED]} />
                            <AppSidemenuLink tab={Tabs.HOLDER} title={t("menu.tab.holder.insured")} enabledFor={[Roles.AUTHENTICATED]} />
                            <AppSidemenuLink tab={Tabs.DETAIL} title={t("menu.tab.detail")} enabledFor={[Roles.AUTHENTICATED]} />
                            <AppSidemenuLink tab={Tabs.CHANGES} title={t("menu.tab.changes")} />
                            <AppSidemenuLink
                                tab={Tabs.FINANCIAL_OPERATIONS}
                                title={t("menu.tab.statementsAndPayments")}
                                enabledFor={[Roles.AUTHENTICATED]}
                            />
                            <AppSidemenuLink tab={Tabs.CLAIMS} title={t("menu.tab.claims")} enabledFor={[Roles.AUTHENTICATED]} />
                            <AppSidemenuLink tab={Tabs.DOCUMENTS} title={t("menu.tab.documents")} enabledFor={[Roles.AUTHENTICATED]} />
                            <AppSidemenuLink tab={Tabs.AGENT} title={t("menu.tab.agent")} enabledFor={[Roles.AUTHENTICATED]} />
                        </Sidemenu>

                        <Outlet />
                    </SubPageWrapper>
                </PageBoundary>
            </Container>
        </ProductDetailPageLayout>
    );
};

export default DetailTravelPage;
