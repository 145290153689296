import { API_PREFIX } from "app/constants";
import superagent from "superagent";
import to from "core/util/fetch/awaitToJs";
import { Map } from "immutable";

const uriPrefix = `${API_PREFIX}/vehicle-change`;

const getDocuments = async (idChange: string) => {
    return await to(superagent.get(`${uriPrefix}/${idChange}/documents`));
};

const getDocumentDownloadUrl = (idChange: string, idDocument: string) => {
    return `${uriPrefix}/${idChange}/document/${idDocument}`;
};

const completeChange = async (idChange: string) => {
    return await to(superagent.get(`${uriPrefix}/${idChange}/complete`));
};

const uploadDocument = async (idChange: string, idDocument: string, fileData: Map<any, any>) => {
    const byteString = atob(fileData.get("data"));

    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([uint8Array], { type: fileData.get("fileFormat") });

    return await to(
        superagent.put(`${uriPrefix}/${idChange}/upload/${idDocument}`).attach("document", blob as any, fileData.get("fileName")),
    );
};

export default {
    getDocuments,
    getDocumentDownloadUrl,
    completeChange,
    uploadDocument,
};
