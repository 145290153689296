import { Button, Typography } from "ui-library/atoms";
import { Box } from "ui-library/layouts";
import { GarageForm } from "../../hooks/useGarageSearch";
import RepairType from "../../types/repairType";
import RepairTypeRadioGroup from "./RepairTypeRadioGroup";
import { useGarageSearchForm } from "../../hooks/useGarageSearchForm";
import { UseFormReturn } from "react-hook-form";
import { Field, Form } from "core/hookForm";

type GarageSearchFormProps = {
    form: UseFormReturn<GarageForm>;
    onSubmit: (data: GarageForm) => void;
};

export default function GarageSearchForm({ form, onSubmit }: GarageSearchFormProps) {
    const { handleSubmit, repairType, region, onRegionChange, carMakes, regions, districts, labels } = useGarageSearchForm(form);

    return (
        <Form form={form} onSubmit={onSubmit}>
            <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                <Typography variant="h1" sx={{ mb: 3, textAlign: "center" }}>
                    {labels.title}
                </Typography>

                <Box mb={4}>
                    <RepairTypeRadioGroup />
                </Box>

                <Box mb={4} fullWidth>
                    {Number(repairType) !== RepairType.HailDamage && (
                        <Box
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={{ xs: "center", sm: "flex-start" }}
                            flexDirection={{ xs: "column", sm: "row" }}
                            mb={{ xs: 0, sm: 2 }}
                        >
                            <Box maxWidth={400} mx={1} fullWidth>
                                <Field name="carMake" input="autocomplete" options={carMakes} label={labels.carMake} />
                            </Box>

                            <Box maxWidth={400} mx={1} fullWidth>
                                <Field name="makeYear" input="textField" type="number" label={labels.makeYear} />
                            </Box>
                        </Box>
                    )}

                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={{ xs: "center", sm: "flex-start" }}
                        flexDirection={{ xs: "column", sm: "row" }}
                    >
                        <Box maxWidth={400} mx={1} fullWidth>
                            <Field name="region" input="autocomplete" options={regions} label={labels.region} onChange={onRegionChange} />
                        </Box>
                        <Box maxWidth={400} mx={1} fullWidth>
                            <Field
                                name="districts"
                                input="autocomplete"
                                multiple
                                options={districts}
                                label={labels.districts}
                                disabled={!region}
                            />
                        </Box>
                    </Box>
                </Box>

                <Box mb={4}>
                    <Button variant="contained" color="blue" onClick={handleSubmit(onSubmit)}>
                        {labels.submit}
                    </Button>
                </Box>
            </Box>
        </Form>
    );
}
