import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { ModalBody, ModalFooter } from "ui-library/modules";
import { Box, ContentBox } from "ui-library/layouts";
import { Button, Loading, Typography } from "ui-library/atoms";

import i18n from "core/i18n";
import { ModalFormLayout } from "containers/modal";
import {
    createGetFormInitialValues,
    email,
    emailCharacters,
    Field,
    form,
    getFormFieldValue,
    InputText,
    max20Characters,
    max50Characters,
    phoneNumberCharacters,
    required,
    SegmentedControl,
} from "core/form";

import { FIELD_EMAIL, FIELD_ENABLED, FIELD_PHONE } from "./constants";

const ElectronicCommunicationForm = ({
    canSubmit,
    handleSubmit,
    submitting,
    electronicCommunicationEnabled,
    initialCommunicationEnabled,
}) => {
    const { t } = i18n.useTranslation();
    return (
        <>
            <ModalBody>
                <Loading loading={submitting}>
                    <ContentBox fullWidth>
                        <Typography component="p" markDown={t("form.electronicCommunication.introduction")} />
                    </ContentBox>
                    <ContentBox fullWidth>
                        <Field
                            name={FIELD_ENABLED}
                            component={SegmentedControl}
                            label={t("form.electronicCommunication.check")}
                            footNote={t("form.electronicCommunication.footNote")}
                        />
                    </ContentBox>
                    <ContentBox fullWidth>
                        <Typography component="p" markDown={t("form.electronicCommunication.text")} />
                    </ContentBox>

                    {electronicCommunicationEnabled && (
                        <>
                            <Box>
                                <ContentBox>
                                    <Field
                                        name={FIELD_PHONE}
                                        component={InputText}
                                        label={t("holder.phone")}
                                        // validated async in saga!
                                        normalize={[phoneNumberCharacters, max20Characters]}
                                        onEnter={handleSubmit}
                                    />
                                </ContentBox>
                            </Box>

                            <Box>
                                <ContentBox>
                                    <Field
                                        name={FIELD_EMAIL}
                                        component={InputText}
                                        label={t("holder.email")}
                                        validateStatic={[required, email]}
                                        normalize={[emailCharacters, max50Characters]}
                                        onEnter={handleSubmit}
                                    ></Field>
                                </ContentBox>
                            </Box>
                        </>
                    )}

                    {initialCommunicationEnabled && !electronicCommunicationEnabled && (
                        <ContentBox>
                            <Typography variant="caption" markDown={t("form.electronicCommunication.cancelCommunication")} />
                        </ContentBox>
                    )}
                </Loading>
            </ModalBody>
            <ModalFooter>
                <Button color="blue" variant="contained" onClick={handleSubmit} disabled={!canSubmit}>
                    {t("common.submitChange")}
                </Button>
            </ModalFooter>
        </>
    );
};

ElectronicCommunicationForm.propTypes = {
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    electronicCommunicationEnabled: PropTypes.bool,
    initialCommunicationEnabled: PropTypes.bool,
};

ElectronicCommunicationForm.defaultProps = {
    electronicCommunicationEnabled: false,
    initialCommunicationEnabled: false,
};

export const createContainer = (formName) =>
    form(formName, { asyncValidationFields: [FIELD_PHONE] })((props) => {
        const { t } = i18n.useTranslation();
        const electronicCommunicationEnabled = useSelector(getFormFieldValue(formName, FIELD_ENABLED));
        const initialCommunicationEnabled = useSelector(createGetFormInitialValues(formName)).get(FIELD_ENABLED);

        return (
            <ModalFormLayout formName={formName} formTitle={t("form.electronicCommunication")} formIcon="contract">
                <ElectronicCommunicationForm
                    electronicCommunicationEnabled={electronicCommunicationEnabled}
                    initialCommunicationEnabled={initialCommunicationEnabled}
                    {...props}
                />
            </ModalFormLayout>
        );
    });
