import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { resetSection } from "redux-form/immutable";
import { Set } from "immutable";

import errorHandling from "core/errorHandling";
import {
    asyncFieldValidator,
    asyncValidateFields,
    fieldChangeMatcher,
    formWrapper,
    getPathFromFieldName,
    required,
    scrollToFirstError,
} from "core/form";
import userInfo from "core/userInfo";
import { sentry } from "core/util";
import { userApi, validationApi } from "serverApi";

import { FIELD_EMAIL, FIELD_ENABLED, FIELD_PHONE } from "./constants";
import contractDataChange from "serverApi/contractDataChange";

export function* createSagaLegacy(formName, idObject, getPolicyHolderSelector, getElectronicCommunicationSelector) {
    try {
        yield call(formSaga(formName, getPolicyHolderSelector, getElectronicCommunicationSelector), idObject);
    } catch (e) {
        sentry.captureException(e);
        yield put(errorHandling.addServiceError(formName, e.identifier));
    }
}

const formSaga = (formName, getPolicyHolderSelector, getElectronicCommunicationSelector) =>
    formWrapper(formName, {
        *persistentEffects() {
            yield takeEvery(fieldChangeMatcher(formName, FIELD_ENABLED), enabledChangedSaga, formName);
        },
        *initialize() {
            yield call(userInfo.checkUserVerifiedOrOneTime);
            yield put(errorHandling.removeServiceErrors(formName));
            const [holder, electronicCommunication] = yield all([
                select(getPolicyHolderSelector),
                select(getElectronicCommunicationSelector),
            ]);

            return {
                [FIELD_ENABLED]: !!electronicCommunication.electronicCommunicationSet,
                [FIELD_EMAIL]: holder.email,
                [FIELD_PHONE]: holder.phone,
            };
        },
        *onSubmitFail() {
            yield call(scrollToFirstError);
        },
        *save(values, idObject) {
            if (values.get(FIELD_ENABLED)) {
                const requestBody = {
                    idObjects: Set([parseInt(idObject)]),
                    enabled: values.get(FIELD_ENABLED),
                    phone: values.get(FIELD_PHONE),
                    email: values.get(FIELD_EMAIL),
                };
                yield call(contractDataChange.updateContractElectronicCommunication, requestBody);
            } else {
                const requestBody = {
                    idObjects: Set([parseInt(idObject)]),
                    enabled: values.get(FIELD_ENABLED),
                };
                yield call(contractDataChange.updateContractElectronicCommunication, requestBody);
            }
        },
        *success() {
            const newUserInfo = yield call(userApi.getAuthenticatedUser, true);
            yield put(userInfo.setAuthenticatedUser(newUserInfo));
        },
        *asyncValidation(values, field) {
            const asyncFieldValidators = [];
            if (values.getIn(getPathFromFieldName(FIELD_ENABLED))) {
                asyncFieldValidators.push(asyncFieldValidator(getPathFromFieldName(FIELD_PHONE), [required, validationApi.validateMobile]));
            }
            return yield call(asyncValidateFields(...asyncFieldValidators), field, values);
        },
    });

function* enabledChangedSaga(formName) {
    yield put(resetSection(formName, FIELD_PHONE, FIELD_EMAIL));
}
