import { NAME } from "./constants";
import reducer from "./reducer";
import saga from "./saga";
import innerRoutes from "./innerRoutes";

import {
    AgentTab,
    ChangesTab,
    DetailTab,
    DocumentsTab,
    FinancialOperationsTab,
    FinancialStatementTab,
    OverviewTab,
    PolicyHolderTab,
    RedeemSavingsTab,
} from "./tabs";
import { FullPageRedeemSavingsForm } from "./form";

export { DetailPensionDpsPage } from "./Container";

export const DetailPensionDpsTabs = {
    OverviewTab,
    PolicyHolderTab,
    DetailTab,
    ChangesTab,
    FinancialOperationsTab,
    FinancialStatementTab,
    DocumentsTab,
    RedeemSavingsTab,
    AgentTab,
};

export const DetailPensionDpsForms = {
    FullPageRedeemSavingsForm,
};

const detailDps = {
    NAME,
    reducer,
    saga,
    innerRoutes,
};

export default detailDps;
