import { List } from "immutable";

import i18n from "core/i18n";
import contractOwnerPaymentsForm from "form/modal/contractOwnerPayments";
import { PaymentContribution } from "enums";

import { FORM_EDIT_INSURANCE_PAYMENTS } from "../constants";

export const InsurancePaymentsFormContainer = contractOwnerPaymentsForm.createInPageContainer(
    FORM_EDIT_INSURANCE_PAYMENTS,
    "change.title.INSURANCE_PAYMENTS",
);

export const InsurancePaymentsForm = () => {
    const { t } = i18n.useTranslation();
    const paymentOptions = List([PaymentContribution.ByBankAccount.id, PaymentContribution.ByDirectDebit.id]);

    return (
        <InsurancePaymentsFormContainer
            isUniqaContract={true}
            canEditAmount={false}
            amountLabel={t("insurance.payments.amount")}
            paymentOptionsUniqaExAxa={paymentOptions}
            displayDisclaimer
        />
    );
};
