import { useHasAnyRole, useHasRole } from "core/hooks";
import { Role } from "enums";
import { Navigate } from "react-router-dom";

type ProtectedProps = {
    /**
     * The role or roles required to access the protected content.
     * Accepts either a single Role or an array of Roles.
     * If the user has at least one of the specified roles, access is granted.
     */
    roles: Role | Role[];
    /**
     * Determines whether to redirect to a different route if access is denied.
     * If true, the user will be redirected to the route specified in `redirectTo`.
     * If false, null or fallback content is returned and no redirection occurs.
     * Default is false.
     * If `redirectTo` is specified, the `redirect` prop is ignored.
     */
    redirect?: boolean;
    /**
     * The path to redirect to if access is denied.
     * Default is "/".
     */
    redirectTo?: string;
    /**
     * The alternative content to render if access is denied and `redirect` is false.
     * This should be a single JSX element.
     */
    fallback?: JSX.Element;
    children: JSX.Element;
};

export function Protected({ roles, redirect = false, redirectTo, fallback, children }: ProtectedProps) {
    let isAllowed = false;

    if (Array.isArray(roles)) {
        isAllowed = useHasAnyRole(roles);
    } else {
        isAllowed = useHasRole(roles);
    }

    if (isAllowed) return children;

    if (redirect || redirectTo) return <Navigate to={redirectTo || "/"} />;

    return fallback || null;
}
