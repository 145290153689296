import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { change } from "redux-form/immutable";

import autocomplete from "core/autocomplete";
import coreEnum from "core/enum";
import errorHandling from "core/errorHandling";
import userInfo from "core/userInfo";
import { FORM_STEPS_BACK } from "app/constants";
import { fieldChangeMatcher, formWrapper, getFormFieldValueWithState, scrollToFirstError } from "core/form";
import { date, sentry } from "core/util";
import { SubjectType } from "enums";
import { PlaceDetail } from "model/autocomplete";
import { autocompleteApi, vehicleCancellationApi } from "serverApi";
import { ChangeStatus, SignatureType, VehicleCancellationReason } from "types";

import {
    FIELD_ATTACHMENTS,
    FIELD_CANCELLATION_DATE,
    FIELD_CANCELLATION_NOTE,
    FIELD_CANCELLATION_REASON,
    FIELD_CITY,
    FIELD_COUNTRY_CODE,
    FIELD_DATE_OF_SIGNING,
    FIELD_DESCRIPTION_NUMBER,
    FIELD_ORIENTATION_NUMBER,
    FIELD_PLACE_OF_SIGNING,
    FIELD_POSTAL_CODE,
    FIELD_SIGNATURE_FILE,
    FIELD_SIGNATURE_TYPE,
    FIELD_STREET,
    FIELD_SUBJECT_TYPE,
    FIELD_VEHICLE_REMOVED_FROM_REGISTRATION_STATEMENT,
    FIELD_VERIFY_IDENTIFICATION_NUMBER,
    FORM_STEP,
    SECTION_ADDRESS,
    SECTION_BANK_ACCOUNT,
    SECTION_CONTACT_PERSON,
    VIRTUAL_FIELD_ATTACHMENTS,
    VIRTUAL_FIELD_CANCELLATION_REASONS,
    VIRTUAL_FIELD_CHANGE_ID,
    VIRTUAL_FIELD_CHANGE_STATUS,
    VIRTUAL_FIELD_FORM_STEP,
    PERMANENT_ADDRESS_AUTOCOMPLETE,
} from "./constants";

export function* createSaga(formName: string, idObject: number) {
    try {
        yield call(formSaga(formName), idObject);
    } catch (e) {
        sentry.captureException(e);
        yield put(errorHandling.addServiceError(formName, e.identifier));
    }
}

const formSaga = (formName: string) =>
    formWrapper(formName, {
        *persistentEffects() {
            // yield takeEvery(fieldChangeMatcher(formName, VIRTUAL_FIELD_FORM_STEP), formStepChangedSaga, formName);

            yield takeEvery(fieldChangeMatcher(formName, FIELD_CANCELLATION_REASON), formCancellationReasonChangedSaga, formName);

            yield takeEvery(
                // @ts-ignore we won't type sagas
                autocomplete.selectMatcher(PERMANENT_ADDRESS_AUTOCOMPLETE),
                placeSelectedSaga,
                formName,
                `${SECTION_CONTACT_PERSON}.${SECTION_ADDRESS}`,
            );
        },
        *initialize() {
            // TODO: Get change data
            yield put(errorHandling.removeServiceErrors(formName));
            yield call(userInfo.checkUserVerifiedOrOneTime, FORM_STEPS_BACK);

            yield put(coreEnum.loadIfNeeded([coreEnum.BANK_LIST]));

            return {
                [VIRTUAL_FIELD_FORM_STEP]: FORM_STEP.ZERO_INTRODUCTION,
                [FIELD_DATE_OF_SIGNING]: date.todayAsIso,
                [FIELD_SIGNATURE_TYPE]: SignatureType.PHYSICAL,
                [SECTION_CONTACT_PERSON]: {
                    [FIELD_SUBJECT_TYPE]: SubjectType.INDIVIDUAL.id,
                },
            };
        },
        // @ts-ignore we won't type sagas
        *onSubmitFail() {
            yield call(scrollToFirstError);
        },
        *save(values, idObject) {
            const formStep = values.get(VIRTUAL_FIELD_FORM_STEP);

            if (formStep === FORM_STEP.ZERO_INTRODUCTION) {
                // Call create request.
                const createData = {
                    [FIELD_VERIFY_IDENTIFICATION_NUMBER]: values.get(FIELD_VERIFY_IDENTIFICATION_NUMBER),
                };
                const createResult = yield call(vehicleCancellationApi.createCancellation, idObject, createData);
                yield put(change(formName, VIRTUAL_FIELD_CHANGE_ID, createResult.id));

                // Download cancellation reasons.
                const cancellationReasons = yield call(vehicleCancellationApi.getCancellationReasons, idObject, createResult.id);
                yield put(change(formName, VIRTUAL_FIELD_CANCELLATION_REASONS, cancellationReasons));

                // Set form status: CREATED and form step.
                yield put(change(formName, VIRTUAL_FIELD_CHANGE_STATUS, ChangeStatus.CREATED));
                yield put(change(formName, VIRTUAL_FIELD_FORM_STEP, FORM_STEP.FIRST_EDIT));
            }

            if (formStep === FORM_STEP.FIRST_EDIT) {
                const idChange = values.get(VIRTUAL_FIELD_CHANGE_ID);
                const cancellationReason = values.get(FIELD_CANCELLATION_REASON);

                // Download list of required attachments.
                const attachments = yield call(vehicleCancellationApi.getCancellationAttachments, idObject, idChange, cancellationReason);
                yield put(change(formName, VIRTUAL_FIELD_ATTACHMENTS, attachments));

                // Set form step.
                yield put(change(formName, VIRTUAL_FIELD_FORM_STEP, FORM_STEP.SECOND_REVIEW_SUBMIT));
            }

            if (formStep === FORM_STEP.SECOND_REVIEW_SUBMIT) {
                const changeStatus = values.get(VIRTUAL_FIELD_CHANGE_STATUS);
                const idChange = values.get(VIRTUAL_FIELD_CHANGE_ID);

                if (changeStatus === ChangeStatus.CREATED) {
                    // Call submit method.
                    const data = yield call(prepareSubmitData, values);
                    yield call(vehicleCancellationApi.submitCancellation, idObject, idChange, data);

                    // Change form status: SUBMITTED.
                    yield put(change(formName, VIRTUAL_FIELD_CHANGE_STATUS, ChangeStatus.SUBMITTED));
                }

                if (changeStatus === ChangeStatus.SUBMITTED) {
                    const files = {
                        signature: values.get(FIELD_SIGNATURE_FILE),
                        attachments: values.get(FIELD_ATTACHMENTS),
                    };

                    // console.log("files", files);
                    yield call(vehicleCancellationApi.completeCancellation, idObject, idChange, files);

                    // Set form step to CONFIRMATION.
                    yield put(change(formName, VIRTUAL_FIELD_FORM_STEP, FORM_STEP.THIRD_CONFIRMATION));
                }
            }
        },
        // *asyncValidation(values, field) {
        //     return yield call(
        //         asyncValidateFields(asyncFieldValidator([SECTION_CONTACT_PERSON, FIELD_MOBILE_PHONE], [validationApi.validateMobile])),
        //         field,
        //         values,
        //     );
        // },
    });

function* placeSelectedSaga(formName, addressType, { payload }) {
    const detail = yield call(autocompleteApi.getPlaceDetail, payload);
    // @ts-ignore we won't type sagas
    const placeDetailModel = PlaceDetail.fromServer(detail);

    yield all([
        put(change(formName, `${addressType}.${FIELD_STREET}`, placeDetailModel.route)),
        put(change(formName, `${addressType}.${FIELD_DESCRIPTION_NUMBER}`, placeDetailModel.premise)),
        put(change(formName, `${addressType}.${FIELD_ORIENTATION_NUMBER}`, placeDetailModel.street_number)),
        put(change(formName, `${addressType}.${FIELD_CITY}`, placeDetailModel.locality)),
        put(change(formName, `${addressType}.${FIELD_POSTAL_CODE}`, placeDetailModel.postal_code)),
        put(change(formName, `${addressType}.${FIELD_COUNTRY_CODE}`, placeDetailModel.country)),
    ]);
}

function* formCancellationReasonChangedSaga(formName) {
    const cancellationReason = yield select(getFormFieldValueWithState, formName, FIELD_CANCELLATION_REASON);
    const allCancellationReasons = yield select(getFormFieldValueWithState, formName, VIRTUAL_FIELD_CANCELLATION_REASONS);

    const filteredReasonDefinition = allCancellationReasons
        .filter((item) => item.code === cancellationReason)
        .map((item) => item && item.defaultCancellationDate);

    const defaultCancellationDate = filteredReasonDefinition ? filteredReasonDefinition[0] : undefined;
    yield put(change(formName, FIELD_CANCELLATION_DATE, defaultCancellationDate));
}

function* prepareSubmitData(values) {
    const cancellationReason = values.get(FIELD_CANCELLATION_REASON);
    const signatureType = values.get(FIELD_SIGNATURE_TYPE);

    return {
        cancellationReason,
        cancellationDate: values.get(FIELD_CANCELLATION_DATE),
        cancellationNote: cancellationReason === VehicleCancellationReason.BY_AGREEMENT ? values.get(FIELD_CANCELLATION_NOTE) : null,
        contactPerson: cancellationReason === VehicleCancellationReason.INSURER_DEATH ? values.get(SECTION_CONTACT_PERSON) : null,
        vehicleRemovedFromRegistrationStatement:
            cancellationReason === VehicleCancellationReason.REMOVED_FROM_REGISTER
                ? values.get(FIELD_VEHICLE_REMOVED_FROM_REGISTRATION_STATEMENT)
                : null,
        fundsTransferBankAccount: values.get(SECTION_BANK_ACCOUNT),
        dateOfSigning: signatureType === SignatureType.PHYSICAL ? values.get(FIELD_DATE_OF_SIGNING) : null,
        placeOfSigning: signatureType === SignatureType.PHYSICAL ? values.get(FIELD_PLACE_OF_SIGNING) : null,
        signatureType,
    };
}

// function* formStepChangedSaga(formName) {
//     const formStep = yield select(getFormFieldValueWithState, formName, VIRTUAL_FIELD_FORM_STEP);
//     const changeId = yield select(getFormFieldValueWithState, formName, VIRTUAL_FIELD_CHANGE_ID);
//
//     switch (formStep) {
//         case FORM_STEP.ZERO_INTRODUCTION:
//             // Call create if changeId is already not generated.
//             if (!changeId) {
//             }
//
//             // Get list of cancellation reasons.
//             // const
//
//             yield put(change(formName, VIRTUAL_FIELD_FORM_STEP, FORM_STEP.SECOND_REVIEW_SUBMIT));
//             break;
//         case FORM_STEP.SECOND_REVIEW_SUBMIT:
//
//         // Handle submit
//
//         // Handle complete
//         case FORM_STEP.THIRD_COMPLETE:
//             // Finalize
//             yield put(change(formName, VIRTUAL_FIELD_FORM_STEP, FORM_STEP.SECOND_REVIEW_SUBMIT));
//             break;
//         default:
//             break;
//     }
// }
