import { Form, FormErrorBox } from "core/hookForm";
import StepSubjects from "./components/StepSubjects";
import StepSignature from "./components/StepSignature";
import { Typography } from "ui-library/atoms";
import StepDocuments from "./components/StepDocuments";
import { Box } from "ui-library/layouts";
import i18n from "core/i18n";
import useVehicleSubjectChangeForm from "./hooks/useVehicleSubjectChangeForm";
import StepConfirmation from "./components/StepConfirmation";

export default function VehicleSubjectChangeForm() {
    const { form, contractNumber, step, vehicles, nextStep, previousStep } = useVehicleSubjectChangeForm();
    const { t } = i18n.useTranslation();

    return (
        <Form form={form} onSubmit={null}>
            <Box mb={3} fullWidth>
                <Typography variant="h3">
                    {t("vehicle.change.subject.title")} {contractNumber}
                </Typography>
            </Box>

            <FormErrorBox />

            {step === 1 && <StepSubjects vehicles={vehicles} nextStep={nextStep} />}
            {step === 2 && <StepSignature nextStep={nextStep} previousStep={previousStep} />}
            {step === 3 && <StepDocuments nextStep={nextStep} />}
            {step === 4 && <StepConfirmation />}
        </Form>
    );
}
