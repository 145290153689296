import { all, call, fork, put, select } from "redux-saga/effects";

import entity from "core/entity";
import router from "core/router";
import userContracts from "core/userContracts";
import contractNameForm from "form/modal/contractName";
import electronicCommunicationForm from "form/modal/electronicCommunication";
import contractOwnerPaymentsForm from "form/modal/contractOwnerPayments";
import policyHolderForm from "form/fullPage/policyHolder";
import contractCancellationForm from "form/fullPage/contractCancellation";
import { contractsApi, documentsApi, paymentApi, vehicleApi } from "serverApi";
import { Tabs } from "routeConstants";
import { ContractChangeType } from "types";

import { clear, setDetailSelectedCoverage, setVehicleContractDetail } from "./actions";
import {
    ENTITY_CONTRACT_DOCUMENTS,
    ENTITY_CONTRACT_INCIDENTS,
    ENTITY_CONTRACT_OVERVIEW,
    ENTITY_FINANCIAL_OPERATIONS,
    ENTITY_GREEN_CARD_DATA,
    ENTITY_PAYMENT_INFORMATION,
    FORM_CONTRACT_CANCELLATION,
    FORM_EDIT_CONTRACT_NAME,
    FORM_EDIT_ELECTRONIC_COMMUNICATION,
    FORM_EDIT_INSURANCE_PAYMENTS,
    FORM_EDIT_POLICY_HOLDER,
} from "./constants";
import {
    getActualSchedulerRecord,
    getElectronicCommunication,
    getInsurancePackagesOptions,
    getInsuredVehicleWithMtpl,
    getPolicy,
    getPolicyHolder,
} from "./selectors";

export default router.routerWrapper({
    *getDataForPage({ idObject }) {
        const hasDetail = yield select(userContracts.createGetContractById(Number(idObject)));
        if (hasDetail) {
            const vehicleContractDetail = yield call(vehicleApi.getVehicleContractDetail, idObject);
            return [setVehicleContractDetail(vehicleContractDetail)];
        }
        return [];
    },
    *onPageEnter({ idObject }) {
        const hasDetail = yield select(userContracts.createGetContractById(Number(idObject)));
        if (hasDetail) {
            yield fork(entity.load, ENTITY_CONTRACT_DOCUMENTS, () => documentsApi.getContractDocuments(idObject));
            yield fork(entity.load, ENTITY_FINANCIAL_OPERATIONS, () => vehicleApi.getVehicleFinancialOperations(idObject));
            yield fork(entity.load, ENTITY_CONTRACT_INCIDENTS, () => vehicleApi.getVehicleClaims(idObject));
            const productGroup = yield select(userContracts.createGetProductGroupUrlNameById(idObject));
            yield fork(entity.load, ENTITY_CONTRACT_OVERVIEW, () => contractsApi.getContractOverview(productGroup, idObject));
            yield fork(loadPaymentInformation, idObject);
            yield fork(entity.periodicallyCheckClaims, 60 * 1000, ENTITY_CONTRACT_INCIDENTS, () => vehicleApi.getVehicleClaims(idObject));

            const coveragePackagesOptions = yield select(getInsurancePackagesOptions);
            if (coveragePackagesOptions?.size === 1) {
                yield put(setDetailSelectedCoverage(coveragePackagesOptions.get(0).value));
            }

            // As last thing - load header data for green card.
            const vehiclesWithMtpl = yield select(getInsuredVehicleWithMtpl);
            yield all([
                ...vehiclesWithMtpl.map((vehicle) =>
                    fork(entity.loadIntoMap, ENTITY_GREEN_CARD_DATA, vehicle.vinNumber, () =>
                        vehicleApi.getVehicleGreenCardHeaderData(idObject, vehicle.vinNumber),
                    ),
                ),
            ]);
        }
    },
    *onInnerRouteChange(name, params, query) {
        if (name === Tabs.CHANGES) {
            yield call(handleChangeTabFormsInitialization, query?.type, params?.idObject);
        }
    },
    clearDataForPage() {
        return [
            clear(),
            entity.clearData(
                ENTITY_CONTRACT_DOCUMENTS,
                ENTITY_FINANCIAL_OPERATIONS,
                ENTITY_CONTRACT_INCIDENTS,
                ENTITY_PAYMENT_INFORMATION,
                ENTITY_GREEN_CARD_DATA,
                ENTITY_CONTRACT_OVERVIEW,
            ),
        ];
    },
});

function* handleChangeTabFormsInitialization(type, idObject) {
    switch (type) {
        case ContractChangeType.POLICY_HOLDER:
            yield call(contractEditHolderFormSaga, idObject);
            break;
        case ContractChangeType.CONTRACT_CANCELLATION:
            yield call(contractCancellationForm.createSaga, FORM_CONTRACT_CANCELLATION, idObject);
            break;
        case ContractChangeType.INSURANCE_PAYMENTS:
            yield call(contractOwnerPaymentsForm.createSagaLegacy, FORM_EDIT_INSURANCE_PAYMENTS, idObject, getActualSchedulerRecord);
            break;
        case ContractChangeType.CONTRACT_NAME:
            yield call(contractNameForm.createSaga, FORM_EDIT_CONTRACT_NAME, idObject);
            break;
        case ContractChangeType.ELECTRONIC_COMMUNICATION:
            yield call(
                electronicCommunicationForm.createSaga,
                FORM_EDIT_ELECTRONIC_COMMUNICATION,
                idObject,
                getPolicyHolder,
                getElectronicCommunication,
            );
            break;
        default:
            break;
    }
}

function* loadPaymentInformation(idObject) {
    const productGroup = yield select(userContracts.createGetProductGroupUrlNameById(idObject));
    yield call(entity.load, ENTITY_PAYMENT_INFORMATION, () => paymentApi.get(productGroup, idObject));
}

const contractEditHolderFormSaga = policyHolderForm.createSagaLegacy(FORM_EDIT_POLICY_HOLDER, getPolicyHolder, getPolicy);
