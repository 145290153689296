import clientStatementSettingsForm from "form/modal/clientStatementSettings";

import { FORM_EDIT_CLIENT_STATEMENT_SETTINGS, INCLUDE_BUY_SELL_CONFIRMATION } from "../constants";
import { isElectronicCommunicationSet } from "../selectors";

export const ClientStatementSettingsForm = clientStatementSettingsForm.createInPageContainer(
    FORM_EDIT_CLIENT_STATEMENT_SETTINGS,
    isElectronicCommunicationSet,
    INCLUDE_BUY_SELL_CONFIRMATION,
);
