import { Modal, ModalBody, ModalFooter, ModalHeader } from "ui-library/modules";
import i18n from "core/i18n";
import { Field } from "core/hookForm";
import { Button } from "ui-library/atoms";

export default function FileUploadModal({ open, onClose }: { open: boolean; onClose: () => void }) {
    const { t } = i18n.useTranslation();

    return (
        <Modal size="small" open={open} onClose={onClose}>
            <ModalHeader icon="document-upload" title={t("vehicle.change.subject.documents.attachmentUpload")} closeAction={onClose} />
            <ModalBody>
                <Field name="signature.uploadedFiles" input="file" />
            </ModalBody>
            <ModalFooter>
                <Button variant="contained" color="primary" onClick={onClose}>
                    {t("common.close")}
                </Button>
            </ModalFooter>
        </Modal>
    );
}
