import { Field } from "core/hookForm";
import { Button, Loading, Typography } from "ui-library/atoms";
import { Radio } from "ui-library/atoms/Inputs/Radio";
import { SignatureType } from "../types/VehicleSubjectChangeFormValues";
import { Box, Flexbox, GridItem, GridLayout } from "ui-library/layouts";
import i18n from "core/i18n";
import pageContext from "core/pageContext";
import { FeatureFlag } from "types";
import useStepSignature from "../hooks/useStepSignature";

export default function StepSignature({ nextStep, previousStep }: { nextStep: () => void; previousStep: () => void }) {
    const { t } = i18n.useTranslation();
    const { editOperator, editOwner, validityStartDate, signature, submit, isSubmitting } = useStepSignature(nextStep);
    const isDigisignEnabled = pageContext.useIsFeatureFlagEnabled(FeatureFlag.ENABLE_DIGISIGN);

    return (
        <>
            <Box>
                <Typography variant="h3">{t("vehicle.change.subject.signature.changelist")}</Typography>
                {editOperator && (
                    <Box>
                        {t("vehicle.change.subject.signature.operatorChange")} {validityStartDate?.toLocaleDateString()}
                    </Box>
                )}
                {editOwner && (
                    <Box>
                        {t("vehicle.change.subject.signature.ownerChange")} {validityStartDate?.toLocaleDateString()}
                    </Box>
                )}
            </Box>

            <Box mt={6} textAlign={"center"}>
                <Typography variant="h3">{isDigisignEnabled ? t("change.signature.type.title") : t("change.signature.title")}</Typography>
            </Box>

            {isDigisignEnabled && (
                <Field input="radioGroup" name="signature.type" direction="row">
                    <Radio
                        id={SignatureType.PHYSICAL}
                        value={SignatureType.PHYSICAL}
                        label={t("vehicle.change.subject.signature.type.physical")}
                    />
                    <Radio
                        id={SignatureType.ELECTRONIC}
                        value={SignatureType.ELECTRONIC}
                        label={t("vehicle.change.subject.signature.type.digital")}
                    />
                </Field>
            )}

            <Box mt={2} mb={6}>
                {signature.type === SignatureType.PHYSICAL && (
                    <GridLayout spacing={2}>
                        <GridItem xs={6}>
                            <Field input="date" name="signature.date" label={t("vehicle.change.subject.signature.date")} />
                        </GridItem>
                        <GridItem xs={6}>
                            <Field input="textField" name="signature.place" label={t("vehicle.change.subject.signature.place")} />
                        </GridItem>
                    </GridLayout>
                )}
            </Box>

            <Flexbox justifyContent="space-between" gap={2}>
                <Button variant="outlined" color="blue" onClick={previousStep}>
                    {t("common.back")}
                </Button>
                <Button variant="contained" color="blue" onClick={submit}>
                    {isSubmitting ? <Loading loading={true} /> : t("common.continue")}
                </Button>
            </Flexbox>
        </>
    );
}
