import { createSaga } from "./createSaga";
import { createContainer } from "./createContainer";
import { FORM_CHOOSE_CONTRACT_FOR_CHANGE } from "./constants";

/**
 * Saga and Container creator for Contract name form
 */
const chooseContractForChangeModal = {
    createSaga,
    createContainer,
    FORM_CHOOSE_CONTRACT_FOR_CHANGE,
};
export default chooseContractForChangeModal;
