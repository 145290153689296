import { FETCHING_AGENTS, NAME } from "./constants";
import reducer from "./reducer";
import saga from "./saga";

import { acceptingGdprActionGroup, expandMessage, revokingGdprActionGroup, setAuthenticatedUser } from "./actions";
import {
    createGetAgentByObjectId,
    getAgents,
    getAuthenticatedUser,
    getClient,
    getConsents,
    getCurrentDisclaimerAccepted,
    getGdprConsentDate,
    getGdprText,
    getGdprUrl,
    getIsVerifiedUser,
    getMessage,
    getMessages,
    getPhone,
    getUnreadMessageCount,
    getVerificationConfirmDate,
    getVerificationDenyReason,
    getVerificationDenyReasonComment,
    getVerificationRequestDate,
    hasGdprConsent,
    isClientPerson,
} from "./selectors";
import {
    checkUserVerified,
    checkUserVerifiedOrOneTime,
    gdprFormSaga,
    loadAgents,
    loadAuthenticatedUser,
    loadCriticalUserInfo,
    loadGdpr,
    loadMessages,
    verify,
} from "./util";

export default {
    NAME,
    FETCHING_AGENTS,
    acceptingGdprActionGroup,
    revokingGdprActionGroup,
    getAgents,
    createGetAgentByObjectId,
    gdprFormSaga,
    getAuthenticatedUser,
    getClient,
    getConsents,
    getCurrentDisclaimerAccepted,
    getIsVerifiedUser,
    getVerificationRequestDate,
    getVerificationConfirmDate,
    getVerificationDenyReason,
    getVerificationDenyReasonComment,
    getGdprConsentDate,
    getGdprText,
    getGdprUrl,
    getPhone,
    getUnreadMessageCount,
    getMessages,
    getMessage,
    expandMessage,
    hasGdprConsent,
    isClientPerson,
    loadAuthenticatedUser,
    loadAgents,
    loadGdpr,
    loadMessages,
    loadCriticalUserInfo,
    checkUserVerified,
    checkUserVerifiedOrOneTime,
    reducer,
    saga,
    setAuthenticatedUser,
    verify,
};
