import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MenuWrapper } from "ui-library/Styled/Modules/Header";

import i18n from "core/i18n";
import chooseContractForChangeModal from "core/chooseContractForChangeModal";
import modal from "core/modal";
import userInfo from "core/userInfo";
import { AppMenuLink } from "containers/link";
import { useHasRole } from "core/hooks";
import { Roles } from "enums";
import { Pages, Tabs } from "routeConstants";

import { UserDropdownWithLang } from "../dropdown";
import LoggedOutButtons from "./LoggedOutButtons";

export interface LoggedInMenuProps {
    isMenuOpened: boolean;
}

export const LoggedInMenu: FC<LoggedInMenuProps> = ({ isMenuOpened }) => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    const unreadMessageCount = useSelector(userInfo.getUnreadMessageCount);
    const unreadMessageCountString = unreadMessageCount > 1 ? `${unreadMessageCount}` : null;
    const isDisclaimerAccepted = useSelector(userInfo.getCurrentDisclaimerAccepted);

    const isAuthenticated = useHasRole(Roles.AUTHENTICATED);

    const handleChangesClicked = (e: any) => {
        e.preventDefault();
        dispatch(modal.openModalForm(chooseContractForChangeModal.FORM_CHOOSE_CONTRACT_FOR_CHANGE));
    };

    const MainMenu = () => (
        <MenuWrapper order={{ xs: 1, md: 0 }} className={isMenuOpened ? "opened" : ""}>
            <AppMenuLink page={Pages.CONTRACTS} title={t("menu.contracts")} enabledFor={[Roles.AUTHENTICATED]} />
            <AppMenuLink page={Pages.ONLINE_PAYMENT} tab={Tabs.PAYMENTS_PAY} title={t("menu.payment")} enabledFor={[Roles.AUTHENTICATED]} />
            <AppMenuLink
                page={Pages.MESSAGES}
                tab={Tabs.ANNOUNCEMENTS_TAB}
                title={t("menu.messages")}
                enabledFor={[Roles.AUTHENTICATED]}
                notificationCount={unreadMessageCountString}
            />
            <AppMenuLink
                page={Pages.USE_CASE}
                title={t("menu.changes")}
                enabledFor={[Roles.AUTHENTICATED]}
                onClick={handleChangesClicked}
            />
            <AppMenuLink page={Pages.CLAIMS} tab={Tabs.REPORT_CLAIM} title={t("menu.claims")} enabledFor={[Roles.AUTHENTICATED]} />
            <AppMenuLink page={Pages.CONTACT} title={t("menu.contact")} enabledFor={[Roles.AUTHENTICATED]} />
        </MenuWrapper>
    );

    return (
        <>
            {isDisclaimerAccepted && <MainMenu />}
            {isAuthenticated ? <UserDropdownWithLang /> : <LoggedOutButtons />}
        </>
    );
};
