import { getVehicles } from "app/pages/detailVehicle/selectors";
import { Option } from "core/hookForm/components/Field";
import { useHasRole } from "core/hooks";
import userContracts from "core/userContracts";
import { Roles } from "enums";
import { useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { SignatureType, VehicleSubjectChangeFormValues } from "../types/VehicleSubjectChangeFormValues";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import getValidationSchema from "../validationSchema";
import { useEffect } from "react";

export default function useVehicleSubjectChangeForm() {
    const { idObject } = useParams();
    const isAuthenticated = useHasRole(Roles.AUTHENTICATED);
    const [searchParams, _] = useSearchParams();
    const initialStep = searchParams.get("step");
    const initialIdChange = searchParams.get("idChange");
    const initialSignatureType = searchParams.get("signatureType");
    const { contractNumber } = userContracts.useGetContractByUrlParam();
    const contractVehicles = useSelector(getVehicles);
    const vehicles = getVehicleOptions(contractVehicles);
    const initialVehicle = vehicles.length === 1 ? vehicles[0] : null;

    const initialValues: VehicleSubjectChangeFormValues = {
        step: initialStep && initialIdChange ? Number(initialStep) : 1,
        idObject: Number(idObject),
        idChange: initialIdChange ?? null,
        validityStartDate: new Date(),
        vinNumber: isAuthenticated ? (initialVehicle.code as string) : null,
        licencePlateNumber: null,
        editOwner: false,
        editOperator: false,
        operator: {
            legalEntity: 1,
            address: {
                street: "",
                descriptiveNo: "",
                orientationNo: "",
                city: "",
                zipCode: "",
                country: null,
            },
        },
        owner: {
            legalEntity: 1,
            address: {
                street: "",
                descriptiveNo: "",
                orientationNo: "",
                city: "",
                zipCode: "",
                country: null,
            },
        },
        signature: {
            type: initialSignatureType ?? SignatureType.PHYSICAL,
            date: new Date(),
            place: "",
            uploadedFiles: [],
        },
    };

    const validationSchema = getValidationSchema();

    const form = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema),
    });

    const { watch, setValue } = form;
    const step = watch().step;
    const vinNumber = watch().vinNumber;

    useEffect(() => {
        const fillSubjectsInfo = () => {
            const vehicle = contractVehicles.find((v) => v.vinNumber === vinNumber);
            const owner = vehicle?.vehicleOwners.first();
            const operator = vehicle?.vehicleOperators.first();

            if (owner) {
                const ownerInfo = getSubjectInfo(owner);
                setValue("owner", ownerInfo);
            }

            if (operator) {
                const operatorInfo = getSubjectInfo(operator);
                setValue("operator", operatorInfo);
            }
        };

        if (isAuthenticated && vinNumber) fillSubjectsInfo();
    }, [vinNumber]);

    function nextStep() {
        setValue("step", step + 1);
    }

    function previousStep() {
        setValue("step", step - 1);
    }

    return {
        form,
        contractNumber,
        step,
        vehicles,
        nextStep,
        previousStep,
    };
}

function getVehicleOptions(contractVehicles) {
    const vehicles: Option[] = [];
    contractVehicles.forEach((vehicle) => {
        const v = {
            code: vehicle.vinNumber,
            value: `${vehicle.manufacturer || ""} ${vehicle.model || ""}`,
        };
        vehicles.push(v);
    });

    return vehicles;
}

function getSubjectInfo(subject) {
    const address = subject.addresses.first();

    return {
        legalEntity: subject.isPerson ? 1 : 2,
        person: subject.isPerson
            ? {
                  birthNumber: subject.birthNumber,
                  firstName: subject.firstName,
                  lastName: subject.lastName,
                  phone: subject.phone,
                  email: subject.email,
              }
            : null,
        organisation: !subject.isPerson
            ? {
                  organisationId: subject.organisationId,
                  name: subject.companyName,
              }
            : null,
        address: address
            ? {
                  street: address.street,
                  descriptiveNo: address.descriptionNumber,
                  orientationNo: address.orientationNumber,
                  city: address.city,
                  zipCode: address.postalCode,
                  country: address.countryCode,
              }
            : null,
    };
}
