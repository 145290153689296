import { combineReducers } from "redux-immutable";

import { app } from "core/util";

import {
    CLEAR,
    SET_DETAIL_SELECTED_COVERAGE,
    SET_IS_FORM_LOADING,
    SET_IS_LICENCE_PLATE_FILL_IN_LOADING,
    SET_IS_SUBMITTING,
    SET_IS_VEHICLE_LICENCE_FILL_IN_LOADING,
    SET_STEP,
    SET_VEHICLE_CONTRACT_DETAIL,
} from "./actions";
import { HarmonizedContract } from "model/harmonized";

export default combineReducers({
    data: combineReducers({
        vehicleContractDetail: app.createDataReducer(SET_VEHICLE_CONTRACT_DETAIL, HarmonizedContract.fromServer(), CLEAR),
    }),
    appState: combineReducers({
        detailSelectedCoverage: app.createDataReducer(SET_DETAIL_SELECTED_COVERAGE, "", CLEAR),
        changes: combineReducers({
            vehicleDataChange: combineReducers({
                step: app.createDataReducer(SET_STEP, 1, CLEAR),
                isFormLoading: app.createDataReducer(SET_IS_FORM_LOADING, true, CLEAR),
                isSubmitting: app.createDataReducer(SET_IS_SUBMITTING, false, CLEAR),
                isLicencePlateFillInLoading: app.createDataReducer(SET_IS_LICENCE_PLATE_FILL_IN_LOADING, false, CLEAR),
                isVehicleLicenceFillInLoading: app.createDataReducer(SET_IS_VEHICLE_LICENCE_FILL_IN_LOADING, false, CLEAR),
            }),
        }),
    }),
});
