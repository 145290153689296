import { DataTable } from "ui-library/modules";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Box } from "ui-library/layouts";
import GaragesMap from "./GaragesMap";
import ModalGarageDetail from "./ModalGarageDetail";
import { useState } from "react";
import { Button } from "ui-library/atoms";
import i18n from "core/i18n";
import { Garage } from "types/garages";

export default function GarageResults({ garages }: { garages: Garage[] }) {
    const { t } = i18n.useTranslation();
    const [selectedGarage, setSelectedGarage] = useState<Garage>(null);

    const columns: GridColDef<typeof rows[number]>[] = [
        {
            field: "name",
            headerName: t("garage.table.name"),
            flex: 2,
        },
        {
            field: "company",
            headerName: t("garage.table.company"),
            flex: 2,
        },
        {
            field: "city",
            headerName: t("garage.table.city"),
            flex: 1,
        },
        {
            field: "vehicleBrands",
            headerName: t("garage.table.vehicleBrands"),
            flex: 2,
        },
        {
            field: "repairShopTypes",
            headerName: t("garage.table.repairShopTypes"),
            flex: 2,
        },
        {
            field: "info",
            headerName: "Info",
            flex: 0.5,
            // TODO: Change to info icon button
            renderCell: (params) => (
                <Button variant="outlined" onClick={() => setSelectedGarage(garages.find((g) => g.id === params.id))}>
                    Info
                </Button>
            ),
        },
    ];

    const rows = garages.map((garage) => ({
        id: garage.id,
        name: garage.shopName,
        company: garage.companyName,
        city: garage.city,
        vehicleBrands: garage.vehicleBrands?.map((brand) => brand.value).join(", "),
        repairShopTypes: garage.repairShopTypes.join(", "),
    }));

    function onClose() {
        setSelectedGarage(null);
    }

    return (
        <Box>
            <DataTable columns={columns} rows={rows} rowHeight={60} />

            <Box fullWidth height={450} mt={4}>
                <GaragesMap garages={garages} setSelectedGarage={setSelectedGarage} />
            </Box>

            {selectedGarage && <ModalGarageDetail garage={selectedGarage} onClose={onClose} />}
        </Box>
    );
}
