import { useFormContext } from "react-hook-form";
import { TextFieldProps } from "ui-library/atoms/Inputs/TextField";
import { TextField as NoRefTextField } from "ui-library/atoms";
import { forwardRef } from "react";
import { Autocomplete } from "@mui/material";
import { Option } from "../Field";

type HookFormAutocompleteProps = {
    name: string;
    disabled?: boolean;
    options?: Option[];
    label?: string;
    multiple?: boolean;
    onChange?: (value: Option | Option[] | null) => void;
};

const TextField = forwardRef((props: TextFieldProps, ref) => <NoRefTextField {...props} reference={ref} />);

const getOptionLabel = (option: Option) => option.value;
const isOptionEqualToValue = (option: Option, value: Option) => option.code === value.code;

export default function HookFormAutocomplete({ name, options = [], multiple, onChange, ...props }: HookFormAutocompleteProps) {
    const {
        register,
        setValue,
        clearErrors,
        watch,
        formState: { errors },
    } = useFormContext();

    const handleChange = (multiple: boolean) => (_: any, value: any) => {
        const newValue = multiple ? value.map((v: Option) => v.code) : value?.code ?? null;
        setValue(name, newValue, {
            shouldValidate: true,
            shouldDirty: true,
        });
        clearErrors(name);
        if (onChange) {
            onChange(value);
        }
    };

    const error = name.split(".").reduce((acc, part) => acc?.[part], errors);

    const renderTextField = (params: any) => (
        <TextField {...params} error={Boolean(error)} errorContent={error?.message.toString()} label={props.label} />
    );

    const commonProps = {
        options,
        getOptionLabel,
        isOptionEqualToValue,
        renderInput: renderTextField,
        onChange: handleChange(!!multiple),
        disabled: props.disabled,
    };

    if (multiple) {
        const valueCodes = watch(name) || [];
        const values = options.filter((o) => valueCodes.includes(o.code));

        return <Autocomplete multiple value={values} {...commonProps} />;
    } else {
        return <Autocomplete {...register(name)} {...commonProps} />;
    }
}
