import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import investImage from "ui-library/assets/images/invest-pozadie.svg";
import { Container } from "ui-library/atoms";
import { SubPageWrapper } from "ui-library/layouts";
import { Sidemenu } from "ui-library/modules";

import i18n from "core/i18n";
import fetching from "core/fetching";
import userContracts from "core/userContracts";
import { ContractHeader, ProductDetailPageLayout } from "containers/contract";
import { AppSidemenuLink } from "containers/link";
import { PageBoundary } from "containers/PageBoundary";
import { localAmountWithCurrency } from "core/formatters";
import { Roles } from "enums";
import { Tabs } from "routeConstants";

import { ENTITY_FINANCIAL_STATEMENT } from "./constants";
import { getFinancialStatementEntity } from "./selectors";

export const DetailMutualFundsRegularPage: FC = () => {
    const { t } = i18n.useTranslation();
    const policy = userContracts.useGetContractByUrlParam();

    const statement = useSelector(getFinancialStatementEntity);
    const isLoading = useSelector(fetching.isFetching(ENTITY_FINANCIAL_STATEMENT, true));
    const lobData = statement.get("actualValueOfInvestments") && {
        label: t("account.state"),
        value: localAmountWithCurrency(statement.get("actualValueOfInvestments")),
    };

    return (
        <ProductDetailPageLayout>
            <Container>
                <ContractHeader backgroundImage={investImage} policy={policy} lobData={lobData} loading={isLoading} />

                <PageBoundary>
                    <SubPageWrapper>
                        <Sidemenu>
                            <AppSidemenuLink tab={Tabs.OVERVIEW} title={t("menu.tab.overview")} enabledFor={[Roles.AUTHENTICATED]} />
                            <AppSidemenuLink
                                tab={Tabs.HOLDER}
                                title={t("menu.tab.holder.shareholder")}
                                enabledFor={[Roles.AUTHENTICATED]}
                            />
                            <AppSidemenuLink tab={Tabs.PORTFOLIO} title={t("menu.tab.portfolio")} enabledFor={[Roles.AUTHENTICATED]} />
                            <AppSidemenuLink tab={Tabs.CHANGES} title={t("menu.tab.changes")} />
                            <AppSidemenuLink
                                tab={Tabs.FINANCIAL_OPERATIONS}
                                title={t("menu.tab.financialOperations")}
                                enabledFor={[Roles.AUTHENTICATED]}
                            />
                            <AppSidemenuLink
                                tab={Tabs.FINANCIAL_STATEMENT}
                                title={t("menu.tab.statementsAndPayments")}
                                enabledFor={[Roles.AUTHENTICATED]}
                            />
                            <AppSidemenuLink tab={Tabs.DOCUMENTS} title={t("menu.tab.documents")} enabledFor={[Roles.AUTHENTICATED]} />
                            <AppSidemenuLink tab={Tabs.AGENT} title={t("menu.tab.agent")} enabledFor={[Roles.AUTHENTICATED]} />
                        </Sidemenu>

                        <Outlet />
                    </SubPageWrapper>
                </PageBoundary>
            </Container>
        </ProductDetailPageLayout>
    );
};
