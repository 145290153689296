import React, { FC } from "react";
import { GridLayout } from "ui-library/layouts";

import { ContractDataGridItem, ContributionsGridItem, PensionPpBeneficiariesGridItem, PensionTypesGridItem } from "../containers";

export const DetailTab: FC = () => (
    <GridLayout defaultSpacing>
        <ContractDataGridItem />

        <PensionTypesGridItem mdSize={6} />

        <ContributionsGridItem />

        <PensionPpBeneficiariesGridItem />
    </GridLayout>
);
