import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "ui-library/atoms";

import pageContext from "core/pageContext";
import { useChangeLanguage } from "core/hooks";

import { Locale } from "types";

const getLocaleSwitchChoice = (availableLocales: Locale[], activeLocale: Locale): Locale =>
    availableLocales?.filter((locale) => locale !== activeLocale).shift();

export const ChangeLanguageButton: FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const changeLanguage = useChangeLanguage(dispatch, navigate, location);

    const { locale: activeLocale, availableLocales } = pageContext.useGetPageLocalization();

    const localSwitchChoice: Locale = getLocaleSwitchChoice(availableLocales, activeLocale);

    return null;

    // return (
    //     <Button variant="text" color="blue" onClick={() => changeLanguage(localSwitchChoice)}>
    //         {localSwitchChoice?.languageCodeUpperCase}
    //     </Button>
    // );
};
