import {
    emailCharacters,
    lettersNumbersBasicInterpunctionCharacters,
    max10Characters,
    max20Characters,
    max50Characters,
    max6Characters,
    numberCharacters,
    numberSpaceCharacters,
    phoneNumberCharacters,
} from "core/form";
import { Field } from "core/hookForm";
import { Option } from "core/hookForm/components/Field";
import { getLocalizedCountryOptions } from "core/localized";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { Typography } from "ui-library/atoms";
import { GridItem, GridLayout } from "ui-library/layouts";
import i18n from "core/i18n";
import useSubjectForm from "../hooks/useSubjectForm";
import { useHasRole } from "core/hooks";
import { Roles } from "enums";
import AddressAutocomplete from "./AddressAutocomplete";

type Props = {
    subject?: string;
};

export default function SubjectForm({ subject }: Props) {
    const { fieldPrefix, legalEntity, legalEntityOptions } = useSubjectForm(subject);
    const { t } = i18n.useTranslation();
    const isAuthenticated = useHasRole(Roles.AUTHENTICATED);

    return (
        <>
            <GridLayout spacing={2}>
                <GridItem xs={6}>
                    <Field
                        input="select"
                        name={fieldPrefix + "legalEntity"}
                        label={t("vehicle.change.subject.legalEntity.label")}
                        disabled={isAuthenticated}
                        options={legalEntityOptions}
                        placeholder={t("vehicle.change.subject.legalEntity.placeholder")}
                    />
                </GridItem>

                {legalEntity === 1 && <PersonInfo fieldPrefix={fieldPrefix} />}
                {legalEntity === 2 && <OrganisationInfo fieldPrefix={fieldPrefix} />}

                {legalEntity && <Address fieldPrefix={fieldPrefix} />}
            </GridLayout>
        </>
    );
}

function PersonInfo({ fieldPrefix, disabled }: { fieldPrefix: string; disabled?: boolean }) {
    const { t } = i18n.useTranslation();
    const isAuthenticated = useHasRole(Roles.AUTHENTICATED);

    return (
        <>
            <GridItem xs={6}>
                <Field
                    input="textField"
                    name={fieldPrefix + "person.birthNumber"}
                    label={t("vehicle.change.subject.person.birthNumber")}
                    disabled={disabled || isAuthenticated}
                    normalize={[numberCharacters, max10Characters]}
                />
            </GridItem>
            <GridItem xs={6}>
                <Field
                    input="textField"
                    name={fieldPrefix + "person.firstName"}
                    label={t("vehicle.change.subject.person.firstName")}
                    disabled={disabled}
                    normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                />
            </GridItem>
            <GridItem xs={6}>
                <Field
                    input="textField"
                    name={fieldPrefix + "person.lastName"}
                    label={t("vehicle.change.subject.person.lastName")}
                    disabled={disabled}
                    normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                />
            </GridItem>
            <GridItem xs={6}>
                <Field
                    input="textField"
                    name={fieldPrefix + "person.phone"}
                    label={t("vehicle.change.subject.person.phone")}
                    disabled={disabled}
                    normalize={[phoneNumberCharacters, max20Characters]}
                />
            </GridItem>
            <GridItem xs={6}>
                <Field
                    input="textField"
                    name={fieldPrefix + "person.email"}
                    label={t("vehicle.change.subject.person.email")}
                    disabled={disabled}
                    normalize={[emailCharacters, max50Characters]}
                />
            </GridItem>
        </>
    );
}

function OrganisationInfo({ fieldPrefix, disabled }: { fieldPrefix: string; disabled?: boolean }) {
    const { t } = i18n.useTranslation();
    const isAuthenticated = useHasRole(Roles.AUTHENTICATED);

    return (
        <>
            <GridItem xs={6}>
                <Field
                    input="textField"
                    name={fieldPrefix + "organisation.organisationId"}
                    label={t("vehicle.change.subject.organisation.organisationId")}
                    disabled={disabled || isAuthenticated}
                />
            </GridItem>
            <GridItem xs={12}>
                <Field
                    input="textField"
                    name={fieldPrefix + "organisation.name"}
                    label={t("vehicle.change.subject.organisation.name")}
                    disabled={disabled}
                    normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                />
            </GridItem>
        </>
    );
}

function Address({ fieldPrefix, disabled }: { fieldPrefix: string; disabled?: boolean }) {
    const { t } = i18n.useTranslation();
    const { watch } = useFormContext();
    const { country } = watch(fieldPrefix + "address");
    const countries = useSelector(getLocalizedCountryOptions);
    const countryOptions: Option[] = countries.map((c) => ({
        code: c.get("value"),
        value: c.get("label"),
    }));

    const isDomesticCountry = (country) => country === "CZ" || country === "SK";

    return (
        <>
            <GridItem xs={12} mt={4} textAlign={"center"}>
                <Typography variant="h3">{t("vehicle.change.subject.address.title")}</Typography>
            </GridItem>
            <GridItem xs={12}>
                <AddressAutocomplete name={fieldPrefix + "address"} label={t("vehicle.change.subject.address.street")} />
            </GridItem>
            <GridItem xs={6}>
                <Field
                    input="textField"
                    name={fieldPrefix + "address.descriptiveNo"}
                    label={t("vehicle.change.subject.address.descriptiveNo")}
                    disabled={disabled}
                    normalize={[lettersNumbersBasicInterpunctionCharacters, max20Characters]}
                />
            </GridItem>
            <GridItem xs={6}>
                <Field
                    input="textField"
                    name={fieldPrefix + "address.orientationNo"}
                    label={t("vehicle.change.subject.address.orientationNo")}
                    disabled={disabled}
                    normalize={[lettersNumbersBasicInterpunctionCharacters, max20Characters]}
                />
            </GridItem>
            <GridItem xs={9}>
                <Field
                    input="textField"
                    name={fieldPrefix + "address.city"}
                    label={t("vehicle.change.subject.address.city")}
                    disabled={disabled}
                    normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                />
            </GridItem>
            <GridItem xs={3}>
                <Field
                    input="textField"
                    name={fieldPrefix + "address.zipCode"}
                    label={t("vehicle.change.subject.address.zipCode")}
                    disabled={disabled}
                    normalize={[
                        isDomesticCountry(country) ? numberSpaceCharacters : lettersNumbersBasicInterpunctionCharacters,
                        isDomesticCountry(country) ? max6Characters : max20Characters,
                    ]}
                />
            </GridItem>
            <GridItem xs={12}>
                <Field
                    input="select"
                    name={fieldPrefix + "address.country"}
                    label={t("vehicle.change.subject.address.country.label")}
                    placeholder={t("vehicle.change.subject.address.country.placeholder")}
                    disabled={disabled}
                    options={countryOptions}
                />
            </GridItem>
        </>
    );
}
