import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Loading } from "ui-library/atoms";
import { ContentBox, Flexbox } from "ui-library/layouts";
import { LabelTextItem, PageLead } from "ui-library/modules";

import i18n from "core/i18n";
import userContracts from "core/userContracts";
import products from "core/products";
import { dateTimeToLocalFullDate } from "core/formatters";
import { useHasRole } from "core/hooks";
import { Roles } from "enums";
import { Pages } from "routeConstants";
import { pageRoutes } from "routeUrls";
import { ContractPolicy } from "types";

export interface ContractHeaderProps {
    backgroundImage: any;
    policy?: ContractPolicy;
    loading?: boolean;
    lobData?: {
        label: string;
        value: string;
    };
}

export const ContractHeader: FC<ContractHeaderProps> = ({ backgroundImage, policy, lobData, loading }) => {
    const { t } = i18n.useTranslation();
    const { isInFutureOrTerminated } = userContracts.useGetContractDerivedData(policy);
    const productDefinition = products.useGetProductDefinition(policy?.idEnuProductGroupLevelTwo);
    const navigate = useNavigate();
    const isAuthenticated = useHasRole(Roles.AUTHENTICATED);

    const contractNumberWithDatesInfo =
        policy?.contractNumber &&
        t("contract.text.contractWithNumberSignedOn", {
            contractNumber: policy?.contractNumber,
            beginDate: dateTimeToLocalFullDate(policy?.beginDate),
        });

    const label = isInFutureOrTerminated ? t("common.endDate") : lobData?.label;
    const value = isInFutureOrTerminated ? dateTimeToLocalFullDate(policy.endDate) : lobData?.value;

    return (
        <PageLead
            leadImg={backgroundImage}
            leadImgDesatured={isInFutureOrTerminated}
            title={t(productDefinition.msg)}
            lead={policy?.productCRM}
            chip={isAuthenticated && (policy?.contractName || t("general.text.customContractNameInfo"))}
        >
            <ContentBox bigger>
                {lobData && (
                    <Loading loading={loading}>
                        <LabelTextItem
                            fontVariant="perex"
                            label={t("common.contractNumber")}
                            text={isAuthenticated ? contractNumberWithDatesInfo : policy?.contractNumber}
                        />
                        {isAuthenticated && <LabelTextItem fontVariant="perex" label={label} text={value} />}
                    </Loading>
                )}
            </ContentBox>

            {isAuthenticated && (
                <Flexbox mb={{ xs: 2, md: 0 }} gap={1}>
                    <Button color="blue" variant="contained" onClick={() => navigate(pageRoutes[Pages.CONTRACTS])}>
                        {t("common.backToContractsList")}
                    </Button>
                </Flexbox>
            )}
        </PageLead>
    );
};
