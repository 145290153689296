import React, { FC } from "react";
import { Typography } from "ui-library/atoms";

import { ContentBox } from "ui-library/layouts";

import i18n from "core/i18n";
import { Field, required, SegmentedControl } from "core/form";

import { FIELD_VEHICLE_REMOVED_FROM_REGISTRATION_STATEMENT } from "../../constants";

export const RemovedFromRegisterationSection: FC = () => {
    const { t } = i18n.useTranslation();

    return (
        <>
            <ContentBox fullWidth>
                <Typography variant="h4" markDown={t("vehicle.change.contractCancellation.vehicleRemovedFromRegistrationStatementTitle")} />
            </ContentBox>
            <ContentBox fullWidth>
                <Field
                    name={FIELD_VEHICLE_REMOVED_FROM_REGISTRATION_STATEMENT}
                    label={t("vehicle.change.contractCancellation.vehicleRemovedFromRegistrationStatement")}
                    component={SegmentedControl}
                    validateStatic={[required]}
                />
            </ContentBox>
        </>
    );
};
