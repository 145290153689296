import React, { FC } from "react";

import i18n from "core/i18n";
import { ContractChangesTab } from "containers/contract";
import { useChangeTypeGetParamForm, useGetUserChangeTypes } from "core/hooks";
import { ContractChangeType } from "types";

import { ContractNameForm, ElectronicCommunicationForm, InsurancePaymentsForm, PolicyHolderForm } from "../form";
import { changesDefinition } from "../changesDefinition";

export const ChangesTab: FC = () => {
    const { t } = i18n.useTranslation();
    const { form, changeType, onChange } = useChangeTypeGetParamForm();
    const changeOptions = useGetUserChangeTypes(changesDefinition(t));

    return (
        <ContractChangesTab form={form} changeOptions={changeOptions} onChange={onChange}>
            {changeType === ContractChangeType.CONTRACT_NAME && <ContractNameForm />}
            {changeType === ContractChangeType.ELECTRONIC_COMMUNICATION && <ElectronicCommunicationForm />}
            {changeType === ContractChangeType.POLICY_HOLDER && <PolicyHolderForm />}
            {changeType === ContractChangeType.INSURANCE_PAYMENTS && <InsurancePaymentsForm />}
        </ContractChangesTab>
    );
};
