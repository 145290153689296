import { model } from "core/util";
import { ContractDto, ContractPolicy } from "types";

export const SubjectObject = model.createModel<ContractDto, ContractPolicy>((json) => ({
    idObject: json.idObject,

    contractNumber: json.contractNumber,
    contractName: json.contractName,

    isUniqaContract: json.isUniqaContract,
    isActive: json.isActive,
    countryCode: json.countryCode,
    hasContractDetail: true, // TODO: json.hasContractDetail,
    canEdit: json.canEdit,

    idEnuProduct: json.idEnuProduct,
    idEnuProductGroup: json.idEnuProductGroup,
    idEnuProductGroupLevelTwo: json.idEnuProductGroupLvl2,
    idEnuContractStatus: json.idEnuContractStatus,

    productGroupLevelTwo: json.productGroupLvl2,
    productGroup: json.productGroup,
    productCRM: json.productCrm,

    beginDate: json.beginDate,
    endDate: json.endDate,
    signatureDate: json.signatureDate,

    spz: json.spz,
    brand: json.brand,
    model: json.model,

    homeAddress: json.homeAddress,
    idObjectAgentContractAgent: json.idAgentContractAgent,

    overview: undefined,
    financialOperations: undefined,
}));
