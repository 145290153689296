import { call, put, select, takeEvery } from "redux-saga/effects";
import { resetSection } from "redux-form/immutable";
import { Selector } from "@reduxjs/toolkit";

import errorHandling from "core/errorHandling";
import { asyncFieldValidator, asyncValidateFields, fieldChangeMatcher, formWrapper, getPathFromFieldName, required } from "core/form";
import userInfo from "core/userInfo";
import { fn, sentry } from "core/util";
import { contractDataChangeApi, validationApi } from "serverApi";
import { PaymentsExtended, PensionPpAccountDetail, State } from "types";

import {
    CONTRIBUTION_AMOUNT,
    CONTRIBUTION_AMOUNT_TYPE_IS_PERCENTAGE,
    CONTRIBUTION_PERCENTAGE,
    EMPLOYER_NAME,
    ENABLE_EMPLOYER_CONTRIBUTION,
    FREQUENCY_OF_PAYMENT,
    IDENTIFICATION_NUMBER,
} from "./constants";

export function* createSaga(formName: string, idObject: number, getEmployerPayments: Selector<State, PaymentsExtended>) {
    try {
        yield call(formSaga(formName, getEmployerPayments), idObject);
    } catch (e) {
        sentry.captureException(e);
        yield put(errorHandling.addServiceError(formName, e.identifier));
    }
}

const formSaga = (formName: string, getEmployerPayments: Selector<State, PaymentsExtended>) =>
    // @ts-ignore we won't type sagas
    formWrapper(formName, {
        *persistentEffects() {
            yield takeEvery(fieldChangeMatcher(formName, ENABLE_EMPLOYER_CONTRIBUTION), enabledChangedSaga, formName);
        },
        *initialize() {
            yield call(userInfo.checkUserVerified);
            yield put(errorHandling.removeServiceErrors(formName));

            const employerPayments: PaymentsExtended = yield select(getEmployerPayments);

            return {
                [ENABLE_EMPLOYER_CONTRIBUTION]: employerPayments.enabled,
                [IDENTIFICATION_NUMBER]: employerPayments.payer.companyID,
                [EMPLOYER_NAME]: employerPayments.payer.companyName,
                [CONTRIBUTION_AMOUNT_TYPE_IS_PERCENTAGE]: fn.isEmptyOrZero(employerPayments.amount),
                [CONTRIBUTION_AMOUNT]: employerPayments.amount,
                [CONTRIBUTION_PERCENTAGE]: employerPayments.percentage,
                [FREQUENCY_OF_PAYMENT]: employerPayments.frequency,
            };
        },
        *save(values, idObject) {
            const isContributionEnabled = values.get(ENABLE_EMPLOYER_CONTRIBUTION);
            const requestBody = {
                idObject,
                [ENABLE_EMPLOYER_CONTRIBUTION]: isContributionEnabled,
                [EMPLOYER_NAME]: isContributionEnabled ? values.get(EMPLOYER_NAME) : null,
                [IDENTIFICATION_NUMBER]: isContributionEnabled ? values.get(IDENTIFICATION_NUMBER) : null,
                [CONTRIBUTION_AMOUNT_TYPE_IS_PERCENTAGE]: isContributionEnabled ? values.get(CONTRIBUTION_AMOUNT_TYPE_IS_PERCENTAGE) : null,
                [CONTRIBUTION_AMOUNT]:
                    isContributionEnabled && !values.get(CONTRIBUTION_AMOUNT_TYPE_IS_PERCENTAGE) ? values.get(CONTRIBUTION_AMOUNT) : null,
                [CONTRIBUTION_PERCENTAGE]:
                    isContributionEnabled && values.get(CONTRIBUTION_AMOUNT_TYPE_IS_PERCENTAGE)
                        ? values.get(CONTRIBUTION_PERCENTAGE)
                        : null,
                [FREQUENCY_OF_PAYMENT]: isContributionEnabled ? values.get(FREQUENCY_OF_PAYMENT) : null,
            };
            yield call(contractDataChangeApi.updateEmployerPayments, requestBody);
        },
        *asyncValidation(values, field) {
            const asyncFieldValidators = [];
            if (values.getIn(getPathFromFieldName(ENABLE_EMPLOYER_CONTRIBUTION))) {
                asyncFieldValidators.push(
                    asyncFieldValidator(getPathFromFieldName(IDENTIFICATION_NUMBER), [
                        required,
                        validationApi.validateEmployerIdentificationNumber,
                    ]),
                );
            }
            return yield call(asyncValidateFields(...asyncFieldValidators), field, values);
        },
    });

function* enabledChangedSaga(formName) {
    yield put(resetSection(formName, EMPLOYER_NAME, IDENTIFICATION_NUMBER));
}
