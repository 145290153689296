import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { InfoPanel, LabelTextItem } from "ui-library/modules";
import { Box, GridItem } from "ui-library/layouts";

import i18n from "core/i18n";
import localized from "core/localized";
import { dateTimeToLocalFullDate } from "core/formatters";
import { fn } from "core/util";
import userContracts from "core/userContracts";

import { createGetActualPaymentScheduler } from "../selectors";

export const ContractDataGridItem: FC = () => {
    const { t } = i18n.useTranslation();
    const { idObject } = useParams();
    const contract = userContracts.useGetContractById(idObject);
    const { isInFutureOrTerminated } = userContracts.useGetContractDerivedData(contract);

    const actualScheduler = useSelector(createGetActualPaymentScheduler);
    const formattedSchedulerPayment = useSelector(
        localized.getLocalizedSchedulerPaymentFullLegacy(fn.getItemOrFirstItemWhenIterable(actualScheduler), false, true),
    );

    return (
        <GridItem display={"flex"} xs={12}>
            <InfoPanel icon={"contract"} title={t("common.contract")}>
                <Box>
                    <LabelTextItem label={t("common.customContractName")} text={contract?.contractName || "-"} />
                    <LabelTextItem label={t("common.contractNumber")} text={contract?.contractNumber} />
                    <LabelTextItem label={t("common.begin")} text={dateTimeToLocalFullDate(contract?.beginDate)} />
                    <LabelTextItem
                        label={t("insurance.insuranceTo")}
                        text={contract?.endDate ? dateTimeToLocalFullDate(contract.endDate) : t("common.unlimitedDate")}
                    />
                    {!isInFutureOrTerminated && <LabelTextItem label={t("insurance.insuranceCost")} text={formattedSchedulerPayment} />}
                </Box>
            </InfoPanel>
        </GridItem>
    );
};
