import React, { FC } from "react";
import { BeneficiariesGridItem } from "containers/contract";

import { getBeneficiaries } from "../selectors";

export const Beneficiaries: FC = () => (
    <BeneficiariesGridItem
        getBeneficiaries={getBeneficiaries}
        tooltipTextKey="pension.beneficiariesInfoText.CZ"
        isSlovakAndContainCompanyID={false}
    />
);
