import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Box, ContentBox, Section } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";
import {
    Field,
    getFormFieldValue,
    InputText,
    lettersNumbersBasicInterpunctionCharacters,
    max12Characters,
    max50Characters,
    required,
    SegmentedControl,
    SingleSelect,
} from "core/form";
import i18n from "core/i18n";
import { fromJS } from "immutable";
import PersonIdentification from "./PersonIdentification";
import {
    ACCOUNT_OWNER_IDENTIFICATION_SECTION,
    COMPANY_ID_FIELD,
    COMPANY_NAME_FIELD,
    IS_ACCOUNT_OWNER_COMPANY_FIELD,
    IS_ACCOUNT_OWNER_CONTRACT_OWNER_FIELD,
} from "../constants";

const AccountOwnerIdentification = ({ formName }) => {
    const { t } = i18n.useTranslation();

    const accountOwnerFieldSelector = getFormFieldValue(
        formName,
        `${ACCOUNT_OWNER_IDENTIFICATION_SECTION}.${IS_ACCOUNT_OWNER_CONTRACT_OWNER_FIELD}`,
    );
    const accountOwnerFieldValue = useSelector(accountOwnerFieldSelector);

    const isCompanyFieldSelector = getFormFieldValue(formName, `${ACCOUNT_OWNER_IDENTIFICATION_SECTION}.${IS_ACCOUNT_OWNER_COMPANY_FIELD}`);
    const isCompanyFieldValue = useSelector(isCompanyFieldSelector);

    // TODO temporary solution - boolean value false is ignored as an option in SingleSelect
    const yesNoOptions = fromJS([
        { value: "true", label: t("common.yes") },
        { value: "false", label: t("common.no") },
    ]);

    return (
        <Section>
            <ContentBox fullWidth>
                <Box mt={4}>
                    <Typography variant="h3">{t("common.disclaimer")}</Typography>
                </Box>
            </ContentBox>
            <ContentBox fullWidth>
                <Field
                    component={SingleSelect}
                    name={`${ACCOUNT_OWNER_IDENTIFICATION_SECTION}.${IS_ACCOUNT_OWNER_CONTRACT_OWNER_FIELD}`}
                    placeholder={t("common.choose")}
                    label={t("investments.sellSwitch.isAccountOwnerContractOwner")}
                    options={yesNoOptions}
                    validateStatic={[required]}
                />
            </ContentBox>

            {accountOwnerFieldValue === "false" && (
                <>
                    <ContentBox fullWidth>
                        <Typography variant="b">{t("investments.sellSwitch.amlDisclaimer")}</Typography>
                    </ContentBox>
                    <ContentBox fullWidth>
                        <Field
                            name={`${ACCOUNT_OWNER_IDENTIFICATION_SECTION}.${IS_ACCOUNT_OWNER_COMPANY_FIELD}`}
                            component={SegmentedControl}
                            label={t("common.legalEntity")}
                        />
                    </ContentBox>
                    {isCompanyFieldValue ? (
                        <>
                            <ContentBox fullWidth>
                                <Field
                                    name={`${ACCOUNT_OWNER_IDENTIFICATION_SECTION}.${COMPANY_NAME_FIELD}`}
                                    label={t("holder.companyName")}
                                    component={InputText}
                                    normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                                    validateStatic={[required]}
                                    md={6}
                                />
                            </ContentBox>
                            <ContentBox fullWidth>
                                <Field
                                    name={`${ACCOUNT_OWNER_IDENTIFICATION_SECTION}.${COMPANY_ID_FIELD}`}
                                    label={t("holder.companyID")}
                                    component={InputText}
                                    normalize={[lettersNumbersBasicInterpunctionCharacters, max12Characters]}
                                    validateStatic={[required]}
                                    md={6}
                                />
                            </ContentBox>
                        </>
                    ) : (
                        <PersonIdentification formName={formName} />
                    )}
                </>
            )}
        </Section>
    );
};

AccountOwnerIdentification.propTypes = {
    formName: PropTypes.string.isRequired,
};

export default AccountOwnerIdentification;
