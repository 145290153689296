import products from "../../products";
import userContracts from "../../userContracts";
import router from "../../router";
import { Tabs } from "../../../routeConstants";
import { ContractChangeType } from "../../../types";
import { CHANGE_TYPE_PARAM_NAME } from "../../../app/constants";

export const useGetContractChangeFormLink: (idObject: number, changeType: ContractChangeType) => string = (idObject, changeType) => {
    if (!idObject) {
        return null;
    }

    const contract = userContracts.useGetContractById(idObject);
    const productDefinition = products.useGetProductDefinition(contract?.idEnuProductGroupLevelTwo);

    if (!productDefinition || !productDefinition.detailRoute) {
        return null;
    }
    return router.getStaticUrl(productDefinition.topLevelRoute, Tabs.CHANGES, { idObject }, { [CHANGE_TYPE_PARAM_NAME]: changeType });
};
