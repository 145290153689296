import React from "react";
import { Grid } from "@mui/material";

import { Button, Label, Loading, Typography } from "ui-library/atoms";
import { Box } from "ui-library/layouts";

import i18n from "core/i18n";
import userContracts from "core/userContracts";
import { Protected } from "containers/roleAccess";
import { lettersNumbersBasicInterpunctionCharacters, max8Characters, max9Characters, upperLettersNumbers } from "core/form";
import { localFullDate } from "core/formatters";
import { Field } from "core/hookForm";
import { useHasRole } from "core/hooks";
import { Roles } from "enums";

import useVehicleData from "../hooks/useVehicleData";
import { VehicleDataChangeFormValues } from "../hooks/useVehicleDataChangeForm";

type Props = {
    onSubmit: (data: VehicleDataChangeFormValues) => void;
    vehicles: any;
    isSubmitting: boolean;
};

export default function VehicleDataForm({ onSubmit, vehicles, isSubmitting }: Props) {
    const {
        isLicencePlateFillInLoading,
        isVehicleLicenceFillInLoading,
        fillInLicencePlate,
        fillInVehicleLicenceNumber,
        watch,
        handleSubmit,
        clearErrors,
    } = useVehicleData();
    const { t } = i18n.useTranslation();
    const { isLicencePlateNumberChange, isVehicleLicenceNumberChange, vinNumber } = watch();
    const isAuthenticated = useHasRole(Roles.AUTHENTICATED);
    const { contractNumber } = userContracts.useGetContractByUrlParam();
    const todayDate = new Date();

    return (
        <>
            <Box maxWidth={400} mb={6}>
                <Typography variant="h4" markDown={t("vehicle.change.vehicleData.validityStartDate")} />
                <Typography markDown={localFullDate(todayDate.toISOString())} />
            </Box>

            <Protected roles={[Roles.AUTHENTICATED]}>
                <Box mb={6}>
                    {vehicles.length > 1 && (
                        <Box maxWidth={400} mb={1}>
                            <Field input="select" name="vinNumber" label={t("vehicle.change.vehicleData.vehicle")} options={vehicles} />
                        </Box>
                    )}

                    {vinNumber && (
                        <>
                            <Grid container>
                                <Grid item xs={2}>
                                    <Typography variant="span" fontWeight={700}>
                                        {t("vehicle.change.vehicleData.vehicle")}:
                                    </Typography>
                                </Grid>
                                <Grid item xs={10}>
                                    {vehicles.find((vehicle) => vehicle.code === vinNumber)?.value}
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item xs={2}>
                                    <Typography variant="span" fontWeight={700}>
                                        {t("vehicle.change.vehicleData.vin")}:
                                    </Typography>
                                </Grid>
                                <Grid item xs={10}>
                                    {vinNumber}
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Box>
            </Protected>

            <Grid container mb={{ xs: 4, md: 2 }} spacing={2} rowSpacing={2}>
                <Grid item md={isAuthenticated ? 3 : 4} display={"flex"} mt={3} width={"100%"}>
                    <Field
                        input="switch"
                        name="isLicencePlateNumberChange"
                        label={t("vehicle.change.vehicleData.licencePlateNumberChange")}
                        onChange={() => {
                            clearErrors(["oldLicencePlateNumber", "newLicencePlateNumber"]);
                        }}
                    />
                </Grid>

                <Grid item md={4} width={"100%"}>
                    <Field
                        input="textField"
                        name="oldLicencePlateNumber"
                        label={t("vehicle.change.vehicleData.oldValue")}
                        normalize={[upperLettersNumbers, max8Characters]}
                        disabled={!isLicencePlateNumberChange || isAuthenticated}
                    />
                </Grid>

                <Grid item md={isAuthenticated ? 5 : 4} display={"flex"} flexDirection={"column"} width={"100%"}>
                    <Label id="newLicencePlateNumber" label={t("vehicle.change.vehicleData.newValue")} />
                    <Box display={"flex"} flexDirection={"row"} alignItems={"flex-start"}>
                        <Box fullWidth>
                            <Field
                                input="textField"
                                name="newLicencePlateNumber"
                                disabled={!isLicencePlateNumberChange}
                                normalize={[upperLettersNumbers, max8Characters]}
                            />
                        </Box>

                        <Protected roles={[Roles.AUTHENTICATED]}>
                            <Box>
                                <Button
                                    variant="contained"
                                    color="blue"
                                    disabled={!isLicencePlateNumberChange}
                                    onClick={fillInLicencePlate}
                                >
                                    {isLicencePlateFillInLoading ? (
                                        <Loading loading={true} />
                                    ) : (
                                        t("vehicle.change.vehicleData.button.fillIn")
                                    )}
                                </Button>
                            </Box>
                        </Protected>
                    </Box>
                </Grid>
            </Grid>

            <Grid container mb={6} spacing={{ xs: 0, md: 2 }} rowSpacing={2}>
                <Grid item md={isAuthenticated ? 3 : 4} display={"flex"} mt={3} width={"100%"}>
                    <Field
                        input="switch"
                        name="isVehicleLicenceNumberChange"
                        label={t("vehicle.change.vehicleData.vehicleLicenceNumberChange")}
                        onChange={() => {
                            clearErrors(["oldVehicleLicenceNumber", "newVehicleLicenceNumber"]);
                        }}
                    />
                </Grid>

                <Grid item md={4} width={"100%"}>
                    <Field
                        input="textField"
                        name="oldVehicleLicenceNumber"
                        label={t("vehicle.change.vehicleData.oldValue")}
                        normalize={[lettersNumbersBasicInterpunctionCharacters, max9Characters]}
                        disabled={!isVehicleLicenceNumberChange || isAuthenticated}
                    />
                </Grid>

                <Grid item md={isAuthenticated ? 5 : 4} display={"flex"} flexDirection={"column"} width={"100%"}>
                    <Label id="newVehicleLicenceNumber" label={t("vehicle.change.vehicleData.newValue")} />
                    <Box display={"flex"} flexDirection={"row"} alignItems={"flex-start"}>
                        <Box fullWidth>
                            <Field
                                input="textField"
                                name="newVehicleLicenceNumber"
                                disabled={!isVehicleLicenceNumberChange}
                                normalize={[lettersNumbersBasicInterpunctionCharacters, max9Characters]}
                            />
                        </Box>

                        <Protected roles={[Roles.AUTHENTICATED]}>
                            <Box>
                                <Button
                                    variant="contained"
                                    color="blue"
                                    disabled={!isVehicleLicenceNumberChange}
                                    onClick={fillInVehicleLicenceNumber}
                                >
                                    {isVehicleLicenceFillInLoading ? (
                                        <Loading loading={true} />
                                    ) : (
                                        t("vehicle.change.vehicleData.button.fillIn")
                                    )}
                                </Button>
                            </Box>
                        </Protected>
                    </Box>
                </Grid>
            </Grid>

            <Box display="flex" justifyContent="end">
                <Button
                    variant="contained"
                    color="blue"
                    onClick={handleSubmit(onSubmit)}
                    disabled={isSubmitting || (!isLicencePlateNumberChange && !isVehicleLicenceNumberChange)}
                >
                    {isSubmitting ? <Loading loading={true} /> : t("vehicle.change.vehicleData.button.next")}
                </Button>
            </Box>
        </>
    );
}
