import React, { FC } from "react";

import { Typography } from "ui-library/atoms";
import { Box } from "ui-library/layouts";

import i18n from "core/i18n";
import { CancelOrCloseRequestButton, InPageFormButtonsLayout } from "containers/inPageForm";

type StepThreeProps = {
    formName: string;
};

export const StepThree: FC<StepThreeProps> = ({ formName }) => {
    const { t } = i18n.useTranslation();

    return (
        <>
            <Box mb={6} fullWidth textAlign={"center"}>
                <Typography variant="h2">{t("vehicle.change.vehicleData.confirmation.title")}</Typography>
            </Box>
            <Box mb={3} fullWidth textAlign={"center"}>
                <Typography variant="body">{t("vehicle.change.contractCancellation.confirmation.text")}</Typography>
            </Box>
            <InPageFormButtonsLayout rightButton={<CancelOrCloseRequestButton label={t("common.close")} />} />
        </>
    );
};
