import { Roles } from "enums";
import { ChangesDefinition, ChangesDefinitionByCountry, ChangeType } from "types";

import { useHasRole } from "../user/useHasRole";
import pageContext from "../../pageContext";

export function useGetUserChangeTypesByCountry(changeDefinition: ChangesDefinitionByCountry): ChangeType[] {
    const isAuthenticated = useHasRole(Roles.AUTHENTICATED);
    const { isSlovakSite } = pageContext.useGetPageLocalization();

    if (isAuthenticated) {
        return isSlovakSite ? changeDefinition.authenticatedChangeOptionsSk : changeDefinition.authenticatedChangeOptionsCz;
    }
    return isSlovakSite ? changeDefinition.oneTimeChangeOptionsSk : changeDefinition.oneTimeChangeOptionsCz;
}
