export const NAME = "page-detail-travel";

// Entity
export const ENTITY_CONTRACT_DOCUMENTS = `${NAME}/ENTITY_CONTRACT_DOCUMENTS`;
export const ENTITY_FINANCIAL_OPERATIONS = `${NAME}/ENTITY_FINANCIAL_OPERATIONS`;
export const ENTITY_FINANCIAL_STATEMENT = `${NAME}/ENTITY_FINANCIAL_STATEMENT`;
export const ENTITY_CONTRACT_INCIDENTS = `${NAME}/ENTITY_CONTRACT_INCIDENTS`;
export const ENTITY_PAYMENT_INFORMATION = `${NAME}/ENTITY_PAYMENT_INFORMATION`;
export const ENTITY_CONTRACT_OVERVIEW = `${NAME}/ENTITY_CONTRACT_OVERVIEW`;

// Entity state
export const ENTITY_CONTRACT_INCIDENTS_OPEN_STATE = `${NAME}/ENTITY_CONTRACT_INCIDENTS_OPEN_STATE`;

// Form
export const FORM_EDIT_POLICY_HOLDER = `${NAME}/FORM_EDIT_POLICY_HOLDER`;
export const FORM_EDIT_CONTRACT_NAME = `${NAME}/FORM_EDIT_CONTRACT_NAME`;
export const FORM_EDIT_ELECTRONIC_COMMUNICATION = `${NAME}/FORM_EDIT_ELECTRONIC_COMMUNICATION`;
export const FORM_EDIT_INSURANCE_PAYMENTS = `${NAME}/FORM_EDIT_CONTRACT_OWNER_PAYMENTS`;
