export const Roles = {
    GUEST: "GUEST",
    WRONG_PORTAL_USER: "WRONG_PORTAL_USER",
    AUTHENTICATED: "AUTHENTICATED",
    VERIFIED: "VERIFIED",
    BANK_ID: "BANK_ID",
    IMPERSONATED: "IMPERSONATED",
    ONETIME: "ONETIME",
    OT_CONTRACT_CHANGE: "OT_CONTRACT_CHANGE",
    OT_PAYMENT: "OT_PAYMENT",
    OT_CLAIM: "OT_CLAIM",
    OT_GREEN_CARD: "OT_GREEN_CARD",
} as const;

export type Role = typeof Roles[keyof typeof Roles];
