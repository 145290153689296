import { Autocomplete, debounce } from "@mui/material";
import { GOOGLE_MAPS_MIN_CHARACTERS_FETCH } from "app/constants";
import { forwardRef, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { TextField as NoRefTextField } from "ui-library/atoms";
import { TextFieldProps } from "ui-library/atoms/Inputs/TextField";

type Props = {
    name: string;
    label: string;
    disabled?: boolean;
    required?: boolean;
};

const TextField = forwardRef((props: TextFieldProps, ref) => <NoRefTextField {...props} reference={ref} />);
const autocompleteService = { current: null };
const placesService = { current: null };

export default function AddressAutocomplete({ name, label, disabled, required }: Props) {
    const {
        setValue,
        watch,
        formState: { errors },
    } = useFormContext();
    const value = watch(name + ".street");
    const [selectedOption, setSelectedOption] = useState(value ? [value] : []);
    const [options, setOptions] = useState([]);

    const error = (name + ".street").split(".").reduce((acc, part) => acc?.[part], errors);

    const fetch = useMemo(
        () =>
            debounce((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 200),
        [],
    );

    useEffect(() => {
        // We want to start fetching only after defined number of characters is typed.
        if (!value || value.length < GOOGLE_MAPS_MIN_CHARACTERS_FETCH) {
            return;
        }

        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return;
        }

        if (value === "") {
            setOptions([]);
            return;
        }

        fetch({ input: value }, (results) => {
            if (active) {
                let newOptions = [];

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });
    }, [value, fetch]);

    useEffect(() => {
        // @ts-ignore
        if (selectedOption?.place_id) {
            if (!placesService.current && window.google) {
                placesService.current = new window.google.maps.places.PlacesService(document.createElement("div"));
            }
            if (!placesService.current) {
                return;
            }

            placesService.current.getDetails(
                // @ts-ignore
                { placeId: selectedOption.place_id },
                (place, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK && place) {
                        const a = place?.address_components;
                        if (!a) return;

                        const street = a.find((component) => component.types.includes("route"))?.long_name;
                        const descriptiveNo = a.find((component) => component.types.includes("street_number"))?.long_name;
                        const orientationNo = a.find((component) => component.types.includes("premise"))?.long_name;
                        const city = (
                            a.find((component) => component.types.includes("locality")) ||
                            a.find((component) => component.types.includes("postal_town")) ||
                            a.find((component) => component.types.includes("sublocality"))
                        )?.long_name;
                        const zipCode = a.find((component) => component.types.includes("postal_code"))?.long_name;
                        const country = a.find((component) => component.types.includes("country"))?.short_name;

                        setValue(name, {
                            street,
                            descriptiveNo,
                            orientationNo,
                            city,
                            zipCode,
                            country,
                        });
                    }
                },
            );
        }
    }, [selectedOption]);

    return (
        <Autocomplete
            disablePortal
            freeSolo
            id={name}
            includeInputInList
            options={options}
            getOptionLabel={(option) => {
                if (Array.isArray(option) && option.length > 0) {
                    return option[0];
                }
                return typeof option === "string" ? option : option.description;
            }}
            disabled={disabled}
            value={value}
            onChange={(_, newValue: any) => {
                if (newValue?.place_id) {
                    setSelectedOption(newValue);
                }
            }}
            onInputChange={(_, newInputValue) => {
                setValue(name + ".street", newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={required ? label + "*" : label}
                    error={Boolean(error)}
                    errorContent={error?.message.toString()}
                />
            )}
        />
    );
}
