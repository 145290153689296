import React, { FC } from "react";
import { ContentBox } from "ui-library/layouts";

import i18n from "core/i18n";
import { Field, InputText, lettersNumbersBasicInterpunctionCharacters, required } from "core/form";

import { FIELD_CANCELLATION_NOTE } from "../../constants";

export const CancellationNoteSection: FC = ({}) => {
    const { t } = i18n.useTranslation();

    return (
        <>
            <ContentBox fullWidth>
                <Field
                    name={FIELD_CANCELLATION_NOTE}
                    component={InputText}
                    label={t("vehicle.change.contractCancellation.cancellationNote")}
                    rows={5}
                    multiline
                    validateStatic={[required]}
                    normalize={[lettersNumbersBasicInterpunctionCharacters]}
                />
            </ContentBox>
        </>
    );
};
