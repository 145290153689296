import React, { FC } from "react";
import { GridLayout } from "ui-library/layouts";

import { BeneficiariesGridItem, ContractDataGridItem, ContributionsGridItem } from "../containers";

export const DetailTab: FC = () => (
    <GridLayout defaultSpacing>
        <ContractDataGridItem />
        <ContributionsGridItem />
        <BeneficiariesGridItem />
    </GridLayout>
);
