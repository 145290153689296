import { FormProvider, UseFormReturn } from "react-hook-form";

type FormProps<T> = {
    form: UseFormReturn<T>;
    onSubmit: (data: T) => void;
    children: React.ReactNode;
};

export default function Form<T>({ form, onSubmit, children }: FormProps<T>) {
    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} style={{ width: "100%" }}>
                {children}
            </form>
        </FormProvider>
    );
}
