import { Box, Flexbox } from "ui-library/layouts";
import SubjectForm from "./SubjectForm";
import { Field } from "core/hookForm";
import { Block, Button, Typography } from "ui-library/atoms";
import { Option } from "core/hookForm/components/Field";
import i18n from "core/i18n";
import VehicleSelect from "./VehicleSelect";
import { useFormContext } from "react-hook-form";

export default function StepSubjects({ vehicles, nextStep }: { vehicles: Option[]; nextStep: () => void }) {
    const { watch, trigger } = useFormContext();
    const { editOwner, editOperator } = watch();
    const { t } = i18n.useTranslation();

    async function next() {
        const isValid = await trigger();
        if (isValid) {
            nextStep();
        }
    }

    return (
        <>
            <Box maxWidth={400} mb={6}>
                <Field input="date" name="validityStartDate" label={t("vehicle.change.subject.validityStartDate")} minDate={new Date()} />
            </Box>

            <VehicleSelect vehicles={vehicles} />

            <Block p={4} variant="white" mb={4}>
                <Flexbox>
                    <Field input="switch" name="editOperator" />
                    <Typography variant="h3">{t("vehicle.change.subject.operator")}</Typography>
                </Flexbox>
                {editOperator && (
                    <Box mt={2}>
                        <SubjectForm subject="operator" />
                    </Box>
                )}
            </Block>

            <Block p={4} variant="white" mb={6}>
                <Flexbox>
                    <Field input="switch" name="editOwner" />
                    <Typography variant="h3">{t("vehicle.change.subject.owner")}</Typography>
                </Flexbox>
                {editOwner && (
                    <Box mt={2}>
                        <SubjectForm subject="owner" />
                    </Box>
                )}
            </Block>

            <Flexbox justifyContent="flex-end">
                <Button variant="contained" color="blue" onClick={next} disabled={!editOwner && !editOperator}>
                    {t("common.continue")}
                </Button>
            </Flexbox>
        </>
    );
}
