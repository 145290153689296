import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Button } from "ui-library/atoms";
import { InfoPanel, LabelTextItem } from "ui-library/modules";
import { Box, ContentBox, GridItem } from "ui-library/layouts";

import i18n from "core/i18n";
import localized, { getLocalizedEmployerPayments } from "core/localized";
import router from "core/router";
import userContracts from "core/userContracts";
import { dateTimeToLocalFullDate, formatYesNo } from "core/formatters";
import { useHasEditContractPermission } from "core/hooks";
import { fn } from "core/util";

import { Pages, Params, Tabs } from "routeConstants";
import { getEmployerPayments, getPaymentsScheduler, getPensionAccountDetail, getPensionTypes } from "../selectors";

const doTransition = (contractId: number) =>
    router.navigate(
        Pages.ONLINE_PAYMENT,
        Tabs.PAYMENTS_PAY,
        {},
        {
            [Params.PAYMENT_CONTRACT_ID]: contractId,
        },
        true,
    );

export const ContributionsGridItem: FC = () => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    const pensionAccountDetail = useSelector(getPensionAccountDetail);
    const paymentsScheduler = useSelector(getPaymentsScheduler);
    const hasEditPermission = useHasEditContractPermission();

    const holderPayments = useSelector(localized.getLocalizedSchedulerPaymentFull(paymentsScheduler, true));
    const employerPayments = useSelector(getLocalizedEmployerPayments(t, getEmployerPayments));
    const { idObject } = useParams();
    const { canEdit: isContractEditable } = userContracts.useGetContractById(idObject);

    // Pension set on contract.
    const pensionTypes = useSelector(getPensionTypes);
    const isPensionSet = !fn.isEmpty(pensionTypes.pensionCode);
    const pensionDescription = isPensionSet ? pensionTypes.pensionDescription : "-";
    const pensionDate = isPensionSet ? dateTimeToLocalFullDate(pensionTypes.pensionDate) : "-";

    return (
        <GridItem display="flex" xs={12} md={12}>
            <InfoPanel
                icon="circle-dollar"
                title={t("pension.contributions")}
                actionButton={
                    hasEditPermission &&
                    isContractEditable && (
                        <Box>
                            <Button color="green" onClick={() => dispatch(doTransition(Number(idObject)))}>
                                {t("common.pay")}
                            </Button>
                        </Box>
                    )
                }
            >
                <ContentBox>
                    <LabelTextItem label={t("pension.pensionType")} text={pensionDescription} />
                    {isPensionSet && <LabelTextItem label={t("common.admittedFrom")} text={pensionDate} />}
                    <LabelTextItem label={t("account.holderPayments")} text={holderPayments} />
                    <LabelTextItem label={t("account.employerPayments")} text={employerPayments} />
                    <LabelTextItem
                        label={t("pension.taxOptimization")}
                        text={formatYesNo(t, pensionAccountDetail.isTaxOptimizationEnabled)}
                    />
                    <LabelTextItem label={t("pension.precedentPension")} text={pensionAccountDetail.precedentPensionInformation} />
                </ContentBox>
            </InfoPanel>
        </GridItem>
    );
};
