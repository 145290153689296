import { TFunction } from "core/i18n";
import { ChangesDefinitionByCountry, ContractChangeType } from "types";

export const changesDefinition = (t: TFunction): ChangesDefinitionByCountry => {
    const authenticatedChangeOptionsCz = [
        {
            code: ContractChangeType.VEHICLE_DATA_CHANGE,
            value: t("vehicle.change.type.VEHICLE_DATA_CHANGE"),
        },
        {
            code: ContractChangeType.VEHICLE_SUBJECT_CHANGE,
            value: t("vehicle.change.type.VEHICLE_SUBJECT_CHANGE"),
        },
        {
            code: ContractChangeType.CONTRACT_CANCELLATION,
            value: t("changeType.contractCancellation"),
        },
        {
            code: ContractChangeType.INSURANCE_PAYMENTS,
            value: t("changeType.INSURANCE_PAYMENTS"),
        },
        {
            code: ContractChangeType.CONTRACT_NAME,
            value: t("changeType.CONTRACT_NAME"),
        },
        {
            code: ContractChangeType.POLICY_HOLDER,
            value: t("changeType.POLICY_HOLDER"),
        },
        {
            code: ContractChangeType.ELECTRONIC_COMMUNICATION,
            value: t("changeType.ELECTRONIC_COMMUNICATION"),
        },
    ];

    const authenticatedChangeOptionsSk = [
        {
            code: ContractChangeType.INSURANCE_PAYMENTS,
            value: t("changeType.INSURANCE_PAYMENTS"),
        },
        {
            code: ContractChangeType.CONTRACT_NAME,
            value: t("changeType.CONTRACT_NAME"),
        },
        {
            code: ContractChangeType.POLICY_HOLDER,
            value: t("changeType.POLICY_HOLDER"),
        },
        {
            code: ContractChangeType.ELECTRONIC_COMMUNICATION,
            value: t("changeType.ELECTRONIC_COMMUNICATION"),
        },
    ];

    const oneTimeChangeOptionsCz = [
        {
            code: ContractChangeType.VEHICLE_DATA_CHANGE,
            value: t("vehicle.change.type.VEHICLE_DATA_CHANGE"),
        },
        {
            code: ContractChangeType.VEHICLE_SUBJECT_CHANGE,
            value: t("vehicle.change.type.VEHICLE_SUBJECT_CHANGE"),
        },
        {
            code: ContractChangeType.CONTRACT_CANCELLATION,
            value: t("changeType.contractCancellation"),
        },
    ];

    const oneTimeChangeOptionsSk = [];

    return {
        authenticatedChangeOptionsCz,
        authenticatedChangeOptionsSk,
        oneTimeChangeOptionsCz,
        oneTimeChangeOptionsSk,
    };
};
