import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { Button, MenuLink } from "ui-library/atoms";
import { Box } from "ui-library/layouts";
import { MenuWrapper } from "ui-library/Styled/Modules/Header";
import useBreakpoints from "ui-library/theme/breakpoints";

import i18n from "core/i18n";
import pageContext from "core/pageContext";
import router from "core/router";
import { pageRoutes } from "routeUrls";
import { Pages } from "routeConstants";
import LoggedOutButtons from "./LoggedOutButtons";

export interface LoggedOutMenuProps {
    isMenuOpened: boolean;
}

export const LoggedOutMenu: FC<LoggedOutMenuProps> = ({ isMenuOpened }) => {
    const { t } = i18n.useTranslation();
    const locale = useSelector(pageContext.getLocale);
    let navigate = useNavigate();

    const breakpointMd = useBreakpoints("md");
    return (
        <MenuWrapper order={{ xs: 1, md: 0 }} className={isMenuOpened ? "opened" : ""}>
            {breakpointMd ? (
                <Box mr={4}>
                    <Button
                        variant="text"
                        color="blue"
                        onClick={() => navigate(router.enhanceUrlWithLangParam(pageRoutes[Pages.CONTACT], locale))}
                    >
                        {t("menu.contact")}
                    </Button>
                </Box>
            ) : (
                <MenuLink to={pageRoutes[Pages.CONTACT]}>{t("menu.contact")}</MenuLink>
            )}

            <LoggedOutButtons />
        </MenuWrapper>
    );
};
