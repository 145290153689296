import policyHolderForm from "form/fullPage/policyHolder";
import { IndividualPersonType } from "enums";

import { FORM_EDIT_POLICY_HOLDER } from "../constants";
import { getIdObject, getPolicyHolder } from "../selectors";

const HIDE_ID_SECTION = true;

export const PolicyHolderForm = policyHolderForm.createInPageContainer(
    FORM_EDIT_POLICY_HOLDER,
    IndividualPersonType.PARTICIPANT,
    getIdObject,
    getPolicyHolder,
    HIDE_ID_SECTION,
);
