import React, { FC } from "react";
import { useSelector } from "react-redux";

import { ContentBox } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";

import i18n from "core/i18n";
import pageContext from "core/pageContext";

import { SECTION_BANK_ACCOUNT } from "../../constants";

import { AccountSectionCs } from "./AccountSectionCs";
import { AccountSectionSk } from "./AccountSectionSk";

export const AccountSection: FC = ({ ...props }) => {
    const { t } = i18n.useTranslation();
    const isSkSite = useSelector(pageContext.getIsSlovakSite);

    return (
        <>
            <ContentBox>
                <Typography variant="h4" markDown={t("vehicle.change.contractCancellation.fundsTransfer")} />
            </ContentBox>

            {isSkSite && <AccountSectionSk shouldCheckIbanCountry={false} sectionName={SECTION_BANK_ACCOUNT} />}
            {!isSkSite && <AccountSectionCs sectionName={SECTION_BANK_ACCOUNT} />}
        </>
    );
};
