import { List } from "immutable";
import { PaymentContribution } from "enums";

import { Pages } from "routeConstants";

export const TMP_INDIVIDUAL_PROFILE_CONTRACTS = ["7601509297", "7601457729"];

export const CHANGE_TYPE_PARAM_NAME = "type";

export const PUBLIC_PAGES = [
    Pages.LOGIN,
    Pages.CONTACT,
    Pages.REGISTRATION,
    Pages.RESET_PASSWORD,
    Pages.EPIN_EXTERNAL,
    Pages.IMPERSONATE,
    Pages.NOTIFICATION,
    Pages.GARAGE,
    Pages.CAMPAIGN,
];

// Authorization type
export const AUTHORIZATION_TYPE_IMPERSONATION = "IMPERSONATION";

// empty object and array with stable references
// preferable to use to optimize React re-renders
export const EMPTY_OBJECT = {};
export const EMPTY_ARRAY = [];
export const DEFAULT_EMPTY_PARAM = 0;
export const nbsp = "\u00A0";
export const API_PREFIX = "/api";

export const DEFAULT_TABLE_PAGE_SIZE = 10;
export const DEFAULT_TABLE_OVERVIEW_PAGE_SIZE = 3;
export const DEFAULT_TABLE_ROWS_OPTIONS = [5, 10, 15, 20];
export const DEFAULT_DESCTIPTION_SIZE = 50;

export const FORM_STEPS_BACK = 2;
export const ADDITIONAL_COVERAGE = "ADDITIONAL_COVERAGE";
export const DEFAULT_CHART_COLOR = "#fcd385";

export const RECAPTCHA_PUBLIC_KEY = "6LcwyDoiAAAAAA5CTm1P0zhruudzUBXomJ4n-bEL";

// Quick view display types.
export const DDS_QUICK_VIEW_MOVEMENT_TYPES = [1, 23, 41, 43];
export const DSS_QUICK_VIEW_MOVEMENT_TYPES = [1, 5, 8];

// Not secret info by design, no need to be different in different enviroments
// so it sufficient to have it here hardcoded in source.
export const GOOGLE_MAPS_API_KEY = "AIzaSyBcqV42lQQkg8L_HfK14NwUag2JWzGsNq0";
export const GOOGLE_MAPS_MIN_CHARACTERS_FETCH = 3;

export const PAYMENT_CONTRIBUTIONS_OPTIONS_UNIQA = List([PaymentContribution.ByDirectDebit.id, PaymentContribution.ByBankAccount.id]);

export const PAYMENT_CONTRIBUTIONS_OPTIONS_EX_AXA = List([
    PaymentContribution.ByPostalOrder.id,
    PaymentContribution.ByEmployment.id,
    PaymentContribution.ByBankAccount.id,
]);

export const HASH_PLACEHOLDER = ":hash";
