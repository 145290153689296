import { FC, ReactNode } from "react";
import { BoxProps } from "@mui/material";

import { Box } from "./Box";

type ContentBoxProps = {
    children: ReactNode;
    fullWidth?: boolean;
    bigger?: boolean;
} & BoxProps;

export const ContentBox: FC<ContentBoxProps> = ({ children, fullWidth = false, bigger = false, ...props }) => {
    return bigger ? (
        <Box mb={{ xs: 3, md: 0, lg: 0 }} fullWidth={fullWidth} {...props}>
            {children}
        </Box>
    ) : (
        <Box mb={2} fullWidth={fullWidth} {...props}>
            {children}
        </Box>
    );
};
