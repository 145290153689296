import React, { FC } from "react";
import { useSelector } from "react-redux";

import { SidemenuLink } from "ui-library/atoms";

import pageContext from "core/pageContext";
import router from "core/router";
import { Tabs } from "routeConstants";
import { innerRoutes } from "routeUrls";
import { Role } from "enums";
import { useHasAnyRole } from "core/hooks";

type AppSidemenuLinkProps = {
    tab: Tabs;
    title: string;
    enabledFor?: Role[];
};

export const AppSidemenuLink: FC<AppSidemenuLinkProps> = ({ tab, title, enabledFor }) => {
    const locale = useSelector(pageContext.getLocale);
    const toRoute = router.enhanceUrlWithLangParam(innerRoutes[tab], locale);

    const isEnabled = enabledFor ? useHasAnyRole(enabledFor) : true;

    return (
        <SidemenuLink id={tab} to={toRoute} disabled={!isEnabled}>
            {title}
        </SidemenuLink>
    );
};
