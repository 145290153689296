import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import i18n from "core/i18n";
import userContracts from "core/userContracts";
import { form, getFormFieldValue } from "core/form";
import { InPageOtpFormLayout } from "containers/inPageForm";
import { ChangeSuccessTextType } from "types";

import { FIELD_PHONE_NUMBER, FORM_STEP, SECTION_CONTACT_PERSON, VIRTUAL_FIELD_FORM_STEP } from "./constants";
import { StepOne, StepThree, StepTwo, StepZero } from "./containers";

const ContractCancellationForm = ({ canSubmit, handleSubmit, submitting, formName }) => {
    const formStep = useSelector(getFormFieldValue(formName, VIRTUAL_FIELD_FORM_STEP));

    return (
        <>
            {formStep === FORM_STEP.ZERO_INTRODUCTION && <StepZero handleSubmit={handleSubmit} isSubmitting={submitting} />}
            {formStep === FORM_STEP.FIRST_EDIT && (
                <StepOne formName={formName} handleSubmit={handleSubmit} isSubmitting={submitting} canSubmit={canSubmit} />
            )}
            {formStep === FORM_STEP.SECOND_REVIEW_SUBMIT && (
                <StepTwo formName={formName} handleSubmit={handleSubmit} isSubmitting={submitting} canSubmit={canSubmit} />
            )}
            {formStep === FORM_STEP.THIRD_CONFIRMATION && <StepThree formName={formName} />}
        </>
    );
};

export const createInPageContainer = (formName) =>
    form(formName, { asyncValidationFields: [SECTION_CONTACT_PERSON, FIELD_PHONE_NUMBER] })((props) => {
        const { t } = i18n.useTranslation();
        const { idObject } = useParams();
        const contract = userContracts.useGetContractById(idObject);
        return (
            <InPageOtpFormLayout
                formName={formName}
                formTitle={t("vehicle.change.contractCancellation.title", { contractNumber: contract.contractNumber })}
                changeSuccessTextType={ChangeSuccessTextType.CHANGE_SUBMITTED_FOR_PROCESSING}
            >
                <ContractCancellationForm formName={formName} {...props} />
            </InPageOtpFormLayout>
        );
    });
