import { NAME } from "./constants";

export const SET_VEHICLE_CONTRACT_DETAIL = `${NAME}/SET_VEHICLE_CONTRACT_DETAIL`;
export const SET_DETAIL_SELECTED_COVERAGE = `${NAME}/SET_DETAIL_SELECTED_COVERAGE`;
export const SET_STEP = `${NAME}/SET_STEP`;
export const SET_IS_FORM_LOADING = `${NAME}/SET_IS_FORM_LOADING`;
export const SET_IS_SUBMITTING = `${NAME}/SET_IS_SUBMITTING`;
export const SET_IS_LICENCE_PLATE_FILL_IN_LOADING = `${NAME}/SET_IS_LICENCE_PLATE_FILL_IN_LOADING`;
export const SET_IS_VEHICLE_LICENCE_FILL_IN_LOADING = `${NAME}/SET_IS_VEHICLE_LICENCE_FILL_IN_LOADING`;
export const CLEAR = `${NAME}/CLEAR`;

export const setVehicleContractDetail = (contractDetail) => ({
    type: SET_VEHICLE_CONTRACT_DETAIL,
    payload: contractDetail,
});

export const setDetailSelectedCoverage = (selectedCoverage) => ({
    type: SET_DETAIL_SELECTED_COVERAGE,
    payload: selectedCoverage,
});

export const setStep = (step) => ({
    type: SET_STEP,
    payload: step,
});

export const setIsFormLoading = (isFormLoading) => ({
    type: SET_IS_FORM_LOADING,
    payload: isFormLoading,
});

export const setIsSubmitting = (isSubmitting) => ({
    type: SET_IS_SUBMITTING,
    payload: isSubmitting,
});

export const setIsLicencePlateFillInLoading = (isLicencePlateFillInLoading) => ({
    type: SET_IS_LICENCE_PLATE_FILL_IN_LOADING,
    payload: isLicencePlateFillInLoading,
});

export const setIsVehicleLicenceFillInLoading = (isVehicleLicenceFillInLoading) => ({
    type: SET_IS_VEHICLE_LICENCE_FILL_IN_LOADING,
    payload: isVehicleLicenceFillInLoading,
});

export const clear = () => ({
    type: CLEAR,
});
