import { Loading } from "ui-library/atoms";
import { Box } from "ui-library/layouts";
import GarageResults from "./GarageResults";
import GarageSearchForm from "./form/GarageSearchForm";
import { useGarageSearch } from "../hooks/useGarageSearch";
import { Typography } from "@mui/material";

export default function GarageSearch() {
    const { form, onSubmit, isLoading, garages, errorMessage } = useGarageSearch();

    return (
        <>
            <Box maxWidth={1200} margin={"0 auto"} px={3}>
                <GarageSearchForm form={form} onSubmit={onSubmit} />

                <Loading loading={isLoading} />
                {errorMessage ? (
                    <Typography variant="body1" color={"error"} textAlign={"center"}>
                        {errorMessage}
                    </Typography>
                ) : (
                    !isLoading && garages.length > 0 && <GarageResults garages={garages} />
                )}
            </Box>
        </>
    );
}
