import { FC } from "react";

import i18n from "core/i18n";
import contractOwnerPaymentsForm from "form/modal/contractOwnerPayments";

import { FORM_EDIT_PARTICIPANT_PAYMENTS } from "../constants";

const ParticipantPaymentsFormContainer = contractOwnerPaymentsForm.createInPageContainer(
    FORM_EDIT_PARTICIPANT_PAYMENTS,
    "change.title.PARTICIPANT_PAYMENTS",
);

export const ParticipantPaymentsForm: FC = () => {
    const { t } = i18n.useTranslation();

    return (
        <ParticipantPaymentsFormContainer showFrequency={false} showPaymentType={false} amountLabel={t("pension.dss.payments.amount")} />
    );
};
