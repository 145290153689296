import HookFormAutocomplete from "./inputs/HookFormAutocomplete";
import HookFormDatePicker from "./inputs/HookFormDatePicker";
import HookFormFileUpload from "./inputs/HookFormFileUpload";
import HookFormRadioGroup from "./inputs/HookFormRadioGroup";
import HookFormSelect from "./inputs/HookFormSelect";
import HookFormSwitch from "./inputs/HookFormSwitch";
import HookFormTextField from "./inputs/HookFormTextField";

export type Option = {
    code: number | string;
    value: string;
};

type FieldProps = {
    /** The name of the field, used for form handling. */
    name: string;
    /** The type of input to render. */
    input: "textField" | "autocomplete" | "date" | "switch" | "select" | "radioGroup" | "file";
    /** The type of the input field, applicable when input is "textField". */
    type?: string;
    /** Whether the field is disabled. */
    disabled?: boolean;
    /** Options for the autocomplete field, applicable when input is "autocomplete". */
    options?: Option[];
    /** Whether multiple options can be selected, applicable when input is "autocomplete". */
    multiple?: boolean;
    /** The label for the input field. */
    label?: string;
    /** Additional onChange handler for the input field. */
    onChange?: (value?: any) => any;
    /** Minimum date that can be selected for the date picker. */
    minDate?: Date;
    /** Maximum date that can be selected for the date picker. */
    maxDate?: Date;
    /** Normalization function for the input field. */
    normalize?: ((value: any) => any)[];
    /** Placeholder for the input field. */
    placeholder?: string;
    /** The direction of the radio group, applicable when input is "radioGroup". */
    direction?: string;
    /** Children elements for the radio group. */
    children?: React.ReactNode;
    /** Whether the field is required. */
    required?: boolean;
};

export default function Field({ input, ...props }: FieldProps) {
    switch (input) {
        case "textField":
            return <HookFormTextField {...props} />;
        case "autocomplete":
            return <HookFormAutocomplete {...props} />;
        case "date":
            return <HookFormDatePicker {...props} />;
        case "switch":
            return <HookFormSwitch {...props} />;
        case "select":
            return <HookFormSelect {...props} />;
        case "radioGroup":
            return <HookFormRadioGroup {...props} />;
        case "file":
            return <HookFormFileUpload {...props} />;
        default:
            return null;
    }
}
