import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Button } from "ui-library/atoms";
import { InfoPanel, LabelTextItem } from "ui-library/modules";
import { Box, ContentBox, GridItem } from "ui-library/layouts";

import i18n from "core/i18n";
import localized, { getLocalizedEmployerPayments, getLocalizedThirdPartyPayments } from "core/localized";
import userContracts from "core/userContracts";
import { useHasEditContractPermission } from "core/hooks";
import { dateTimeToLocalFullDate, formatYesNo } from "core/formatters";
import { fn } from "core/util";

import { getEmployerPayments, getPaymentsScheduler, getPensionAccountDetail, getPensionTypes, getThirdPartyPayments } from "../selectors";

export const ContributionsGridItem: FC = () => {
    const { t } = i18n.useTranslation();

    const pensionAccountDetail = useSelector(getPensionAccountDetail);
    const paymentsScheduler = useSelector(getPaymentsScheduler);
    const hasEditPermission = useHasEditContractPermission();
    const { idObject } = useParams();
    const { canEdit: isContractEditable } = userContracts.useGetContractById(idObject);

    const holderPayments = useSelector(localized.getLocalizedSchedulerPaymentFull(paymentsScheduler, true));
    const thirdPartyPayments = getLocalizedThirdPartyPayments(t, useSelector(getThirdPartyPayments));
    const employerPayments = useSelector(getLocalizedEmployerPayments(t, getEmployerPayments));

    // Pension set on contract.
    const pensionTypes = useSelector(getPensionTypes);
    const isPensionSet = !fn.isEmpty(pensionTypes.pensionCode);
    const pensionDescription = isPensionSet ? pensionTypes.pensionDescription : "-";
    const pensionDate = isPensionSet ? dateTimeToLocalFullDate(pensionTypes.pensionDate) : "-";

    return (
        <GridItem display="flex" xs={12} md={12}>
            <InfoPanel
                icon="circle-dollar"
                title={t("pension.contributions")}
                actionButton={
                    hasEditPermission &&
                    isContractEditable && (
                        <Box>
                            <Button color="green">{t("common.pay")}</Button>
                        </Box>
                    )
                }
            >
                <ContentBox>
                    <LabelTextItem label={t("pension.pensionType")} text={pensionDescription} />
                    {isPensionSet && <LabelTextItem label={t("common.admittedFrom")} text={pensionDate} />}
                    <LabelTextItem label={t("account.holderPayments")} text={holderPayments} />
                    <LabelTextItem label={t("account.thirdPartyPayments")} text={thirdPartyPayments} />
                    <LabelTextItem label={t("account.employerPayments")} text={employerPayments} />
                    <LabelTextItem
                        label={t("pension.taxOptimization")}
                        text={formatYesNo(t, pensionAccountDetail.isTaxOptimizationEnabled)}
                    />
                    <LabelTextItem label={t("pension.precedentPension")} text={pensionAccountDetail.precedentPensionInformation} />
                </ContentBox>
            </InfoPanel>
        </GridItem>
    );
};
