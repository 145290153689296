import { createSagaPp } from "./createSagaPp";
import { createSagaDps } from "./createSagaDps";
import { createContainerDps } from "./createContainerDps";
import { createContainerPp } from "./createContainerPp";
import { createInPageContainerPp } from "./createInPageContainerPp";
import { createSagaDdsDss } from "./createSagaDdsDss";
import { createContainerDdsDss } from "./createContainerDdsDss";
import { createInPageContainerDps } from "./createInPageContainerDps";
import { createInPageContainerDdsDss } from "./createInPageContainerDdsDss";

/**
 * Saga and Container creator for Contract name form
 */
const pensionBeneficiaries = {
    createSagaPp,
    createSagaDps,
    createSagaDdsDss,
    createContainerDps,
    createContainerPp,
    createContainerDdsDss,
    createInPageContainerPp,
    createInPageContainerDps,
    createInPageContainerDdsDss,
};
export default pensionBeneficiaries;
