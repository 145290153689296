import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, ContentBox } from "ui-library/layouts";
import { Button, Loading, Typography } from "ui-library/atoms";

import i18n from "core/i18n";
import userContracts from "core/userContracts";
import { InPageFormButtonsLayout, InPageFormLayout } from "containers/inPageForm";
import {
    createGetFormInitialValues,
    email,
    emailCharacters,
    Field,
    form,
    FormWideDisabledProvider,
    getFormFieldValue,
    InputText,
    max20Characters,
    max50Characters,
    phoneNumberCharacters,
    required,
    SegmentedControl,
} from "core/form";

import { FIELD_EMAIL, FIELD_ENABLED, FIELD_PHONE } from "./constants";

const ElectronicCommunicationForm = ({
    canSubmit,
    handleSubmit,
    submitting,
    electronicCommunicationEnabled,
    initialCommunicationEnabled,
}) => {
    const { t } = i18n.useTranslation();
    return (
        <>
            <FormWideDisabledProvider value={submitting}>
                <ContentBox fullWidth>
                    <Typography component="p" markDown={t("form.electronicCommunication.introduction")} />
                </ContentBox>
                <ContentBox fullWidth>
                    <Field
                        name={FIELD_ENABLED}
                        component={SegmentedControl}
                        label={t("form.electronicCommunication.check")}
                        footNote={t("form.electronicCommunication.footNote")}
                    />
                </ContentBox>
                <ContentBox fullWidth>
                    <Typography component="p" markDown={t("form.electronicCommunication.text")} />
                </ContentBox>

                {electronicCommunicationEnabled && (
                    <>
                        <ContentBox fullWidth>
                            <Field
                                name={FIELD_PHONE}
                                component={InputText}
                                label={t("holder.phone")}
                                // validated async in saga!
                                normalize={[phoneNumberCharacters, max20Characters]}
                                onEnter={handleSubmit}
                            />
                        </ContentBox>

                        <ContentBox fullWidth>
                            <Field
                                name={FIELD_EMAIL}
                                component={InputText}
                                label={t("holder.email")}
                                validateStatic={[required, email]}
                                normalize={[emailCharacters, max50Characters]}
                                onEnter={handleSubmit}
                            ></Field>
                        </ContentBox>
                    </>
                )}

                {initialCommunicationEnabled && !electronicCommunicationEnabled && (
                    <ContentBox>
                        <Typography variant="caption" markDown={t("form.electronicCommunication.cancelCommunication")} />
                    </ContentBox>
                )}
            </FormWideDisabledProvider>

            <InPageFormButtonsLayout
                rightButton={
                    <Button color="blue" variant="contained" onClick={handleSubmit} disabled={!canSubmit || submitting}>
                        {submitting ? <Loading loading /> : t("common.submitChange")}
                    </Button>
                }
            />
        </>
    );
};

ElectronicCommunicationForm.propTypes = {
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    electronicCommunicationEnabled: PropTypes.bool,
    initialCommunicationEnabled: PropTypes.bool,
};

ElectronicCommunicationForm.defaultProps = {
    electronicCommunicationEnabled: false,
    initialCommunicationEnabled: false,
};

export const createInPageContainer = (formName) =>
    form(formName, { asyncValidationFields: [FIELD_PHONE] })((props) => {
        const { t } = i18n.useTranslation();
        const electronicCommunicationEnabled = useSelector(getFormFieldValue(formName, FIELD_ENABLED));
        const initialCommunicationEnabled = useSelector(createGetFormInitialValues(formName)).get(FIELD_ENABLED);
        const { idObject } = useParams();
        const contract = userContracts.useGetContractById(idObject);

        return (
            <InPageFormLayout
                formName={formName}
                formTitle={t("change.title.ELECTRONIC_COMMUNICATION", { contractNumber: contract.contractNumber })}
            >
                {/*<ModalFormLayout formName={formName} formTitle={t("form.electronicCommunication")} formIcon="contract">*/}
                <ElectronicCommunicationForm
                    electronicCommunicationEnabled={electronicCommunicationEnabled}
                    initialCommunicationEnabled={initialCommunicationEnabled}
                    {...props}
                />
            </InPageFormLayout>
        );
    });
