import React, { FC } from "react";
import { useSelector } from "react-redux";

import { GridLayout } from "ui-library/layouts";

import i18n from "core/i18n";

import { GenerateStatementGridItem } from "containers/contract";

import { ENTITY_FINANCIAL_OPERATIONS } from "../constants";
import { PensionFinancialOperationsGridItem } from "../containers";
import { ActualStatements, ExtraStatements, FinancialOperationsTableFilter, TaxStatements, YearStatements } from "../modules";
import { getFinancialOperations } from "../selectors";

export const FinancialOperationsTab: FC = () => {
    const { t } = i18n.useTranslation();
    const { setStatement } = GenerateStatementGridItem;
    const financialOperations = useSelector(getFinancialOperations);

    return (
        <GridLayout defaultSpacing>
            <PensionFinancialOperationsGridItem
                entityName={ENTITY_FINANCIAL_OPERATIONS}
                financialOperations={financialOperations}
                Filter={FinancialOperationsTableFilter.Container}
                filterModelSelector={FinancialOperationsTableFilter.getFilterModel}
            />

            <GenerateStatementGridItem
                statements={[
                    setStatement(t("statements.yearlyStatement"), YearStatements),
                    setStatement(t("statements.extraStatement"), ExtraStatements),
                    setStatement(t("statements.taxStatement"), TaxStatements),
                    setStatement(t("statements.actualStatement"), ActualStatements),
                ]}
            />
        </GridLayout>
    );
};
