import { Garage, Region } from "types/garages";
import { NAME } from "./constants";
import { CodeValue } from "types";

export const SET_REGIONS = `${NAME}/SET_REGIONS`;
export const SET_CAR_MAKES = `${NAME}/SET_CAR_MAKES`;
export const SET_GARAGES = `${NAME}/SET_GARAGES`;
export const SET_IS_LOADING = `${NAME}/SET_IS_LOADING`;
export const SET_ERROR_MESSAGE = `${NAME}/SET_ERROR_MESSAGE`;
export const CLEAR = `${NAME}/CLEAR`;

export const setCarMakes = (carMakes: CodeValue[]) => ({
  type: SET_CAR_MAKES,
  payload: carMakes,
});

export const setRegions = (regions: Region[]) => ({
  type: SET_REGIONS,
  payload: regions,
});

export const setGarages = (garages: Garage[]) => ({
  type: SET_GARAGES,
  payload: garages,
});

export const setIsLoading = (isLoading: boolean) => ({
  type: SET_IS_LOADING,
  payload: isLoading,
});

export const setErrorMessage = (errorMessage: string | null) => ({
  type: SET_ERROR_MESSAGE,
  payload: errorMessage,
});

export const clear = () => ({
  type: CLEAR,
});
