import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { ModalBody, ModalFooter } from "ui-library/modules";
import { Box, ContentBox } from "ui-library/layouts";
import { Button, Loading } from "ui-library/atoms";

import { ModalFormLayout } from "containers/modal";
import {
    Field,
    form,
    getAmountNormalizerBySite,
    getFormFieldValue,
    getValidateAmountBySite,
    greaterThanZero,
    InputText,
    lettersNumbersBasicInterpunctionCharacters,
    lettersNumbersSpecialCharactersExtended,
    max10Characters,
    max50Characters,
    maxLengthCharacters,
    maxValue,
    numberCharacters,
    required,
    SegmentedControl,
    SingleSelect,
} from "core/form";
import i18n from "core/i18n";
import pageContext from "core/pageContext";

import {
    CONTRIBUTION_AMOUNT,
    CONTRIBUTION_AMOUNT_TYPE_IS_PERCENTAGE,
    CONTRIBUTION_PERCENTAGE,
    EMPLOYER_NAME,
    ENABLE_EMPLOYER_CONTRIBUTION,
    FREQUENCY_OF_PAYMENT,
    IDENTIFICATION_NUMBER,
} from "./constants";
import { FeatureFlag } from "types";
import { localCurrency } from "core/formatters";
import localized from "core/localized";

const EmployerPaymentsForm = ({ formName, canSubmit, handleSubmit, submitting }) => {
    const { t } = i18n.useTranslation();
    const { siteId, currencyCode } = pageContext.useGetPageLocalization();
    const isSpecialCharactersEnabled = pageContext.useIsFeatureFlagEnabled(FeatureFlag.EDIT_COMPANY_NAME_SPECIAL_CHARACTERS);

    const isEmployerPaymentsEnabled = useSelector(getFormFieldValue(formName, ENABLE_EMPLOYER_CONTRIBUTION));
    const isContributionAmountTypePercentage = useSelector(getFormFieldValue(formName, CONTRIBUTION_AMOUNT_TYPE_IS_PERCENTAGE));

    const frequencyOptions = useSelector(localized.getLocalizedFrequencyOptions);

    return (
        <>
            <ModalBody>
                <Loading loading={submitting}>
                    <Box>
                        <ContentBox>
                            <Field name={ENABLE_EMPLOYER_CONTRIBUTION} component={SegmentedControl} label={t("form.employerPayments")} />
                        </ContentBox>
                    </Box>

                    {isEmployerPaymentsEnabled && (
                        <>
                            <ContentBox>
                                <Field
                                    name={IDENTIFICATION_NUMBER}
                                    component={InputText}
                                    label={t("holder.identificationNumber")}
                                    // validated async in saga!
                                    normalize={[numberCharacters, max10Characters]}
                                    onEnter={handleSubmit}
                                />
                            </ContentBox>

                            <ContentBox>
                                <Field
                                    name={EMPLOYER_NAME}
                                    component={InputText}
                                    label={t("holder.employerName")}
                                    validateStatic={[required]}
                                    normalize={[
                                        isSpecialCharactersEnabled
                                            ? lettersNumbersSpecialCharactersExtended
                                            : lettersNumbersBasicInterpunctionCharacters,
                                        max50Characters,
                                    ]}
                                    onEnter={handleSubmit}
                                />
                            </ContentBox>

                            <ContentBox mt={2}>
                                <Field
                                    name={CONTRIBUTION_AMOUNT_TYPE_IS_PERCENTAGE}
                                    component={SegmentedControl}
                                    label={t("form.employerContribution.amountTypePercentage")}
                                />
                            </ContentBox>

                            {/* Percentage field*/}
                            {isContributionAmountTypePercentage && (
                                <ContentBox>
                                    <Field
                                        name={CONTRIBUTION_PERCENTAGE}
                                        component={InputText}
                                        label={t("form.employerContribution.percentage")}
                                        sufix="%"
                                        validateStatic={[required, greaterThanZero, maxValue(100)]}
                                        normalize={[numberCharacters, maxLengthCharacters(2)]}
                                        onEnter={handleSubmit}
                                    />
                                </ContentBox>
                            )}

                            {/* Amount field */}
                            {!isContributionAmountTypePercentage && (
                                <ContentBox>
                                    <Field
                                        name={CONTRIBUTION_AMOUNT}
                                        component={InputText}
                                        label={t("form.employerContribution.amount")}
                                        sufix={localCurrency(currencyCode)}
                                        validateStatic={getValidateAmountBySite(siteId)}
                                        normalize={[getAmountNormalizerBySite(siteId), max10Characters]}
                                        onEnter={handleSubmit}
                                    />
                                </ContentBox>
                            )}

                            <ContentBox>
                                <Field
                                    name={FREQUENCY_OF_PAYMENT}
                                    component={SingleSelect}
                                    label={t("form.employerContribution.frequency")}
                                    options={frequencyOptions}
                                    validateStatic={[required]}
                                    placeholder={t("common.choose")}
                                />
                            </ContentBox>
                        </>
                    )}
                </Loading>
            </ModalBody>
            <ModalFooter>
                <Button color="blue" variant="contained" onClick={handleSubmit} disabled={!canSubmit}>
                    {t("common.submitChange")}
                </Button>
            </ModalFooter>
        </>
    );
};

EmployerPaymentsForm.propTypes = {
    formName: PropTypes.string.isRequired,
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
};

export const createContainer = (formName) =>
    form(formName, { asyncValidationFields: [IDENTIFICATION_NUMBER] })((props) => {
        const { t } = i18n.useTranslation();

        return (
            <ModalFormLayout formName={formName} formTitle={t("form.employerPayments")} formIcon="circle-dollar">
                <EmployerPaymentsForm formName={formName} {...props} />
            </ModalFormLayout>
        );
    });
