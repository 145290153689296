import { createSelector } from "reselect";

import { app, claims } from "core/util";
import pageContext from "core/pageContext";

import { ClaimsLineOfBusinessClass } from "enums";
import { ContractPolicy, NormalizedData, ProductPolicy } from "types";

import { ContractListState, NAME } from "./constants";
import products from "core/products";

const getModel: (state) => ContractListState = app.createGetModel(NAME);

export const getActiveContractIds = (state): number[] => getModel(state).activeContractIds;

export const getInactiveContractIds = (state): number[] => getModel(state).inactiveContractIds;

export const getContractList = (state): ContractPolicy[] => Object.values(getModel(state).contracts);

export const getContractsWithoutElCom = createSelector(getContractList, (contractsList: ContractPolicy[]) =>
    contractsList
        .filter((item: ContractPolicy) => item.isActive)
        .filter((item: ContractPolicy) => item.overview && !item.overview.isElectronicCommunicationEnabled),
);

//todo - do not export NormalizedData
export const getContractNormalizedData = (state): NormalizedData<ContractPolicy> => getModel(state).contracts;

export const getContractById = (state, idObject: number): ContractPolicy => getContractNormalizedData(state)[idObject];

export const createGetContractById = (idObject: number) => (state) => getContractById(state, idObject);

const getProductGroupLevelTwo = (idObject: number): ((idObject: number) => ProductPolicy) =>
    createSelector(
        (state) => getContractById(state, idObject),
        (contract: ContractPolicy) => contract && products.getProductPolicyByPGL2(contract.idEnuProductGroupLevelTwo),
    );

export const createGetProductGroupLvl2ById = (idObject: number) =>
    createSelector(getProductGroupLevelTwo(idObject), (policy) => policy && policy.productGroupLevelTwo);

export const createGetProductGroupUrlNameById = (idObject: number) =>
    createSelector(
        (state) => getContractById(state, idObject),
        (policy: ContractPolicy) => policy && products.getProductPolicyByPGL2(policy.idEnuProductGroupLevelTwo)?.urlName,
    );

export const createGetFinalRedirectUrl = (idObject, selectedLineOfBusinessClassId) =>
    createSelector(pageContext.getClaimReportingUrl, (redirectUrlPrefix) => {
        if (selectedLineOfBusinessClassId) {
            return claims.getClaimReportRedirectUrl(
                redirectUrlPrefix,
                idObject,
                ClaimsLineOfBusinessClass[selectedLineOfBusinessClassId].externalId,
            );
        } else {
            return null;
        }
    });
