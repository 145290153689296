import { NAME } from "./constants";
import reducer from "./reducer";
import saga from "./saga";
import innerRoutes from "./innerRoutes";

import {
    AgentTab,
    ChangesTab,
    DetailTab,
    DocumentsTab,
    FinancialOperationsTab,
    FinancialStatementTab,
    OverviewTab,
    PisTab,
    PolicyHolderTab,
} from "./tabs";
import { FullPageFundsChangeForm } from "./form";

export { DetailPensionDssPage } from "./Container";

export const DetailPensionDssTabs = {
    OverviewTab,
    PolicyHolderTab,
    DetailTab,
    ChangesTab,
    FinancialOperationsTab,
    FinancialStatementTab,
    DocumentsTab,
    AgentTab,
    PisTab,
};

export const DetailPensionDssForms = {
    FullPageFundsChangeForm,
};

const detailDss = {
    NAME,
    reducer,
    saga,
    innerRoutes,
};

export default detailDss;
