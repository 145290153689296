import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { List } from "immutable";

import { Accordion, Option, Select, Typography, UnorderedList } from "ui-library/atoms";
import { Box, ContentBox, Flexbox, GridItem } from "ui-library/layouts";
import { DataTable, InfoPanel } from "ui-library/modules";

import i18n from "core/i18n";
import { AmountLegacy } from "model/common";
import { formatYesNo, localAmountWithCurrencyLegacy } from "core/formatters";

import { setDetailSelectedInsured } from "../actions";
import { createGetTariffLegendsAxa, getDetailSelectedInsured, getInsuredsSelectOptionsAxa, getSelectedInsured } from "../selectors";

const insurantColumns = (t, onetimeInsurance) => [
    {
        field: "insuranceTariff",
        headerName: t("life.tariffCode"),
        sortable: false,
        flex: 2,
        renderCell: (params) => params.value?.code,
    },
    {
        field: "insuranceIndexation",
        headerName: t("life.indexation"),
        sortable: false,
        flex: 1,
        renderCell: (params) => formatYesNo(t, params.value),
    },
    {
        field: "insuranceAmount",
        headerName: t("insurance.insuranceAmount"),
        sortable: false,
        flex: 2,
        align: "right",
        headerAlign: "right",
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
    },
    {
        field: "insuranceCost",
        headerName: onetimeInsurance ? t("life.oneTimeInsuranceCost") : t("insurance.insuranceCost"),
        sortable: false,
        flex: 2,
        align: "right",
        headerAlign: "right",
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
    },
];

const getInsuranceSum = (rows) => {
    if (rows.size === 0) {
        return null;
    }
    const amount = rows.reduce((acc, value) => acc + Number(value.insuranceCost.value), 0);
    return AmountLegacy.fromServer({ Value: `${amount}`, CurrencyCode: rows.first().insuranceCost.currencyCode });
};

export const InsuredPersonGridItemAxa = () => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    const insuredsSelectOptions = useSelector(getInsuredsSelectOptionsAxa);
    const actualChooseSelection = useSelector(getDetailSelectedInsured);
    const selectedInsuredData = useSelector(getSelectedInsured);
    const tariffs = selectedInsuredData ? selectedInsuredData.get("tariff") : new List();

    const tariffRows = selectedInsuredData && selectedInsuredData.get("tariff").toArray();
    const insuranceSum = getInsuranceSum(tariffs);
    const tariffLegends = useSelector(createGetTariffLegendsAxa(tariffs));

    return (
        <GridItem xs={12}>
            <InfoPanel
                icon={"user-check"}
                title={
                    <Flexbox alignItems="center" gap={1}>
                        {t("insurance.coverages")}
                    </Flexbox>
                }
            >
                <Box>
                    {/* Select */}
                    <ContentBox>
                        <Select
                            value={actualChooseSelection}
                            onChange={(event) => dispatch(setDetailSelectedInsured(event.target.value))}
                            placeholder={t("common.chooseInsurance")}
                            label={t("insurance.insurance")}
                            id="selectOther"
                            fullWidth
                        >
                            {actualChooseSelection && (
                                <Option key="" value="">
                                    {t("common.noneSelected")}
                                </Option>
                            )}
                            {insuredsSelectOptions &&
                                insuredsSelectOptions.map((selectOption) => (
                                    <Option key={selectOption.value} value={selectOption.value}>
                                        {selectOption.label}
                                    </Option>
                                ))}
                        </Select>
                    </ContentBox>

                    {selectedInsuredData && (
                        <>
                            {/* Insured person tariff table with amounts */}
                            <Box>
                                <DataTable
                                    disableColumnReorder
                                    getRowId={(row) => row.reactKey}
                                    rows={tariffRows}
                                    columns={insurantColumns(t, false)}
                                    summaryRow={{
                                        desc: t("life.insuranceCostSummary"),
                                        total: localAmountWithCurrencyLegacy(insuranceSum),
                                    }}
                                />
                            </Box>

                            {/* Risk only contract insurance cost note. */}
                            <Box mt={2}>
                                <Typography markDown={t("life.insuranceCostNote")} />
                            </Box>

                            {/* Tariff legend */}
                            <Box>
                                <Accordion
                                    id="tarifAccorID"
                                    data={[
                                        {
                                            title: t("life.tarrifCodes"),
                                            children: (
                                                <UnorderedList>
                                                    {tariffLegends.map((item) => (
                                                        <Typography key={item}>{item}</Typography>
                                                    ))}
                                                </UnorderedList>
                                            ),
                                        },
                                    ]}
                                />
                            </Box>
                        </>
                    )}
                </Box>
            </InfoPanel>
        </GridItem>
    );
};
