import React, { FC } from "react";

import { GridLayout } from "ui-library/layouts";

import i18n from "core/i18n";
import { GenerateStatementGridItem } from "containers/contract";
import { MovementsOverviewGridItem } from "../containers";
import { ActualStatements, ExtraStatements, YearStatements } from "../modules";

export const StatementsAndPaymentsTab: FC = () => {
    const { t } = i18n.useTranslation();
    const { setStatement } = GenerateStatementGridItem;

    return (
        <GridLayout defaultSpacing>
            <MovementsOverviewGridItem />

            <GenerateStatementGridItem
                statements={[
                    setStatement(t("statements.yearlyStatement"), YearStatements),
                    setStatement(t("statements.extraStatement"), ExtraStatements),
                    setStatement(t("statements.actualStatement"), ActualStatements),
                ]}
            />
        </GridLayout>
    );
};
