import { call, fork, put, select } from "redux-saga/effects";

import queryString, { parse } from "query-string";
import { compile, match, pathToRegexp } from "path-to-regexp";

import { fn } from "core/util";
import { innerRoutes, pageRoutes } from "routeUrls";
import { Locales } from "enums";
import { Pages, Params, ParamStrings, Tabs } from "routeConstants";

import { getAllRoutes } from "./staticRouteRegister";
import { PageInformation, LANG_PARAM } from "./constants";
import { Locale } from "types";
import userContracts from "../userContracts";
import products from "../products";
import router from "./index";
import { createGetContractById } from "../userContracts/selectors";
import { getProductDefinition } from "../products/helper";
import pageContext from "../pageContext";

export const routerWrapper = ({
    getDataForPage = () => [],
    onPageEnter = fn.noop(),
    onPageNonAuthenticatedEnter = fn.noop(),
    onInnerRouteChange = fn.noop,
    onModalOpen = fn.noop,
    clearDataForPage = () => [],
}) => ({
    getDataForPage,
    onPageEnter,
    onPageNonAuthenticatedEnter,
    onInnerRouteChange,
    onModalOpen,
    clearDataForPage,
});

export function* getEditLinkByIdObject(idObject: number) {
    if (!idObject) {
        return null;
    }

    const contract = yield select(userContracts.createGetContractById(idObject));
    const isSlovakSite = yield select(pageContext.getIsSlovakSite);
    const productDefinition = products.getProductDefinition(contract?.idEnuProductGroupLevelTwo, isSlovakSite);

    if (!productDefinition || !productDefinition.detailRoute) {
        return null;
    }
    return getStaticUrl(productDefinition.topLevelRoute, Tabs.CHANGES, { idObject });
}

export const enhanceUrlWithLangParam = (originalLink: string, locale: Locale) => {
    const linkParts = originalLink.split("?");
    const searchParams = new URLSearchParams(linkParts[1]);

    if (locale === Locales.en_US) {
        searchParams.set(LANG_PARAM, Locales.en_US.languageCode);
    } else if (locale !== Locales.en_US && searchParams.get(LANG_PARAM)) {
        searchParams.delete(LANG_PARAM);
    }

    return searchParams.toString() ? `${linkParts[0]}?${searchParams.toString()}` : linkParts[0];
};

const concatNoMultipleSlash = (path: string, innerPath: string): string => {
    if (path.endsWith("/")) {
        return path.concat(innerPath);
    } else {
        return path.concat("/" + innerPath);
    }
};

interface ParamsObject {
    [param: string | Params | ParamStrings]: string | number;
}

export const getStaticUrl = (route: Pages, innerRoute: Tabs = null, params: ParamsObject = {}, query = {}): string => {
    if (!route) {
        return "";
    }

    // Quick and dirty solution to preserve lang param in url.
    const paramsFromUrl = new URLSearchParams(window.location.search);
    if (paramsFromUrl.get(LANG_PARAM)) {
        query[LANG_PARAM] = paramsFromUrl.get(LANG_PARAM);
    }

    const path = pageRoutes[route];
    const innerPath = !!innerRoute ? innerRoutes[innerRoute] : null;
    const resolvedPath = !!innerRoute ? compile(concatNoMultipleSlash(path, innerPath))(params) : compile(path)(params);
    const stringQuery = Object.keys(query).length > 0 ? `?${queryString.stringify(query)}` : "";
    return `${resolvedPath}${stringQuery}`;
};

export const parseLocation = (pathname: string, search: string): PageInformation => {
    const allRoutes = getAllRoutes();
    let name: string = null;
    let topUrl: string = null;
    let innerName: string = null;
    let innerUrl: string = null;
    let redirect = null;

    Object.values(allRoutes).every((page) => {
        const topRouteUrl = page.topRouteUrl;

        // First find matcher that prefixes the page - does not have to be exact match.
        const evalTopMatch = match(topRouteUrl + "(.*)", { decode: decodeURIComponent });
        if (evalTopMatch(pathname) !== false) {
            name = page.name;
            topUrl = topRouteUrl;
            const innerRoutes = page.innerRoutes;

            // If this page does not have inner pages we need exact match. Otherwise, redirect to proper top url.
            const evalExactTopMatch = match(topRouteUrl, { decode: decodeURIComponent });
            if (!innerRoutes && evalExactTopMatch(pathname) === false) {
                redirect = getTopLevelUrl(pathname, topRouteUrl);
                return false;
            }

            if (innerRoutes) {
                Object.keys(innerRoutes).forEach((innerLevelName: string) => {
                    const innerLevelUrlPath = innerRoutes[innerLevelName];
                    const evalInnerMatch = match(concatNoMultipleSlash(topRouteUrl, innerLevelUrlPath), { decode: decodeURIComponent });
                    if (evalInnerMatch(pathname) !== false) {
                        innerName = innerLevelName;
                        innerUrl = innerLevelUrlPath;
                    }
                });

                // At this point if we did not find exact match we need to redirect to first of inner routes.
                if (!innerName) {
                    const first = Object.keys(innerRoutes)[0];
                    redirect = getTopLevelUrl(pathname, topRouteUrl) + "/" + innerRoutes[first];
                    innerName = first;
                    return false;
                }
            }
            return false;
        }
        return true;
    });

    if (redirect) {
        const matchedTopLevelObject = match(topUrl)(pathname);
        const matchedInnerObject = match(concatNoMultipleSlash(topUrl, innerUrl))(pathname);
        return {
            redirect,
            name: Pages[name],
            innerName: Tabs[innerName],
            // @ts-ignore todo
            params: { ...matchedTopLevelObject.params, ...matchedInnerObject.params },
            query: parse(search),
        };
    }
    if (!name) {
        return null;
    }
    if (!innerName) {
        const matchedTopLevelObject = match(topUrl)(pathname);
        // @ts-ignore
        return { name, params: matchedTopLevelObject.params, query: parse(search) };
    } else {
        const matchedTopLevelObject = match(topUrl)(pathname);
        const matchedInnerObject = match(concatNoMultipleSlash(topUrl, innerUrl))(pathname);
        // @ts-ignore
        return { name, innerName, params: { ...matchedTopLevelObject.params, ...matchedInnerObject.params }, query: parse(search) };
    }
};

// TODO: Make saner version.
const getTopLevelUrl = (locationPath: string, topLevelUrl: string): string => {
    const pathMask = pathToRegexp(topLevelUrl).toString();
    const updatedPathMask = pathMask.replace("/^", "(").replace("$/i", ")").replace("(?:\\/([^\\/#\\?]+?))", "(?:\\/([^\\/#\\?]+))");

    const topUrlRegEx = new RegExp(updatedPathMask);
    const matches = locationPath.match(topUrlRegEx);
    if (matches && matches[1]) {
        return matches[1].replace(/\/+$/, "");
    }
    return null;
};
