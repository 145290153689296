import { Route, Routes } from "react-router-dom";

import { innerRoutes, pageRoutes } from "routeUrls";
import { Pages, Tabs } from "routeConstants";

import { LoginPage } from "app/pages/login";
import { RegistrationPage } from "app/pages/registration";
import { ResetPasswordPage } from "app/pages/resetPassword";
import { DisclaimerPage } from "app/pages/disclaimer";
import { ContactPage } from "app/pages/contact";
import { ContractListPage } from "app/pages/contractList";
import { PaymentPage, PaymentsTabs } from "app/pages/payment";
import { PaymentNotification } from "app/pages/onlinePaymentNotification";
import { DetailVehiclePage, DetailVehicleTabs } from "app/pages/detailVehicle";
import { DetailHomePage, DetailHomeTabs } from "app/pages/detailHome";
import { DetailLifePage, DetailLifeTabs } from "app/pages/detailLife";
import { DetailPensionPpForms, DetailPensionPpPage, DetailPensionPpTabs } from "app/pages/detailPp";
import { DetailPensionDpsForms, DetailPensionDpsPage, DetailPensionDpsTabs } from "app/pages/detailDps";
import { DetailPensionDssForms, DetailPensionDssPage, DetailPensionDssTabs } from "app/pages/detailDss";
import { DetailPensionDdsForms, DetailPensionDdsPage, DetailPensionDdsTabs } from "app/pages/detailDds";
import { DetailTravelPage, DetailTravelTabs } from "app/pages/detailTravel";
import {
    DetailFundsDirectForms,
    DetailMutualFundsDirectPage,
    DetailMutualFundsDirectTabs,
    ProfitDetailTable as ProfitDetailTableDirect,
} from "app/pages/detailMutualFundsDirect";
import {
    DetailFundsRegularForms,
    DetailMutualFundsRegularPage,
    DetailMutualFundsRegularTabs,
    FinancialOperationDetailBuy,
    FinancialOperationDetailSell,
    ProfitDetailTable as ProfitDetailTableRegular,
} from "app/pages/detailMutualFundsRegular";
import { ClaimsPage, ClaimsTabs } from "app/pages/claims";
import { IncidentDetailPage } from "app/pages/detailIncident";
import { MessagesPage, MessagesTabs } from "app/pages/messages";
import { UserSettingsPage, UserSettingsTabs, UserVerificationForm } from "app/pages/userSettings";
import { EpinExternalPage, EpinExternalTabs } from "app/pages/epinExternal";
import { ImpersonatePage, ImpersonateTabs } from "app/pages/impersonate";
import { NotificationPage, NotificationTabs } from "app/pages/notification";
import { GaragePage } from "app/pages/garage";
import { Protected } from "containers/roleAccess";
import { Roles } from "enums";
import { CampaignPage, CampaignTabs } from "app/pages/campaign";

export const AppRoutes = () => {
    return (
        <Routes>
            <Route path={pageRoutes[Pages.LOGIN]} element={<LoginPage />} />

            <Route path={pageRoutes[Pages.REGISTRATION]} element={<RegistrationPage />} />

            <Route path={pageRoutes[Pages.RESET_PASSWORD]} element={<ResetPasswordPage />} />

            <Route path={pageRoutes[Pages.IMPERSONATE]} element={<ImpersonatePage />}>
                <Route path={innerRoutes[Tabs.IMPERSONATE_UUID]} element={<ImpersonateTabs.InnerContainer />} />
            </Route>

            <Route path={pageRoutes[Pages.DISCLAIMER]} element={<DisclaimerPage />} />

            <Route path={pageRoutes[Pages.CONTACT]} element={<ContactPage />} />

            <Route path={pageRoutes[Pages.CONTRACTS]} element={<ContractListPage />} />

            <Route path={pageRoutes[Pages.EPIN_EXTERNAL]} element={<EpinExternalPage />}>
                <Route path={innerRoutes[Tabs.EPIN_EXTERNAL_HASH]} element={<EpinExternalTabs.InnerContainer />} />
            </Route>

            <Route path={pageRoutes[Pages.NOTIFICATION]} element={<NotificationPage />}>
                <Route path={innerRoutes[Tabs.NOTIFICATION_CHANGE_PASSWORD_SUCCESS]} element={<NotificationTabs.ChangePasswordSuccess />} />
                <Route path={innerRoutes[Tabs.NOTIFICATION_CHANGE_USERNAME_SUCCESS]} element={<NotificationTabs.ChangeUsernameSuccess />} />
            </Route>

            <Route
                path={pageRoutes[Pages.CONTRACT_VEHICLE]}
                element={
                    <Protected roles={[Roles.AUTHENTICATED, Roles.OT_CONTRACT_CHANGE]} redirect>
                        <DetailVehiclePage />
                    </Protected>
                }
            >
                <Route
                    path={innerRoutes[Tabs.OVERVIEW]}
                    element={
                        <Protected roles={[Roles.AUTHENTICATED, Roles.OT_CONTRACT_CHANGE]} redirect>
                            <DetailVehicleTabs.OverviewTab />
                        </Protected>
                    }
                />
                <Route
                    path={innerRoutes[Tabs.HOLDER]}
                    element={
                        <Protected roles={[Roles.AUTHENTICATED]} redirect>
                            <DetailVehicleTabs.PolicyHolderTab />
                        </Protected>
                    }
                />
                <Route
                    path={innerRoutes[Tabs.DETAIL]}
                    element={
                        <Protected roles={[Roles.AUTHENTICATED]} redirect>
                            <DetailVehicleTabs.DetailTab />
                        </Protected>
                    }
                />
                <Route
                    path={innerRoutes[Tabs.CHANGES]}
                    element={
                        <Protected roles={[Roles.AUTHENTICATED, Roles.OT_CONTRACT_CHANGE]} redirect>
                            <DetailVehicleTabs.ChangesTab />
                        </Protected>
                    }
                />
                <Route
                    path={innerRoutes[Tabs.FINANCIAL_OPERATIONS]}
                    element={
                        <Protected roles={[Roles.AUTHENTICATED]} redirect>
                            <DetailVehicleTabs.FinancialOperationsTab />
                        </Protected>
                    }
                />
                <Route
                    path={innerRoutes[Tabs.CLAIMS]}
                    element={
                        <Protected roles={[Roles.AUTHENTICATED]} redirect>
                            <DetailVehicleTabs.ClaimsTab />
                        </Protected>
                    }
                />
                <Route
                    path={innerRoutes[Tabs.DOCUMENTS]}
                    element={
                        <Protected roles={[Roles.AUTHENTICATED]} redirect>
                            <DetailVehicleTabs.DocumentsTab />
                        </Protected>
                    }
                />
                <Route
                    path={innerRoutes[Tabs.AGENT]}
                    element={
                        <Protected roles={[Roles.AUTHENTICATED]} redirect>
                            <DetailVehicleTabs.AgentTab />
                        </Protected>
                    }
                />
            </Route>

            <Route path={pageRoutes[Pages.CONTRACT_HOME]} element={<DetailHomePage />}>
                <Route path={innerRoutes[Tabs.OVERVIEW]} element={<DetailHomeTabs.OverviewTab />} />
                <Route path={innerRoutes[Tabs.HOLDER]} element={<DetailHomeTabs.PolicyHolderTab />} />
                <Route path={innerRoutes[Tabs.DETAIL]} element={<DetailHomeTabs.DetailTab />} />
                <Route path={innerRoutes[Tabs.CHANGES]} element={<DetailHomeTabs.ChangesTab />} />
                <Route path={innerRoutes[Tabs.FINANCIAL_OPERATIONS]} element={<DetailHomeTabs.FinancialOperationsTab />} />
                <Route path={innerRoutes[Tabs.CLAIMS]} element={<DetailHomeTabs.ClaimsTab />} />
                <Route path={innerRoutes[Tabs.DOCUMENTS]} element={<DetailHomeTabs.DocumentsTab />} />
                <Route path={innerRoutes[Tabs.AGENT]} element={<DetailHomeTabs.AgentTab />} />
            </Route>

            <Route path={pageRoutes[Pages.CONTRACT_PP]} element={<DetailPensionPpPage />}>
                <Route path={innerRoutes[Tabs.OVERVIEW]} element={<DetailPensionPpTabs.OverviewTab />} />
                <Route path={innerRoutes[Tabs.HOLDER]} element={<DetailPensionPpTabs.PolicyHolderTab />} />
                <Route path={innerRoutes[Tabs.DETAIL]} element={<DetailPensionPpTabs.DetailTab />} />
                <Route path={innerRoutes[Tabs.CHANGES]} element={<DetailPensionPpTabs.ChangesTab />} />
                <Route path={innerRoutes[Tabs.FINANCIAL_STATEMENT]} element={<DetailPensionPpTabs.FinancialStatementTab />} />
                <Route path={innerRoutes[Tabs.FINANCIAL_OPERATIONS]} element={<DetailPensionPpTabs.FinancialOperationsTab />} />
                <Route path={innerRoutes[Tabs.DOCUMENTS]} element={<DetailPensionPpTabs.DocumentsTab />} />
                <Route path={innerRoutes[Tabs.REDEEM_SAVINGS]} element={<DetailPensionPpTabs.RedeemSavingsTab />} />
                <Route path={innerRoutes[Tabs.AGENT]} element={<DetailPensionPpTabs.AgentTab />} />
                <Route path={innerRoutes[Tabs.PENSION_REDEEM_SAVINGS_FORM]} element={<DetailPensionPpForms.FullPageRedeemSavingsForm />} />
            </Route>

            <Route path={pageRoutes[Pages.CONTRACT_DPS]} element={<DetailPensionDpsPage />}>
                <Route path={innerRoutes[Tabs.OVERVIEW]} element={<DetailPensionDpsTabs.OverviewTab />} />
                <Route path={innerRoutes[Tabs.HOLDER]} element={<DetailPensionDpsTabs.PolicyHolderTab />} />
                <Route path={innerRoutes[Tabs.DETAIL]} element={<DetailPensionDpsTabs.DetailTab />} />
                <Route path={innerRoutes[Tabs.CHANGES]} element={<DetailPensionDpsTabs.ChangesTab />} />
                <Route path={innerRoutes[Tabs.FINANCIAL_STATEMENT]} element={<DetailPensionDpsTabs.FinancialStatementTab />} />
                <Route path={innerRoutes[Tabs.FINANCIAL_OPERATIONS]} element={<DetailPensionDpsTabs.FinancialOperationsTab />} />
                <Route path={innerRoutes[Tabs.DOCUMENTS]} element={<DetailPensionDpsTabs.DocumentsTab />} />
                <Route path={innerRoutes[Tabs.REDEEM_SAVINGS]} element={<DetailPensionDpsTabs.RedeemSavingsTab />} />
                <Route path={innerRoutes[Tabs.AGENT]} element={<DetailPensionDpsTabs.AgentTab />} />
                <Route path={innerRoutes[Tabs.PENSION_REDEEM_SAVINGS_FORM]} element={<DetailPensionDpsForms.FullPageRedeemSavingsForm />} />
            </Route>

            <Route path={pageRoutes[Pages.CONTRACT_DSS]} element={<DetailPensionDssPage />}>
                <Route path={innerRoutes[Tabs.OVERVIEW]} element={<DetailPensionDssTabs.OverviewTab />} />
                <Route path={innerRoutes[Tabs.HOLDER]} element={<DetailPensionDssTabs.PolicyHolderTab />} />
                <Route path={innerRoutes[Tabs.DETAIL]} element={<DetailPensionDssTabs.DetailTab />} />
                <Route path={innerRoutes[Tabs.CHANGES]} element={<DetailPensionDssTabs.ChangesTab />} />
                <Route path={innerRoutes[Tabs.FINANCIAL_STATEMENT]} element={<DetailPensionDssTabs.FinancialStatementTab />} />
                <Route path={innerRoutes[Tabs.FINANCIAL_OPERATIONS]} element={<DetailPensionDssTabs.FinancialOperationsTab />} />
                <Route path={innerRoutes[Tabs.PIS]} element={<DetailPensionDssTabs.PisTab />} />
                <Route path={innerRoutes[Tabs.DOCUMENTS]} element={<DetailPensionDssTabs.DocumentsTab />} />
                <Route path={innerRoutes[Tabs.AGENT]} element={<DetailPensionDssTabs.AgentTab />} />
                <Route path={innerRoutes[Tabs.PENSION_DSS_FUNDS_CHANGE_FORM]} element={<DetailPensionDssForms.FullPageFundsChangeForm />} />
            </Route>

            <Route path={pageRoutes[Pages.CONTRACT_DDS]} element={<DetailPensionDdsPage />}>
                <Route path={innerRoutes[Tabs.OVERVIEW]} element={<DetailPensionDdsTabs.OverviewTab />} />
                <Route path={innerRoutes[Tabs.HOLDER]} element={<DetailPensionDdsTabs.PolicyHolderTab />} />
                <Route path={innerRoutes[Tabs.DETAIL]} element={<DetailPensionDdsTabs.DetailTab />} />
                <Route path={innerRoutes[Tabs.CHANGES]} element={<DetailPensionDdsTabs.ChangesTab />} />
                <Route path={innerRoutes[Tabs.FINANCIAL_STATEMENT]} element={<DetailPensionDdsTabs.FinancialStatementTab />} />
                <Route path={innerRoutes[Tabs.FINANCIAL_OPERATIONS]} element={<DetailPensionDdsTabs.FinancialOperationsTab />} />
                <Route path={innerRoutes[Tabs.DOCUMENTS]} element={<DetailPensionDdsTabs.DocumentsTab />} />
                <Route path={innerRoutes[Tabs.AGENT]} element={<DetailPensionDdsTabs.AgentTab />} />
                <Route path={innerRoutes[Tabs.PENSION_DDS_FUNDS_CHANGE_FORM]} element={<DetailPensionDdsForms.FullPageFundsChangeForm />} />
            </Route>

            <Route path={pageRoutes[Pages.CONTRACT_LIFE]} element={<DetailLifePage />}>
                <Route path={innerRoutes[Tabs.OVERVIEW]} element={<DetailLifeTabs.OverviewTab />} />
                <Route path={innerRoutes[Tabs.HOLDER]} element={<DetailLifeTabs.PolicyHolderTab />} />
                <Route path={innerRoutes[Tabs.DETAIL]} element={<DetailLifeTabs.DetailTab />} />
                <Route path={innerRoutes[Tabs.CHANGES]} element={<DetailLifeTabs.ChangesTab />} />
                <Route path={innerRoutes[Tabs.FINANCIAL_OPERATIONS]} element={<DetailLifeTabs.FinancialOperationsTab />} />
                <Route path={innerRoutes[Tabs.CLAIMS]} element={<DetailLifeTabs.ClaimsTab />} />
                <Route path={innerRoutes[Tabs.DOCUMENTS]} element={<DetailLifeTabs.DocumentsTab />} />
                <Route path={innerRoutes[Tabs.AGENT]} element={<DetailLifeTabs.AgentTab />} />
            </Route>

            <Route path={pageRoutes[Pages.CONTRACT_DIRECT]} element={<DetailMutualFundsDirectPage />}>
                <Route path={innerRoutes[Tabs.OVERVIEW]} element={<DetailMutualFundsDirectTabs.OverviewTab />} />
                <Route path={innerRoutes[Tabs.HOLDER]} element={<DetailMutualFundsDirectTabs.PolicyHolderTab />} />
                <Route path={innerRoutes[Tabs.PORTFOLIO]} element={<DetailMutualFundsDirectTabs.PortfolioTab />} />
                <Route path={innerRoutes[Tabs.CHANGES]} element={<DetailMutualFundsDirectTabs.ChangesTab />} />
                <Route path={innerRoutes[Tabs.FINANCIAL_STATEMENT]} element={<DetailMutualFundsDirectTabs.StatementsAndPaymentsTab />} />
                <Route path={innerRoutes[Tabs.FINANCIAL_OPERATIONS]} element={<DetailMutualFundsDirectTabs.FinancialOperationsTab />} />
                <Route path={innerRoutes[Tabs.DOCUMENTS]} element={<DetailMutualFundsDirectTabs.DocumentsTab />} />
                <Route path={innerRoutes[Tabs.AGENT]} element={<DetailMutualFundsDirectTabs.AgentTab />} />
                <Route path={innerRoutes[Tabs.DIRECT_PROFIT_DETAIL]} element={<ProfitDetailTableDirect />} />
                <Route path={innerRoutes[Tabs.SELL_SWITCH_FORM]} element={<DetailFundsDirectForms.FundsSellSwitchForm />} />
                <Route path={innerRoutes[Tabs.SELL_SWITCH_FORM_WITH_ACTION]} element={<DetailFundsDirectForms.FundsSellSwitchForm />} />
            </Route>

            <Route path={pageRoutes[Pages.CONTRACT_REGULAR]} element={<DetailMutualFundsRegularPage />}>
                <Route path={innerRoutes[Tabs.OVERVIEW]} element={<DetailMutualFundsRegularTabs.OverviewTab />} />
                <Route path={innerRoutes[Tabs.HOLDER]} element={<DetailMutualFundsRegularTabs.PolicyHolderTab />} />
                <Route path={innerRoutes[Tabs.PORTFOLIO]} element={<DetailMutualFundsRegularTabs.PortfolioTab />} />
                <Route path={innerRoutes[Tabs.CHANGES]} element={<DetailMutualFundsRegularTabs.ChangesTab />} />
                <Route path={innerRoutes[Tabs.FINANCIAL_OPERATIONS]} element={<DetailMutualFundsRegularTabs.FinancialOperationsTab />} />
                <Route path={innerRoutes[Tabs.FINANCIAL_STATEMENT]} element={<DetailMutualFundsRegularTabs.StatementsAndPaymentsTab />} />
                <Route path={innerRoutes[Tabs.DOCUMENTS]} element={<DetailMutualFundsRegularTabs.DocumentsTab />} />
                <Route path={innerRoutes[Tabs.AGENT]} element={<DetailMutualFundsRegularTabs.AgentTab />} />
                <Route path={innerRoutes[Tabs.REGULAR_FINANCIAL_OPERATION_DETAIL_BUY]} element={<FinancialOperationDetailBuy />} />
                <Route path={innerRoutes[Tabs.REGULAR_FINANCIAL_OPERATION_DETAIL_SELL]} element={<FinancialOperationDetailSell />} />
                <Route path={innerRoutes[Tabs.REGULAR_PROFIT_DETAIL]} element={<ProfitDetailTableRegular />} />
                <Route path={innerRoutes[Tabs.SELL_SWITCH_FORM]} element={<DetailFundsRegularForms.FundsSellSwitchForm />} />
                <Route path={innerRoutes[Tabs.INVESTMENT_PROFILE_FORM]} element={<DetailFundsRegularForms.InvestmentProfileForm />} />
                <Route path={innerRoutes[Tabs.SELL_SWITCH_FORM_WITH_ACTION]} element={<DetailFundsRegularForms.FundsSellSwitchForm />} />
            </Route>

            <Route path={pageRoutes[Pages.CONTRACT_TRAVEL]} element={<DetailTravelPage />}>
                <Route path={innerRoutes[Tabs.OVERVIEW]} element={<DetailTravelTabs.OverviewTab />} />
                <Route path={innerRoutes[Tabs.HOLDER]} element={<DetailTravelTabs.PolicyHolderTab />} />
                <Route path={innerRoutes[Tabs.DETAIL]} element={<DetailTravelTabs.DetailTab />} />
                <Route path={innerRoutes[Tabs.CHANGES]} element={<DetailTravelTabs.ChangesTab />} />
                <Route path={innerRoutes[Tabs.FINANCIAL_OPERATIONS]} element={<DetailTravelTabs.FinancialOperationsTab />} />
                <Route path={innerRoutes[Tabs.CLAIMS]} element={<DetailTravelTabs.ClaimsTab />} />
                <Route path={innerRoutes[Tabs.DOCUMENTS]} element={<DetailTravelTabs.DocumentsTab />} />
                <Route path={innerRoutes[Tabs.AGENT]} element={<DetailTravelTabs.AgentTab />} />
            </Route>

            <Route path={pageRoutes[Pages.CLAIMS]} element={<ClaimsPage />}>
                <Route path={innerRoutes[Tabs.REPORT_CLAIM]} element={<ClaimsTabs.ReportClaimTab />} />
                <Route path={innerRoutes[Tabs.REPORT_CLAIM_OLD]} element={<ClaimsTabs.ReportClaimTabOld />} />
                <Route path={innerRoutes[Tabs.CLAIMS_LIST]} element={<ClaimsTabs.ClientClaimsTab />} />
            </Route>

            <Route path={pageRoutes[Pages.INCIDENT_DETAIL]} element={<IncidentDetailPage />} />

            <Route path={pageRoutes[Pages.ONLINE_PAYMENT_NOTIFICATION]} element={<PaymentNotification />} />

            <Route path={pageRoutes[Pages.MESSAGES]} element={<MessagesPage />}>
                <Route path={innerRoutes[Tabs.ANNOUNCEMENTS_TAB]} element={<MessagesTabs.AnnouncementsTab />} />
                <Route path={innerRoutes[Tabs.REQUESTS]} element={<MessagesTabs.RequestsTab />} />
            </Route>

            <Route path={pageRoutes[Pages.USER]} element={<UserSettingsPage />}>
                <Route path={innerRoutes[Tabs.USER_ACCOUNT]} element={<UserSettingsTabs.ProfileTab />} />
                <Route path={innerRoutes[Tabs.VERIFICATION]} element={<UserSettingsTabs.VerificationTab />} />
                <Route path={innerRoutes[Tabs.DATA_PROCESSING]} element={<UserSettingsTabs.DataProcessingTab />} />
                <Route path={innerRoutes[Tabs.UNVERIFIED_USER]} element={<UserSettingsTabs.UnverifiedUserTab />} />
                <Route path={innerRoutes[Tabs.USER_VERIFICATION_FORM]} element={<UserVerificationForm />} />
            </Route>

            <Route path={pageRoutes[Pages.ONLINE_PAYMENT]} element={<PaymentPage />}>
                <Route path={innerRoutes[Tabs.PAYMENTS_PAY]} element={<PaymentsTabs.PayTab />} />
            </Route>

            <Route path={pageRoutes[Pages.CAMPAIGN]} element={<CampaignPage />}>
                <Route path={innerRoutes[Tabs.ELECTRONIC_COMMUNICATION]} element={<CampaignTabs.ElectronicCommunicationTab />} />
                <Route
                    path={innerRoutes[Tabs.ELECTRONIC_COMMUNICATION_SUCCESS]}
                    element={<CampaignTabs.ElectronicCommunicationSuccessTab />}
                />
            </Route>

            <Route path={pageRoutes[Pages.GARAGE]} element={<GaragePage />}></Route>
        </Routes>
    );
};
