import { combineReducers } from "redux-immutable";

import { app } from "core/util";

import { CLEAR, SET_CAR_MAKES, SET_GARAGES, SET_REGIONS, SET_IS_LOADING, SET_ERROR_MESSAGE } from "./actions";

export default combineReducers({
  data: combineReducers({
    carMakes: app.createDataReducer(SET_CAR_MAKES, [], CLEAR),
    regions: app.createDataReducer(SET_REGIONS, [], CLEAR),
    garages: app.createDataReducer(SET_GARAGES, [], CLEAR),
  }),
  appState: combineReducers({
    isLoading: app.createDataReducer(SET_IS_LOADING, false, CLEAR),
    errorMessage: app.createDataReducer(SET_ERROR_MESSAGE, null, CLEAR),
  }),
});
