import React, { FC } from "react";
import { GridLayout } from "ui-library/layouts";

import userContracts from "core/userContracts";

import {
    ActualInvestmentValueTable,
    ContractDataGridItem,
    InvestmentDetailTable,
    InvestmentsOverviewPieChartGridItem,
} from "../containers";

export const PortfolioTab: FC = () => {
    const policy = userContracts.useGetContractByUrlParam();
    const { isInFutureOrTerminated } = userContracts.useGetContractDerivedData(policy);

    return (
        <GridLayout defaultSpacing>
            <ActualInvestmentValueTable />
            {!isInFutureOrTerminated && <InvestmentDetailTable />}
            {!isInFutureOrTerminated && <InvestmentsOverviewPieChartGridItem />}
            <ContractDataGridItem />
        </GridLayout>
    );
};
