import React, { FC } from "react";
import { useSelector } from "react-redux";

import { Typography } from "ui-library/atoms";
import { ContentBox, GridItem, GridLayout } from "ui-library/layouts";

import i18n from "core/i18n";
import { DatePicker, Field, getFormFieldValue, required } from "core/form";
import { localFullDate } from "core/formatters";
import { VehicleCancellationReason } from "types";
import { date } from "core/util";

import { FIELD_CANCELLATION_DATE, FIELD_CANCELLATION_REASON, SECTION_MARGIN } from "../../constants";
import { getCancellationDateTitle } from "../../util";

const EDITABLE_DATE_REASONS = [
    VehicleCancellationReason.BY_AGREEMENT,
    VehicleCancellationReason.REMOVED_FROM_REGISTER,
    VehicleCancellationReason.STOLEN_VEHICLE,
    VehicleCancellationReason.INSURER_DEATH,
];
const DATE_MUST_BE_SET_TO_FUTURE = [VehicleCancellationReason.BY_AGREEMENT];

type ContactPersonSectionProps = {
    formName: string;
};

export const ChangeDateSection: FC<ContactPersonSectionProps> = ({ formName }) => {
    const { t } = i18n.useTranslation();
    const selectedCancellationReason = useSelector(getFormFieldValue(formName, FIELD_CANCELLATION_REASON));

    const cancellationDate = useSelector(getFormFieldValue(formName, FIELD_CANCELLATION_DATE));
    const title = getCancellationDateTitle(t, selectedCancellationReason);

    if (EDITABLE_DATE_REASONS.includes(selectedCancellationReason)) {
        return (
            <ContentBox fullWidth mb={SECTION_MARGIN}>
                <GridLayout spacing={2}>
                    <GridItem xs={12} md={6}>
                        <Field
                            name={FIELD_CANCELLATION_DATE}
                            component={DatePicker}
                            label={title}
                            validateStatic={[required]}
                            minDate={DATE_MUST_BE_SET_TO_FUTURE.includes(selectedCancellationReason) ? date.tomorrow : undefined}
                            maxDate={!DATE_MUST_BE_SET_TO_FUTURE.includes(selectedCancellationReason) ? Date.now() : undefined}
                        />
                    </GridItem>
                </GridLayout>
            </ContentBox>
        );
    }

    return (
        <>
            <ContentBox fullWidth mb={SECTION_MARGIN}>
                <Typography variant="h4" markDown={title} />
                <Typography markDown={localFullDate(cancellationDate)} />
            </ContentBox>
        </>
    );
};
