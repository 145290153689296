import { model } from "core/util";
import { PaymentsExtended as PaymentsExtendedType, PaymentsExtendedDto } from "types";

import { LegalEntity } from "./LegalEntity";

export const PaymentsExtended = model.createModel<PaymentsExtendedDto, PaymentsExtendedType>((json) => ({
    enabled: json.Enabled,
    payer: LegalEntity.fromServer(json.Payer),
    frequency: json.Frequency,
    amount: json.Amount,
    percentage: json.Percentage,
}));
