import { TFunction } from "core/i18n";
import { getLocalizedCountryCode } from "core/localized";
import { fn } from "core/util";
import { SubjectType } from "enums";
import { Attachment } from "types";

import {
    FIELD_ACCOUNT_NUMBER,
    FIELD_ACCOUNT_PREFIX,
    FIELD_BANK_CODE,
    FIELD_BIRTH_NUMBER,
    FIELD_CITY,
    FIELD_COMPANY_IDENTIFICATION_NUMBER,
    FIELD_COMPANY_NAME,
    FIELD_COUNTRY_CODE,
    FIELD_DESCRIPTION_NUMBER,
    FIELD_EMAIL,
    FIELD_FIRST_NAME,
    FIELD_IBAN,
    FIELD_LAST_NAME,
    FIELD_ORIENTATION_NUMBER,
    FIELD_PHONE_NUMBER,
    FIELD_POSTAL_CODE,
    FIELD_STREET,
    FIELD_SUBJECT_TYPE,
} from "./constants";

export const getCancellationDateTitle = (t, cancellationReason) => {
    return t(`vehicle.change.contractCancellation.dateTitle.${cancellationReason}`);
};

export const formatBankAccount = (t: TFunction, fundsTransferBankAccount: any) => {
    if (!!fundsTransferBankAccount.get(FIELD_IBAN)) {
        return fundsTransferBankAccount.get(FIELD_IBAN);
    } else {
        const accountPrefix = fundsTransferBankAccount.get(FIELD_ACCOUNT_PREFIX)
            ? `${fundsTransferBankAccount.get(FIELD_ACCOUNT_PREFIX)}-`
            : "";
        return `${accountPrefix}${fundsTransferBankAccount.get(FIELD_ACCOUNT_NUMBER)}/${fundsTransferBankAccount.get(FIELD_BANK_CODE)}`;
    }
};

export const formatSubjectData = (t: TFunction, person: any) => {
    if (person.get(FIELD_SUBJECT_TYPE) === SubjectType.INDIVIDUAL.id) {
        return `${person.get(FIELD_FIRST_NAME)} ${person.get(FIELD_LAST_NAME)}, ${t("holder.birthNumber")}: ${person.get(
            FIELD_BIRTH_NUMBER,
        )}`;
    } else {
        return `${person.get(FIELD_COMPANY_NAME)}, ${t("holder.companyID")}: ${person.get(FIELD_COMPANY_IDENTIFICATION_NUMBER)}`;
    }
};

export const formatPhoneAndEmail = (t: TFunction, person: any) => {
    return `${t("holder.tel")}: ${person.get(FIELD_PHONE_NUMBER)}, ${t("holder.email")}: ${person.get(FIELD_EMAIL)}`;
};

export const formatAttachments = (t, attachments: Attachment[]) => {
    return attachments.map((item) => item.label).join(", ");
};

export const formatAddressOneLine = (t: TFunction, address: any) => {
    let result = "";

    if (address) {
        if (address.get(FIELD_STREET)) {
            result += address.get(FIELD_STREET);
        }

        if (address.get(FIELD_DESCRIPTION_NUMBER)) {
            if (result.length > 0) {
                result += " ";
            }
            result += address.get(FIELD_DESCRIPTION_NUMBER);
        }

        if (address.get(FIELD_ORIENTATION_NUMBER)) {
            if (address.get(FIELD_DESCRIPTION_NUMBER)) {
                result += "/";
            } else if (result.length > 0) {
                result += " ";
            }
            result += address.get(FIELD_ORIENTATION_NUMBER);
        }

        if (address.get(FIELD_CITY)) {
            if (result.length > 0) {
                result += ", ";
            }
            result += address.get(FIELD_CITY);
        }

        if (address.get(FIELD_POSTAL_CODE)) {
            if (result.length > 0) {
                result += ", ";
            }
            result += address.get(FIELD_POSTAL_CODE);
        }
        if (address.get(FIELD_COUNTRY_CODE)) {
            const localizedCountry = getLocalizedCountryCode(t, address.get(FIELD_COUNTRY_CODE));
            if (!fn.isEmpty(localizedCountry)) {
                if (result.length > 0) {
                    result += ", ";
                }
                result += localizedCountry;
            }
        }
    }
    return result;
};
