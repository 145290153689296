import * as yup from "yup";
import i18n from "core/i18n";
import { validationApi } from "serverApi";
import { debounce } from "lodash";
import { useRef } from "react";
import { SignatureType } from "./types/VehicleSubjectChangeFormValues";
import { useHasRole } from "core/hooks";
import { Roles } from "enums";

export default function getValidationSchema() {
    const { t } = i18n.useTranslation();
    const isAuthenticated = useHasRole(Roles.AUTHENTICATED);

    const validateOrganisationId = async (value) => {
        const [error, res] = await validationApi.validateEmployerIdentificationNumberAsync(value);
        if (!error && res && res.ok) return true;
        return false;
    };

    const useDebouncedValidation = (validateFn, wait) => {
        const debouncedValidateFn = useRef(debounce(validateFn, wait)).current;
        return debouncedValidateFn;
    };

    const debouncedValidateOrganisationId = useDebouncedValidation(validateOrganisationId, 300);

    let today = new Date();
    today = new Date(today.setHours(0, 0, 0, 0));

    const personSchema = yup.object().shape({
        birthNumber: yup.string().required(t("error.required")),
        firstName: yup.string().required(t("error.required")),
        lastName: yup.string().required(t("error.required")),
        phone: yup.string().required(t("error.required")),
        email: yup.string().email(t("error.email.notValid")).required(t("error.required")),
    });

    const organisationSchema = yup.object().shape({
        organisationId: yup
            .string()
            .required(t("error.required"))
            .test("organisationId", t("error.notValid"), async function _(value) {
                return await debouncedValidateOrganisationId(value);
            }),
        name: yup.string().required(t("error.required")),
    });

    const addressSchema = yup.object().shape({
        street: yup.string().required(t("error.required")),
        descriptiveNo: yup.string().required(t("error.required")),
        orientationNo: yup.string().nullable(),
        city: yup.string().required(t("error.required")),
        zipCode: yup.string().required(t("error.required")),
        country: yup.string().required(t("error.required")),
    });

    const subjectSchema = yup.object().shape({
        legalEntity: yup.number().required(t("error.required")),
        person: yup.object().when("legalEntity", {
            is: 1,
            then: () => personSchema,
            otherwise: () => yup.mixed().nullable(),
        }),
        organisation: yup.object().when("legalEntity", {
            is: 2,
            then: () => organisationSchema,
            otherwise: () => yup.mixed().nullable(),
        }),
        address: addressSchema,
    });

    const signatureSchema = yup.object().shape({
        type: yup.string().required(t("error.required")),
        date: yup.date().when("type", {
            is: SignatureType.PHYSICAL,
            then: () => yup.date().required(t("error.required")),
            otherwise: () => yup.mixed().nullable(),
        }),
        place: yup.string().when("type", {
            is: SignatureType.PHYSICAL,
            then: () => yup.string().required(t("error.required")),
            otherwise: () => yup.mixed().nullable(),
        }),
    });

    const validationSchema = yup.object().shape({
        step: yup.number(),
        idChange: yup.string().nullable(),
        vinNumber: yup.string().nullable(),
        validityStartDate: yup.date().typeError(t("error.notValid")).min(today, t("error.notValid")).required(t("error.required")),
        licencePlateNumber: yup.string().when([], {
            is: () => !isAuthenticated,
            then: () => yup.string().required(t("error.required")),
            otherwise: () => yup.string().nullable(),
        }),
        editOwner: yup.boolean(),
        editOperator: yup.boolean(),
        owner: yup.object().when("editOwner", {
            is: true,
            then: () => subjectSchema,
            otherwise: () => yup.mixed().nullable(),
        }),
        operator: yup.object().when("editOperator", {
            is: true,
            then: () => subjectSchema,
            otherwise: () => yup.mixed().nullable(),
        }),
        signature: yup.object().when("step", {
            is: 2,
            then: () => signatureSchema,
            otherwise: () => yup.mixed().nullable(),
        }),
    });

    return validationSchema;
}
