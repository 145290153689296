import { createSelector } from "reselect";

import { DEFAULT_EMPTY_PARAM } from "app/constants";
import entity from "core/entity";
import { oneLineLegalEntityWithDescription } from "core/formatters";
import { app, claims } from "core/util";
import userContracts from "core/userContracts";
import { ClaimsEvents } from "model/claim";
import { ContractOverview } from "model/common";
import { HarmonizedFinancialOperation } from "model/harmonized";

import { ENTITY_CONTRACT_INCIDENTS, ENTITY_CONTRACT_OVERVIEW, ENTITY_FINANCIAL_OPERATIONS, NAME } from "./constants";

/** contract data */
const getModel = app.createGetModel(NAME);
const getData = (state) => getModel(state).get("data");

const getHomeContract = (state) => getData(state).get("homeContractDetail");
const getLineOfBusinessClasses = (state) => getContractOverview(state).getIn(["lineOfBusinessClasses"]);
export const createGetCompliantLineOfBusinessClasses = (idObject) =>
    createSelector(
        getLineOfBusinessClasses,
        userContracts.createGetProductGroupLvl2ById(idObject),
        (lineOfBusinessClasses, productGroupLvl2) => claims.getCompliantLineOfBusinesses(lineOfBusinessClasses, productGroupLvl2),
    );

export const getIdObject = (state) => getHomeContract(state).get("idObject") || DEFAULT_EMPTY_PARAM;
export const getContractDetail = (state) => getHomeContract(state).get("contractDetail");

export const getPolicyHolder = (state) => getHomeContract(state).getIn(["contractDetail", "holder"]);
export const getBalanceOnContract = (state) => getContractDetail(state).get("balanceOnContract");

export const getElectronicCommunication = (state) => getContractDetail(state).get("electronicCommunication");

export const getActualScheduler = (state) => getContractDetail(state).get("actualPaymentsScheduler");
export const getActualSchedulerRecord = (state) => getActualScheduler(state).first();
export const getAdvancedPaymentScheduler = (state) => getContractDetail(state).get("advancePaymentsScheduler");

export const getInsuredProperties = (state) => getContractDetail(state).get("insuredRealEstateProperties");
export const getInsureds = (state) => getContractDetail(state).getIn(["lifeAccountDetail", "insureds"]);

export const getFinancialOperations = (state) =>
    entity.getDataSelector(state, ENTITY_FINANCIAL_OPERATIONS, HarmonizedFinancialOperation.fromServerList());
export const getContractOverview = (state) => entity.getDataSelector(state, ENTITY_CONTRACT_OVERVIEW, ContractOverview.fromServer());
export const getIncidents = (state) => entity.getDataSelector(state, ENTITY_CONTRACT_INCIDENTS, ClaimsEvents.fromServer());
export const getClaims = (state) => getIncidents(state).get("claims");

/** appState */
const getAppState = (state) => getModel(state).get("appState");
export const getDetailSelectedCoverage = (state) => getAppState(state).get("detailSelectedCoverage");

/** user data */
export const getPolicy = createSelector(userContracts.getContractNormalizedData, getIdObject, (policies, idObject) =>
    idObject ? policies[idObject] : null,
);

/** custom selectors */
export const getSelectedRisk = createSelector(
    getInsureds,
    getInsuredProperties,
    getDetailSelectedCoverage,
    (insureds, insuredProperties, selectedCoverage) => {
        const foundInsuredPerson = insureds.find((insuredPerson) => insuredPerson.reactKey === selectedCoverage);
        if (foundInsuredPerson) {
            return foundInsuredPerson;
        }

        const foundProperty = insuredProperties.find((property) => property.reactKey === selectedCoverage);
        if (foundProperty) {
            return foundProperty;
        }
    },
);

export const getInsuredsSelectOptions = createSelector(getInsureds, getInsuredProperties, (insureds, insuredProperties) => {
    const peopleInsurance = insureds
        .filter((risk) => !risk.coverages.isEmpty())
        .map((insured) => ({
            value: insured.get("reactKey"),
            label: oneLineLegalEntityWithDescription(insured.get("insured")),
        }));

    const propertyInsurance = insuredProperties
        .filter((risk) => !risk.coverages.isEmpty())
        .map((risk) => ({
            value: risk.reactKey,
            label: `${risk.description} - ${risk.category?.value}`,
        }));

    return peopleInsurance.concat(propertyInsurance);
});
