import i18n from "core/i18n";
import { ContractChangesTab } from "containers/contract";
import { useChangeTypeGetParamForm, useGetUserChangeTypes } from "core/hooks";
import { ContractChangeType } from "types";

import { ContractNameForm, ElectronicCommunicationForm, ShareHolderForm, ClientStatementSettingsForm } from "../form";
import { changesDefinition } from "../changesDefinition";

export function ChangesTab() {
    const { t } = i18n.useTranslation();
    const { form, changeType, onChange } = useChangeTypeGetParamForm();
    const changeOptions = useGetUserChangeTypes(changesDefinition(t));

    return (
        <ContractChangesTab form={form} changeOptions={changeOptions} onChange={onChange}>
            {changeType === ContractChangeType.CONTRACT_NAME && <ContractNameForm />}
            {changeType === ContractChangeType.ELECTRONIC_COMMUNICATION && <ElectronicCommunicationForm />}
            {changeType === ContractChangeType.SHAREHOLDER && <ShareHolderForm />}
            {changeType === ContractChangeType.STATEMENTS_SETTINGS && <ClientStatementSettingsForm />}
        </ContractChangesTab>
    );
}
