import { NAME } from "./constants";
import reducer from "./reducer";
import saga from "./saga";
import innerRoutes from "./innerRoutes";

import {
    AgentTab,
    ChangesTab,
    DocumentsTab,
    FinancialOperationsTab,
    OverviewTab,
    PolicyHolderTab,
    PortfolioTab,
    StatementsAndPaymentsTab,
} from "./tabs";

import { FundsSellSwitchForm, InvestmentProfileForm } from "./form";

export { DetailMutualFundsRegularPage } from "./Container";

export const DetailMutualFundsRegularTabs = {
    OverviewTab,
    PolicyHolderTab,
    FinancialOperationsTab,
    PortfolioTab,
    ChangesTab,
    StatementsAndPaymentsTab,
    DocumentsTab,
    AgentTab,
};

export const DetailFundsRegularForms = {
    FundsSellSwitchForm,
    InvestmentProfileForm,
};

export { FinancialOperationDetailBuy } from "./containers";
export { FinancialOperationDetailSell } from "./containers";
export { ProfitDetailTable } from "./containers";

const detailMutualFundsRegular = {
    NAME,
    reducer,
    saga,
    innerRoutes,
};

export default detailMutualFundsRegular;
