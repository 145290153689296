import { forwardRef } from "react";
import { useFormContext } from "react-hook-form";
import { TextField as NoRefTextField } from "ui-library/atoms";
import { TextFieldProps } from "ui-library/atoms/Inputs/TextField";

type HookFormTextFieldProps = {
    name: string;
    type?: string;
    disabled?: boolean;
    label?: string;
    normalize?: ((value: any) => any)[];
    required?: boolean;
};

const TextField = forwardRef((props: TextFieldProps, ref) => <NoRefTextField {...props} reference={ref} />);

export default function HookFormTextField({ name, normalize, required, label, ...props }: HookFormTextFieldProps) {
    const {
        register,
        setValue,
        watch,
        formState: { errors },
    } = useFormContext();

    const error = name.split(".").reduce((acc, part) => acc?.[part], errors);

    function onChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;

        let normalized = value;
        if (normalize) {
            for (const fn of normalize) normalized = fn(normalized);
        }

        setValue(name, normalized, { shouldDirty: true, shouldValidate: true });
    }

    return (
        <TextField
            id={name}
            {...register(name)}
            {...props}
            value={watch(name) || ""}
            label={required ? label + "*" : label}
            onChange={onChange}
            error={Boolean(error)}
            errorContent={error?.message.toString()}
            fullWidth
        />
    );
}
