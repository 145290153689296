import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { ContentBox } from "ui-library/layouts";
import { Button, Loading } from "ui-library/atoms";

import i18n from "core/i18n";
import userContracts from "core/userContracts";
import { InPageFormButtonsLayout, InPageFormLayout } from "containers/inPageForm";
import {
    Field,
    form,
    FormWideDisabledProvider,
    InputText,
    lettersNumbersBasicInterpunctionCharacters,
    max50Characters,
    required,
} from "core/form";
import { ChangeSuccessTextType } from "types";

const ContractNameForm = ({ canSubmit, handleSubmit, submitting }) => {
    const { t } = i18n.useTranslation();
    return (
        <>
            <FormWideDisabledProvider value={submitting}>
                <ContentBox fullWidth>
                    <Field
                        name="contractName"
                        component={InputText}
                        label={t("common.customContractNameFull")}
                        validateStatic={[required]}
                        normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                        onEnter={handleSubmit}
                    />
                </ContentBox>
            </FormWideDisabledProvider>

            <InPageFormButtonsLayout
                rightButton={
                    <Button color="blue" variant="contained" onClick={handleSubmit} disabled={!canSubmit || submitting}>
                        {submitting ? <Loading loading /> : t("common.submitChange")}
                    </Button>
                }
            />
        </>
    );
};

ContractNameForm.propTypes = {
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
};

export const createInPageContainer = (formName) =>
    form(formName)((props) => {
        const { t } = i18n.useTranslation();
        const { idObject } = useParams();
        const contract = userContracts.useGetContractById(idObject);
        return (
            <InPageFormLayout
                formName={formName}
                formTitle={t("change.title.CONTRACT_NAME", { contractNumber: contract.contractNumber })}
                changeSuccessTextType={ChangeSuccessTextType.CHANGE_PROCESSED}
            >
                <ContractNameForm {...props} />
            </InPageFormLayout>
        );
    });
