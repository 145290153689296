import policyHolderForm from "form/fullPage/policyHolder";

import { IndividualPersonType } from "enums";

import { FORM_EDIT_PARTICIPANT } from "../constants";
import { getIdObject, getPolicyHolder } from "../selectors";

const SHOW_ID_SECTION = true;

export const ParticipantForm = policyHolderForm.createInPageContainer(
    FORM_EDIT_PARTICIPANT,
    IndividualPersonType.PARTICIPANT,
    getIdObject,
    getPolicyHolder,
    SHOW_ID_SECTION,
);
