import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { oneLineNameWithTitles } from "core/formatters";
import { createGetChangedFieldsList, getFormFieldValue } from "core/form";
import { fn } from "core/util";
import i18n from "core/i18n";
import { getLocalizedIdentityCard, getLocalizedOneLineAddressFull } from "core/localized";
import {
    CITY_FORM_FIELD,
    COMPANY_NAME_FIELD,
    CONTACT_ADDRESS_SECTION,
    DESCRIPTION_NUMBER_FORM_FIELD,
    EMAIL_FIELD,
    IDENTIFICATION_CARD_SECTION,
    MOBILE_FIELD,
    ORIENTATION_NUMBER_FORM_FIELD,
    PERMANENT_ADDRESS_SECTION,
    PERSON_SECTION,
    STATE_FORM_FIELD,
    STREET_FORM_FIELD,
    ZIP_FORM_FIELD,
} from "form/fullPage/policyHolder/constants";
import { SectionDescription } from "form/fullPage/policyHolder/components";
import { Typography } from "ui-library/atoms";
import { ContentBox } from "ui-library/layouts";
import { LabelTextItem } from "ui-library/modules";

import { VISIBLE_CHANGED_FIELDS } from "./constants";

const getHolderPerAddressIdChangeInfoMsg = (changeFieldsList, msg) => {
    const isHolderChanged = changeFieldsList.find((obj) => obj.field === PERSON_SECTION || obj.field === COMPANY_NAME_FIELD);
    const holderPartMsg = isHolderChanged ? msg.ofHolder : null;
    const perAddressPartMsg = changeFieldsList.find((obj) => obj.field === PERMANENT_ADDRESS_SECTION) ? msg.ofPerAddress : null;
    const idCardParMsg = changeFieldsList.find((obj) => obj.field === IDENTIFICATION_CARD_SECTION) ? msg.ofIdCard : null;

    return [holderPartMsg, perAddressPartMsg, idCardParMsg].filter((item) => !!item).join("/");
};

const getEmailMobilePhoneChangeInfoMsg = (changeFieldsList, msg) => {
    const emailPartMsg = changeFieldsList.find((obj) => obj.field === EMAIL_FIELD) ? msg.ofEmail : null;
    const mobilePhonePartMsg = changeFieldsList.find((obj) => obj.field === MOBILE_FIELD) ? msg.ofMobilePhone : null;
    if (emailPartMsg && mobilePhonePartMsg) {
        return `${mobilePhonePartMsg} ${msg.and} ${emailPartMsg}`;
    } else if (emailPartMsg) {
        return emailPartMsg;
    } else if (mobilePhonePartMsg) {
        return mobilePhonePartMsg;
    } else {
        return null;
    }
};

const valuesAreDifferent = (value1, value2) => (!value1 && !value2 ? false : value1 !== value2);

const addressFields = [
    STREET_FORM_FIELD,
    ORIENTATION_NUMBER_FORM_FIELD,
    DESCRIPTION_NUMBER_FORM_FIELD,
    CITY_FORM_FIELD,
    ZIP_FORM_FIELD,
    STATE_FORM_FIELD,
];

const areAddressesFilledIdentically = (perAddress, conAddress) => {
    if (!perAddress || !conAddress) {
        return false;
    }
    const result = addressFields.find((field) => valuesAreDifferent(perAddress.get(field), conAddress.get(field)));
    return !result;
};

export const BulkChangeFormBox = ({ formName, isCompany, individualPersonType, isUniqaContract, areInasNumbersTheSame }) => {
    const { t } = i18n.useTranslation();
    const msg = getMsg(t);
    const fieldMessages = createFieldMessages(t, isCompany, individualPersonType);

    const getFormChangedFieldsList = createGetChangedFieldsList(VISIBLE_CHANGED_FIELDS, formName);
    const changedFieldsList = useSelector(getFormChangedFieldsList);

    const perAddress = useSelector(getFormFieldValue(formName, PERMANENT_ADDRESS_SECTION));
    const conAddress = useSelector(getFormFieldValue(formName, CONTACT_ADDRESS_SECTION));

    const addressFormatter = (address) => getLocalizedOneLineAddressFull(t, address);
    const formatIdentityCard = (values) => getLocalizedIdentityCard(t, values);
    const formatters = {
        person: oneLineNameWithTitles,
        identificationCard: formatIdentityCard,
        permanentAddress: addressFormatter,
        contactAddress: addressFormatter,
    };

    const getFormatter = (field) => formatters[field] || fn.identity;
    const convertValue = (value) => (value && typeof value === "object" ? value.toJS() : value);
    const formatValue = (field, value) => getFormatter(field)(convertValue(value));

    const emailMobileChangeInfoMsg = getEmailMobilePhoneChangeInfoMsg(changedFieldsList, msg);

    return (
        <>
            <SectionDescription content={t("form.bulkChange.summary")} />

            {isUniqaContract &&
                areInasNumbersTheSame &&
                areAddressesFilledIdentically(perAddress, conAddress) &&
                emailMobileChangeInfoMsg && (
                    <SectionDescription content={`${msg.change} ${emailMobileChangeInfoMsg} ${msg.onBothAddresses}`} />
                )}

            <ContentBox>
                {changedFieldsList.length === 0 ? <Typography markDown={t("form.bulkChange.noChanges")} /> : null}
                {changedFieldsList.map(({ field, value }) => (
                    <LabelTextItem
                        key={field}
                        label={fieldMessages[field]}
                        text={`${fieldMessages.newValue}: ${formatValue(field, value)}`}
                    />
                ))}
            </ContentBox>

            {isUniqaContract && getHolderPerAddressIdChangeInfoMsg(changedFieldsList, msg) && (
                <SectionDescription
                    content={`${msg.change} ${getHolderPerAddressIdChangeInfoMsg(changedFieldsList, msg)} ${
                        msg.onAllContractsWithTheSameId
                    }`}
                />
            )}
        </>
    );
};

BulkChangeFormBox.propTypes = {
    formName: PropTypes.string.isRequired,
    isCompany: PropTypes.bool.isRequired,
    individualPersonType: PropTypes.object.isRequired,
    isUniqaContract: PropTypes.bool,
    areInasNumbersTheSame: PropTypes.bool,
};

BulkChangeFormBox.defaultProps = {
    isUniqaContract: false,
    areInasNumbersTheSame: false,
};

const getMsg = (t) => ({
    ofHolder: t("holder.bulkChangeInfo.ofHolder"),
    ofPerAddress: t("holder.bulkChangeInfo.ofPermanentAddress"),
    ofIdCard: t("holder.bulkChangeInfo.ofIdentificationCard"),
    change: t("holder.bulkChangeInfo.change"),
    onAllContractsWithTheSameId: t("holder.bulkChangeInfo.onAllContractsWithTheSameId"),
    ofEmail: t("holder.bulkChangeInfo.ofEmail"),
    ofMobilePhone: t("holder.bulkChangeInfo.ofMobilePhone"),
    and: t("holder.bulkChangeInfo.and"),
    onBothAddresses: t("holder.bulkChangeInfo.onBothAddresses"),
});

const createFieldMessages = (t, isCompany, individualPersonType) => ({
    email: t("holder.emailAddress"),
    attachments: t("common.attachments"),
    companyName: t("holder.companyName"),
    contactAddress: t("holder.contactAddress"),
    identificationCard: t("holder.identityCard"),
    mobile: t("holder.mobile"),
    permanentAddress: isCompany ? t("holder.companyAddress") : t("holder.permanentAddress"),
    person: t(individualPersonType.msg),
    newValue: t("common.newValue"),
});

export default BulkChangeFormBox;
