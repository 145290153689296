import { NAME } from "./constants";
import reducer from "./reducer";
import saga from "./saga";
import innerRoutes from "./innerRoutes";

import {
    AgentTab,
    ChangesTab,
    DocumentsTab,
    FinancialOperationsTab,
    OverviewTab,
    PolicyHolderTab,
    PortfolioTab,
    StatementsAndPaymentsTab,
} from "./tabs";

import { FundsSellSwitchForm } from "./form";

export { DetailMutualFundsDirectPage } from "./Container";

export const DetailMutualFundsDirectTabs = {
    OverviewTab,
    PolicyHolderTab,
    FinancialOperationsTab,
    ChangesTab,
    PortfolioTab,
    StatementsAndPaymentsTab,
    DocumentsTab,
    AgentTab,
};

export const DetailFundsDirectForms = {
    FundsSellSwitchForm,
};

export { ProfitDetailTable } from "./containers";

const detailMutualFundsDirect = {
    NAME,
    reducer,
    saga,
    innerRoutes,
};

export default detailMutualFundsDirect;
