import React, { FC, PropsWithChildren, ReactNode } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Box } from "ui-library/layouts";
import { Button, Typography } from "ui-library/atoms";

import errorHandling from "core/errorHandling";
import i18n from "core/i18n";
import { useGetContractChangeTabLink } from "core/hooks";

import { InPageFormButtonsLayout } from "./InPageFormButtonsLayout";

export interface FullPageErrorBoundaryProps extends PropsWithChildren {
    service: string;
    children: ReactNode;
}

export const InPageFormErrorBoundary: FC<FullPageErrorBoundaryProps> = ({ children, service }) => {
    const { t } = i18n.useTranslation();
    const isServiceInError = useSelector(errorHandling.isServiceInErrorState(service));

    const { idObject } = useParams();
    const navigate = useNavigate();
    const editTabUrl = useGetContractChangeTabLink(idObject);

    if (isServiceInError) {
        return (
            <>
                <Box mt={4} mb={3} fullWidth textAlign={"center"}>
                    <Typography variant="h4" markDown={t("error.change.text")} />
                </Box>
                <InPageFormButtonsLayout
                    rightButton={
                        <Button variant="contained" color="blue" onClick={() => navigate(editTabUrl, { replace: true })}>
                            {t("common.close")}
                        </Button>
                    }
                />
            </>
        );
    } else {
        return <>{children}</>;
    }
};
