import React, { FC } from "react";
import { useSelector } from "react-redux";

import useBreakpoints from "ui-library/theme/breakpoints";
import { InfoPanel } from "ui-library/modules";
import { ContentBox, Flexbox } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";

import i18n from "core/i18n";
import userContracts from "core/userContracts";

import { EditContractElComLink } from "./component";

export const SetElectronicCommunicationInfoPanel: FC = () => {
    const { t } = i18n.useTranslation();
    const breakpointMd = useBreakpoints("md");
    const contractsWithoutElCom = useSelector(userContracts.getContractsWithoutElCom);

    return (
        <InfoPanel
            droppable={!breakpointMd}
            bgVariant={"greenGradient"}
            icon={"mail"}
            title={t("page.contractsList.electronicCommunication.title")}
        >
            <ContentBox>
                <Typography color={"gray"} variant={"p"} markDown={t("page.contractsList.electronicCommunication.text")} />
            </ContentBox>

            <Flexbox direction={{ xs: "column" }} gap={1}>
                {contractsWithoutElCom.map((contract) => (
                    <EditContractElComLink key={contract.idObject} contract={contract} />
                ))}
            </Flexbox>
        </InfoPanel>
    );
};
