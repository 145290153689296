import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { InfoPanel, LabelTextItem } from "ui-library/modules";
import { ContentBox, GridItem } from "ui-library/layouts";

import i18n from "core/i18n";
import userContracts from "core/userContracts";
import { dateTimeToLocalFullDate } from "core/formatters";

export const ContractDataGridItem: FC = () => {
    const { t } = i18n.useTranslation();
    const { idObject } = useParams();
    const contract = userContracts.useGetContractById(idObject);

    return (
        <GridItem display="flex" xs={12} md={6}>
            <InfoPanel icon="contract" title={t("common.contract")}>
                <ContentBox>
                    <LabelTextItem label={t("common.customContractName")} text={contract?.contractName || "-"} />
                    <LabelTextItem label={t("common.contractNumber")} text={contract?.contractNumber} />
                    <LabelTextItem label={t("common.begin")} text={dateTimeToLocalFullDate(contract?.beginDate)} />
                    {contract?.endDate && <LabelTextItem label={t("common.end")} text={dateTimeToLocalFullDate(contract.endDate)} />}
                </ContentBox>
            </InfoPanel>
        </GridItem>
    );
};
