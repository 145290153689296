import { setIsLicencePlateFillInLoading, setIsVehicleLicenceFillInLoading } from "app/pages/detailVehicle/actions";
import { getIsLicencePlateFillInLoading, getIsVehicleLicenceFillInLoading } from "app/pages/detailVehicle/selectors";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import vehicleDataChange from "serverApi/vehicleDataChange";

export default function useVehicleData() {
    const { setValue, getValues, watch, handleSubmit, clearErrors } = useFormContext();
    const dispatch = useDispatch();
    const isLicencePlateFillInLoading = useSelector(getIsLicencePlateFillInLoading);
    const isVehicleLicenceFillInLoading = useSelector(getIsVehicleLicenceFillInLoading);

    async function fillInLicencePlate() {
        dispatch(setIsLicencePlateFillInLoading(true));

        const [error, res] = await vehicleDataChange.getLicencePlateNumber(getValues().idChange, getValues().vinNumber);
        if (error) {
            dispatch(setIsLicencePlateFillInLoading(false));
            return;
        }

        if (res.ok && res.text) {
            const licencePlate = res.text;
            setValue("newLicencePlateNumber", licencePlate);
        }

        dispatch(setIsLicencePlateFillInLoading(false));
    }

    async function fillInVehicleLicenceNumber() {
        dispatch(setIsVehicleLicenceFillInLoading(true));

        const [error, res] = await vehicleDataChange.getVehicleLicenceNumber(getValues().idChange, getValues().vinNumber);
        if (error) {
            dispatch(setIsVehicleLicenceFillInLoading(false));
            return;
        }

        if (res.ok && res.text) {
            const licenceNumber = res.text;
            setValue("newVehicleLicenceNumber", licenceNumber);
        }

        dispatch(setIsVehicleLicenceFillInLoading(false));
    }

    return {
        isLicencePlateFillInLoading,
        isVehicleLicenceFillInLoading,
        fillInLicencePlate,
        fillInVehicleLicenceNumber,
        watch,
        handleSubmit,
        clearErrors,
    };
}
