import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { GridLayout } from "ui-library/layouts";

import { IncidentsSection } from "containers/incident";
import { ClaimReportCategory, ProductGroupLvl2 } from "enums";
import { ReportClaimGridItem } from "containers/contract";
import fetching from "core/fetching";
import userContracts from "core/userContracts";

import { ENTITY_CONTRACT_INCIDENTS, ENTITY_CONTRACT_INCIDENTS_OPEN_STATE, ENTITY_CONTRACT_OVERVIEW } from "../constants";
import { createGetCompliantLineOfBusinessClasses } from "../selectors";

export const ClaimsTab: FC = () => {
    const { idObject } = useParams();

    const { idEnuProductGroupLevelTwo } = userContracts.useGetContractById(idObject);
    const isLifeCategory = idEnuProductGroupLevelTwo === ProductGroupLvl2.BUFT;
    const compliantLineOfBusinessClasses = useSelector(createGetCompliantLineOfBusinessClasses(idObject));
    const isContractOverviewLoading = useSelector(fetching.isFetching(ENTITY_CONTRACT_OVERVIEW, false));
    return (
        <GridLayout defaultSpacing>
            <ReportClaimGridItem
                idObject={idObject}
                claimCategoryId={isLifeCategory ? ClaimReportCategory.LIFE.id : ClaimReportCategory.HOME_AND_LIABILITY.id}
                compliantLineOfBusinessClasses={compliantLineOfBusinessClasses}
                isContractOverviewLoading={isContractOverviewLoading}
            />
            <IncidentsSection
                incidentsEntity={ENTITY_CONTRACT_INCIDENTS}
                displayedIncidentStateEntity={ENTITY_CONTRACT_INCIDENTS_OPEN_STATE}
            />
        </GridLayout>
    );
};
