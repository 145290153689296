import React, { FC } from "react";

import { Box, ContentBox, GridItem, GridLayout } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";

import i18n from "core/i18n";
import { ContinueButton, InPageFormButtonsLayout } from "containers/inPageForm";
import { Field, InputText, max10Characters, numberCharacters, required } from "core/form";
import { useHasRole } from "core/hooks";
import { Roles } from "enums";

import { FIELD_VERIFY_IDENTIFICATION_NUMBER } from "../constants";

type StepZeroProps = {
    handleSubmit: () => void;
    isSubmitting: boolean;
};

export const StepZero: FC<StepZeroProps> = ({ handleSubmit, isSubmitting }) => {
    const { t } = i18n.useTranslation();
    const isAuthenticated = useHasRole(Roles.AUTHENTICATED);
    const isOneTimeChange = useHasRole(Roles.OT_CONTRACT_CHANGE);

    const introductionText = isAuthenticated
        ? t("vehicle.change.contractCancellation.introduction")
        : t("vehicle.change.contractCancellation.introductionOneTime");

    return (
        <>
            <Box mb={5} mt={4} fullWidth textAlign={isAuthenticated ? "center" : "left"}>
                <Typography markDown={introductionText} />
            </Box>

            {isOneTimeChange && (
                <ContentBox fullWidth>
                    <GridLayout spacing={2}>
                        <GridItem xs={12} md={6}>
                            <Field
                                name={FIELD_VERIFY_IDENTIFICATION_NUMBER}
                                component={InputText}
                                label={t("holder.birthNumberAndID")}
                                validateStatic={[required]}
                                normalize={[numberCharacters, max10Characters]}
                            />
                        </GridItem>
                    </GridLayout>
                </ContentBox>
            )}

            <InPageFormButtonsLayout
                rightButton={<ContinueButton handleSubmit={handleSubmit} disabled={isSubmitting} isSubmitting={isSubmitting} />}
            />
        </>
    );
};
