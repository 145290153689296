import React, { FC } from "react";
import { Button, Loading } from "ui-library/atoms";

import i18n from "core/i18n";

type ContinueButtonProps = {
    handleSubmit: () => void;
    isSubmitting: boolean;
    disabled: boolean;
};

export const ContinueButton: FC<ContinueButtonProps> = ({ handleSubmit, isSubmitting, disabled }) => {
    const { t } = i18n.useTranslation();

    return (
        <Button variant="contained" color="blue" onClick={handleSubmit} disabled={disabled}>
            {isSubmitting ? <Loading loading /> : t("common.continue")}
        </Button>
    );
};
