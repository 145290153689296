import { createSaga } from "./createSaga";
import { createInPageContainer } from "./createInPageContainer";

/**
 * Saga and Container creator for Holder edit form
 */
const contractCancellationForm = {
    createSaga,
    createInPageContainer,
};
export default contractCancellationForm;
