import { AnchorHTMLAttributes, FC, MouseEventHandler, PropsWithChildren, ReactNode } from "react";
import { NavLinkProps } from "react-router-dom";

import {
    StyledHtmlLink,
    StyledLink,
    StyledMenuLink,
    StyledSidemenuLink,
    StyledStandaloneHtmlLink,
    StyledStandaloneLink,
} from "ui-library/Styled";
import { Badge } from "ui-library/atoms";
import { RequireOnlyOne } from "types";
import { Box } from "@mui/material";

type HtmlLinkProps = {
    children: ReactNode;
    href: string;
    inheritStyle?: boolean;
    target?: string;
};

export const HtmlLink: FC<HtmlLinkProps> = ({ children, href, inheritStyle = false, target, ...props }) => (
    <StyledHtmlLink $inheritStyle={inheritStyle} href={href} target={target} {...props}>
        {children}
    </StyledHtmlLink>
);

type LinkProps = {
    children: ReactNode;
    to: string;
    inheritStyle?: boolean;
};

export const Link: FC<LinkProps> = ({ children = "Base link", to, inheritStyle = false, ...props }) => (
    // @ts-ignore TODO: fix this later after typing atom components
    <StyledLink $inheritStyle={inheritStyle} to={to} {...props}>
        {children}
    </StyledLink>
);

type StandaloneHtmlLinkProps = {
    color?: string;
    href: string;
} & PropsWithChildren &
    AnchorHTMLAttributes<HTMLAnchorElement>;

export const StandaloneHtmlLink: FC<StandaloneHtmlLinkProps> = ({ children, color = "gray", href, ...props }) => (
    <StyledStandaloneHtmlLink $color={color} href={href} {...props}>
        {children}
    </StyledStandaloneHtmlLink>
);

type StandaloneLinkVariantProps = {
    children: ReactNode;
    color?: string;
    to?: string;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
};

type StandaloneLinkProps = RequireOnlyOne<StandaloneLinkVariantProps, "to" | "onClick">;

export const StandaloneLink: FC<StandaloneLinkProps> = ({ children, color = "gray", to = "", onClick }) => (
    // @ts-ignore TODO: fix this later after typing atom components
    <StyledStandaloneLink $color={color} to={to} onClick={onClick}>
        {children}
    </StyledStandaloneLink>
);

interface MenuLinkProps extends NavLinkProps {
    notificationCount?: number | string;
    to: string;
    disabled?: boolean;
}

export const MenuLink: FC<MenuLinkProps> = ({ children = "Base link", notificationCount, to, ...props }) => {
    const disabledStyles = props.disabled
        ? {
              box: {
                  cursor: "not-allowed",
                  opacity: 0.5,
              },
              link: {
                  pointerEvents: "none",
              },
          }
        : {};

    return (
        <Box display={"flex"} sx={disabledStyles.box}>
            {notificationCount ? (
                <Badge childPadding={2} badgeContent={notificationCount}>
                    <StyledMenuLink to={to} {...props} style={disabledStyles.link}>
                        {children}
                    </StyledMenuLink>
                </Badge>
            ) : (
                <StyledMenuLink to={to} {...props} style={disabledStyles.link}>
                    {children}
                </StyledMenuLink>
            )}
        </Box>
    );
};

interface SidemenuLinkProps extends NavLinkProps {
    to: string;
    disabled?: boolean;
}

export const SidemenuLink: FC<SidemenuLinkProps> = ({ children = "Base link", to, ...props }) => {
    const checkActive = (_match, location) => {
        //some additional logic to verify you are in the home URI
        if (!location) {
            return false;
        }

        const { pathname } = location;

        return pathname === "/";
    };

    const disabledStyles = props.disabled
        ? {
              box: {
                  cursor: "not-allowed",
                  opacity: 0.5,
              },
              link: {
                  pointerEvents: "none",
              },
          }
        : {};

    return (
        // @ts-ignore TODO: fix this later after typing atom components
        <Box sx={disabledStyles.box}>
            <StyledSidemenuLink to={to} $isActive={checkActive} {...props} style={disabledStyles.link}>
                {children}
            </StyledSidemenuLink>
        </Box>
    );
};
