import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    RoutingState,
    initialState,
    NAME,
    ActivePageErrorPayload,
    NavigatePayload,
    NavigateExternalPayload,
    PageInformation,
} from "./constants";
import { Pages, Tabs } from "routeConstants";

const routeEntered: CaseReducer<RoutingState, PayloadAction<PageInformation>> = (state, { payload }) => {
    state.pageInformation = payload;
};

const navigate: CaseReducer<RoutingState, PayloadAction<NavigatePayload>> = (state, { payload }) => {
    // fetching in saga
};

const navigateByDirectLink: CaseReducer<RoutingState, PayloadAction<string>> = (state, { payload }) => {
    // fetching in saga
};

const navigateExternal: CaseReducer<RoutingState, PayloadAction<NavigateExternalPayload>> = (state, { payload }) => {
    // fetching in saga
};

const navigateSsoLogin: CaseReducer<RoutingState, PayloadAction<boolean>> = (state, { payload }) => {
    // fetching in saga
};

const goBack: CaseReducer<RoutingState> = (state) => {
    // fetching in saga
};

const setActivePageIsLoading: CaseReducer<RoutingState> = (state) => {
    state.activePageStatus = {
        isLoading: true,
        isError: false,
    };
};

const setActivePageLoadedSuccessfully: CaseReducer<RoutingState> = (state) => {
    state.activePageStatus = {
        isLoading: false,
        isError: false,
    };
};

const setActivePageLoadedWithError: CaseReducer<RoutingState, PayloadAction<ActivePageErrorPayload>> = (state, { payload }) => {
    state.activePageStatus = {
        isLoading: false,
        isError: true,
        errorIdentifier: payload.identifier,
        msgKey: payload.msgKey,
    };
};

export const slice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        navigate: {
            reducer: navigate,
            prepare: (topLevelRouteName: Pages, innerLevelRouteName: Tabs = null, params = {}, query = {}, replace = false) => ({
                payload: { name: topLevelRouteName, innerName: innerLevelRouteName, params, query, replace },
            }),
        },
        navigateExternal: {
            reducer: navigateExternal,
            prepare: (url: string, query: any) => ({
                payload: { url, query },
            }),
        },
        routeEntered,
        goBack,
        navigateByDirectLink,
        navigateSsoLogin,
        setActivePageIsLoading,
        setActivePageLoadedSuccessfully,
        setActivePageLoadedWithError,
    },
});

export default slice;
