import React from "react";
import PropTypes from "prop-types";

import { ActualAndAdvancedPaymentSchedulerPanel } from "./ActualAndAdvancedPaymentSchedulerPanel";
import { ActualPaymentOnlySchedulerPanel } from "./ActualPaymentOnlySchedulerPanel";

export const ContractPaymentSchedulerGridItem = ({ actualPaymentScheduler, advancePaymentsScheduler }) => {
    // In case advance scheduler is available we do display both.
    if (advancePaymentsScheduler?.modalAmount?.value) {
        return (
            <ActualAndAdvancedPaymentSchedulerPanel
                actualScheduler={actualPaymentScheduler}
                advancePaymentsScheduler={advancePaymentsScheduler}
            />
        );
    }

    return <ActualPaymentOnlySchedulerPanel actualScheduler={actualPaymentScheduler} />;
};

ContractPaymentSchedulerGridItem.propTypes = {
    actualPaymentScheduler: PropTypes.object.isRequired,
    advancePaymentsScheduler: PropTypes.object,
};

ContractPaymentSchedulerGridItem.defaultProps = {
    advancePaymentsScheduler: null,
};
