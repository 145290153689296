import type { Selector } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";
import { Map } from "immutable";

import entity from "core/entity";
import { DEFAULT_EMPTY_PARAM } from "app/constants";
import userContracts from "core/userContracts";
import { app, date } from "core/util";
import {
    createGetIsAnotherRedeemInProcess,
    createGetIsRedeemPossible,
    createGetRedeemAllowedPensionTypes,
    createGetRedeemAllowedRedeemTypes,
    createGetRedeemParameters,
} from "core/util/selector";
import { Frequency, PensionPpPensionPlanGroup, RedeemPensionPpRedeemTypes } from "enums";
import { PensionContractAdditionalInformation } from "model/pension/additionalInfomation";
import { PensionPpFinancialOperation, PensionPpFinancialStatement } from "model/pension/pp";
import {
    ElectronicCommunication,
    Holder,
    Payments,
    PaymentsExtended,
    PaymentsScheduler,
    PensionBeneficiary,
    PensionPpAccountDetail,
    PensionPpContract,
    PensionPpTypes,
    PensionStatementSettings,
    State,
} from "types";

import { ENTITY_CONTRACT_ADDITIONAL_INFO, ENTITY_FINANCIAL_OPERATIONS, ENTITY_FINANCIAL_STATEMENT, NAME } from "./constants";

/** contract data */
const getModel = app.createGetModel(NAME);
const getData = (state) => getModel(state).get("data");

const getPensionPpContractDetail: Selector<State, PensionPpContract> = (state) => getData(state).pensionPpContractDetail;

export const getIdObject: Selector<State, number> = (state) => getPensionPpContractDetail(state).idObject || DEFAULT_EMPTY_PARAM;
export const getPolicyHolder: Selector<State, Holder> = (state) => getPensionPpContractDetail(state).contractDetail.holder;
export const getElectronicCommunication: Selector<State, ElectronicCommunication> = (state) =>
    getPensionPpContractDetail(state).contractDetail.electronicCommunication;
export const isElectronicCommunicationSet: Selector<State, boolean> = (state) =>
    getPensionPpContractDetail(state).contractDetail.electronicCommunication.electronicCommunicationSet || false;
export const getClientStatementSettings: Selector<State, PensionStatementSettings> = (state) =>
    getPensionPpContractDetail(state).contractDetail.statementSettings;
export const getPensionAccountDetail: Selector<State, PensionPpAccountDetail> = (state) =>
    getPensionPpContractDetail(state).contractDetail.pensionPpAccountDetail;
export const getPensionTypes: Selector<State, PensionPpTypes> = (state) => getPensionAccountDetail(state).pensionTypes;
export const getPensionPlan: Selector<State, String> = (state) => getPensionTypes(state).pensionPlan;
export const getBeneficiaries: Selector<State, PensionBeneficiary[]> = (state) =>
    getPensionPpContractDetail(state).contractDetail.beneficiaries;
export const getEmployerPayments: Selector<State, PaymentsExtended> = (state) => getPensionAccountDetail(state).employerPayments;
export const getThirdPartyPayments: Selector<State, Payments> = (state) => getPensionAccountDetail(state).thirdPartyPayments;
export const getPaymentsScheduler: Selector<State, PaymentsScheduler> = (state) => {
    const validFrequencyOptions = Object.keys(Frequency);
    const paymentsScheduler = getPensionAccountDetail(state).paymentsScheduler;

    if (validFrequencyOptions.includes(paymentsScheduler.periodicity.code)) {
        return paymentsScheduler;
    } else {
        return {
            ...paymentsScheduler,
            periodicity: {
                ...paymentsScheduler.periodicity,
                code: undefined,
            },
        };
    }
};

export const getContractAdditionalInfo = (state) =>
    // @ts-ignore
    entity.getDataSelector(state, ENTITY_CONTRACT_ADDITIONAL_INFO, PensionContractAdditionalInformation.fromServer());

export const getFinancialOperations = (state) =>
    // @ts-ignore
    entity.getDataSelector(state, ENTITY_FINANCIAL_OPERATIONS, PensionPpFinancialOperation.fromServerList());
export const getFinancialOperationsCategoryNames = createSelector(getFinancialOperations, (financialOperations) =>
    financialOperations
        .map((operation) => Map().set("label", operation.get("categoryName")).set("value", operation.get("categoryName")))
        .toSet(),
);

export const getFinancialStatement = (state) =>
    // @ts-ignore
    entity.getDataSelector(state, ENTITY_FINANCIAL_STATEMENT, PensionPpFinancialStatement.fromServer());
export const getContractBalanceSheet = (state) => getFinancialStatement(state).get("contractBalanceSheet");

/** user data */
export const getPolicy = createSelector(userContracts.getContractNormalizedData, getIdObject, (policies, idObject) =>
    idObject ? policies[idObject] : null,
);
export const getPolicyBeginDateYear = (state) => date.getYearFromStringDateTime(getPolicy(state)?.beginDate);

/** Pension Plan Link **/
export const getPensionPlanGroup = createSelector(getPensionPlan, (pensionPlan) =>
    Object.values(PensionPpPensionPlanGroup).find((item) => item.listOfPlans.includes(pensionPlan.trim())),
);

/** Redeem savings **/
export const getRedeemAllowedPensionTypes = createGetRedeemAllowedPensionTypes(getContractAdditionalInfo, RedeemPensionPpRedeemTypes);
export const getRedeemAllowedRedeemTypes = (pensionType) =>
    createGetRedeemAllowedRedeemTypes(pensionType, getContractAdditionalInfo, RedeemPensionPpRedeemTypes);
export const getNotApplicableRedeems = createSelector(getContractAdditionalInfo, (contractAdditionalInfo) =>
    contractAdditionalInfo.redeemInvestmentsOptions.filter((redeemOption) => redeemOption.isApplicable === 0),
);

export const getIsAnotherRedeemInProcess = createGetIsAnotherRedeemInProcess(getContractAdditionalInfo);
export const getIsRedeemPossible = createGetIsRedeemPossible(getRedeemAllowedPensionTypes, getIsAnotherRedeemInProcess);
export const getRedeemParameters = (redeemType) => createGetRedeemParameters(redeemType, getContractAdditionalInfo);
