import moment from "moment";
import { differenceInYears } from "date-fns";

import { formatContractEndDate, localFullDate } from "core/formatters";

import fn from "../fn";
import { Frequency } from "enums";

const unlimitedDateFormat = ["31.12.2500", "2500-12-31"];

const getYearFromStringDateTime = (value) => {
    if (value) {
        return moment.utc(value).year();
    } else {
        return null;
    }
};

const isDibisibleAndMinTwo = (months, frequency) => months % frequency == 0 && months / frequency >= 2;

const getListOfApplicableFrequenciesByModulo = (numberOfMonths) => {
    const numOfMonthsAsNumber = Number(numberOfMonths);
    const allowedFrequencies = [];

    // Monthly
    if (isDibisibleAndMinTwo(numOfMonthsAsNumber, 1)) {
        allowedFrequencies.push(Frequency.MONTHLY.id);
    }
    // Quarterly
    if (isDibisibleAndMinTwo(numOfMonthsAsNumber, 3)) {
        allowedFrequencies.push(Frequency.QUARTERLY.id);
    }
    // Halfyearly
    if (isDibisibleAndMinTwo(numOfMonthsAsNumber, 6)) {
        allowedFrequencies.push(Frequency.HALFYEARLY.id);
    }
    // Yearly
    if (isDibisibleAndMinTwo(numOfMonthsAsNumber, 12)) {
        allowedFrequencies.push(Frequency.YEARLY.id);
    }

    return allowedFrequencies;
};

const formatToDateWithUnlimited = (toDate, unlimitedMessage) =>
    unlimitedDateFormat.includes(toDate) ? unlimitedMessage : formatContractEndDate(toDate, unlimitedMessage);

const formatCoverageFromTo = (dateValidFrom, dateValidTo, unlimitedMessage) =>
    dateValidFrom || dateValidTo ? `${localFullDate(dateValidFrom)} - ${formatToDateWithUnlimited(dateValidTo, unlimitedMessage)}` : null;

const compareDatesSafe = (firstDate, secondDate, compareFunction) => {
    if (fn.isEmpty(firstDate) || fn.isEmpty(secondDate)) {
        return false;
    }
    let date1 = new Date(firstDate).getTime();
    let date2 = new Date(secondDate).getTime();

    return compareFunction(date1, date2);
};

const isBefore = (firstDate, secondDate) => compareDatesSafe(firstDate, secondDate, (date1, date2) => date1 < date2);
const isAfter = (firstDate, secondDate) => compareDatesSafe(firstDate, secondDate, (date1, date2) => date1 > date2);

export default {
    unlimitedDateFormat,
    dateFormat: "dd.MM.yyyy",
    dateFormatWithTime: "dd.MM.yyyy HH:mm",
    dateFormatWithTimeAndSecs: "dd.MM.yyyy HH:mm:ss",
    today: Object.freeze(Date.now()),
    todayAsIso: new Date().toISOString(),
    firstDayOfMonth: Object.freeze(moment.utc().startOf("month").toDate()),
    tomorrow: Object.freeze(moment.utc().startOf("day").add(1, "days").toDate()),
    yesterday: Object.freeze(moment.utc().startOf("day").subtract(1, "days").toDate()),
    getPreviousDay: (stringDate) => moment.utc(stringDate).subtract(1, "day").toDate(),
    getNextDay: (stringDate) => moment.utc(stringDate).add(1, "day").toDate(),
    getAgeFromDate: (date) => differenceInYears(Date.now(), date),
    previousYear: Object.freeze(moment.utc().subtract(1, "year").year()),
    getYearFromStringDateTime,
    formatToDateWithUnlimited,
    formatCoverageFromTo,
    isBefore,
    isAfter,
    getMonthYearViewOptions: (minDate, maxDate) => (moment(minDate).isSame(maxDate, "year") ? ["month"] : ["month", "year"]),
    getListOfApplicableFrequenciesByModulo,
};
