import React from "react";
import PropTypes from "prop-types";
import { List } from "immutable";

import { Box, GridItem } from "ui-library/layouts";
import { InfoPanel } from "ui-library/modules";

import i18n from "core/i18n";

import { HarmonizedPaymentSchedulerDataTable } from "./HarmonizedPaymentSchedulerDataTable";

export const HarmonizedActualPaymentOnlySchedulerPanel = ({ actualScheduler }) => {
    const { t } = i18n.useTranslation();

    return (
        <GridItem display={"flex"} xs={12} lg={7}>
            <InfoPanel icon={"contract"} title={t("insurance.actualPaymentScheduler")}>
                {actualScheduler instanceof List &&
                    actualScheduler.map((scheduler) => (
                        <Box key={scheduler.reactKey} mb={3}>
                            <HarmonizedPaymentSchedulerDataTable schedulerData={scheduler} />
                        </Box>
                    ))}
            </InfoPanel>
        </GridItem>
    );
};

HarmonizedActualPaymentOnlySchedulerPanel.propTypes = {
    actualScheduler: PropTypes.object.isRequired,
};
