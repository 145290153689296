import i18n from "core/i18n";
import { UseFormReturn } from "react-hook-form";
import { useSelector } from "react-redux";
import { getCarMakes, getRegions } from "../selectors";
import RepairType from "../types/repairType";
import { GarageForm } from "./useGarageSearch";

export function useGarageSearchForm(form: UseFormReturn<GarageForm>) {
  const { t } = i18n.useTranslation();

  const { handleSubmit, watch, setValue } = form;
  const repairType = watch("repairType");
  const region = watch("region");

  const carMakes = useSelector(getCarMakes);
  const regions = useSelector(getRegions);

  const districts = regions.find((r) => r.code === Number(region))?.districts || [];

  const labels = {
    title: t("garage.title"),
    submit: t("garage.form.submit"),
    carMake: t("garage.form.carMake") + " *",
    makeYear: t("garage.form.makeYear") + (Number(repairType) === RepairType.VehicleDamage ? " *" : ""),
    region: t("garage.form.region"),
    districts: t("garage.form.district"),
  };

  const onRegionChange = () => {
    setValue("districts", null)
  };

  return {
    handleSubmit, repairType, region, onRegionChange, carMakes, regions, districts, labels
  };
}
