import { forwardRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { RadioGroup as NoRefRadioGroup, RadioGroupProps } from "ui-library/atoms/Inputs/Radio";

type HookFormRadioGroupProps = {
    name: string;
    label?: string;
    disabled?: boolean;
    onChange?: (value?: any) => any;
    children?: React.ReactNode;
    direction?: string;
};

const RadioGroup = forwardRef((props: RadioGroupProps, ref) => <NoRefRadioGroup {...props} reference={ref} />);

export default function HookFormRadioGroup({
    name,
    disabled = false,
    label,
    onChange,
    direction = "column",
    children,
}: HookFormRadioGroupProps) {
    const { control, setValue } = useFormContext();

    function onRadioChange(_: any, value: any) {
        setValue(name, value);
        onChange?.(value);
    }

    return (
        <Controller
            control={control}
            name={name}
            disabled={disabled}
            render={({ field, formState: { errors } }) => {
                const error = name.split(".").reduce((acc, part) => acc?.[part], errors);

                return (
                    <RadioGroup
                        {...field}
                        label={label}
                        direction={direction}
                        error={Boolean(error)}
                        errorContent={error?.message.toString()}
                        onChange={onRadioChange}
                    >
                        {children}
                    </RadioGroup>
                );
            }}
        />
    );
}
