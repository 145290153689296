import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import router from "core/router";
import { ContractPolicyHolderTabLegacy } from "containers/contract";

import { getPolicyHolder } from "../selectors";

import { Pages, Tabs } from "routeConstants";

export const PolicyHolderTab = () => {
    const { idObject } = useParams();
    const holder = useSelector(getPolicyHolder);
    return (
        <ContractPolicyHolderTabLegacy
            holder={holder}
            editLink={router.getStaticUrl(Pages.CONTRACT_TRAVEL, Tabs.EDIT_POLICY_HOLDER_FORM, { idObject })}
            isContractEditable={false}
        />
    );
};
