export const NAME = "detail-mutual-funds-direct";
export const INCLUDE_BUY_SELL_CONFIRMATION = true;

// Entity
export const ENTITY_CONTRACT_DOCUMENTS = `${NAME}/ENTITY_CONTRACT_DOCUMENTS`;
export const ENTITY_ENQUEUED_DOCUMENTS = `${NAME}/ENTITY_ENQUEUED_DOCUMENTS`;
export const ENTITY_FINANCIAL_OPERATIONS = `${NAME}/ENTITY_FINANCIAL_OPERATIONS`;
export const ENTITY_FINANCIAL_STATEMENT = `${NAME}/ENTITY_FINANCIAL_STATEMENT`;
export const ENTITY_FINANCIAL_REQUESTS = `${NAME}/ENTITY_FINANCIAL_REQUESTS`;
export const ENTITY_PAYMENT_INFORMATION = `${NAME}/ENTITY_PAYMENT_INFORMATION`;
export const ENTITY_CHANGE_PROPOSITIONS = `${NAME}/ENTITY_CHANGE_PROPOSITIONS`;

// Form
export const FORM_EDIT_ELECTRONIC_COMMUNICATION = `${NAME}/FORM_EDIT_ELECTRONIC_COMMUNICATION`;
export const FORM_EDIT_SHAREHOLDER = `${NAME}/FORM_EDIT_POLICY_HOLDER`;
export const FORM_EDIT_CONTRACT_NAME = `${NAME}/FORM_EDIT_CONTRACT_NAME`;
export const FORM_EDIT_CLIENT_STATEMENT_SETTINGS = `${NAME}/FORM_EDIT_CLIENT_STATEMENT_SETTINGS`;
export const FORM_EDIT_ACTIVE_OPERATIONS_PHONE_NUMBER = `${NAME}/FORM_EDIT_ACTIVE_OPERATIONS_PHONE_NUMBER`;
export const FORM_SELL_SWITCH = `${NAME}/FORM_SELL_SWITCH`;

// Joined Modal + Entity elements
export const MODAL_ENTITY_PRINT_CONFIRMATION = `${NAME}/MODAL_ENTITY_PRINT_CONFIRMATION`;

// Generate statement params
export const BUY_SELL_CONFIRMATION = "BUY_SELL_CONFIRMATION";

// Columns
export const FUND_COLUMN = "fund";
export const NETTO_INVESTED_AMOUNT_COLUMN = "nettoInvestedAmount";
export const TYPE_COLUMN = "type";
export const VALUE_DATE_COLUMN = "valueDate";
