import { createSaga } from "./createSaga";
import { createSagaLegacy } from "./createSagaLegacy";
import { createContainer } from "./createContainer";
import { createInPageContainer } from "./createInPageContainer";

/**
 * Saga and Container creator for Holder edit form
 */
const electronicCommunicationForm = {
    createSaga,
    createSagaLegacy,
    createContainer,
    createInPageContainer,
};
export default electronicCommunicationForm;
