import { FC } from "react";
import { useSelector } from "react-redux";

import { ContractPolicyHolderTab } from "containers/contract";
import { getElectronicCommunication, getPolicyHolder } from "../selectors";

export const PolicyHolderTab: FC = () => {
    const holder = useSelector(getPolicyHolder);
    const electronicCommunication = useSelector(getElectronicCommunication);

    return (
        <ContractPolicyHolderTab
            holder={holder}
            isExtended
            electronicCommunication={electronicCommunication}
            shouldDisplayElCommunication
        />
    );
};
