import { NAME } from "./constants";
import reducer from "./reducer";
import saga from "./saga";
import innerRoutes from "./innerRoutes";

import {
    AgentTab,
    ChangesTab,
    DetailTab,
    DocumentsTab,
    FinancialOperationsTab,
    FinancialStatementTab,
    OverviewTab,
    PolicyHolderTab,
} from "./tabs";
import { FullPageFundsChangeForm } from "./form";

export { DetailPensionDdsPage } from "./Container";

export const DetailPensionDdsTabs = {
    OverviewTab,
    PolicyHolderTab,
    DetailTab,
    ChangesTab,
    FinancialOperationsTab,
    FinancialStatementTab,
    DocumentsTab,
    AgentTab,
};

export const DetailPensionDdsForms = {
    FullPageFundsChangeForm,
};

const detailDds = {
    NAME,
    reducer,
    saga,
    innerRoutes,
};

export default detailDds;
