import React, { FC } from "react";
import { useSelector } from "react-redux";
import { ContractPolicyHolderTab } from "containers/contract";
import { getElectronicCommunication, getPolicyHolder } from "../selectors";

export const PolicyHolderTab: FC = () => {
    const policyHolder = useSelector(getPolicyHolder);
    const electronicCommunication = useSelector(getElectronicCommunication);

    return (
        <ContractPolicyHolderTab
            holder={policyHolder}
            isExtended
            electronicCommunication={electronicCommunication}
            shouldDisplayElCommunication
        />
    );
};
