import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "ui-library/atoms";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "ui-library/modules";

import i18n from "core/i18n";
import modal from "core/modal";
import { acceptedFileSizeValidator, acceptedFileTypesValidator, Field, FileUpload, getFormFieldValue } from "core/form";

import { MODAL_UPLOAD_DOCUMENT, VIRTUAL_FIELD_DOCUMENT_TO_UPLOAD_FIELD_NAME } from "../../constants";

export default function FileUploadModal({ formName }: { formName: string }) {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    const isModalFormOpen = useSelector(modal.isModalFormOpen(MODAL_UPLOAD_DOCUMENT));
    const fieldName = useSelector(getFormFieldValue(formName, VIRTUAL_FIELD_DOCUMENT_TO_UPLOAD_FIELD_NAME));

    const closeModalForm = () => {
        dispatch(modal.closeModalForm(MODAL_UPLOAD_DOCUMENT));
    };

    return (
        <Modal size="small" open={isModalFormOpen} onClose={closeModalForm}>
            <ModalHeader
                icon="document-upload"
                title={t("vehicle.change.subject.documents.attachmentUpload")}
                closeAction={closeModalForm}
            />
            <ModalBody>
                <Field
                    name={fieldName}
                    component={FileUpload}
                    maxUploadedFiles={1}
                    validateStatic={[acceptedFileTypesValidator, acceptedFileSizeValidator]}
                />
            </ModalBody>
            <ModalFooter>
                <Button variant="contained" color="primary" onClick={closeModalForm}>
                    {t("common.close")}
                </Button>
            </ModalFooter>
        </Modal>
    );
}
