import React, { FC } from "react";
import { useSelector } from "react-redux";

import { ContentBox, GridItem, GridLayout } from "ui-library/layouts";

import i18n from "core/i18n";
import coreEnum from "core/enum";
import {
    Field,
    InputText,
    maxLengthCharacters,
    numberCharacters,
    required,
    SingleSelect,
    validAccountNumber,
    validAccountNumberPrefix,
} from "core/form";
import { FIELD_ACCOUNT_NUMBER, FIELD_ACCOUNT_PREFIX, FIELD_BANK_CODE } from "../../constants";

type AccountSectionCsProps = {
    sectionName: string;
};

export const AccountSectionCs: FC<AccountSectionCsProps> = ({ sectionName }) => {
    const { t } = i18n.useTranslation();

    const bankListTypesSelector = coreEnum.createGetEnum(coreEnum.BANK_LIST);
    const bankListTypes = useSelector(bankListTypesSelector);

    const getLocalizedCodeOptions = i18n.createGetLocalizedCodeOptions(bankListTypes);
    const bankCodeOptions = useSelector(getLocalizedCodeOptions);

    return (
        <>
            <ContentBox fullWidth>
                <GridLayout spacing={1}>
                    <GridItem xs={12} md={4}>
                        <Field
                            name={`${sectionName}.${FIELD_ACCOUNT_PREFIX}`}
                            label={t("payments.targetAccountPrefix")}
                            component={InputText}
                            validateStatic={[validAccountNumberPrefix]}
                            normalize={[numberCharacters, maxLengthCharacters(6)]}
                        />
                    </GridItem>
                    <GridItem xs={12} md={8}>
                        <Field
                            name={`${sectionName}.${FIELD_ACCOUNT_NUMBER}`}
                            label={t("common.accountNumber")}
                            component={InputText}
                            validateStatic={[required, validAccountNumber]}
                            normalize={[numberCharacters, maxLengthCharacters(10)]}
                        />
                    </GridItem>
                </GridLayout>
            </ContentBox>
            <ContentBox fullWidth>
                <GridLayout spacing={1}>
                    <GridItem xs={12}>
                        <Field
                            name={`${sectionName}.${FIELD_BANK_CODE}`}
                            label={t("payments.bankCode")}
                            component={SingleSelect}
                            options={bankCodeOptions}
                            validateStatic={[required]}
                        />
                    </GridItem>
                </GridLayout>
            </ContentBox>
        </>
    );
};
