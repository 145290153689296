import { Tabs } from "routeConstants";
import { innerRoutes } from "routeUrls";

const detailMutualFundsDirectInnerRoutes = {
    [Tabs.OVERVIEW]: innerRoutes[Tabs.OVERVIEW],
    [Tabs.HOLDER]: innerRoutes[Tabs.HOLDER],
    [Tabs.PORTFOLIO]: innerRoutes[Tabs.PORTFOLIO],
    [Tabs.CHANGES]: innerRoutes[Tabs.CHANGES],
    [Tabs.FINANCIAL_OPERATIONS]: innerRoutes[Tabs.FINANCIAL_OPERATIONS],
    [Tabs.FINANCIAL_STATEMENT]: innerRoutes[Tabs.FINANCIAL_STATEMENT],
    [Tabs.DOCUMENTS]: innerRoutes[Tabs.DOCUMENTS],
    [Tabs.AGENT]: innerRoutes[Tabs.AGENT],
    [Tabs.DIRECT_PROFIT_DETAIL]: innerRoutes[Tabs.DIRECT_PROFIT_DETAIL],
    [Tabs.EDIT_POLICY_HOLDER_FORM]: innerRoutes[Tabs.EDIT_POLICY_HOLDER_FORM],
    [Tabs.SELL_SWITCH_FORM]: innerRoutes[Tabs.SELL_SWITCH_FORM],
    [Tabs.SELL_SWITCH_FORM_WITH_ACTION]: innerRoutes[Tabs.SELL_SWITCH_FORM_WITH_ACTION],
};

export default detailMutualFundsDirectInnerRoutes;
