import {
    formValueSelector,
    getFormError as reduxFormGetFormError,
    getFormInitialValues as reduxGetFormInitialValues,
    getFormSyncErrors as reduxGetFormSyncErrors,
    getFormValues as reduxGetFormValues,
    hasSubmitFailed as reduxHasSubmitFailed,
    hasSubmitSucceeded as reduxHasSubmitSucceeded,
    isDirty,
    isSubmitting as reduxFormIsSubmitting,
} from "redux-form/immutable";
import { Map } from "immutable";
import { createSelector } from "reselect";

import { app, fn } from "core/util";

import { NAME } from "./constants";

const getModel = app.createGetModel(NAME);
export const getForm = (state) => getModel(state).get("form");
export const getSuccessfullySubmittedOtpForms = (state) => getModel(state).get("otpFormSuccess");
export const isOtpFormSuccessfullySubmitted = (formName) => (state) => getSuccessfullySubmittedOtpForms(state).includes(formName);

export const getFormFieldValueWithState = (state, formName, fieldNameArray) => formValueSelector(formName, getForm)(state, fieldNameArray);
export const getFormFieldValue = (formName, fieldNameArray) => (state) => formValueSelector(formName, getForm)(state, fieldNameArray);
export const isSubmitting = (formName) => (state) => reduxFormIsSubmitting(formName, getForm)(state);
export const isFieldDirty = (state, formName, fieldNameArray) => isDirty(formName, getForm)(state, fieldNameArray);
// returns true if any of the fields in the list is dirty
export const isFieldGroupDirty = (state, formName, listOfFieldNameArray) =>
    listOfFieldNameArray.map((fieldNameArray) => isFieldDirty(state, formName, fieldNameArray)).some((value) => value === true);
export const getFormError = (state, formName) => reduxFormGetFormError(formName, getForm)(state);
export const createGetFormError = (formName) => (state) => reduxFormGetFormError(formName, getForm)(state);
export const getFormSyncErrorsWithState = (state, formName) => reduxGetFormSyncErrors(formName, getForm)(state);
export const getFormSyncErrors = (formName) => (state) => reduxGetFormSyncErrors(formName, getForm)(state);
export const getFormInitialValues = (state, formName) => reduxGetFormInitialValues(formName, getForm)(state) || Map();
export const createGetFormInitialValues = (formName) => (state) => reduxGetFormInitialValues(formName, getForm)(state) || Map();
export const getFormValues = (state, formName) => reduxGetFormValues(formName, getForm)(state) || Map();
export const createFieldValueMatcher = (formName, fieldName, value) => (state) => getFormFieldValue(formName, fieldName)(state) === value;
export const isSectionEmpty = (state, formName, sectionName) =>
    getFormValues(state, formName).get(sectionName) === undefined ||
    getFormValues(state, formName)
        .get(sectionName)
        .every((val) => fn.isArrayEmpty(val));
export const hasSubmitSucceeded = (formName) => (state) => reduxHasSubmitSucceeded(formName, getForm)(state);
export const hasSubmitFailed = (formName) => (state) => reduxHasSubmitFailed(formName, getForm)(state);

export const getFormChangedFields = createSelector(getFormInitialValues, getFormValues, (initialValues, values) =>
    values.filter((value, key) => {
        if (Map.isMap(value)) {
            return !value.equals(initialValues.get(key));
        }
        return value !== initialValues.get(key);
    }),
);

export const createGetChangedFieldsList = (fieldList, form) => (state) => {
    const changedFields = getFormChangedFields(state, form);
    return fieldList
        .map((field) => ({
            field,
            value: changedFields.get(field),
        }))
        .filter((field) => field.value !== undefined);
};
