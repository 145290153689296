import beneficiariesDssForm from "form/fullPage/pensionBeneficiaries";

import { FORM_EDIT_BENEFICIARIES } from "../constants";
import { getBeneficiaries, getIdObject } from "../selectors";

export const BeneficiariesForm = beneficiariesDssForm.createInPageContainerDdsDss(
    FORM_EDIT_BENEFICIARIES,
    getBeneficiaries,
    getIdObject,
    false,
);
