import products from "../../products";
import userContracts from "../../userContracts";
import router from "../../router";
import { Tabs } from "../../../routeConstants";

export const useGetContractChangeTabLink: (idObject: string) => string = (idObject) => {
    if (!idObject) {
        return null;
    }

    const contract = userContracts.useGetContractById(idObject);
    const productDefinition = products.useGetProductDefinition(contract?.idEnuProductGroupLevelTwo);

    if (!productDefinition || !productDefinition.detailRoute) {
        return null;
    }
    return router.getStaticUrl(productDefinition.topLevelRoute, Tabs.CHANGES, { idObject }, { clean: 1 });
};
