import React, { FC } from "react";
import { useSelector } from "react-redux";

import { ContentBox, GridItem, GridLayout } from "ui-library/layouts";

import i18n from "core/i18n";
import localized from "core/localized";
import {
    birthNumber,
    email,
    emailCharacters,
    Field,
    getFormFieldValue,
    InputText,
    lettersNumbersBasicInterpunctionCharacters,
    max10Characters,
    max20Characters,
    max50Characters,
    minTwoLetters,
    numberCharacters,
    phoneNumberCharacters,
    required,
    SingleSelect,
} from "core/form";
import { SubjectType } from "enums";

import {
    FIELD_BIRTH_NUMBER,
    FIELD_COMPANY_IDENTIFICATION_NUMBER,
    FIELD_COMPANY_NAME,
    FIELD_EMAIL,
    FIELD_FIRST_NAME,
    FIELD_LAST_NAME,
    FIELD_PHONE_NUMBER,
    FIELD_SUBJECT_TYPE,
} from "../../constants";

type SubjectFieldsProps = {
    formName: string;
    sectionName: string;
};

export const SubjectFields: FC<SubjectFieldsProps> = ({ formName, sectionName }) => {
    const { t } = i18n.useTranslation();
    const subjectTypeOptions = useSelector(localized.getLocalizedSubjectType);
    const selectedLegalPersonCode = useSelector(getFormFieldValue(formName, `${sectionName}.${FIELD_SUBJECT_TYPE}`));

    return (
        <>
            <ContentBox fullWidth>
                <GridLayout spacing={2}>
                    <GridItem xs={12} md={6}>
                        <Field
                            name={`${sectionName}.${FIELD_SUBJECT_TYPE}`}
                            component={SingleSelect}
                            label={t("common.legalPersonCode")}
                            options={subjectTypeOptions}
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        {selectedLegalPersonCode === SubjectType.INDIVIDUAL.id ? (
                            <Field
                                name={`${sectionName}.${FIELD_BIRTH_NUMBER}`}
                                component={InputText}
                                label={t("holder.birthNumber")}
                                validateStatic={[required, birthNumber]}
                                normalize={[numberCharacters, max10Characters]}
                            />
                        ) : (
                            <Field
                                name={`${sectionName}.${FIELD_COMPANY_IDENTIFICATION_NUMBER}`}
                                component={InputText}
                                label={t("holder.companyID")}
                                // validated async in saga!
                                normalize={[numberCharacters, max10Characters]}
                            />
                        )}
                    </GridItem>
                </GridLayout>
            </ContentBox>

            {/* Person subject */}
            {selectedLegalPersonCode === SubjectType.INDIVIDUAL.id && (
                <ContentBox fullWidth>
                    <GridLayout spacing={2}>
                        <GridItem xs={12} md={6}>
                            <Field
                                name={`${sectionName}.${FIELD_FIRST_NAME}`}
                                component={InputText}
                                label={t("holder.firstName")}
                                validateStatic={[required, minTwoLetters]}
                                normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <Field
                                name={`${sectionName}.${FIELD_LAST_NAME}`}
                                component={InputText}
                                label={t("holder.lastName")}
                                validateStatic={[required, minTwoLetters]}
                                normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                            />
                        </GridItem>
                    </GridLayout>
                </ContentBox>
            )}

            {/* Company subject */}
            {selectedLegalPersonCode === SubjectType.COMPANY.id && (
                <ContentBox fullWidth>
                    <Field
                        name={`${sectionName}.${FIELD_COMPANY_NAME}`}
                        component={InputText}
                        label={t("holder.companyName")}
                        validateStatic={[required, minTwoLetters]}
                        normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                    />
                </ContentBox>
            )}

            <ContentBox fullWidth>
                <GridLayout spacing={2}>
                    <GridItem xs={12} md={6}>
                        <Field
                            name={`${sectionName}.${FIELD_PHONE_NUMBER}`}
                            component={InputText}
                            label={t("holder.tel")}
                            // validated async in saga!
                            normalize={[phoneNumberCharacters, max20Characters]}
                            validateStatic={[required]}
                            preventDefaultNormalization
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <Field
                            name={`${sectionName}.${FIELD_EMAIL}`}
                            component={InputText}
                            label={t("holder.emailAddress")}
                            validateStatic={[email, required]}
                            normalize={[emailCharacters, max50Characters]}
                            preventDefaultNormalization
                        />
                    </GridItem>
                </GridLayout>
            </ContentBox>
        </>
    );
};
