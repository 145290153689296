import { useFormContext, UseFormReturn } from "react-hook-form";
import i18n from "core/i18n";

type FieldError = {
    field: string;
    message: string;
};

export default function useFormRequest(form?: UseFormReturn<any>) {
    const { setError, setValue } = form ?? useFormContext();
    const { t } = i18n.useTranslation();

    async function handleRequest(request: () => any, onError?: () => void, onSuccess?: (res?: any) => void) {
        const genericErrorMessage = t("error.request.generic");
        const invalidDataMessage = t("error.request.invalidData");
        setValue("errors", []);

        function setErrors(errors?: string[]) {
            setValue("errors", errors || [genericErrorMessage]);
            onError?.();
        }

        function mapFieldErrors(fieldErrors?: FieldError[]) {
            fieldErrors?.forEach((error: FieldError) => {
                setError(error.field, { type: "custom", message: error.message });
            });
        }

        try {
            const [error, response] = await request();

            if (error || !response) {
                if (error.status === 412) {
                    mapFieldErrors(response?.body?.fieldErrors);
                    setErrors(response?.body?.errors?.length > 0 ? response?.body?.errors : [invalidDataMessage]);
                    return [new Error(), null];
                }

                setErrors();
                return [new Error(), null];
            }

            onSuccess?.(response);
            return [null, response];
        } catch (e) {
            setErrors();
            return [new Error(), null];
        }
    }

    return handleRequest;
}
