import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import i18n from "core/i18n";
import RepairType from "../types/repairType";
import { setCarMakes, setErrorMessage, setGarages, setIsLoading, setRegions } from "../actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { garageApi } from "serverApi";
import { GarageSearchQuery } from "types/garages";
import { getErrorMessage, getGarages, getIsLoading } from "../selectors";

export type GarageForm = {
    repairType?: RepairType;
    carMake?: number;
    makeYear?: number;
    region?: number;
    districts?: number[];
};

export function useGarageSearch() {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();
    const isLoading = useSelector(getIsLoading);
    const errorMessage = useSelector(getErrorMessage);
    const garages = useSelector(getGarages);

    const validationSchema = yup.object().shape({
        repairType: yup.number().required(t("error.required")),
        carMake: yup
            .number()
            .when("repairType", {
                is: (val: any) => Number(val) !== RepairType.HailDamage,
                then: (schema) => schema.required(t("error.required")),
            })
            .nullable(),
        makeYear: yup
            .number()
            .transform((value, originalValue) => {
                return originalValue === "" ? null : value;
            })
            .typeError(t("error.notValid"))
            .min(1900, t("error.notValid"))
            .max(new Date().getFullYear(), t("error.notValid"))
            .when("repairType", {
                is: (val: any) => Number(val) === RepairType.VehicleDamage,
                then: (schema) => schema.required(t("error.required")),
            })
            .nullable(),
        region: yup.number().nullable(),
        districts: yup.array().nullable(),
    });

    const initialValues = {
        repairType: RepairType.Windshield,
        carMake: null,
        makeYear: null,
        region: null,
        districts: null,
    };

    const form = useForm<GarageForm>({
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema),
    });

    async function onSubmit(data: any) {
        dispatch(setIsLoading(true));
        await searchGarages(data);
        dispatch(setIsLoading(false));
    }

    async function searchGarages(data: any) {
        dispatch(setErrorMessage(undefined));

        const searchQuery: GarageSearchQuery = {
            region: data.region,
            district: data.districts,
            vehicleBrand: data.carMake,
            repairType: data.repairType,
            manufactureYear: data.makeYear,
        };

        const [error, res] = await garageApi.searchGarages(searchQuery);
        if (error) {
            dispatch(setErrorMessage(t("garage.search.error.failure")));
            return;
        }

        if (res.ok && res.body) {
            if (res.body.length === 0) {
                dispatch(setErrorMessage(t("garage.search.error.noResults")));
            }
            dispatch(setGarages(res.body));
        }
    }

    useEffect(() => {
        const fetchCarMakes = async () => {
            const [error, res] = await garageApi.getCodeTable("VEHICLE_BRAND");
            if (error) return;

            if (res.ok && res.body) {
                const carMakes = res.body;
                const carMakeOptions = carMakes.map((c) => ({ code: c.code, value: c.label }));
                dispatch(setCarMakes(carMakeOptions));
            }
        };

        const fetchRegions = async () => {
            const [error, res]: [Error, any] = await garageApi.getCodeTable("REGION");
            if (error) return;

            if (res.ok && res.body) {
                const regions = res.body;
                const regionOptions = regions.map((r) => ({ code: r.code, value: r.label }));
                dispatch(setRegions(regionOptions));
            }
        };

        const fetchDistricts = async () => {
            const [error, res]: [Error, any] = await garageApi.getCodeTable("DISTRICT");
            if (error) return;

            if (res.ok && res.body) {
                const districts = res.body;
                const districtOptions = districts.map((d) => ({ code: d.code, value: d.label }));
                console.log(districtOptions);
            }
        };

        fetchCarMakes();
        fetchRegions();
        fetchDistricts();
    }, []);

    return {
        form,
        onSubmit,
        isLoading,
        garages,
        errorMessage,
    };
}
