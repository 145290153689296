import { NAME } from "./constants";
import reducer from "./reducer";
import saga from "./saga";
import innerRoutes from "./innerRoutes";

import { AgentTab, ChangesTab, ClaimsTab, DetailTab, DocumentsTab, FinancialOperationsTab, OverviewTab, PolicyHolderTab } from "./tabs";

export { default as DetailTravelPage } from "./Container";

export const DetailTravelTabs = {
    OverviewTab,
    PolicyHolderTab,
    DetailTab,
    ChangesTab,
    FinancialOperationsTab,
    ClaimsTab,
    DocumentsTab,
    AgentTab,
};

export default {
    NAME,
    reducer,
    saga,
    innerRoutes,
};
