import React, { FC, PropsWithChildren } from "react";
import { Box, Flexbox } from "ui-library/layouts";

export interface InPageFormButtonsLayoutProps extends PropsWithChildren {
    leftButton?: React.ReactNode;
    middleButton?: React.ReactNode;
    rightButton?: React.ReactNode;
}

export const InPageFormButtonsLayout: FC<InPageFormButtonsLayoutProps> = ({ leftButton, middleButton, rightButton }) => {
    if (leftButton) {
        return (
            <Flexbox mt={4} gap={2} justifyContent="space-between">
                {leftButton}
                {rightButton}
            </Flexbox>
        );
    }

    return (
        <Box fullWidth display="flex" justifyContent="end" mt={3}>
            {rightButton}
        </Box>
    );
};
