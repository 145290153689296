import React, { FC } from "react";

import { Typography } from "ui-library/atoms";
import { ContentBox } from "ui-library/layouts";

import autocomplete from "core/autocomplete";
import i18n from "core/i18n";

import { PERMANENT_ADDRESS_AUTOCOMPLETE, SECTION_ADDRESS } from "../../constants";

import { SubjectFields } from "./SubjectFields";
import { AddressFieldsContainer } from "./AddressFields";

const PermanentAddressAutocomplete = autocomplete.createSingle(PERMANENT_ADDRESS_AUTOCOMPLETE);

type ContactPersonSectionProps = {
    formName: string;
    sectionName: string;
    isSubmitting: boolean;
};

export const ContactPersonSection: FC<ContactPersonSectionProps> = ({ formName, sectionName, isSubmitting }) => {
    const { t } = i18n.useTranslation();

    return (
        <>
            <ContentBox>
                <Typography variant="h4" markDown={t("vehicle.change.contractCancellation.newContractPerson")} />
            </ContentBox>
            <SubjectFields formName={formName} sectionName={sectionName} />
            <AddressFieldsContainer
                sectionName={`${sectionName}.${SECTION_ADDRESS}`}
                addressAutocomplete={PermanentAddressAutocomplete}
                disabled={isSubmitting}
            />
        </>
    );
};
