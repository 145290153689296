import React from "react";
import PropTypes from "prop-types";
import { List } from "immutable";

import { HarmonizedActualAndAdvancedPaymentSchedulerPanel } from "./HarmonizedActualAndAdvancedPaymentSchedulerPanel";
import { HarmonizedActualPaymentOnlySchedulerPanel } from "./HarmonizedActualPaymentOnlySchedulerPanel";

export const HarmonizedContractPaymentSchedulerGridItem = ({ actualPaymentScheduler, advancePaymentsScheduler }) => {
    // In case advance scheduler is available we do display both.
    if (advancePaymentsScheduler instanceof List && !advancePaymentsScheduler.isEmpty()) {
        return (
            <HarmonizedActualAndAdvancedPaymentSchedulerPanel
                actualScheduler={actualPaymentScheduler}
                advancePaymentsScheduler={advancePaymentsScheduler}
            />
        );
    }

    return <HarmonizedActualPaymentOnlySchedulerPanel actualScheduler={actualPaymentScheduler} />;
};

HarmonizedContractPaymentSchedulerGridItem.propTypes = {
    actualPaymentScheduler: PropTypes.object.isRequired,
    advancePaymentsScheduler: PropTypes.object,
};

HarmonizedContractPaymentSchedulerGridItem.defaultProps = {
    advancePaymentsScheduler: null,
};
