import React, { FC } from "react";
import { useDispatch } from "react-redux";

import { GridItem, GridLayout, LandingPageLayout } from "ui-library/layouts";
import { SectionBanner } from "ui-library/modules";
import bannerImg from "ui-library/assets/images/banner-prihlasenie.svg";
import { Button, ErrorContent } from "ui-library/atoms";

import auth from "core/auth";
import i18n from "core/i18n";

export const WrongRolesMessage: FC = () => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    return (
        <LandingPageLayout>
            <SectionBanner title={t("page.login.welcomeTitle")} imageUrl={bannerImg}>
                <GridLayout spacing={1}>
                    <GridItem xs={12} mb={2}>
                        <ErrorContent content={t("error.page.login.wrongRole")} />
                    </GridItem>
                    <GridItem xs={12}>
                        <Button
                            onClick={() => dispatch(auth.logOut(auth.LogoutAction.WRONG_PORTAL_USER))}
                            color="blue"
                            variant="contained"
                            size="large"
                        >
                            {t("common.logout")}
                        </Button>
                    </GridItem>
                </GridLayout>
            </SectionBanner>
        </LandingPageLayout>
    );
};
