import React, { FC, ForwardedRef, PropsWithChildren } from "react";
import { SelectProps as MaterialSelectProps } from "@mui/material";

import { StyledSelect } from "ui-library/Styled";
import { ErrorContent, Icon, PopoverTooltip, Typography } from "ui-library/atoms";
import { theme } from "ui-library/theme/theme";
import { Box, Flexbox } from "ui-library/layouts";

import { Label } from "../Label";
import { Option } from "./Option";

export interface SelectProps extends PropsWithChildren, MaterialSelectProps {
    id: string;
    options?: any[];
    label?: string;
    tooltip?: string;
    error?: boolean;
    errorContent?: string;
    labelDesc?: string;
    labelOptional?: string;
    multiple?: boolean;
    fullWidth?: boolean;
    value: string;
    reference?: ForwardedRef<any>;
}

export const Select: FC<SelectProps> = ({
    labelDesc,
    labelOptional,
    errorContent,
    id,
    label,
    tooltip,
    error,
    options,
    value,
    multiple,
    placeholder,
    renderValue,
    fullWidth,
    children,
    reference,
    ...props
}) => {
    return (
        <div>
            <Box>
                {label && (
                    <Flexbox alignItems={"flex-start"} gap={1}>
                        <Label label={label} id={id} labelOptional={labelOptional} />
                        <Box mt={0.5}>{tooltip && <PopoverTooltip title={tooltip} />}</Box>
                    </Flexbox>
                )}

                {labelDesc && (
                    <Box mt={0.5} mb={1}>
                        <Typography color={"gray"} variant="caption">
                            {labelDesc}
                        </Typography>
                    </Box>
                )}
            </Box>

            <StyledSelect
                $fullWidth={fullWidth}
                value={value}
                id={id}
                error={error}
                label={null}
                variant="outlined"
                multiple={multiple}
                IconComponent={({ className }) => <Icon className={className} size={14} icon={"chevron-down"} />}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            borderRadius: "0 0 4px 4px",
                            // @ts-ignore
                            border: "1px solid" + theme.palette.gray.softBorder,
                            boxShadow: "1px 5px 9px -6px #DEDEE1 inset",
                            marginTop: "-2px",
                        },
                    },
                }}
                displayEmpty={true}
                placeholder={placeholder}
                renderValue={
                    value === "" || !value || value.length === 0
                        ? () => <Typography>{placeholder}</Typography>
                        : renderValue && ((selected) => renderValue(selected))
                }
                // renderValue={
                //     value !== '' || value.length !== 0 ? renderValue : () => <Typography>{placeholder}</Typography>
                // }
                {...props}
                ref={reference}
            >
                {options
                    ? options.map((item) => {
                          return (
                              <Option value={item.get("value")} key={item.get("value")}>
                                  {item.get("label")}
                              </Option>
                          );
                      })
                    : children}
            </StyledSelect>

            {error ? <ErrorContent content={errorContent} /> : null}
        </div>
    );
};
