export type VehicleSubjectChangeFormValues = {
    step: number;
    errors?: string[];
    idObject: number;
    idChange?: string;
    vinNumber?: string;
    licencePlateNumber?: string;
    validityStartDate?: Date;
    owner?: Subject;
    operator?: Subject;
    editOwner: boolean;
    editOperator: boolean;
    signature: {
        type: string;
        date?: Date;
        place?: string;
        uploadedFiles?: any[];
    };
};

type Subject = {
    legalEntity: number;
    person?: Person;
    organisation?: Organisation;
    address: Address;
};

type Person = {
    birthNumber: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
};

type Organisation = {
    organisationId: string;
    name: string;
};

type Address = {
    street: string;
    descriptiveNo: string;
    orientationNo: string;
    city: string;
    zipCode: string;
    country: string;
};

export enum SignatureType {
    PHYSICAL = "PHYSICAL",
    ELECTRONIC = "ELECTRONIC",
}
