import { Option } from "core/hookForm/components/Field";
import { useFormContext } from "react-hook-form";

export default function useSubjectForm(subject: string) {
    const fieldPrefix = subject ? subject + "." : "";
    const { watch } = useFormContext();
    const legalEntity = watch(fieldPrefix + "legalEntity");

    const legalEntityOptions: Option[] = [
        {
            code: 1,
            value: "Fyzická osoba",
        },
        {
            code: 2,
            value: "Právnická osoba",
        },
    ];

    return { fieldPrefix, legalEntity, legalEntityOptions };
}
