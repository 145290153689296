import { NAME } from "./constants";

export const SET_LOGIN_PAGE_STEP = `${NAME}/SET_LOGIN_PAGE_STEP`;
export const SET_ERROR = `${NAME}/SET_ERROR`;
export const CLEAR = `${NAME}/CLEAR`;

export const setLoginPageStep = (step) => ({
    type: SET_LOGIN_PAGE_STEP,
    payload: step,
});

export const setError = (error) => ({
    type: SET_ERROR,
    payload: error,
});

export const clear = () => ({
    type: CLEAR,
});
