import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";
import { resetSection } from "redux-form/immutable";

import auth from "core/auth";
import router from "core/router";
import { formWrapper, validateWithRecaptcha } from "core/form";
import { fn, sentry } from "core/util";
import { authApi } from "serverApi";

import { clear, SET_LOGIN_PAGE_STEP, setError } from "./actions";
import { LOGIN_FORM, LOGIN_PAGE_STEP } from "./constants";
import { ResetPassword } from "./modules";
import { getLoginPageState } from "./selectors";

export default router.routerWrapper({
    *onPageNonAuthenticatedEnter() {
        yield fork(loginFormSaga);
        yield takeLatest(SET_LOGIN_PAGE_STEP, onLoginPageStateChange);
    },
    clearDataForPage() {
        return [clear()];
    },
});

function* onLoginPageStateChange() {
    const step = yield select(getLoginPageState);

    switch (step) {
        case LOGIN_PAGE_STEP.LOGIN_FORM:
            yield fork(loginFormSaga);
            break;
        case LOGIN_PAGE_STEP.RESET_PASSWORD:
            yield fork(ResetPassword.saga);
            yield put(ResetPassword.setEpinPhase(ResetPassword.EPIN_PHASE_ISSUE));
            break;
        default:
            break;
    }
}

const loginFormSaga = formWrapper(LOGIN_FORM, {
    *initialize() {
        yield put(setError(null));
        return {};
    },
    *save(values) {
        yield put(setError(null));
        const recaptchaValue = yield call(validateWithRecaptcha);

        if (fn.isEmpty(recaptchaValue) && process.env.RECAPTCHA_REQUIRED) {
            yield put(setError(auth.UnsuccessfulReason.ERROR_MISSING_RECAPTCHA));
            sentry.captureException(new Error("Google recaptcha missing"));
        } else {
            const response = yield call(authApi.authenticate, {
                username: values.get("username"),
                password: values.get("password"),
                "g-recaptcha-response": recaptchaValue,
            });

            if (response.isLoggedInSuccessfully) {
                yield put(auth.logIn());
                yield call(fn.block); // to avoid visual blink of page (loading will stop), we will block until redirected
            } else {
                yield all([put(resetSection(LOGIN_FORM, "password")), put(setError(response.loginUnsuccessfulReason))]);
            }
        }
    },
});
