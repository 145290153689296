import { call, put } from "redux-saga/effects";

import errorHandling from "core/errorHandling";
import modal from "core/modal";
import router from "core/router";
import { formWrapper } from "core/form";
import { sentry } from "core/util";

import { FIELD_CONTRACT_TO_EDIT } from "./constants";

export function* createSaga(formName) {
    try {
        yield call(formSaga(formName));
    } catch (e) {
        sentry.captureException(e);
        yield put(errorHandling.addServiceError(formName, e.identifier));
    }
}

const formSaga = (formName) =>
    formWrapper(formName, {
        *save(values) {
            yield put(modal.closeModalForm(formName));
            const idObject = values.get(FIELD_CONTRACT_TO_EDIT);
            const editLink = yield call(router.getEditLinkByIdObject, idObject);
            yield put(router.navigateByDirectLink(editLink));
        },
    });
