import i18n from "core/i18n";
import { ContractChangesTab } from "containers/contract";
import { useChangeTypeGetParamForm, useGetUserChangeTypes } from "core/hooks";
import { ContractChangeType } from "types";

import {
    BeneficiariesForm,
    ContractNameForm,
    ElectronicCommunicationForm,
    EmployerPaymentsForm,
    ParticipantForm,
    ParticipantPaymentsForm,
    PensionStatementSettingsForm,
    TaxOptimizationsForm,
} from "../form";
import { changesDefinition } from "../changesDefinition";

export function ChangesTab() {
    const { t } = i18n.useTranslation();
    const { form, changeType, onChange } = useChangeTypeGetParamForm();
    const changeOptions = useGetUserChangeTypes(changesDefinition(t));

    return (
        <ContractChangesTab form={form} changeOptions={changeOptions} onChange={onChange}>
            {changeType === ContractChangeType.CONTRACT_NAME && <ContractNameForm />}
            {changeType === ContractChangeType.ELECTRONIC_COMMUNICATION && <ElectronicCommunicationForm />}
            {changeType === ContractChangeType.PARTICIPANT && <ParticipantForm />}
            {changeType === ContractChangeType.BENEFICIARIES && <BeneficiariesForm />}
            {changeType === ContractChangeType.PARTICIPANT_PAYMENTS && <ParticipantPaymentsForm />}
            {changeType === ContractChangeType.EMPLOYER_PAYMENTS && <EmployerPaymentsForm />}
            {changeType === ContractChangeType.TAX_OPTIMUM && <TaxOptimizationsForm />}
            {changeType === ContractChangeType.STATEMENTS_SETTINGS && <PensionStatementSettingsForm />}
        </ContractChangesTab>
    );
}
