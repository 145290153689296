import { useFormContext } from "react-hook-form";
import { VehicleSubjectChangeFormValues } from "../types/VehicleSubjectChangeFormValues";
import { useSearchParams } from "react-router-dom";
import vehicleSubjectChange, { PersonDTO, VehicleSubjectChangeSubmitDTO } from "serverApi/vehicleSubjectChange";
import { useState } from "react";
import useFormRequest from "core/hookForm/hooks/useFormRequest";

export default function useStepSignature(nextStep: () => void) {
    const { watch, getValues, trigger, setValue } = useFormContext<VehicleSubjectChangeFormValues>();
    const validityStartDate: Date = watch("validityStartDate");
    const { signature, editOwner, editOperator } = watch();
    const [searchParams, setSearchParams] = useSearchParams();
    const handleRequest = useFormRequest();

    const [isSubmitting, setIsSubmitting] = useState(false);

    async function submit() {
        setIsSubmitting(true);
        const isValid = await trigger();

        if (!isValid) {
            setIsSubmitting(false);
            return;
        }

        const dto = getSubmitDto();

        const onError = () => {
            setIsSubmitting(false);
        };

        const onSuccess = (res) => {
            setIsSubmitting(false);

            const idChange = res?.text;

            setValue("idChange", idChange);
            searchParams.set("idChange", idChange);
            searchParams.set("step", "3");
            searchParams.set("signatureType", signature.type);
            setSearchParams(searchParams);

            nextStep();
        };

        handleRequest(() => vehicleSubjectChange.submit(dto), onError, onSuccess);
    }

    function getSubmitDto() {
        const v = getValues();

        const owner: PersonDTO = v.editOwner
            ? {
                  subjectType: v.owner.legalEntity === 1 ? "INDIVIDUAL" : "COMPANY",
                  firstName: v.owner.person?.firstName,
                  lastName: v.owner.person?.lastName,
                  birthNumber: v.owner.person?.birthNumber,
                  phoneNumber: v.owner.person?.phone,
                  email: v.owner.person?.email,
                  companyIdentificationNumber: v.owner.organisation?.organisationId,
                  companyName: v.owner.organisation?.name,
                  address: {
                      street: v.owner.address.street,
                      orientationNumber: v.owner.address.orientationNo,
                      descriptionNumber: v.owner.address.descriptiveNo,
                      city: v.owner.address.city,
                      postalCode: v.owner.address.zipCode,
                      countryCode: v.owner.address.country,
                  },
              }
            : undefined;

        const operator: PersonDTO = v.editOperator
            ? {
                  subjectType: v.operator.legalEntity === 1 ? "INDIVIDUAL" : "COMPANY",
                  firstName: v.operator.person?.firstName,
                  lastName: v.operator.person?.lastName,
                  birthNumber: v.operator.person?.birthNumber,
                  phoneNumber: v.operator.person?.phone,
                  email: v.operator.person?.email,
                  companyIdentificationNumber: v.operator.organisation?.organisationId,
                  companyName: v.operator.organisation?.name,
                  address: {
                      street: v.operator.address.street,
                      orientationNumber: v.operator.address.orientationNo,
                      descriptionNumber: v.operator.address.descriptiveNo,
                      city: v.operator.address.city,
                      postalCode: v.operator.address.zipCode,
                      countryCode: v.operator.address.country,
                  },
              }
            : undefined;

        const dto: VehicleSubjectChangeSubmitDTO = {
            vinNumber: v.vinNumber,
            validityStartDate: v.validityStartDate,
            idObject: v.idObject,
            licencePlateNumber: v.licencePlateNumber,
            signatureType: v.signature.type,
            dateOfSigning: v.signature.date,
            placeOfSigning: v.signature.place,
            owner: owner,
            operator: operator,
        };

        return dto;
    }

    return {
        editOperator,
        editOwner,
        validityStartDate,
        signature,
        submit,
        isSubmitting,
    };
}
