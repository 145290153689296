import React, { FC, PropsWithChildren, useRef } from "react";
import { RadioProps as MuiRadioProps, RadioGroupProps as MuiRadioGroupProps } from "@mui/material";
import { StyledRadio, StyledRadioGroup, StyledRadioLabel } from "ui-library/Styled";
import { ErrorContent, PopoverTooltip, Typography } from "ui-library/atoms";
import { Box, Flexbox } from "ui-library/layouts";
import { Label } from "./Label";

export interface RadioProps extends MuiRadioProps {
    id: string;
    label?: string;
    labelOptional?: string;
    tooltip?: string;
    error?: boolean;
    hasGroup?: boolean;
    errorContent?: string;
    hasTooltip?: boolean;
}

export const Radio: FC<RadioProps> = ({ hasGroup, id, label, labelOptional, tooltip, error, errorContent, hasTooltip, ...props }) => {
    const radioRef = useRef("");

    return (
        <div className="radio-wrapper">
            <Flexbox alignItems={"center"} gap={1}>
                <StyledRadioLabel
                    $hasGroup={hasGroup}
                    $hasTooltip={hasTooltip}
                    className={radioRef.current && radioRef.current.checked && !error ? "checked" : "unchecked"}
                    control={<StyledRadio $error={error} inputRef={radioRef} id={id} {...props} />}
                    label={<Label labelOptional={labelOptional} label={label} id={id} />}
                />

                {tooltip && <PopoverTooltip className="radio-tooltip" title={tooltip} />}
            </Flexbox>

            {error ? <ErrorContent content={errorContent} /> : ""}
        </div>
    );
};

export interface RadioGroupProps extends PropsWithChildren, MuiRadioGroupProps {
    label?: string;
    labelDesc?: string;
    labelOptional?: string;
    tooltip?: string;
    direction?: string;
    error?: boolean;
    errorContent?: string;
    reference?: React.ForwardedRef<any>;
    disabled?: boolean;
}

export const RadioGroup: FC<RadioGroupProps> = ({
    label,
    tooltip,
    labelOptional,
    labelDesc,
    children,
    direction = "row",
    error,
    errorContent,
    reference,
    disabled = false,
    ...props
}) => (
    <>
        <Box>
            <Flexbox alignItems={"flex-start"} gap={1}>
                <Label label={label} id="" labelOptional={labelOptional} />
                <Box mt={0.5}>{tooltip && <PopoverTooltip title={tooltip} />}</Box>
            </Flexbox>
            {labelDesc && (
                <Box mt={0.5} mb={1}>
                    <Typography color={"gray"} variant="caption">
                        {labelDesc}
                    </Typography>
                </Box>
            )}
        </Box>
        <StyledRadioGroup direction={direction} {...props} ref={reference}>
            {children
                ? React.Children.map(children, (item) => (
                      <Radio
                          disabled={disabled}
                          hasGroup={true}
                          hasTooltip={!!item.props.tooltip}
                          error={error}
                          errorContent={""}
                          id={item.props.id}
                          {...item.props}
                      />
                  ))
                : ""}
        </StyledRadioGroup>
        {error ? <ErrorContent content={errorContent} /> : ""}
    </>
);
