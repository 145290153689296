import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import router from "core/router";
import { Pages, Tabs } from "routeConstants";
import { ContractPolicyHolderTabLegacy } from "containers/contract";

import { getPolicyHolder } from "../selectors";

export const PolicyHolderTab = () => {
    const { idObject } = useParams();
    const policyHolder = useSelector(getPolicyHolder);
    return (
        <ContractPolicyHolderTabLegacy
            holder={policyHolder}
            editLink={router.getStaticUrl(Pages.CONTRACT_VEHICLE, Tabs.EDIT_POLICY_HOLDER_FORM, { idObject })}
            isContractEditable={false}
        />
    );
};
