import { all, call, put, select } from "redux-saga/effects";
import { Map } from "immutable";
import { v4 as uuid } from "uuid";

import fetching from "core/fetching";
import user from "core/user";
import userContracts from "core/userContracts";
import errorHandling from "core/errorHandling";
import { sentry } from "core/util";
import { PermissionTypes } from "enums";
import { consentApi, contractAgentApi, messagesApi, userApi } from "serverApi";

import { formWrapper } from "../form";

import { acceptingGdprActionGroup, gdprData, revokingGdprActionGroup, setAgents, setAuthenticatedUser, setMessages } from "./actions";
import { FETCHING_AGENTS, MESSAGES_UNAVAILABLE } from "./constants";
import { getGdprVersion, getIsVerifiedUser, hasGdprConsent } from "./selectors";
import userInfo from "./index";
import router from "core/router";
import fn from "core/util/fn";
import { Pages, Tabs } from "routeConstants";

export function* loadCriticalUserInfo() {
    yield call(user.loadUserAccount);
    const isAuthenticated = yield select(user.getIsAuthenticated);
    const isOneTimeChange = yield select(user.getIsOneTimeChangeRole);

    if (isOneTimeChange) {
        yield call(userContracts.loadOneTimeChangeContracts);
    }

    if (isAuthenticated) {
        // TODO in this order? Do we want user to be authenticated only if contracts are loaded
        yield call(userContracts.loadUserContracts);
        const authenticatedUser = yield call(userApi.getAuthenticatedUser);
        yield put(setAuthenticatedUser(authenticatedUser));
    }
}

export function* loadAgents() {
    yield put(fetching.setFetching(FETCHING_AGENTS, true, true));
    try {
        const agents = yield call(contractAgentApi.getAllAgents);
        yield put(setAgents(agents));
    } catch (e) {
        sentry.captureException(e);
        //yield put(addServiceError(AGENTS_UNAVAILABLE));
    } finally {
        yield put(fetching.setFetching(FETCHING_AGENTS, false));
    }
}

export function* checkUserVerified(stepsBack = 1) {
    const verified = yield select(getIsVerifiedUser);
    const hasEditPermission = yield select(user.hasPermission(PermissionTypes.EDIT_CONTRACT_DATA));
    const stepsBackParam = stepsBack === 1 ? {} : { type: "form" };

    if (!hasEditPermission || !verified) {
        yield put(router.navigate(Pages.USER, Tabs.UNVERIFIED_USER, {}, stepsBackParam, false));
        yield call(fn.block); // do not continue in original saga, block until redirect
    }
}

export function* checkUserVerifiedOrOneTime(stepsBack = 1) {
    const isAuthenticated = yield select(user.getIsAuthenticated);
    const isOneTimeChange = yield select(user.getIsOneTimeChangeRole);

    const verified = yield select(getIsVerifiedUser);
    const hasEditPermission = yield select(user.hasPermission(PermissionTypes.EDIT_CONTRACT_DATA));
    const stepsBackParam = stepsBack === 1 ? {} : { type: "form" };

    if (isOneTimeChange) {
        return;
    }

    if (isAuthenticated && (!hasEditPermission || !verified)) {
        yield put(router.navigate(Pages.USER, Tabs.UNVERIFIED_USER, {}, stepsBackParam, false));
        yield call(fn.block); // do not continue in original saga, block until redirect
    }
}

export function* loadAuthenticatedUser() {
    const authenticatedUser = yield call(userApi.getAuthenticatedUser);
    yield put(setAuthenticatedUser(authenticatedUser));
    return authenticatedUser;
}

export function* loadMessages() {
    try {
        yield put(errorHandling.removeServiceErrors(MESSAGES_UNAVAILABLE));
        const messages = yield call(messagesApi.getMessages);
        yield put(setMessages(messages));
    } catch (e) {
        sentry.captureException(e);
        yield put(errorHandling.addServiceError(MESSAGES_UNAVAILABLE, e.identifier));
    }
}

export function* loadGdpr(authenticatedUser) {
    try {
        const [gdprInfo, gdprConfirmed, gdprFormInfo] = yield all([
            call(consentApi.getGdprVersionAndText),
            call(consentApi.getGdprConfirmed),
            call(consentApi.getGdprFormVersionAndText),
        ]);
        if (gdprConfirmed) {
            try {
                const rawConsentsData = yield call(consentApi.getGdprTableData, authenticatedUser.consent.idConsent);
                const consents = getConsentData(rawConsentsData);

                yield all([
                    put(
                        gdprData(
                            authenticatedUser.consent.date,
                            gdprInfo.text,
                            gdprInfo.version,
                            gdprFormInfo.text,
                            gdprFormInfo.version,
                            authenticatedUser.consent.modifyDate,
                            authenticatedUser.consent.consentUrl,
                            consents,
                        ),
                    ),
                ]);
            } catch (e) {
                sentry.captureException(e);
                // yield put(addServiceError(GDPR_UNAVAILABLE));
            }
        } else {
            yield all([
                put(
                    gdprData(
                        null,
                        gdprInfo.text,
                        gdprInfo.version,
                        gdprFormInfo.text,
                        gdprFormInfo.version,
                        authenticatedUser.consent.modifyDate,
                        authenticatedUser.consent.consentUrl,
                        null,
                        null,
                        null,
                    ),
                ),
            ]);
        }
    } catch (e) {
        sentry.captureException(e);
        // yield put(addServiceError(GDPR_UNAVAILABLE));
    }
}

function getConsentData(consentList) {
    return consentList
        .groupBy((consent) => consent.get("consentEntity"))
        .map((entityEntries) =>
            entityEntries.map((entry) =>
                Map({
                    reactKey: uuid(),
                    consentReason: entry.consentReason,
                    isActive: entry.isActive,
                }),
            ),
        );
}

export function* verify(values) {
    yield call(userApi.verify, values);
    const newUserInfo = yield call(userApi.getAuthenticatedUser);
    yield put(setAuthenticatedUser(newUserInfo));
}

export const gdprFormSaga = (formName) =>
    formWrapper(formName, {
        *save() {
            const hasConsent = yield select(hasGdprConsent);
            if (hasConsent) {
                yield call(gdprRevokeSaga);
            } else {
                yield call(gdprAcceptSaga);
            }
        },
    });

function* gdprRevokeSaga() {
    yield call(consentApi.revokeGdprConsent);
    yield put(revokingGdprActionGroup.response());

    // Refresh data.
    const authenticatedUser = yield call(userInfo.loadAuthenticatedUser);
    yield call(userInfo.loadGdpr, authenticatedUser);
}

function* gdprAcceptSaga() {
    const version = yield select(getGdprVersion);
    yield call(consentApi.acceptGdprConsent, version);
    const [user, confirmed] = yield all([call(userApi.getAuthenticatedUser), call(consentApi.getGdprConfirmed)]);
    const rawConsentsData = yield call(consentApi.getGdprTableData, user.consent.idConsent);
    const consents = getConsentData(rawConsentsData);
    yield all([
        put(userInfo.setAuthenticatedUser(user)),
        put(confirmed ? userInfo.acceptingGdprActionGroup.response(user.consent.date, consents) : acceptingGdprActionGroup.cancel()),
    ]);
}
