import React, { FC } from "react";

import { InfoPanel, LabelTextItem } from "ui-library/modules";
import { Box, GridItem } from "ui-library/layouts";

import i18n from "core/i18n";
import { formatYesNo } from "core/formatters";

export interface VehicleGridItemProps {
    insuredVehicle: any;
}

export const VehicleGridItem: FC<VehicleGridItemProps> = ({ insuredVehicle }) => {
    const { t } = i18n.useTranslation();

    return (
        <GridItem display={"flex"} xs={12} md={6}>
            <InfoPanel icon={"car"} title={t("insurance.insurance")}>
                <Box>
                    <LabelTextItem
                        label={t("vehicle.insuredVehicle")}
                        text={`${insuredVehicle.manufacturer || ""} ${insuredVehicle.model || ""}`}
                    />
                    <LabelTextItem label={t("vehicle.vin")} text={insuredVehicle.vinNumber} />
                    <LabelTextItem label={t("vehicle.licencePlateNumber")} text={insuredVehicle.licencePlateNumber} />
                    <LabelTextItem label={t("vehicle.vehicleLicenceNumber")} text={insuredVehicle.vehicleLicenceNumber} />
                    <LabelTextItem
                        label={t("vehicle.leasing")}
                        text={formatYesNo(t, insuredVehicle.leasingInformations && !insuredVehicle.leasingInformations.isEmpty())}
                    />
                </Box>
            </InfoPanel>
        </GridItem>
    );
};
