import { useFormContext, Controller } from "react-hook-form";
import { Label, Select as NoRefSelect } from "ui-library/atoms";
import { FormControl, MenuItem } from "@mui/material";
import { Box, Flexbox } from "ui-library/layouts";
import { Option } from "../Field";
import { forwardRef } from "react";

type HookFormSelectProps = {
    name: string;
    disabled?: boolean;
    options?: Option[];
    label?: string;
    multiple?: boolean;
    placeholder?: string;
    required?: boolean;
    onChange?: (value?: any) => any;
};

const Select = forwardRef((props: any, ref) => <NoRefSelect {...props} reference={ref} />);

export default function HookFormSelect({
    name,
    options = [],
    multiple,
    label,
    placeholder,
    disabled,
    required,
    onChange,
}: HookFormSelectProps) {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const error = name.split(".").reduce((acc, part) => acc?.[part], errors);

    return (
        <>
            <Box>
                <Flexbox alignItems={"flex-start"} gap={1}>
                    <Label label={required ? label + "*" : label} id={name} />
                </Flexbox>
            </Box>
            <FormControl fullWidth>
                <Controller
                    name={name}
                    control={control}
                    render={({ field }) => (
                        <Select
                            displayEmpty
                            fullWidth
                            id={name}
                            {...field}
                            onChange={(e) => {
                                field.onChange(e);
                                onChange?.(e.target.value || null);
                            }}
                            placeholder={placeholder}
                            multiple={multiple}
                            disabled={disabled}
                            error={Boolean(error)}
                            errorContent={error?.message.toString()}
                        >
                            <MenuItem key="null" value={null}>
                                ---
                            </MenuItem>
                            {options.map((option) => (
                                <MenuItem key={option.code} value={option.code}>
                                    {option.value}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                />
            </FormControl>
        </>
    );
}
