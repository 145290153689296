import React from "react";
import PropTypes from "prop-types";

import { ModalBody, ModalFooter } from "ui-library/modules";
import { Box, ContentBox } from "ui-library/layouts";
import { Button, ContractOption, Option } from "ui-library/atoms";

import i18n from "core/i18n";
import userContracts from "core/userContracts";
import { ModalFormLayout } from "containers/modal";
import { CustomSelect, Field, form } from "core/form";

import { FIELD_CONTRACT_TO_EDIT } from "./constants";

const ChooseContractForChangeForm = ({ canSubmit, handleSubmit, submitting }) => {
    const { t } = i18n.useTranslation();
    const contracts = userContracts.useGetActiveContracts();

    return (
        <>
            <ModalBody>
                <Box>
                    <ContentBox>
                        <Field
                            name={FIELD_CONTRACT_TO_EDIT}
                            component={CustomSelect}
                            label={t("common.contract")}
                            id="selectOther"
                            fullWidth
                            placeholder={t("common.contractPlaceholder")}
                        >
                            {contracts &&
                                contracts.map((contract) => (
                                    <Option value={contract.idObject} key={contract.idObject}>
                                        <ContractOption
                                            contractName={contract.contractName}
                                            contractNumber={`${contract.contractNumber}`}
                                        />
                                    </Option>
                                ))}
                        </Field>
                    </ContentBox>
                </Box>
            </ModalBody>
            <ModalFooter>
                <Button color="blue" variant="contained" onClick={handleSubmit} disabled={!canSubmit}>
                    {t("common.goToChange")}
                </Button>
            </ModalFooter>
        </>
    );
};

ChooseContractForChangeForm.propTypes = {
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
};

export const createContainer = (formName) =>
    form(formName)((props) => {
        const { t } = i18n.useTranslation();
        return (
            <ModalFormLayout formName={formName} formTitle={t("menu.changesAndTermination")} formIcon="edit" preventDefaultSuccess>
                <ChooseContractForChangeForm {...props} />
            </ModalFormLayout>
        );
    });
