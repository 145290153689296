import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import { theme } from "ui-library/theme/theme";

const BasicHtmlLink = ({ children, href, ...props }) => (
    <a href={href} {...props}>
        {children}
    </a>
);

export const StyledHtmlLink = styled(BasicHtmlLink)`
    font-family: ${(props) =>
        props.$inheritStyle
            ? "inherit"
            : `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`};
    color: ${(props) => (props.$inheritStyle ? "inherit" : theme.palette.blue.main)};
    font-weight: ${(props) => (props.$inheritStyle ? "inherit" : 700)};
    text-underline-offset: 2px;
    text-decoration: unset;
    &:hover,
    &:focus,
    &:active {
        cursor: pointer;
        text-decoration: underline;
        color: ${(props) => (props.$inheritStyle ? "inherit" : theme.palette.blue.dark)};
    }
`;

export const StyledLink = styled(Link)`
    font-family: ${(props) =>
        props.$inheritStyle
            ? "inherit"
            : `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`};
    color: ${(props) => (props.$inheritStyle ? "inherit" : theme.palette.blue.main)};
    font-weight: ${(props) => (props.$inheritStyle ? "inherit" : 700)};
    text-underline-offset: 2px;
    text-decoration: unset;
    &:hover,
    &:focus,
    &:active {
        cursor: pointer;
        text-decoration: underline;
        color: ${(props) => (props.$inheritStyle ? "inherit" : theme.palette.blue.dark)};
    }
`;

export const StyledStandaloneHtmlLink = styled(BasicHtmlLink)`
    text-decoration: underline;
    color: ${(props) => theme.palette[props.$color].main};
    &:hover {
        cursor: pointer;
        text-decoration: none;
        color: ${(props) => (props.$color === "gray" ? theme.palette[props.$color].extraDark : theme.palette[props.$color].dark)};
    }
`;

export const StyledStandaloneLink = styled(Link)`
    text-decoration: underline;
    color: ${(props) => theme.palette[props.$color].main};
    &:hover {
        text-decoration: none;
        color: ${(props) => (props.$color === "gray" ? theme.palette[props.$color].extraDark : theme.palette[props.$color].dark)};
    }
`;

export const StyledMenuLink = styled(({ ...props }) => <NavLink {...props} />)`
    text-decoration: none;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol";
    color: ${theme.palette.black.main};
    position: relative;
    padding: 16px;
    @media (max-width: ${theme.breakpoints.values.md}px) {
        padding: 25px 0;
        border-bottom: 1px solid ${theme.palette.gray.line};
        &:last-child {
            border-bottom: none;
        }
    }
    &:hover {
        cursor: pointer;
        &:after {
            content: "";
            position: absolute;
            top: 72px;
            left: 14px;
            width: calc(100% - 32px);
            height: 4px;
            background-color: ${theme.palette.blue.main};
            @media (max-width: ${theme.breakpoints.values.md}px) {
                top: 72px;
                width: 100%;
                left: 0;
            }
        }
    }
    &.active {
        &:after {
            content: "";
            position: absolute;
            top: 72px;
            left: 14px;
            width: calc(100% - 28px);
            height: 4px;
            background-color: ${theme.palette.blue.main};
            @media (max-width: ${theme.breakpoints.values.md}px) {
                top: 72px;
                width: 100%;
                left: 0;
            }
        }
    }
`;

export const StyledSidemenuLink = styled(({ ...props }) => <NavLink {...props} />)`
    font-size: 21px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol";
    color: ${theme.palette.gray.main};
    text-decoration: none;
    line-height: 32px;
    &:hover {
        cursor: pointer;
        font-weight: 500;
    }
    &.active {
        font-weight: 500;
        color: ${theme.palette.black.main};
        text-decoration: underline;
        text-decoration-color: ${theme.palette.blue.main};
        text-decoration-thickness: 3px;
        text-underline-offset: 2px;
    }
`;
