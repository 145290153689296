import React, { FC, PropsWithChildren } from "react";
import { useSelector } from "react-redux";

import { isOtpFormSuccessfullySubmitted } from "core/form";
import { ChangeSuccessTextType } from "types";

import { InPageFormHeader } from "./InPageFormHeader";
import { InPageFormErrorBoundary } from "./InPageFormErrorBoundary";
import { InPageSuccess } from "./InPageSuccess";

export interface InPageOtpFormLayoutProps extends PropsWithChildren {
    formName: string;
    formTitle: string;
    formLead?: string;
    changeSuccessTextType: ChangeSuccessTextType;
}

export const InPageOtpFormLayout: FC<InPageOtpFormLayoutProps> = ({ children, formName, formTitle, formLead, changeSuccessTextType }) => {
    const isFormSubmitted = useSelector(isOtpFormSuccessfullySubmitted(formName));

    return (
        <>
            <InPageFormHeader title={formTitle} lead={formLead} />
            <InPageFormErrorBoundary service={formName}>
                {isFormSubmitted ? <InPageSuccess changeSuccessTextType={changeSuccessTextType} /> : children}
            </InPageFormErrorBoundary>
        </>
    );
};
