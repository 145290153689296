export const NAME = "detail-mutual-funds-regular";
export const DIP_PRODUCT_CODE = 1898;

export const ENTITY_CONTRACT_DOCUMENTS = `${NAME}/ENTITY_CONTRACT_DOCUMENTS`;
export const ENTITY_ENQUEUED_DOCUMENTS = `${NAME}/ENTITY_ENQUEUED_DOCUMENTS`;
export const ENTITY_FINANCIAL_OPERATIONS = `${NAME}/ENTITY_FINANCIAL_OPERATIONS`;
export const ENTITY_FINANCIAL_OPERATION_DETAIL = `${NAME}/ENTITY_FINANCIAL_OPERATION_DETAIL`;
export const ENTITY_FINANCIAL_STATEMENT = `${NAME}/ENTITY_FINANCIAL_STATEMENT`;
export const ENTITY_FINANCIAL_REQUESTS = `${NAME}/ENTITY_FINANCIAL_REQUESTS`;
export const ENTITY_CHANGE_PROPOSITIONS = `${NAME}/ENTITY_CHANGE_PROPOSITIONS`;
export const ENTITY_AVAILABLE_FUNDS_PROFILE_CHANGE = `${NAME}/ENTITY_AVAILABLE_FUNDS_PROFILE_CHANGE`;

// Form
export const FORM_EDIT_SHAREHOLDER = `${NAME}/FORM_EDIT_POLICY_HOLDER`;
export const FORM_EDIT_ELECTRONIC_COMMUNICATION = `${NAME}/FORM_EDIT_ELECTRONIC_COMMUNICATION`;
export const FORM_EDIT_CONTRACT_NAME = `${NAME}/FORM_EDIT_CONTRACT_NAME`;
export const FORM_EDIT_CLIENT_STATEMENT_SETTINGS = `${NAME}/FORM_EDIT_CLIENT_STATEMENT_SETTINGS`;
export const FORM_EDIT_ACTIVE_OPERATIONS_PHONE_NUMBER = `${NAME}/FORM_EDIT_ACTIVE_OPERATIONS_PHONE_NUMBER`;
export const FORM_INVESTMENT_PROFILE = `${NAME}/FORM_INVESTMENT_PROFILE`;
export const FORM_SELL_SWITCH = `${NAME}/FORM_SELL_SWITCH`;

// Joined Modal + Entity elements
export const MODAL_ENTITY_PRINT_CONFIRMATION = `${NAME}/MODAL_ENTITY_PRINT_CONFIRMATION`;

// Generate statement params
export const BUY_SELL_CONFIRMATION = "BUY_SELL_CONFIRMATION";

// Columns
export const DATE_COLUMN = "date";
export const TYPE_COLUMN = "type";
export const PAYMENT_COLUMN = "payment";
