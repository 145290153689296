import { FC } from "react";

import { MenuLink } from "ui-library/atoms";

import router from "core/router";
import { useHasAnyRole } from "core/hooks";
import { Role } from "enums";
import { Pages, Tabs } from "routeConstants";

type AppMenuLink = {
    page: Pages;
    tab?: Tabs;
    title: string;
    enabledFor: Role[];
    notificationCount?: string;
    onClick?: (e?: any) => void;
};

export const AppMenuLink: FC<AppMenuLink> = ({ title, page, tab = null, enabledFor = [], notificationCount = null, onClick = null }) => {
    const isEnabled = useHasAnyRole(enabledFor);

    return (
        <MenuLink onClick={onClick} to={router.getStaticUrl(page, tab)} notificationCount={notificationCount} disabled={!isEnabled}>
            {title}
        </MenuLink>
    );
};
