import React, { FC } from "react";
import { useSelector } from "react-redux";

import init from "core/init";
import { Loading } from "ui-library/atoms";

import { useHasRole } from "core/hooks";
import { Roles } from "enums";
import { AppRoutes } from "routes";

import { Footer } from "./Footer";
import { Header } from "./Header";
import { LoadingLayout } from "./LoadingLayout";
import { ModalFormChooseContractForChange } from "./modal";
import { WrongRolesMessage } from "./components";

export const MainLayout: FC = () => {
    const initialized = useSelector(init.isInitialized);
    const canUseApplication = !useHasRole(Roles.WRONG_PORTAL_USER);

    const applicationContent = canUseApplication ? <AppRoutes /> : <WrongRolesMessage />;

    return (
        <>
            <Loading fullPage loading={!initialized} />
            <Header />

            {initialized ? applicationContent : <LoadingLayout />}

            <Footer />

            {/* Modals */}
            <ModalFormChooseContractForChange />
        </>
    );
};
