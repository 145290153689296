import { forwardRef } from "react";
import { useFormContext } from "react-hook-form";
import { DatePickerProps } from "ui-library/atoms/Inputs/DatePicker";
import { DatePicker as NoRefDatePicker } from "ui-library/atoms";

type HookFormDatePickerProps = {
    name: string;
    disabled?: boolean;
    label?: string;
    minDate?: Date;
    maxDate?: Date;
    required?: boolean;
};

const DatePicker = forwardRef((props: DatePickerProps, ref) => <NoRefDatePicker {...props} />);

export default function HookFormDatePicker({ name, required, label, ...props }: HookFormDatePickerProps) {
    const {
        register,
        formState: { errors },
        setValue,
        getValues,
    } = useFormContext();

    const error = name.split(".").reduce((acc, part) => acc?.[part], errors);

    const onChange = (value: any) => {
        if (value) {
            setValue(name, value, { shouldDirty: true, shouldValidate: true });
        }
    };

    return (
        <DatePicker
            id={name}
            {...register(name)}
            {...props}
            label={required ? label + "*" : label}
            onChange={onChange}
            value={getValues(name)}
            error={Boolean(error)}
            errorContent={error?.message.toString()}
        />
    );
}
