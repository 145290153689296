import { model } from "core/util";
import { Attachment as AttachmentType } from "types";

export const Attachment = model.createModel<AttachmentType, AttachmentType>((json) => ({
    id: json.id,
    type: json.type,
    label: json.label,
    requestDate: json.requestDate,
    checked: json.checked,
}));
