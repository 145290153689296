import { all, call, put, select } from "redux-saga/effects";
import { Set } from "immutable";
import { Selector } from "@reduxjs/toolkit";

import errorHandling from "core/errorHandling";
import { asyncFieldValidator, asyncValidateFields, formWrapper, getPathFromFieldName, required, scrollToFirstError } from "core/form";
import userInfo from "core/userInfo";
import { sentry } from "core/util";
import { userApi, validationApi } from "serverApi";
import { ElectronicCommunication, Holder, State } from "types";

import { FIELD_EMAIL, FIELD_ENABLED, FIELD_PHONE } from "./constants";
import contractDataChange from "serverApi/contractDataChange";

export function* createSaga(
    formName: string,
    idObject: number,
    getPolicyHolderSelector: Selector<State, Holder>,
    getElectronicCommunicationSelector: Selector<State, ElectronicCommunication>,
) {
    try {
        yield call(formSaga(formName, getPolicyHolderSelector, getElectronicCommunicationSelector), idObject);
    } catch (e) {
        sentry.captureException(e);
        yield put(errorHandling.addServiceError(formName, e.identifier));
    }
}

const formSaga = (
    formName: string,
    getPolicyHolderSelector: Selector<State, Holder>,
    getElectronicCommunicationSelector: Selector<State, ElectronicCommunication>,
) =>
    formWrapper(formName, {
        *initialize() {
            yield call(userInfo.checkUserVerifiedOrOneTime);
            yield put(errorHandling.removeServiceErrors(formName));
            const [holder, electronicCommunication]: [holder: Holder, electronicCommunication: ElectronicCommunication] = yield all([
                select(getPolicyHolderSelector),
                select(getElectronicCommunicationSelector),
            ]);

            return {
                [FIELD_ENABLED]: !!electronicCommunication.electronicCommunicationSet,
                [FIELD_EMAIL]: holder.email,
                [FIELD_PHONE]: holder.phone,
            };
        },
        // @ts-ignore we won't type sagas
        *onSubmitFail() {
            yield call(scrollToFirstError);
        },
        *save(values, idObject) {
            if (values.get(FIELD_ENABLED)) {
                const requestBody = {
                    idObjects: Set([parseInt(idObject)]),
                    enabled: values.get(FIELD_ENABLED),
                    phone: values.get(FIELD_PHONE),
                    email: values.get(FIELD_EMAIL),
                };
                yield call(contractDataChange.updateContractElectronicCommunication, requestBody);
            } else {
                const requestBody = {
                    idObjects: Set([parseInt(idObject)]),
                    enabled: values.get(FIELD_ENABLED),
                };
                yield call(contractDataChange.updateContractElectronicCommunication, requestBody);
            }
        },
        *success() {
            const newUserInfo = yield call(userApi.getAuthenticatedUser, true);
            yield put(userInfo.setAuthenticatedUser(newUserInfo));
        },
        *asyncValidation(values, field) {
            const asyncFieldValidators = [];
            if (values.getIn(getPathFromFieldName(FIELD_ENABLED))) {
                asyncFieldValidators.push(asyncFieldValidator(getPathFromFieldName(FIELD_PHONE), [required, validationApi.validateMobile]));
            }
            return yield call(asyncValidateFields(...asyncFieldValidators), field, values);
        },
    });
