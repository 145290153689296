import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { change } from "redux-form/immutable";

import { Button, Icon, Typography } from "ui-library/atoms";
import { ContentBox } from "ui-library/layouts";
import { DataTable } from "ui-library/modules";

import i18n from "core/i18n";
import modal from "core/modal";
import { getFormFieldValue, getFormSyncErrors } from "core/form";

import {
    FIELD_ATTACHMENTS,
    FIELD_SIGNATURE_FILE,
    MODAL_UPLOAD_DOCUMENT,
    SECTION_MARGIN,
    VIRTUAL_FIELD_ATTACHMENTS,
    VIRTUAL_FIELD_DOCUMENT_TO_UPLOAD_FIELD_NAME,
} from "../../constants";

type AttachmentsSectionProps = {
    formName: string;
};

export const AttachmentsSection: FC<AttachmentsSectionProps> = ({ formName }) => {
    const { t } = i18n.useTranslation();
    const attachments = useSelector(getFormFieldValue(formName, VIRTUAL_FIELD_ATTACHMENTS));
    const uploadedAttachments = useSelector(getFormFieldValue(formName, FIELD_ATTACHMENTS));
    const formSyncErrors = useSelector(getFormSyncErrors(formName));

    const dispatch = useDispatch();

    const openUploadDocumentDialog = (fieldName: string) => {
        dispatch(change(formName, VIRTUAL_FIELD_DOCUMENT_TO_UPLOAD_FIELD_NAME, fieldName));
        dispatch(modal.openModalForm(MODAL_UPLOAD_DOCUMENT));
    };

    const checkIsAttachmentTypeUploaded = (type: string) => uploadedAttachments?.get(type)?.size > 0;

    const columns = [
        {
            field: "label",
            headerName: `${t("change.attachment")}`,
            flex: 3.5,
            sortable: false,
        },
        {
            field: "type",
            headerName: `${t("common.insertDocument")}`,
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                const type = params?.row?.type;
                return (
                    <Button
                        onClick={() => openUploadDocumentDialog(`${FIELD_ATTACHMENTS}[${type}]`)}
                        rounded
                        variant="text"
                        color="blue"
                        startIcon={"file-plus"}
                    />
                );
            },
        },
        {
            field: "id",
            headerName: `${t("vehicle.change.subject.documents.attachmentStatus")}`,
            flex: 0.5,
            sortable: false,
            renderCell: (params) => {
                const type = params?.row?.type;
                const isAttachmentUploaded = checkIsAttachmentTypeUploaded(type);
                const isAttachmentWithError = !!(formSyncErrors[FIELD_ATTACHMENTS] && formSyncErrors[FIELD_ATTACHMENTS][type]);
                return (
                    isAttachmentUploaded && (
                        <Icon
                            size={16}
                            color={isAttachmentWithError ? "red" : "green"}
                            icon={isAttachmentWithError ? "circle-error" : "circle-check"}
                        />
                    )
                );
            },
        },
    ];

    return (
        <>
            <ContentBox fullWidth textAlign={"center"} mt={SECTION_MARGIN}>
                <Typography variant="h3">{t("change.attachments")}</Typography>
            </ContentBox>

            <ContentBox fullWidth>
                <DataTable
                    disableColumnReorder
                    getRowId={(row) => row.id}
                    pageSize={10}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    rows={attachments}
                    columns={columns}
                    hideFooter
                />
            </ContentBox>
        </>
    );
};
