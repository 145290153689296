import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import IPropTypes from "react-immutable-proptypes";

import { RadioGroup as NoRefRadioGroup } from "ui-library/atoms";

import { Consumer } from "../FormWideOnEnterContext";
import { getErrorsAsString } from "../util";

const RadioGroup = forwardRef((props, ref) => <NoRefRadioGroup {...props} reference={ref} />);

// TODO: not appropriate name ...
const RadioSelect = ({ input, meta, disabled, id, label, labelOptional, labelDesc, tooltip, direction, children }) => {
    // TODO handle error content.
    const errorContent = getErrorsAsString(meta);

    return (
        <Consumer>
            {(formWideOnEnter) => (
                <RadioGroup
                    label={label}
                    labelOptional={labelOptional}
                    labelDesc={labelDesc}
                    tooltip={tooltip}
                    direction={direction}
                    error={meta.touched && meta.error && !meta.error.isEmpty()}
                    errorContent={errorContent}
                    onChange={input.onChange}
                    disabled={disabled}
                    id={id}
                >
                    {children}
                </RadioGroup>
            )}
        </Consumer>
    );
};

RadioSelect.propTypes = {
    meta: PropTypes.shape({
        error: IPropTypes.list,
        touched: PropTypes.bool.isRequired,
        asyncValidating: PropTypes.bool,
        dirty: PropTypes.bool,
        valid: PropTypes.bool,
        form: PropTypes.string,
    }).isRequired,
    input: PropTypes.shape({
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        onBlur: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    tooltip: PropTypes.string,
    error: PropTypes.bool,
    errorContent: PropTypes.string,
    labelDesc: PropTypes.string,
};

RadioSelect.defaultProps = {
    disabled: false,
};

export default RadioSelect;
