import React from "react";
import PropTypes from "prop-types";

import { InfoPanel, LabelTextItem } from "ui-library/modules";
import { Box, GridItem } from "ui-library/layouts";

import i18n from "core/i18n";
import { MultilineContent } from "containers/MultilineContent";
import { formatBirthNumber, localPhone, oneLineNameWithTitles } from "core/formatters";
import { getLocalizedAddressAsList } from "core/localized";

export const VehicleOwnerOperatorGridItem = ({ entityType, entity }) => {
    const { t } = i18n.useTranslation();
    return (
        <GridItem display={"flex"} xs={12} md={6}>
            <InfoPanel
                icon={entityType === VehicleOwnerOperatorGridItem.EntityType.VEHICLE_OPERATOR ? "user-settings" : "user-check"}
                title={entityType === VehicleOwnerOperatorGridItem.EntityType.VEHICLE_OPERATOR ? t("person.operator") : t("person.owner")}
            >
                <Box>
                    <LabelTextItem
                        label={entity.isPerson ? t("holder.fullName") : t("holder.companyName")}
                        text={oneLineNameWithTitles(entity)}
                    />
                    <LabelTextItem
                        label={entity.isPerson ? t("holder.birthNumber") : t("holder.companyID")}
                        text={entity.isPerson ? formatBirthNumber(entity.birthNumber) : entity.companyID}
                    />
                    <LabelTextItem label={t("holder.phone")} text={localPhone(entity.phone)} />
                    <LabelTextItem label={t("holder.email")} text={entity.email} />
                    <LabelTextItem
                        label={t("holder.address")}
                        text={<MultilineContent value={getLocalizedAddressAsList(t, entity?.get("addresses")?.first())} />}
                    />
                </Box>
            </InfoPanel>
        </GridItem>
    );
};

VehicleOwnerOperatorGridItem.propTypes = {
    entityType: PropTypes.string.isRequired,
    entity: PropTypes.object.isRequired,
};

VehicleOwnerOperatorGridItem.EntityType = Object.freeze({
    VEHICLE_OWNER: "VEHICLE_OWNER",
    VEHICLE_OPERATOR: "VEHICLE_OPERATOR",
});
