import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

import { ContractChangeType } from "types";

const TYPE_PARAM: string = "type";

type VehicleChangesForm = {
    changeType: ContractChangeType | string;
};

export function useChangeTypeGetParamForm() {
    const [searchParams, setSearchParams] = useSearchParams();

    const form = useForm<VehicleChangesForm>({
        defaultValues: {
            changeType: searchParams.get(TYPE_PARAM) ? searchParams.get(TYPE_PARAM) : null,
        },
    });

    const { changeType } = form.watch();

    // This part is here to clean selected changeType form value in case we want to reset selection
    // by change of url via navigate. Without this part url query param change is not working
    // because form value is always propagated back to url.
    useEffect(() => {
        if (searchParams.get("clean")) {
            form.setValue("changeType", null);
            setSearchParams((params) => {
                const newSearchParams = new URLSearchParams(params);
                newSearchParams.delete("clean");
                newSearchParams.delete("type");
                return newSearchParams;
            });
        }
    }, [searchParams, setSearchParams]);

    function onChange(type) {
        const newSearchParams = new URLSearchParams();
        newSearchParams.set("type", type);
        setSearchParams(newSearchParams);
    }

    return {
        form,
        changeType,
        fieldName: "changeType",
        onChange,
    };
}
