import { formatEmployerPayments, localCurrency } from "core/formatters";
import { PaymentsExtended, State } from "types";

import { TFunction } from "../i18n";
import { createSelector } from "reselect";
import pageContext from "../pageContext";
import { getLocalizedFrequencyMap } from "./selectors";
import type { Selector } from "@reduxjs/toolkit";

export const getLocalizedEmployerPayments = (t: TFunction, getEmployerPayments: Selector<State, PaymentsExtended>) =>
    createSelector(
        getLocalizedFrequencyMap,
        pageContext.useGetPageLocalization,
        getEmployerPayments,
        (frequencyMap, pageLocalization, employerPayments) =>
            formatEmployerPayments(t, employerPayments, localCurrency(pageLocalization.currencyCode), frequencyMap),
    );
