import { createSaga } from "./createSaga";
import { createContainer } from "./createContainer";
import { createInPageContainer } from "./createInPageContainer";

/**
 * Saga and Container creator for Holder edit form
 */
const employerPaymentsForm = {
    createSaga,
    createContainer,
    createInPageContainer,
};
export default employerPaymentsForm;
