import { API_PREFIX } from "app/constants";
import { GarageSearchQuery } from "types/garages";
import superagent from "superagent";
import to from "core/util/fetch/awaitToJs";

const garageUriPrefix = `${API_PREFIX}/auto-repair-shop`;

const getCodeTable = async (codetableType: string): Promise<[Error | null, any]> => {
  return await to(
    superagent.get(`${garageUriPrefix}/codetable/${codetableType}`)
      .accept("json")
  );
}

const searchGarages = async (body: GarageSearchQuery): Promise<[Error | null, any]> => {
  return await to(
    superagent.post(`${garageUriPrefix}/search`)
      .send(body)
      .set('Content-Type', 'application/json')
      .accept("json")
  );
}

export default {
  getCodeTable,
  searchGarages,
}