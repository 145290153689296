import { List } from "immutable";
import { useParams } from "react-router-dom";

import i18n from "core/i18n";
import userContracts from "core/userContracts";
import { PaymentContribution } from "enums";
import contractOwnerPaymentsForm from "form/modal/contractOwnerPayments";

import { FORM_EDIT_INSURANCE_PAYMENTS } from "../constants";

export const InsurancePaymentsFormContainer = contractOwnerPaymentsForm.createInPageContainer(
    FORM_EDIT_INSURANCE_PAYMENTS,
    "change.title.INSURANCE_PAYMENTS",
);

export const InsurancePaymentsForm = () => {
    const { t } = i18n.useTranslation();

    const { idObject } = useParams();
    const contract = userContracts.useGetContractById(idObject);

    const homePaymentOptions = List([PaymentContribution.ByBankAccount.id, PaymentContribution.ByDirectDebit.id]);

    return (
        <InsurancePaymentsFormContainer
            isUniqaContract={contract?.isUniqaContract}
            canEditAmount={false}
            hasMonthlyFrequency={false}
            amountLabel={t("insurance.payments.amount")}
            paymentOptionsUniqa={homePaymentOptions}
            paymentOptionsUniqaExAxa={homePaymentOptions}
            displayDisclaimer
        />
    );
};
