import React, { FC } from "react";
import { change } from "redux-form/immutable";
import { useDispatch, useSelector } from "react-redux";

import { Button, Loading } from "ui-library/atoms";

import i18n from "core/i18n";
import { CancelOrCloseRequestButton, InPageFormButtonsLayout } from "containers/inPageForm";
import { getFormFieldValue, getFormSyncErrors } from "core/form";
import { ChangeStatus } from "types";

import { Recapitulation } from "./components";
import { AttachmentsSection, SignatureSection, SignSection } from "./field";
import { FileUploadModal } from "./modal";

import {
    FIELD_ATTACHMENTS,
    FIELD_SIGNATURE_FILE,
    FIELD_SIGNATURE_TYPE,
    FORM_STEP,
    VIRTUAL_FIELD_ATTACHMENTS,
    VIRTUAL_FIELD_CHANGE_STATUS,
    VIRTUAL_FIELD_FORM_STEP,
} from "../constants";

type StepOneProps = {
    formName: string;
    handleSubmit: () => void;
    isSubmitting: boolean;
    canSubmit: boolean;
};

const isAllRequiredAttachmentsUploaded = (requiredAttachments, uploadedAttachments) => {
    const missingAttachments = requiredAttachments
        ?.map((requiredAttachment) => uploadedAttachments?.get(requiredAttachment?.type)?.size > 0)
        ?.filter((item) => !item);
    return missingAttachments?.length === 0;
};

const isSignatureUploaded = (uploadedSignature) => uploadedSignature && uploadedSignature?.size > 0;

const hasAttachmentOrSignatureErrors = (formSyncErrors) => !!formSyncErrors[FIELD_ATTACHMENTS] || !!formSyncErrors[FIELD_SIGNATURE_FILE];

export const StepTwo: FC<StepOneProps> = ({ formName, handleSubmit, isSubmitting, canSubmit }) => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    const formSyncErrors = useSelector(getFormSyncErrors(formName));
    const changeStatus = useSelector(getFormFieldValue(formName, VIRTUAL_FIELD_CHANGE_STATUS));
    const attachments = useSelector(getFormFieldValue(formName, VIRTUAL_FIELD_ATTACHMENTS));
    const uploadedAttachments = useSelector(getFormFieldValue(formName, FIELD_ATTACHMENTS));

    const selectedSignatureType = useSelector(getFormFieldValue(formName, FIELD_SIGNATURE_TYPE));
    const uploadedSignature = useSelector(getFormFieldValue(formName, FIELD_SIGNATURE_FILE));

    const submitButtonLabel = changeStatus === ChangeStatus.SUBMITTED ? t("change.finalizeRequest") : t("common.continue");

    const isNotSubmittableSubmit = !selectedSignatureType || !canSubmit || isSubmitting;

    const isNotSubmittableComplete =
        !isAllRequiredAttachmentsUploaded(attachments, uploadedAttachments) ||
        !isSignatureUploaded(uploadedSignature) ||
        hasAttachmentOrSignatureErrors(formSyncErrors); // Not all documents uploaded

    return (
        <>
            <Recapitulation formName={formName} />

            <SignatureSection formName={formName} isDisabled={changeStatus === ChangeStatus.SUBMITTED} />

            {changeStatus === ChangeStatus.SUBMITTED && (
                <>
                    {attachments?.length > 0 && <AttachmentsSection formName={formName} />}

                    <SignSection formName={formName} />
                </>
            )}

            <InPageFormButtonsLayout
                leftButton={
                    changeStatus === ChangeStatus.SUBMITTED ? (
                        <CancelOrCloseRequestButton />
                    ) : (
                        <Button
                            variant="outlined"
                            color="blue"
                            onClick={() => dispatch(change(formName, VIRTUAL_FIELD_FORM_STEP, FORM_STEP.FIRST_EDIT))}
                        >
                            {t("common.back")}
                        </Button>
                    )
                }
                rightButton={
                    <Button
                        variant="contained"
                        color="blue"
                        onClick={handleSubmit}
                        disabled={changeStatus === ChangeStatus.SUBMITTED ? isNotSubmittableComplete : isNotSubmittableSubmit}
                    >
                        {isSubmitting ? <Loading loading /> : submitButtonLabel}
                    </Button>
                }
            />

            {/* Modals */}
            <FileUploadModal formName={formName} />
        </>
    );
};
