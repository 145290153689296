import React from "react";
import PropTypes from "prop-types";

import { StandaloneLink } from "ui-library/atoms";
import { Box, GridItem, GridLayout } from "ui-library/layouts";
import { InfoPanel, LabelTextItem } from "ui-library/modules";

import i18n from "core/i18n";
import { useHasEditContractPermission } from "core/hooks";
import { EditLinksDropdown } from "containers/EditLinksDropdown";
import { localPhone } from "core/formatters";
import { getLocalizedOneLineAddressFull } from "core/localized";
import { model } from "core/util";
import { AddressType } from "enums";

import { HolderPersonSectionLegacy } from "./HolderPersonSectionLegacy";
import { HolderCompanySectionLegacy } from "./HolderCompanySectionLegacy";

export const ContractPolicyHolderTabLegacy = ({
    holder,
    isExtended,
    showInsuranceNumber,
    editLink,
    electronicCommunication,
    electronicCommunicationFormName,
    ElectronicCommunicationForm,
    shouldDisplayElCommunication,
    isContractEditable,
}) => {
    const { t } = i18n.useTranslation();
    const hasEditPermission = useHasEditContractPermission();

    let EditLink = () => (
        <StandaloneLink color="blue" to={editLink}>
            {t("common.edit")}
        </StandaloneLink>
    );

    if (shouldDisplayElCommunication) {
        const editLinks = [
            {
                link: editLink,
                name: "common.contactInformation",
            },
            {
                formConst: electronicCommunicationFormName,
                name: "form.electronicCommunication",
            },
        ];
        EditLink = () => <EditLinksDropdown links={editLinks} />;
    }

    return (
        <GridLayout defaultSpacing>
            <GridItem xs={12}>
                <InfoPanel
                    icon={"user"}
                    title={t("common.personalData")}
                    headerAction={
                        hasEditPermission &&
                        isContractEditable && (
                            <StandaloneLink color="blue" to={editLink}>
                                {t("common.edit")}
                            </StandaloneLink>
                        )
                    }
                >
                    <>
                        {holder.isPerson && (
                            <HolderPersonSectionLegacy holder={holder} isExtended={isExtended} showInsuranceNumber={showInsuranceNumber} />
                        )}
                        {!holder.isPerson && <HolderCompanySectionLegacy holder={holder} />}
                    </>
                </InfoPanel>
            </GridItem>
            <GridItem xs={12}>
                <InfoPanel
                    icon={"messages"}
                    title={t("common.contact")}
                    headerAction={hasEditPermission && isContractEditable && <EditLink />}
                >
                    <Box>
                        <LabelTextItem label={t("holder.phone")} text={localPhone(holder.get("phone"))} />
                        <LabelTextItem label={t("holder.email")} text={holder.get("email")} />
                        <LabelTextItem
                            label={t("holder.contactAddress")}
                            text={getLocalizedOneLineAddressFull(t, model.getAddressByTypeCodeLegacy(holder, AddressType.CON))}
                        />
                        {shouldDisplayElCommunication && (
                            <LabelTextItem
                                label={t("common.eCommunication")}
                                text={electronicCommunication?.electronicCommunicationSet ? t("common.yes") : t("common.no")}
                            />
                        )}
                    </Box>
                </InfoPanel>
            </GridItem>

            {/* Modals */}
            {shouldDisplayElCommunication && <ElectronicCommunicationForm />}
        </GridLayout>
    );
};

ContractPolicyHolderTabLegacy.propTypes = {
    holder: PropTypes.object.isRequired,
    isExtended: PropTypes.bool,
    showInsuranceNumber: PropTypes.bool,
    editLink: PropTypes.string.isRequired,
    electronicCommunication: PropTypes.object,
    ElectronicCommunicationForm: PropTypes.func,
    shouldDisplayElCommunication: PropTypes.bool,
};

ContractPolicyHolderTabLegacy.defaultProps = {
    isExtended: false,
    showInsuranceNumber: true,
    electronicCommunication: null,
    ElectronicCommunicationForm: null,
    shouldDisplayElCommunication: false,
};
