import { Controller, useFormContext } from "react-hook-form";
import { Switch } from "ui-library/atoms";

type HookFormSwitchProps = {
    name: string;
    label?: string;
    disabled?: boolean;
    onChange?: (value?: any) => any;
};

export default function HookFormSwitch({ name, disabled = false, onChange, ...props }: HookFormSwitchProps) {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const error = name.split(".").reduce((acc, part) => acc?.[part], errors);

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <Switch
                    id={name}
                    isChecked={field.value}
                    onChange={(e) => {
                        onChange?.(e.target.checked);
                        field.onChange(e);
                    }}
                    {...props}
                    disabled={disabled}
                    errors={[error?.message]}
                />
            )}
        />
    );
}
