import { useVehicleDataChangeForm } from "./hooks/useVehicleDataChangeForm";
import VehicleDataForm from "./components/VehicleDataForm";
import Documents from "./components/Documents";
import { Form, FormErrorBox } from "core/hookForm";
import { Loading, Typography } from "ui-library/atoms";
import Confirmation from "./components/Confirmation";
import { Box } from "ui-library/layouts";
import i18n from "core/i18n";

export default function VehicleDataChangeForm() {
    const { form, onSubmit, vehicles, step, previousStep, isFormLoading, isSubmitting, contractNumber } = useVehicleDataChangeForm();
    const { t } = i18n.useTranslation();

    return (
        <Loading loading={isFormLoading}>
            <Form form={form} onSubmit={onSubmit}>
                <Box mb={3} fullWidth>
                    <Typography variant="h3">
                        {t("vehicle.change.vehicleData.title")} {contractNumber}
                    </Typography>
                </Box>

                <FormErrorBox />

                {step === 1 && <VehicleDataForm onSubmit={onSubmit} vehicles={vehicles} isSubmitting={isSubmitting} />}
                {step === 2 && <Documents previousStep={previousStep} />}
                {step === 3 && <Confirmation />}
            </Form>
        </Loading>
    );
}
