import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { change } from "redux-form/immutable";

import { Button, Icon, Typography } from "ui-library/atoms";
import { ContentBox } from "ui-library/layouts";
import { DataTable } from "ui-library/modules";

import i18n from "core/i18n";
import modal from "core/modal";
import { DocumentLink } from "containers/DocumentLink";
import { getFormFieldValue, getFormSyncErrors } from "core/form";
import { useHasRole } from "core/hooks";
import { Roles } from "enums";
import { vehicleCancellationApi } from "serverApi";

import {
    FIELD_SIGNATURE_FILE,
    MODAL_UPLOAD_DOCUMENT,
    SECTION_MARGIN,
    VIRTUAL_FIELD_CHANGE_ID,
    VIRTUAL_FIELD_DOCUMENT_TO_UPLOAD_FIELD_NAME,
} from "../../constants";

type SignSectionProps = {
    formName: string;
};

export const SignSection: FC<SignSectionProps> = ({ formName }) => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();
    const isAuthenticated = useHasRole(Roles.AUTHENTICATED);

    const formSyncErrors = useSelector(getFormSyncErrors(formName));
    const changeId = useSelector(getFormFieldValue(formName, VIRTUAL_FIELD_CHANGE_ID));
    const uploadedSignature = useSelector(getFormFieldValue(formName, FIELD_SIGNATURE_FILE));
    const downloadUrl = () => vehicleCancellationApi.getDocumentToSignDownloadUrl(changeId);

    const openUploadDocumentDialog = () => {
        dispatch(change(formName, VIRTUAL_FIELD_DOCUMENT_TO_UPLOAD_FIELD_NAME, FIELD_SIGNATURE_FILE));
        dispatch(modal.openModalForm(MODAL_UPLOAD_DOCUMENT));
    };

    const columns = [
        {
            field: "label",
            headerName: `${t("change.attachment")}`,
            flex: 3.5,
            sortable: false,
            renderCell: () => {
                return isAuthenticated ? (
                    <DocumentLink fileName={t("change.signature.downloadDocumentToSign")} fileUrl={downloadUrl()} />
                ) : (
                    t("change.signature.signedRequest")
                );
            },
        },
        {
            field: "type",
            headerName: `${t("common.insertDocument")}`,
            flex: 1,
            sortable: false,
            renderCell: () => {
                return <Button onClick={() => openUploadDocumentDialog()} rounded variant="text" color="blue" startIcon={"file-plus"} />;
            },
        },
        {
            field: "id",
            headerName: `${t("vehicle.change.subject.documents.attachmentStatus")}`,
            flex: 0.5,
            sortable: false,
            renderCell: () => {
                const isAttachmentUploaded = uploadedSignature?.size > 0;
                const isAttachmentWithError = !!formSyncErrors[FIELD_SIGNATURE_FILE];
                return (
                    isAttachmentUploaded && (
                        <Icon
                            size={16}
                            color={isAttachmentWithError ? "red" : "green"}
                            icon={isAttachmentWithError ? "circle-error" : "circle-check"}
                        />
                    )
                );
            },
        },
    ];

    return (
        <>
            <ContentBox fullWidth textAlign={"center"} mt={SECTION_MARGIN}>
                <Typography variant="h3">{t("change.signature.requestSignatures")}</Typography>
            </ContentBox>

            <ContentBox fullWidth textAlign={"center"}>
                <Typography>{t("change.signature.infoText")}</Typography>
            </ContentBox>

            <ContentBox fullWidth>
                <DataTable
                    disableColumnReorder
                    getRowId={(row) => row.id}
                    pageSize={10}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    rows={[{ label: "sign", id: "123", type: "sign" }]}
                    columns={columns}
                    hideFooter
                />
            </ContentBox>
        </>
    );
};
