export const NAME = "user/auth";

const DEFAULT_SESSION_TIMEOUT_SECONDS = 15 * 60;

// These are constants from backend, do not change value
export enum UnsuccessfulReason {
    BAD_CREDENTIALS = "BAD_CREDENTIALS",
    ERROR_TECHNICAL_ISSUE = "ERROR_TECHNICAL_ISSUE",
    ERROR_MISSING_RECAPTCHA = "ERROR_MISSING_RECAPTCHA",
}

export enum LogoutAction {
    USER_LOGOUT = "USER_LOGOUT",
    ONE_TIME_LOGOUT = "ONE_TIME_LOGOUT",
    AUTO_LOGOUT = "AUTO_LOGOUT",
    PASSWORD_CHANGED = "PASSWORD_CHANGED",
    USERNAME_CHANGED = "USERNAME_CHANGED",
    WRONG_PORTAL_USER = "WRONG_PORTAL_USER",
}

export interface AuthState {
    logged: boolean;
    logoutAction: LogoutAction;
    inactiveInterval: number;
    loginUnsuccessfulReason: UnsuccessfulReason;
}

export const initialState: AuthState = {
    logged: false,
    logoutAction: null,
    inactiveInterval: DEFAULT_SESSION_TIMEOUT_SECONDS,
    loginUnsuccessfulReason: null,
};

export interface AddServiceErrorPayload {
    service: string;
    errorIdentifier: string;
}
