import React, { FC } from "react";

import { Link } from "ui-library/atoms";
import { useGetContractChangeFormLink } from "core/hooks";
import { ContractChangeType, ContractPolicy } from "types";

type EditContractElComLinkProps = {
    contract: ContractPolicy;
};

export const EditContractElComLink: FC<EditContractElComLinkProps> = ({ contract }) => {
    const editElComLink = useGetContractChangeFormLink(contract.idObject, ContractChangeType.ELECTRONIC_COMMUNICATION);

    return (
        <Link key={contract.idObject} to={editElComLink}>
            {`${contract.productCRM}, ${contract.contractNumber}`}
        </Link>
    );
};
