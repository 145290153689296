import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Flexbox } from "ui-library/layouts";
import { Dropdown, DropdownItem, LoggedUser } from "ui-library/modules";

import auth from "core/auth";
import i18n from "core/i18n";
import router from "core/router";
import userInfo from "core/userInfo";
import user from "core/user";
import { AUTHORIZATION_TYPE_IMPERSONATION } from "app/constants";
import { ChangeLanguageButton } from "containers/buttons";
import { oneLineNameWithTitles } from "core/formatters";
import { Pages, Tabs } from "routeConstants";

export const UserDropdownWithLang: FC = () => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    const authenticatedUser = useSelector(userInfo.getAuthenticatedUser);
    const userName = oneLineNameWithTitles(authenticatedUser.get("client"));
    const isDisclaimerAccepted = useSelector(userInfo.getCurrentDisclaimerAccepted);

    const userAccount = useSelector(user.getUserAccount);
    const isImpersonation = userAccount?.authorizationType === AUTHORIZATION_TYPE_IMPERSONATION;
    const impersonator = userAccount?.impersonator;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const isUserMenuOpened = Boolean(anchorEl);
    const handleOpenUserMenu = (event) => setAnchorEl(event.currentTarget);
    const handleCloseUserMenu = () => setAnchorEl(null);

    let navigate = useNavigate();
    const handleUserMenuClick = (path) => {
        navigate(path);
        handleCloseUserMenu();
    };

    return (
        <>
            <Flexbox flexDirection={{ xs: "row", md: "row" }} alignItems={"center"}>
                <Box>
                    <ChangeLanguageButton />
                </Box>
                <Box order={{ xs: 0, md: 1 }} mr={{ xs: 0, md: 0 }}>
                    <LoggedUser
                        name={userName}
                        open={isUserMenuOpened}
                        onClick={handleOpenUserMenu}
                        isImpersonation={isImpersonation}
                        impersonator={impersonator}
                    />
                    <Dropdown
                        position={"right"}
                        title={t("holder.personalProfile")}
                        anchorEl={anchorEl}
                        open={isUserMenuOpened}
                        onClose={handleCloseUserMenu}
                    >
                        {isDisclaimerAccepted && (
                            <>
                                <DropdownItem
                                    icon={"address-card"}
                                    onClick={() => handleUserMenuClick(router.getStaticUrl(Pages.USER, Tabs.VERIFICATION))}
                                >
                                    {t("holder.verification")}
                                </DropdownItem>
                                <DropdownItem
                                    icon={"settings"}
                                    onClick={() => handleUserMenuClick(router.getStaticUrl(Pages.USER, Tabs.USER_ACCOUNT))}
                                >
                                    {t("holder.settings")}
                                </DropdownItem>
                                <DropdownItem
                                    icon={"user-settings"}
                                    onClick={() => handleUserMenuClick(router.getStaticUrl(Pages.USER, Tabs.DATA_PROCESSING))}
                                >
                                    {t("userSettings.personalDataProcessing.title")}
                                </DropdownItem>
                            </>
                        )}
                        <DropdownItem icon={"logout"} onClick={() => dispatch(auth.logOut(auth.LogoutAction.USER_LOGOUT))}>
                            {t("common.logout")}
                        </DropdownItem>
                    </Dropdown>
                </Box>
            </Flexbox>
        </>
    );
};
