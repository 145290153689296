import { Dispatch, SetStateAction, useState } from "react";
import { mapTheme } from "ui-library/modules/Map/mapTheme";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import { Button } from "@mui/material";
import { Box } from "ui-library/layouts";
import i18n from "core/i18n";
import { Garage } from "types/garages";

// TODO: Refactor, separate logic into custom hook
export default function GaragesMap({
    garages,
    setSelectedGarage,
}: {
    garages: Garage[];
    setSelectedGarage: Dispatch<SetStateAction<Garage>>;
}) {
    const { t } = i18n.useTranslation();
    const [activeMarker, setActiveMarker] = useState(null);

    const markers = garages
        .filter((garage) => !!garage.latitude && !!garage.longitude)
        .map((garage) => ({
            id: garage.id,
            name: garage.name,
            position: {
                lat: garage.latitude,
                lng: garage.longitude,
            },
        }));

    const options = {
        styles: mapTheme,
    };

    const containerStyle = {
        width: "100%",
        height: "100%",
    };

    function handleActiveMarker(marker) {
        if (marker === activeMarker) {
            setSelectedGarage(null);
            return;
        }

        setActiveMarker(marker);
    }

    function handleOnLoad(map) {
        const bounds = new google.maps.LatLngBounds();
        markers.forEach(({ position }) => bounds.extend(position));
        map.fitBounds(bounds);
    }

    function openDetail(id: number) {
        const garage = garages.find((g) => g.id === id);
        if (!garage) return;
        setSelectedGarage(garage);
    }

    return (
        <GoogleMap options={options} mapContainerStyle={containerStyle} onClick={() => setActiveMarker(null)} onLoad={handleOnLoad}>
            {markers.map((marker) => (
                <Marker key={marker.id} position={marker.position} onClick={() => handleActiveMarker(marker.id)}>
                    {activeMarker === marker.id ? (
                        <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                            <Box display={"flex"} alignItems={"center"} flexDirection={"column"} mt={1}>
                                <Box mb={1} fontWeight={"bold"}>
                                    {marker.name}
                                </Box>
                                <Button variant="outlined" onClick={() => openDetail(marker.id)}>
                                    {t("garage.map.openDetail")}
                                </Button>
                            </Box>
                        </InfoWindow>
                    ) : null}
                </Marker>
            ))}
        </GoogleMap>
    );
}
