import React, { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "ui-library/atoms";

import i18n from "core/i18n";
import { useGetContractChangeTabLink } from "core/hooks";

type CancelOrCloseRequestButtonProps = {
    label?: string;
};

export const CancelOrCloseRequestButton: FC<CancelOrCloseRequestButtonProps> = ({ label }) => {
    const { t } = i18n.useTranslation();
    const navigate = useNavigate();

    const { idObject } = useParams();
    const editTabUrl = useGetContractChangeTabLink(idObject);

    return (
        <Button variant="outlined" color="blue" onClick={() => navigate(editTabUrl, { replace: true })}>
            {label ? label : t("change.cancelRequest")}
        </Button>
    );
};
