import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";

import entity from "core/entity";
import fetching from "core/fetching";
import { FETCHING_AVAILABLE_PROFILES } from "core/fetching/constants";
import modal from "core/modal";
import router from "core/router";

import { documentsApi, mutualFundsRegularApi } from "serverApi";
import { Params, Tabs } from "routeConstants";
import { ProductGroupLvl2 } from "enums";

// forms
import activeOperationsPhoneNumber from "form/modal/activeOperationsPhoneNumber";
import contractNameForm from "form/modal/contractName";
import electronicCommunicationForm from "form/modal/electronicCommunication";
import mutualFundsClientStatementSettings from "form/modal/clientStatementSettings";
import investmentProfile from "form/fullPage/investmentProfile";
import mutualFundsSellSwitch from "form/mutualFundsSellSwitch";
import policyHolderForm from "form/fullPage/policyHolder";

import { clear, SEND_ENQUEUE_REQUEST, setAvailableProfiles, setMutualFundsRegularContractDetail, setSelectedFund } from "./actions";
import {
    BUY_SELL_CONFIRMATION,
    ENTITY_AVAILABLE_FUNDS_PROFILE_CHANGE,
    ENTITY_CHANGE_PROPOSITIONS,
    ENTITY_CONTRACT_DOCUMENTS,
    ENTITY_ENQUEUED_DOCUMENTS,
    ENTITY_FINANCIAL_OPERATION_DETAIL,
    ENTITY_FINANCIAL_OPERATIONS,
    ENTITY_FINANCIAL_REQUESTS,
    ENTITY_FINANCIAL_STATEMENT,
    FORM_EDIT_ACTIVE_OPERATIONS_PHONE_NUMBER,
    FORM_EDIT_CLIENT_STATEMENT_SETTINGS,
    FORM_EDIT_CONTRACT_NAME,
    FORM_EDIT_ELECTRONIC_COMMUNICATION,
    FORM_EDIT_SHAREHOLDER,
    FORM_INVESTMENT_PROFILE,
    FORM_SELL_SWITCH,
    MODAL_ENTITY_PRINT_CONFIRMATION,
} from "./constants";
import {
    getActiveOperationsPhoneNumber,
    getClientStatementSettings,
    getElectronicCommunication,
    getIdObject,
    getMutualFundsFinancialRequest,
    getPolicy,
    getPolicyHolder,
    isProductGenerationXSk,
    isProfileAvailable,
} from "./selectors";
import {
    ActualStatements,
    ChangePropositionModule,
    ExtraStatements,
    FinancialOperationsTableFilter,
    OtpModule,
    YearStatements,
} from "./modules";
import { ContractChangeType } from "types";

export default router.routerWrapper({
    *getDataForPage({ idObject }) {
        const contractDetail = yield call(mutualFundsRegularApi.getMutualFundsRegularContractDetail, idObject);
        return [setMutualFundsRegularContractDetail(contractDetail)];
    },
    *onPageEnter({ idObject }) {
        yield fork(entity.load, ENTITY_FINANCIAL_OPERATIONS, () =>
            mutualFundsRegularApi.getMutualFundsRegularFinancialOperations(idObject),
        );
        yield fork(entity.load, ENTITY_FINANCIAL_STATEMENT, () => mutualFundsRegularApi.getMutualFundsRegularFinancialStatement(idObject));
        yield fork(entity.load, ENTITY_FINANCIAL_REQUESTS, () => mutualFundsRegularApi.getMutualFundsRegularFinancialRequests(idObject));
        yield fork(entity.load, ENTITY_CONTRACT_DOCUMENTS, () => documentsApi.getContractDocuments(idObject));
        yield fork(entity.load, ENTITY_ENQUEUED_DOCUMENTS, () => documentsApi.getContractEnqueuedDocuments(idObject));
        yield fork(entity.load, ENTITY_AVAILABLE_FUNDS_PROFILE_CHANGE, () =>
            mutualFundsRegularApi.getMutualFundsRegularAvailableFundsProfileChange(idObject),
        );
        yield fork(loadAvailableProfiles, idObject);
        yield fork(YearStatements.saga);
        yield fork(ExtraStatements.saga);
        yield fork(ActualStatements.saga);
        yield fork(OtpModule.saga);
        yield fork(ChangePropositionModule.sagaDataInitialization, idObject);
        yield fork(FinancialOperationsTableFilter.saga);
    },
    *onInnerRouteChange(name, params, query) {
        switch (name) {
            case Tabs.FINANCIAL_STATEMENT:
                yield put(modal.closeModalForm(MODAL_ENTITY_PRINT_CONFIRMATION));
                yield takeEvery(SEND_ENQUEUE_REQUEST, sendEnqueueRequestSaga);
                break;
            case Tabs.AGENT:
                yield call(ChangePropositionModule.saga, params.idObject);
                break;
            case Tabs.CHANGES:
                yield call(handleChangeTabFormsInitialization, query?.type, params?.idObject);
                break;
            case Tabs.SELL_SWITCH_FORM:
                yield call(fundsSellSwitchFormSaga, params.idObject, query[Params.CHANGE_PROPOSITION_ID]);
                break;
            case Tabs.SELL_SWITCH_FORM_WITH_ACTION:
                yield call(fundsSellSwitchFormSaga, params.idObject, params.requestId, params.actionType);
                break;
            case Tabs.REGULAR_FINANCIAL_OPERATION_DETAIL_BUY:
            case Tabs.REGULAR_FINANCIAL_OPERATION_DETAIL_SELL:
                yield call(entity.load, ENTITY_FINANCIAL_OPERATION_DETAIL, () =>
                    mutualFundsRegularApi.getMutualFundsRegularFinancialOperationDetail(params.idObject, params.operationId),
                );
                break;
            case Tabs.INVESTMENT_PROFILE_FORM:
                yield call(
                    investmentProfile.createSaga,
                    FORM_INVESTMENT_PROFILE,
                    OtpModule.setResent,
                    OtpModule.setPhoneNumber,
                    isProfileAvailable,
                    isProductGenerationXSk,
                    params.idObject,
                );
                break;
            case Tabs.REGULAR_PROFIT_DETAIL:
                yield put(setSelectedFund(params.fund));
                break;
            default:
                break;
        }
    },
    *onModalOpen(modalName) {
        const idObject = yield select(getIdObject);
        switch (modalName) {
            case FORM_EDIT_ACTIVE_OPERATIONS_PHONE_NUMBER:
                yield call(
                    activeOperationsPhoneNumber.createSaga,
                    FORM_EDIT_ACTIVE_OPERATIONS_PHONE_NUMBER,
                    idObject,
                    getActiveOperationsPhoneNumber,
                    OtpModule.setResent,
                    OtpModule.setPhoneNumber,
                );
                break;
            default:
                break;
        }
    },
    clearDataForPage() {
        return [
            clear(),
            modal.closeAllModalForms(),
            entity.clearData(
                ENTITY_CONTRACT_DOCUMENTS,
                ENTITY_ENQUEUED_DOCUMENTS,
                ENTITY_FINANCIAL_OPERATIONS,
                ENTITY_FINANCIAL_STATEMENT,
                ENTITY_FINANCIAL_REQUESTS,
                ENTITY_CHANGE_PROPOSITIONS,
                ENTITY_AVAILABLE_FUNDS_PROFILE_CHANGE,
                MODAL_ENTITY_PRINT_CONFIRMATION,
            ),
        ];
    },
});

function* loadAvailableProfiles(idObject) {
    const availableProfiles = yield call(mutualFundsRegularApi.getMutualFundsRegularAvailableProfiles, idObject);
    yield all([put(setAvailableProfiles(availableProfiles)), put(fetching.setFetching(FETCHING_AVAILABLE_PROFILES, false))]);
}

const contractEditHolderFormSaga = policyHolderForm.createSaga(FORM_EDIT_SHAREHOLDER, getPolicyHolder, getPolicy);

const fundsSellSwitchFormSaga = mutualFundsSellSwitch.createSaga(
    FORM_SELL_SWITCH,
    OtpModule.setResent,
    OtpModule.setPhoneNumber,
    ProductGroupLvl2.REGULAR_INVESTMENTS,
    ChangePropositionModule.getChangeProposition,
    getMutualFundsFinancialRequest,
    loadChangePropositions,
    function* () {
        yield put(OtpModule.clear(FORM_SELL_SWITCH));
    },
);

function* loadChangePropositions(idObject) {
    yield fork(ChangePropositionModule.sagaDataInitialization, idObject);
}

function* sendEnqueueRequestSaga({ payload }) {
    yield put(modal.openModalForm(MODAL_ENTITY_PRINT_CONFIRMATION));
    yield call(entity.load, MODAL_ENTITY_PRINT_CONFIRMATION, () =>
        documentsApi.generateAndEnqueueDocument(payload.idObject, BUY_SELL_CONFIRMATION, { idOperation: payload.idOperation }),
    );
    yield call(entity.periodicallyCheckDocuments, 250, ENTITY_ENQUEUED_DOCUMENTS, () =>
        documentsApi.getContractEnqueuedDocuments(payload.idObject),
    );
}

function* handleChangeTabFormsInitialization(type, idObject) {
    switch (type) {
        case ContractChangeType.SHAREHOLDER:
            yield call(contractEditHolderFormSaga, idObject);
            break;
        case ContractChangeType.CONTRACT_NAME:
            yield call(contractNameForm.createSaga, FORM_EDIT_CONTRACT_NAME, idObject);
            break;
        case ContractChangeType.ELECTRONIC_COMMUNICATION:
            yield call(
                electronicCommunicationForm.createSaga,
                FORM_EDIT_ELECTRONIC_COMMUNICATION,
                idObject,
                getPolicyHolder,
                getElectronicCommunication,
            );
            break;
        case ContractChangeType.STATEMENTS_SETTINGS:
            yield call(
                mutualFundsClientStatementSettings.createSaga,
                FORM_EDIT_CLIENT_STATEMENT_SETTINGS,
                idObject,
                getClientStatementSettings,
            );
            break;
        default:
            break;
    }
}
