import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import vehicleChange from "serverApi/vehicleChange";
import { setStep } from "app/pages/detailVehicle/actions";
import useFormRequest from "core/hookForm/hooks/useFormRequest";

export default function useDocuments() {
    const form = useFormContext();
    const dispatch = useDispatch();
    const handleRequest = useFormRequest();

    const [isDocumentsFetching, setIsDocumentsFetching] = useState(true);
    const [documents, setDocuments] = useState([]);
    const [isCompleteLoading, setIsCompleteLoading] = useState(false);

    const idChange = form.getValues().idChange;

    const href = (idChange: string, idDocument: string) => vehicleChange.getDocumentDownloadUrl(idChange, idDocument);

    useEffect(() => {
        const getDocuments = async () => {
            const [error, res] = await vehicleChange.getDocuments(idChange);

            if (error) {
                setIsDocumentsFetching(false);
                return;
            }

            if (res.ok && res.body) {
                setDocuments(res.body);
            }

            setIsDocumentsFetching(false);
        };

        getDocuments();
    }, []);

    async function onComplete() {
        const request = () => vehicleChange.completeChange(idChange);
        const onError = () => {
            setIsCompleteLoading(false);
        };
        const onSuccess = () => {
            dispatch(setStep(3));
            setIsCompleteLoading(false);
        };

        handleRequest(request, onError, onSuccess);
    }

    return {
        documents,
        onComplete,
        href,
        idChange,
        isCompleteLoading,
        isDocumentsFetching,
    };
}
