import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { ContentBox, GridItem, GridLayout } from "ui-library/layouts";
import { Button, Loading } from "ui-library/atoms";

import i18n from "core/i18n";
import userContracts from "core/userContracts";
import {
    Field,
    form,
    FormWideDisabledProvider,
    getFormFieldValue,
    InputText,
    max20Characters,
    phoneNumberCharacters,
    SegmentedControl,
} from "core/form";
import { InPageFormButtonsLayout, InPageFormLayout } from "containers/inPageForm";
import { ChangeSuccessTextType } from "types";

import { FIELD_ENABLED, FIELD_PHONE } from "./constants";

const TaxOptimizationsForm = ({ canSubmit, handleSubmit, submitting, taxOptimizationEnabled }) => {
    const { t } = i18n.useTranslation();
    return (
        <>
            <FormWideDisabledProvider value={submitting}>
                <ContentBox fullWidth>
                    <Field name={FIELD_ENABLED} component={SegmentedControl} label={t("form.taxOptimization")} />
                </ContentBox>

                {taxOptimizationEnabled && (
                    <ContentBox fullWidth>
                        <GridLayout defaultSpacing>
                            <GridItem xs={6}>
                                <Field
                                    name={FIELD_PHONE}
                                    component={InputText}
                                    label={t("holder.phone")}
                                    // validated async in saga!
                                    normalize={[phoneNumberCharacters, max20Characters]}
                                    onEnter={handleSubmit}
                                />
                            </GridItem>
                        </GridLayout>
                    </ContentBox>
                )}
            </FormWideDisabledProvider>

            <InPageFormButtonsLayout
                rightButton={
                    <Button variant="contained" color="blue" onClick={handleSubmit} disabled={!canSubmit || submitting}>
                        {submitting ? <Loading loading /> : t("common.submitChange")}
                    </Button>
                }
            />
        </>
    );
};

TaxOptimizationsForm.propTypes = {
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    taxOptimizationEnabled: PropTypes.bool,
};

TaxOptimizationsForm.defaultProps = {
    taxOptimizationEnabled: false,
};

export const createInPageContainer = (formName) =>
    form(formName, { asyncValidationFields: [FIELD_PHONE] })((props) => {
        const { t } = i18n.useTranslation();
        const taxOptimizationEnabled = useSelector(getFormFieldValue(formName, FIELD_ENABLED));
        const { idObject } = useParams();
        const contract = userContracts.useGetContractById(idObject);

        return (
            <InPageFormLayout
                formName={formName}
                formTitle={t("change.title.TAX_OPTIMUM", { contractNumber: contract.contractNumber })}
                changeSuccessTextType={ChangeSuccessTextType.CHANGE_SUBMITTED_FOR_PROCESSING}
            >
                <TaxOptimizationsForm taxOptimizationEnabled={taxOptimizationEnabled} {...props} />
            </InPageFormLayout>
        );
    });
