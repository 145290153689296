export const NAME = "page-detail-pp";

// In tab state
export const REDEEM_SAVINGS_STEP = {
    FIRST_STEP_INTRODUCTION: `${NAME}/FIRST_STEP_INTRODUCTION`,
    SECOND_STEP_REDEEM_NOT_POSSIBLE: `${NAME}/SECOND_STEP_REDEEM_NOT_POSSIBLE`,
};

// Entity
export const ENTITY_CONTRACT_ADDITIONAL_INFO = `${NAME}/ENTITY_CONTRACT_ADDITIONAL_INFO`;
export const ENTITY_CONTRACT_DOCUMENTS = `${NAME}/ENTITY_CONTRACT_DOCUMENTS`;
export const ENTITY_ENQUEUED_DOCUMENTS = `${NAME}/ENTITY_ENQUEUED_DOCUMENTS`;
export const ENTITY_FINANCIAL_OPERATIONS = `${NAME}/ENTITY_FINANCIAL_OPERATIONS`;
export const ENTITY_FINANCIAL_STATEMENT = `${NAME}/ENTITY_FINANCIAL_STATEMENT`;
export const ENTITY_PAYMENT_INFORMATION = `${NAME}/ENTITY_PAYMENT_INFORMATION`;

// Form
export const FORM_EDIT_ELECTRONIC_COMMUNICATION = `${NAME}/FORM_EDIT_ELECTRONIC_COMMUNICATION`;
export const FORM_EDIT_PARTICIPANT = `${NAME}/FORM_EDIT_POLICY_HOLDER`;
export const FORM_EDIT_BENEFICIARIES = `${NAME}/FORM_EDIT_BENEFICIARIES`;
export const FORM_EDIT_CONTRACT_NAME = `${NAME}/FORM_EDIT_CONTRACT_NAME`;
export const FORM_EDIT_PARTICIPANT_PAYMENTS = `${NAME}/FORM_EDIT_PARTICIPANT_PAYMENTS`;
export const FORM_EDIT_EMPLOYER_PAYMENTS = `${NAME}/FORM_EDIT_EMPLOYER_PAYMENTS`;
export const FORM_EDIT_TAX_OPTIMIZATION = `${NAME}/FORM_EDIT_TAX_OPTIMIZATION`;
export const FORM_EDIT_CLIENT_STATEMENT_SETTINGS = `${NAME}/FORM_EDIT_CLIENT_STATEMENT_SETTINGS`;
export const FORM_REDEEM_SAVINGS = `${NAME}/FORM_REDEEM_SAVINGS`;

// Columns
export const CATEGORY_NAME_COLUMN = "categoryName";
export const GROSS_AMOUNT_COLUMN = "grossAmount";
export const VALUE_DATE_COLUMN = "valueDate";
