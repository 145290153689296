import { Protected } from "containers/roleAccess";
import { Field } from "core/hookForm";
import { Roles } from "enums";
import { Typography } from "ui-library/atoms";
import { Box, GridItem, GridLayout } from "ui-library/layouts";
import i18n from "core/i18n";
import { useHasRole } from "core/hooks";
import { useFormContext } from "react-hook-form";
import { Option } from "core/hookForm/components/Field";
import { lettersNumbersBasicInterpunctionCharacters, max20Characters } from "core/form";

export default function VehicleSelect({ vehicles }: { vehicles: Option[] }) {
    const { watch } = useFormContext();
    const { t } = i18n.useTranslation();
    const isAuthenticated = useHasRole(Roles.AUTHENTICATED);
    const { vinNumber } = watch();

    return (
        <>
            <Protected roles={[Roles.AUTHENTICATED]}>
                <Box mb={6}>
                    {vehicles.length > 1 && (
                        <Box maxWidth={400} mb={1}>
                            <Field input="select" name="vinNumber" label={t("vehicle.change.vehicleData.vehicle")} options={vehicles} />
                        </Box>
                    )}

                    {vinNumber && (
                        <>
                            <GridLayout>
                                <GridItem xs={2}>
                                    <Typography variant="span" fontWeight={700}>
                                        {t("vehicle.change.vehicleData.vehicle")}:
                                    </Typography>
                                </GridItem>
                                <GridItem xs={10}>{vehicles.find((vehicle) => vehicle.code === vinNumber)?.value}</GridItem>
                            </GridLayout>

                            <GridLayout>
                                <GridItem xs={2}>
                                    <Typography variant="span" fontWeight={700}>
                                        {t("vehicle.change.vehicleData.vin")}:
                                    </Typography>
                                </GridItem>
                                <GridItem xs={10}>{vinNumber}</GridItem>
                            </GridLayout>
                        </>
                    )}
                </Box>
            </Protected>

            {!isAuthenticated && (
                <GridLayout>
                    <GridItem xs={4} md={3} lg={2} mb={6}>
                        <Field
                            input="textField"
                            name="licencePlateNumber"
                            label={t("vehicle.change.vehicleData.licencePlateNumber")}
                            normalize={[lettersNumbersBasicInterpunctionCharacters, max20Characters]}
                        />
                    </GridItem>
                </GridLayout>
            )}
        </>
    );
}
