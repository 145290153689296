import { LandingPageLayout } from "ui-library/layouts";
import GarageSearch from "./components/GarageSearch";

export default () => (
    <>
        <LandingPageLayout>
            <GarageSearch />
        </LandingPageLayout>
    </>
);
