import policyHolderForm from "form/fullPage/policyHolder";
import { IndividualPersonType } from "enums";

import { FORM_EDIT_SHAREHOLDER } from "../constants";
import { getIdObject, getPolicyHolder } from "../selectors";

const SHOW_ID_SECTION = true;

export const ShareHolderForm = policyHolderForm.createInPageContainer(
    FORM_EDIT_SHAREHOLDER,
    IndividualPersonType.SHAREHOLDER,
    getIdObject,
    getPolicyHolder,
    SHOW_ID_SECTION,
);
