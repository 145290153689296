import { FC } from "react";
import { useParams } from "react-router-dom";

import userContracts from "core/userContracts";
import contractOwnerPaymentsForm from "form/modal/contractOwnerPayments";

import { FORM_EDIT_PARTICIPANT_PAYMENTS } from "../constants";

export const ParticipantPaymentsFormContainer = contractOwnerPaymentsForm.createInPageContainer(
    FORM_EDIT_PARTICIPANT_PAYMENTS,
    "change.title.PARTICIPANT_PAYMENTS",
);

export const ParticipantPaymentsForm: FC = () => {
    const { idObject } = useParams();
    const contract = userContracts.useGetContractById(idObject);

    return <ParticipantPaymentsFormContainer isUniqaContract={contract?.isUniqaContract} />;
};
