import { all, call, fork, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { List, Set } from "immutable";
import { change } from "redux-form/immutable";

import userInfo from "core/userInfo";
import { FORM_STEPS_BACK } from "app/constants";
import { AddressType } from "enums";
import { autocompleteApi, contractDataChangeApi, validationApi } from "serverApi";
import { model } from "core/util";
import {
    asyncFieldValidator,
    asyncValidateFields,
    fieldChangeMatcher,
    formWrapper,
    getFormFieldValueWithState,
    required,
    scrollToFirstError,
    sectionChangeMatcher,
} from "core/form";
import autocomplete from "core/autocomplete";
import { PlaceDetail } from "model/autocomplete";

import {
    ARE_ADDRESSES_IDENTICAL_FIELD,
    AUTHORITY_FORM_FIELD,
    CARD_ID_FORM_FIELD,
    CITY_FORM_FIELD,
    CLIENT_TYPE_COMPANY,
    CLIENT_TYPE_INDIVIDUAL,
    COMPANY_NAME_FIELD,
    CONTACT_ADDRESS_AUTOCOMPLETE,
    CONTACT_ADDRESS_SECTION,
    DATE_OF_EXPIRY_FORM_FIELD,
    DESCRIPTION_NUMBER_FORM_FIELD,
    EMAIL_FIELD,
    FIRST_NAME_FIELD,
    IDENTIFICATION_CARD_SECTION,
    IDENTIFICATION_FORM_FIELD,
    LAST_NAME_FIELD,
    MOBILE_FIELD,
    ORIENTATION_NUMBER_FORM_FIELD,
    PERMANENT_ADDRESS_AUTOCOMPLETE,
    PERMANENT_ADDRESS_SECTION,
    PERSON_SECTION,
    RELEASE_DATE_FORM_FIELD,
    SHOW_CONTACT_PERSON_HIDDEN_FIELD,
    STATE_FORM_FIELD,
    STREET_FORM_FIELD,
    TITLE_AFTER_FIELD,
    TITLE_BEFORE_FIELD,
    ZIP_FORM_FIELD,
} from "./constants";
import {
    createGetContactPersonType,
    getAddressesIdentical,
    getClientType,
    getShowContactPerson,
    isIdentificationCardIdDirty,
    isIdentificationTypeDirty,
} from "./selectors";
import { createToggleCopyActionType } from "./createActions";

export default function (formName, getHolderSelector, getPolicySelector) {
    return function* holderSaga(idObject) {
        const holderFormSaga = formSaga(formName, getHolderSelector, getPolicySelector);
        yield all([fork(holderFormSaga, idObject)]);
    };
}

const formSaga = (formName, getHolderSelector, getPolicySelector) =>
    formWrapper(formName, {
        *persistentEffects() {
            yield takeEvery(fieldChangeMatcher(formName, IDENTIFICATION_FORM_FIELD), editFormIdTypeCodeChangeSaga, formName);
            yield takeEvery(
                autocomplete.selectMatcher(PERMANENT_ADDRESS_AUTOCOMPLETE),
                placeSelectedSaga,
                formName,
                PERMANENT_ADDRESS_SECTION,
            );
            yield takeEvery(autocomplete.selectMatcher(CONTACT_ADDRESS_AUTOCOMPLETE), placeSelectedSaga, formName, CONTACT_ADDRESS_SECTION);
            yield takeLatest(createToggleCopyActionType(formName), toggleAddressesIdentitySaga, formName, getPolicySelector);
            yield takeLatest(
                sectionChangeMatcher(formName, PERMANENT_ADDRESS_SECTION),
                copyPerAddressIfNeededSaga,
                formName,
                getPolicySelector,
            );
            yield takeLatest(sectionChangeMatcher(formName, PERSON_SECTION), copyIndividualPersonIfNeededSaga, formName);
            yield takeLatest(fieldChangeMatcher(formName, COMPANY_NAME_FIELD), copyCompanyPersonIfNeededSaga, formName);
        },
        *initialize() {
            yield call(userInfo.checkUserVerified, FORM_STEPS_BACK);
            const holder = yield select(getHolderSelector);

            if (holder) {
                const isCompany = !holder.isPerson;
                const contactAddress = model.getPlainObjAddressByTypeCodeLegacy(holder.addresses, AddressType.CON);
                return {
                    files: List(),
                    clientType: isCompany ? CLIENT_TYPE_COMPANY : CLIENT_TYPE_INDIVIDUAL,
                    [PERSON_SECTION]: isCompany
                        ? undefined
                        : {
                              [FIRST_NAME_FIELD]: holder.firstName,
                              [LAST_NAME_FIELD]: holder.lastName,
                              [TITLE_BEFORE_FIELD]: holder.titleBefore,
                              [TITLE_AFTER_FIELD]: holder.titleAfter,
                          },
                    [COMPANY_NAME_FIELD]: isCompany ? holder.companyName : undefined,
                    [EMAIL_FIELD]: holder.email,
                    [MOBILE_FIELD]: holder.phone,
                    [IDENTIFICATION_CARD_SECTION]: isCompany ? undefined : getIdentificationCard(holder.ids),
                    [PERMANENT_ADDRESS_SECTION]: model.getPlainObjAddressByTypeCodeLegacy(holder.addresses, AddressType.PER),
                    [CONTACT_ADDRESS_SECTION]: Object.assign(contactAddress, {
                        [ARE_ADDRESSES_IDENTICAL_FIELD]: holder.addressesIdentical,
                        [SHOW_CONTACT_PERSON_HIDDEN_FIELD]: holder.showPersonInContactAddressForm,
                        [PERSON_SECTION]:
                            holder.showPersonInContactAddressForm && !isCompany
                                ? {
                                      [FIRST_NAME_FIELD]: holder.firstName,
                                      [LAST_NAME_FIELD]: holder.lastName,
                                      [TITLE_BEFORE_FIELD]: holder.titleBefore,
                                      [TITLE_AFTER_FIELD]: holder.titleAfter,
                                  }
                                : null,
                        [COMPANY_NAME_FIELD]: holder.showPersonInContactAddressForm && isCompany ? holder.companyName : null,
                    }),
                    additionalIdObjects: Set(),
                };
            }
            return {};
        },
        *onSubmitFail() {
            yield call(scrollToFirstError);
        },
        *save(values, idObject) {
            const contactPersonTypeSelector = createGetContactPersonType(formName);
            const contactPersonType = yield select(contactPersonTypeSelector);
            const areAddressesMarkedAsIdentical = yield select(getAddressesIdentical, formName);
            const data = values
                .set("addressesMarkedAsIdentical", areAddressesMarkedAsIdentical)
                .set("contactPersonType", contactPersonType)
                .set(
                    "contactPerson",
                    contactPersonType === CLIENT_TYPE_INDIVIDUAL ? values.getIn([CONTACT_ADDRESS_SECTION, PERSON_SECTION]) : null,
                )
                .set(
                    "contactCompanyName",
                    contactPersonType === CLIENT_TYPE_COMPANY ? values.getIn([CONTACT_ADDRESS_SECTION, COMPANY_NAME_FIELD]) : null,
                )
                .set("idObject", idObject);

            yield call(contractDataChangeApi.updateHolder, data);
        },
        *asyncValidation(values, field) {
            const holder = yield select(getHolderSelector);
            const isTypeDirty = yield select(isIdentificationTypeDirty, formName);
            const isCardIdDirty = yield select(isIdentificationCardIdDirty, formName);
            const originalCardId = holder.isPerson ? getIdentificationCard(holder.ids).cardId : undefined;
            const cardIdValidation = [validationApi.validateIdentificationCard];
            const cardTypeValidation = holder.isPerson && isCardIdDirty ? [required] : [];

            if (originalCardId || (holder.isPerson && isTypeDirty)) {
                cardIdValidation.splice(0, 0, required);
            }
            return yield call(
                asyncValidateFields(
                    asyncFieldValidator(["mobile"], [validationApi.validateMobile]),
                    asyncFieldValidator(["identificationCard", "cardId"], cardIdValidation),
                    asyncFieldValidator(["identificationCard", "identification"], cardTypeValidation),
                ),
                field,
                values,
            );
        },
    });

function getIdentificationCard(ids) {
    const id = {};
    if (ids && ids.first()) {
        id.identification = ids.first().idTypeCode;
        id.cardId = ids.first().number;
        id.releaseDate = ids.first().releaseDate;
        id.dateOfExpiry = ids.first().dateOfExpiry;
        id.authority = ids.first().authority;
    }
    return id;
}

function* editFormIdTypeCodeChangeSaga(formName) {
    yield all([
        put(change(formName, CARD_ID_FORM_FIELD, "")),
        put(change(formName, RELEASE_DATE_FORM_FIELD, "")),
        put(change(formName, DATE_OF_EXPIRY_FORM_FIELD, "")),
        put(change(formName, AUTHORITY_FORM_FIELD, "")),
    ]);
}

function* placeSelectedSaga(formName, addressType, { payload }) {
    const detail = yield call(autocompleteApi.getPlaceDetail, payload);
    const placeDetailModel = PlaceDetail.fromServer(detail);

    yield all([
        put(change(formName, `${addressType}.${STREET_FORM_FIELD}`, placeDetailModel.route)),
        put(change(formName, `${addressType}.${DESCRIPTION_NUMBER_FORM_FIELD}`, placeDetailModel.premise)),
        put(change(formName, `${addressType}.${ORIENTATION_NUMBER_FORM_FIELD}`, placeDetailModel.street_number)),
        put(change(formName, `${addressType}.${CITY_FORM_FIELD}`, placeDetailModel.locality)),
        put(change(formName, `${addressType}.${ZIP_FORM_FIELD}`, placeDetailModel.postal_code)),
        put(change(formName, `${addressType}.${STATE_FORM_FIELD}`, placeDetailModel.country)),
    ]);
}

function* copyPerAddressIfNeededSaga(formName, getPolicySelector) {
    const addressesIdentical = yield select(getAddressesIdentical, formName);
    const policy = yield select(getPolicySelector);

    if (addressesIdentical) {
        const perAddress = yield select(getFormFieldValueWithState, formName, `${PERMANENT_ADDRESS_SECTION}`);

        // On INAS contacts country other domestic is prohibited on contact address.
        if (addressesIdentical && policy.isUniqaContract && perAddress.get(STATE_FORM_FIELD) !== policy.countryCode) {
            yield put(change(formName, `${CONTACT_ADDRESS_SECTION}.${ARE_ADDRESSES_IDENTICAL_FIELD}`, !addressesIdentical));
        } else {
            yield all([
                put(change(formName, `${CONTACT_ADDRESS_SECTION}.${STREET_FORM_FIELD}`, perAddress.get(STREET_FORM_FIELD))),
                put(
                    change(
                        formName,
                        `${CONTACT_ADDRESS_SECTION}.${DESCRIPTION_NUMBER_FORM_FIELD}`,
                        perAddress.get(DESCRIPTION_NUMBER_FORM_FIELD),
                    ),
                ),
                put(
                    change(
                        formName,
                        `${CONTACT_ADDRESS_SECTION}.${ORIENTATION_NUMBER_FORM_FIELD}`,
                        perAddress.get(ORIENTATION_NUMBER_FORM_FIELD),
                    ),
                ),
                put(change(formName, `${CONTACT_ADDRESS_SECTION}.${CITY_FORM_FIELD}`, perAddress.get(CITY_FORM_FIELD))),
                put(change(formName, `${CONTACT_ADDRESS_SECTION}.${ZIP_FORM_FIELD}`, perAddress.get(ZIP_FORM_FIELD))),
                put(change(formName, `${CONTACT_ADDRESS_SECTION}.${STATE_FORM_FIELD}`, perAddress.get(STATE_FORM_FIELD))),
            ]);
        }
    }
}

function* copyContactPersonIfNeededSaga(formName) {
    const showContactPerson = yield select(getShowContactPerson, formName);
    const addressesIdentical = yield select(getAddressesIdentical, formName);
    const clientType = yield select(getClientType, formName);

    if (showContactPerson && addressesIdentical) {
        if (clientType === CLIENT_TYPE_COMPANY) {
            const companyName = yield select(getFormFieldValueWithState, formName, `${COMPANY_NAME_FIELD}`);
            yield put(change(formName, `${CONTACT_ADDRESS_SECTION}.${COMPANY_NAME_FIELD}`, companyName));
        } else {
            const person = yield select(getFormFieldValueWithState, formName, `${PERSON_SECTION}`);
            yield all([
                put(change(formName, `${CONTACT_ADDRESS_SECTION}.${PERSON_SECTION}.${TITLE_BEFORE_FIELD}`, person.get(TITLE_BEFORE_FIELD))),
                put(change(formName, `${CONTACT_ADDRESS_SECTION}.${PERSON_SECTION}.${FIRST_NAME_FIELD}`, person.get(FIRST_NAME_FIELD))),
                put(change(formName, `${CONTACT_ADDRESS_SECTION}.${PERSON_SECTION}.${LAST_NAME_FIELD}`, person.get(LAST_NAME_FIELD))),
                put(change(formName, `${CONTACT_ADDRESS_SECTION}.${PERSON_SECTION}.${TITLE_AFTER_FIELD}`, person.get(TITLE_AFTER_FIELD))),
            ]);
        }
    }
}

function* copyIndividualPersonIfNeededSaga(formName) {
    const addressesIdentical = yield select(getAddressesIdentical, formName);
    const showContactPerson = yield select(getShowContactPerson, formName);
    const clientType = yield select(getClientType, formName);

    if (addressesIdentical && showContactPerson && clientType === CLIENT_TYPE_INDIVIDUAL) {
        const clientPerson = yield select(getFormFieldValueWithState, formName, `${PERSON_SECTION}`);
        yield all([
            put(
                change(
                    formName,
                    `${CONTACT_ADDRESS_SECTION}.${PERSON_SECTION}.${TITLE_BEFORE_FIELD}`,
                    clientPerson.get(TITLE_BEFORE_FIELD),
                ),
            ),
            put(change(formName, `${CONTACT_ADDRESS_SECTION}.${PERSON_SECTION}.${FIRST_NAME_FIELD}`, clientPerson.get(FIRST_NAME_FIELD))),
            put(change(formName, `${CONTACT_ADDRESS_SECTION}.${PERSON_SECTION}.${LAST_NAME_FIELD}`, clientPerson.get(LAST_NAME_FIELD))),
            put(change(formName, `${CONTACT_ADDRESS_SECTION}.${PERSON_SECTION}.${TITLE_AFTER_FIELD}`, clientPerson.get(TITLE_AFTER_FIELD))),
        ]);
    }
}

function* copyCompanyPersonIfNeededSaga(formName) {
    const addressesIdentical = yield select(getAddressesIdentical, formName);
    const showContactPerson = yield select(getShowContactPerson, formName);
    const clientType = yield select(getClientType, formName);

    if (addressesIdentical && showContactPerson && clientType === CLIENT_TYPE_COMPANY) {
        const companyName = yield select(getFormFieldValueWithState, formName, `${COMPANY_NAME_FIELD}`);
        yield put(change(formName, `${CONTACT_ADDRESS_SECTION}.${COMPANY_NAME_FIELD}`, companyName));
    }
}

function* toggleAddressesIdentitySaga(formName, getPolicySelector) {
    const addressesIdentical = yield select(getAddressesIdentical, formName);
    yield put(change(formName, `${CONTACT_ADDRESS_SECTION}.${ARE_ADDRESSES_IDENTICAL_FIELD}`, !addressesIdentical));
    yield all([call(copyPerAddressIfNeededSaga, formName, getPolicySelector), call(copyContactPersonIfNeededSaga, formName)]);
}
