import useFormRequest from "core/hookForm/hooks/useFormRequest";
import { Map } from "immutable";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import vehicleChange from "serverApi/vehicleChange";
import vehicleSubjectChange, { SignatureDto } from "serverApi/vehicleSubjectChange";
import { SignatureType } from "../types/VehicleSubjectChangeFormValues";
import { useSearchParams } from "react-router-dom";
import { useHasRole } from "core/hooks";
import { Roles } from "enums";
import { useSelector } from "react-redux";
import { getPolicyHolder } from "app/pages/detailVehicle/selectors";
import i18n from "core/i18n";

const DOCUMENT_TO_SIGN = "VehicleDataOrSubjectChangeRequest";

export default function useStepDocuments(nextStep: () => void) {
    const { t } = i18n.useTranslation();
    const form = useFormContext();
    const idChange = form.getValues().idChange;
    const signatureType = form.getValues().signature.type;
    const handleRequest = useFormRequest(form);
    const uploadedFiles = form.watch("signature.uploadedFiles");
    const uploadedFile: Map<any, any> = uploadedFiles[0] ?? null;

    const [searchParams, setSearchParams] = useSearchParams();
    const envelopeId = searchParams.get("envelopeId");
    const isDocumentSigned = searchParams.get("event") === "success" && envelopeId;

    const [documents, setDocuments] = useState([]);
    const [isDocumentsFetching, setIsDocumentsFetching] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSigning, setIsSigning] = useState(false);

    const href = (idChange: string, idDocument: string) => vehicleChange.getDocumentDownloadUrl(idChange, idDocument);
    const documentToSign = documents.find((document) => document.documentType === DOCUMENT_TO_SIGN);
    const rows = documentToSign
        ? [
              {
                  id: documentToSign.documentId,
                  localName: documentToSign.label || documentToSign.documentType,
                  documentType: documentToSign.documentType,
              },
          ]
        : [];

    const isAuthenticated = useHasRole(Roles.AUTHENTICATED);
    const policyHolder = useSelector(getPolicyHolder);

    const emailInfoText = isAuthenticated
        ? t("vehicle.change.vehicleData.documents.emailInfoAuthenticated", { email: policyHolder?.email })
        : t("vehicle.change.vehicleData.documents.emailInfoOneTime");

    useEffect(() => {
        const getDocuments = async () => {
            const [error, res] = await vehicleChange.getDocuments(idChange);

            if (error) {
                setIsDocumentsFetching(false);
                return;
            }

            if (res.ok && res.body) {
                setDocuments(res.body);
            }

            setIsDocumentsFetching(false);
        };

        getDocuments();
    }, []);

    function getIsSubmitButtonDisabled() {
        switch (signatureType) {
            case SignatureType.PHYSICAL:
                return uploadedFile ? false : true;
            case SignatureType.ELECTRONIC:
                return !isDocumentSigned;
            default:
                return true;
        }
    }

    function uploadDocument() {
        if (!uploadedFile) return;

        const onError = () => {
            setIsSubmitting(false);
            return;
        };

        const onSuccess = () => {
            setIsSubmitting(false);
            searchParams.set("step", "4");
            setSearchParams(searchParams);
            nextStep();
        };

        handleRequest(() => vehicleChange.uploadDocument(idChange, documentToSign.documentId, uploadedFile), onError, onSuccess);
    }

    function signDocument() {
        setIsSigning(true);
        const dto: SignatureDto = {
            idObject: form.getValues().idObject,
            redirectUrl: window.location.href,
        };

        const onError = () => {
            setIsSigning(false);
            return;
        };

        const onSuccess = (res) => {
            if (!res) return;
            const redirectUrl = res.body.signRedirectUrl;
            window.location.href = redirectUrl;
        };

        handleRequest(() => vehicleSubjectChange.sign(idChange, dto), onError, onSuccess);
    }

    function finalize() {
        if (!isDocumentSigned) return;

        const onError = () => {
            setIsSubmitting(false);
            return;
        };

        const onSuccess = () => {
            setIsSubmitting(false);
            searchParams.set("step", "4");
            setSearchParams(searchParams);
            nextStep();
        };

        handleRequest(() => vehicleSubjectChange.finalize(idChange, envelopeId), onError, onSuccess);
    }

    async function submit() {
        setIsSubmitting(true);
        switch (signatureType) {
            case SignatureType.PHYSICAL:
                uploadDocument();
                break;

            case SignatureType.ELECTRONIC:
                finalize();
                break;

            default:
                setIsSubmitting(false);
                break;
        }
    }

    return {
        idChange,
        documents,
        href,
        isDocumentsFetching,
        signatureType,
        signDocument,
        rows,
        uploadedFile,
        submit,
        isSubmitting,
        getIsSubmitButtonDisabled,
        isSigning,
        isDocumentSigned,
        emailInfoText,
    };
}
