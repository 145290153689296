import { TFunction } from "core/i18n";
import { ChangesDefinition, ContractChangeType } from "types";

export const changesDefinition = (t: TFunction): ChangesDefinition => {
    const authenticatedChangeOptions = [
        {
            code: ContractChangeType.CONTRACT_NAME,
            value: t("changeType.CONTRACT_NAME"),
        },
        {
            code: ContractChangeType.ELECTRONIC_COMMUNICATION,
            value: t("changeType.ELECTRONIC_COMMUNICATION"),
        },
        {
            code: ContractChangeType.SHAREHOLDER,
            value: t("changeType.SHAREHOLDER"),
        },
        {
            code: ContractChangeType.STATEMENTS_SETTINGS,
            value: t("changeType.STATEMENTS_SETTINGS"),
        },
    ];

    const oneTimeChangeOptions = [];

    return {
        authenticatedChangeOptions,
        oneTimeChangeOptions,
    };
};
