import { Controller, useFormContext } from "react-hook-form";
import { Radio, RadioGroup as NoRefRadioGroup, RadioGroupProps } from "ui-library/atoms/Inputs/Radio";
import RepairType from "../../types/repairType";
import i18n from "core/i18n";
import { forwardRef } from "react";

const RadioGroup = forwardRef((props: RadioGroupProps, ref) => <NoRefRadioGroup {...props} reference={ref} />);

export default function RepairTypeRadioGroup() {
    const { t } = i18n.useTranslation();
    const { control, formState, setValue, clearErrors } = useFormContext();

    function onRepairTypeChange(_: any, value: any) {
        setValue("repairType", value);
        clearErrors();
        if (Number(value) === RepairType.HailDamage) {
            setValue("carMake", null);
            setValue("makeYear", null);
        }
    }

    return (
        <Controller
            control={control}
            name="repairType"
            render={({ field }) => (
                <RadioGroup
                    {...field}
                    direction="column"
                    label={`${t("garage.form.repairType")} *`}
                    error={Boolean(formState.errors?.repairType)}
                    errorContent={formState.errors?.repairType?.message.toString()}
                    onChange={onRepairTypeChange}
                >
                    <Radio value={RepairType.Windshield} id="repairType1" label={t("garage.form.repairType.windshield")} />
                    <Radio value={RepairType.VehicleDamage} id="repairType2" label={t("garage.form.repairType.vehicleDamage")} />
                    <Radio value={RepairType.HailDamage} id="repairType3" label={t("garage.form.repairType.hailDamage")} />
                </RadioGroup>
            )}
        />
    );
}
