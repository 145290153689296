import React, { FC } from "react";
import { List } from "immutable";
import { useParams } from "react-router-dom";

import i18n from "core/i18n";
import contractOwnerPaymentsForm from "form/modal/contractOwnerPayments";
import userContracts from "core/userContracts";
import { PaymentContribution } from "enums";

import { FORM_EDIT_INSURANCE_PAYMENTS } from "../constants";

export const InsurancePaymentsFormContainer = contractOwnerPaymentsForm.createInPageContainer(
    FORM_EDIT_INSURANCE_PAYMENTS,
    "change.title.INSURANCE_PAYMENTS",
);

export const InsurancePaymentsForm: FC = () => {
    const { t } = i18n.useTranslation();

    const { idObject } = useParams();
    const contract = userContracts.useGetContractById(idObject);

    const lifePaymentOptions = List([PaymentContribution.ByPostalOrder.id, PaymentContribution.ByBankAccount.id]);

    return (
        <InsurancePaymentsFormContainer
            isUniqaContract={contract?.isUniqaContract}
            canEditAmount={false}
            amountLabel={t("insurance.payments.amount")}
            paymentOptionsUniqaExAxa={lifePaymentOptions}
            displayDisclaimer
        />
    );
};
