import { FC } from "react";

import contractOwnerPaymentsForm from "form/modal/contractOwnerPayments";

import { FORM_EDIT_PARTICIPANT_PAYMENTS } from "../constants";

export const ParticipantPaymentsFormContainer = contractOwnerPaymentsForm.createInPageContainer(
    FORM_EDIT_PARTICIPANT_PAYMENTS,
    "change.title.PARTICIPANT_PAYMENTS",
);

export const ParticipantPaymentsForm: FC = () => <ParticipantPaymentsFormContainer showPaymentType={false} />;
