import { useHasRole } from "core/hooks";
import { Roles } from "enums";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import vehicleDataChange, { VehicleDataSubmitDto } from "serverApi/vehicleDataChange";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import i18n from "core/i18n";
import { useDispatch } from "react-redux";
import { getIsFormLoading, getIsSubmitting, getPolicyHolder, getStep, getVehicles } from "../../../selectors";
import {
    setIsFormLoading,
    setIsLicencePlateFillInLoading,
    setIsSubmitting,
    setIsVehicleLicenceFillInLoading,
    setStep,
} from "../../../actions";
import useFormRequest from "core/hookForm/hooks/useFormRequest";
import userContracts from "core/userContracts";
import pageContext from "core/pageContext";

const LICENCE_PLATE_FORMAT_CZ = new RegExp("^[0-9A-Z]{5,8}$");
const LICENCE_PLATE_FORMAT_SK = new RegExp("^[0-9A-Z]{5,7}$");

const VEHICLE_LICENCE_FORMAT_CZ = new RegExp("^[A-Z]{2,3}[0-9]{6}$");
const VEHICLE_LICENCE_FORMAT_SK = new RegExp("^[A-Z]{2}[0-9]{6}$");

export type VehicleDataChangeFormValues = {
    errors?: string[];
    idChange?: string;
    vinNumber?: string;
    validityStartDate?: Date;
    isLicencePlateNumberChange?: boolean;
    isVehicleLicenceNumberChange?: boolean;
    oldLicencePlateNumber?: string;
    newLicencePlateNumber?: string;
    oldVehicleLicenceNumber?: string;
    newVehicleLicenceNumber?: string;
    email?: string;
};

export function useVehicleDataChangeForm() {
    const { idObject } = useParams();
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();
    const { contractNumber } = userContracts.useGetContractByUrlParam();

    const step = useSelector(getStep);
    const isFormLoading = useSelector(getIsFormLoading);
    const isSubmitting = useSelector(getIsSubmitting);

    const { isSlovakSite } = pageContext.useGetPageLocalization();
    const isAuthenticated = useHasRole(Roles.AUTHENTICATED);
    const holder = useSelector(getPolicyHolder);

    const vehicles = [];
    const vehiclesList = useSelector(getVehicles);
    vehiclesList.forEach((vehicle) => {
        const v = {
            code: vehicle.vinNumber,
            value: `${vehicle.manufacturer || ""} ${vehicle.model || ""}`,
            licencePlateNumber: vehicle.licencePlateNumber,
            vehicleLicenceNumber: vehicle.vehicleLicenceNumber,
        };
        vehicles.push(v);
    });

    const initialVehicle = vehicles.length === 1 ? vehicles[0] : null;

    const initialValues: VehicleDataChangeFormValues = {
        idChange: null,
        vinNumber: isAuthenticated ? initialVehicle.code : null,
        validityStartDate: new Date(),
        isLicencePlateNumberChange: false,
        isVehicleLicenceNumberChange: false,
        oldLicencePlateNumber: isAuthenticated ? initialVehicle.licencePlateNumber : null,
        newLicencePlateNumber: null,
        oldVehicleLicenceNumber: isAuthenticated ? initialVehicle.vehicleLicenceNumber : null,
        newVehicleLicenceNumber: null,
        email: isAuthenticated ? holder?.email || "" : null,
    };

    let today = new Date();
    today = new Date(today.setHours(0, 0, 0, 0));

    const validationSchema = yup.object().shape({
        idChange: yup.string().nullable(),
        vinNumber: yup.string().nullable(),
        validityStartDate: yup.date().typeError(t("error.notValid")).min(today, t("error.notValid")).required(t("error.required")),
        isLicencePlateNumberChange: yup.boolean(),
        isVehicleLicenceNumberChange: yup.boolean(),
        newLicencePlateNumber: yup.string().when("isLicencePlateNumberChange", {
            is: true,
            then: (schema) =>
                schema
                    .required(t("error.required"))
                    .test({
                        name: "isDifferent",
                        message: t("error.mustBeDifferent"),
                        test: function (value) {
                            const { oldLicencePlateNumber } = this.parent;
                            return value !== oldLicencePlateNumber;
                        },
                    })
                    .test({
                        name: "correctValue",
                        message: t("error.notValid"),
                        test: function (value) {
                            return isSlovakSite ? LICENCE_PLATE_FORMAT_SK.test(value) : LICENCE_PLATE_FORMAT_CZ.test(value);
                        },
                    }),
            otherwise: (schema) => schema.nullable(),
        }),
        newVehicleLicenceNumber: yup.string().when("isVehicleLicenceNumberChange", {
            is: true,
            then: (schema) =>
                schema
                    .required(t("error.required"))
                    .test({
                        name: "isDifferent",
                        message: t("error.mustBeDifferent"),
                        test: function (value) {
                            const { oldVehicleLicenceNumber } = this.parent;
                            return value !== oldVehicleLicenceNumber;
                        },
                    })
                    .test({
                        name: "correctValue",
                        message: t("error.notValid"),
                        test: function (value) {
                            return isSlovakSite ? VEHICLE_LICENCE_FORMAT_SK.test(value) : VEHICLE_LICENCE_FORMAT_CZ.test(value);
                        },
                    }),
            otherwise: (schema) => schema.nullable(),
        }),
        oldLicencePlateNumber: yup.string().when("isLicencePlateNumberChange", {
            is: true,
            then: (schema) =>
                schema.required(t("error.required")).test({
                    name: "correctValue",
                    message: t("error.notValid"),
                    test: function (value) {
                        return isSlovakSite ? LICENCE_PLATE_FORMAT_SK.test(value) : LICENCE_PLATE_FORMAT_CZ.test(value);
                    },
                }),
            otherwise: (schema) => schema.nullable(),
        }),
        oldVehicleLicenceNumber: yup.string().when("isVehicleLicenceNumberChange", {
            is: true,
            then: (schema) =>
                schema.required(t("error.required")).test({
                    name: "correctValue",
                    message: t("error.notValid"),
                    test: function (value) {
                        return isSlovakSite ? VEHICLE_LICENCE_FORMAT_SK.test(value) : VEHICLE_LICENCE_FORMAT_CZ.test(value);
                    },
                }),
            otherwise: (schema) => schema.nullable(),
        }),
        email: yup.string().email().nullable(),
    });

    const form = useForm<VehicleDataChangeFormValues>({
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema),
    });
    const { setValue, getValues } = form;

    const handleRequest = useFormRequest(form);

    useEffect(() => {
        const createChange = async () => {
            const onError = () => {
                return;
            };

            const onSuccess = (res) => {
                dispatch(setIsFormLoading(false));
                if (res.text) {
                    setValue("idChange", res.text);
                }
            };

            handleRequest(() => vehicleDataChange.createChange(idObject), onError, onSuccess);
        };

        isAuthenticated ? createChange() : dispatch(setIsFormLoading(false));

        return () => {
            dispatch(setStep(1));
            dispatch(setIsFormLoading(true));
            dispatch(setIsSubmitting(false));
            dispatch(setIsLicencePlateFillInLoading(false));
            dispatch(setIsVehicleLicenceFillInLoading(false));
        };
    }, []);

    async function onSubmit(data: VehicleDataChangeFormValues) {
        dispatch(setIsSubmitting(true));
        const dto = getSubmitDataDto(data);

        const onError = () => {
            dispatch(setIsSubmitting(false));
        };

        const onSuccess = (response) => {
            if (!isAuthenticated) {
                setValue("idChange", response.text);
            }

            dispatch(setStep(2));
            dispatch(setIsSubmitting(false));
        };

        isAuthenticated
            ? handleRequest(() => vehicleDataChange.submitAuthenticated(dto, getValues().idChange), onError, onSuccess)
            : handleRequest(() => vehicleDataChange.submitUnauthenticated(dto), onError, onSuccess);
    }

    function getSubmitDataDto(data: VehicleDataChangeFormValues): VehicleDataSubmitDto {
        return {
            newLicencePlateNumber: data.isLicencePlateNumberChange ? data.newLicencePlateNumber : null,
            newVehicleLicenceNumber: data.isVehicleLicenceNumberChange ? data.newVehicleLicenceNumber : null,
            oldLicencePlateNumber: data.isLicencePlateNumberChange ? data.oldLicencePlateNumber : null,
            oldVehicleLicenceNumber: data.isVehicleLicenceNumberChange ? data.oldVehicleLicenceNumber : null,
            vinNumber: data.vinNumber,
            validityStartDate: data.validityStartDate,
            idObject: Number(idObject),
        };
    }

    function previousStep() {
        dispatch(setStep(step - 1));
    }

    return {
        form,
        onSubmit,
        vehicles,
        step,
        previousStep,
        isFormLoading,
        isSubmitting,
        contractNumber,
    };
}
