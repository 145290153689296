import { TFunction } from "core/i18n";
import { fn } from "core/util";
import { Holder, LegalEntity, PaymentsExtended } from "types";
import { nbsp } from "app/constants";
import { formatYesNo } from "./stringFormatter";
import { formatLocalizedValueMap } from "./productFormatter";

export const formatBirthNumber = (value?: string): string => {
    if (value) {
        if (value.length === 10) {
            return `${value.substring(0, 6)}/${value.substring(6)}`;
        }
        if (value.length === 9) {
            return value;
        }
    }
    return "";
};

export const formatBirthNumberOrCompanyId = (entity: Holder | LegalEntity): string => {
    if (fn.isEmpty(entity)) {
        return "";
    }

    let birthNumberOrCompanyId;
    if (entity.isPerson) {
        birthNumberOrCompanyId = formatBirthNumber(entity.birthNumber);
    } else {
        birthNumberOrCompanyId = entity.companyID;
    }
    return birthNumberOrCompanyId;
};

export const oneLineNameWithTitles = (value: Holder | LegalEntity): string => {
    let result = "";

    if (value) {
        if (value.companyName && value.companyName.trim() !== "") {
            result = value.companyName;
        } else {
            if (value.titleBefore) {
                result += value.titleBefore;
            }

            if (value.firstName) {
                result += ` ${value.firstName}`;
            }

            if (value.lastName) {
                result += ` ${value.lastName}`;
            }

            if (value.titleAfter) {
                result += ` ${value.titleAfter}`;
            }
        }
    }

    return result.trim();
};

export const localPhone = (value: string): string => {
    if (value) {
        return value;
    } else {
        return "";
    }
};

export const oneLineLegalEntityWithDescription = (value): string => {
    const formattedName = oneLineNameWithTitles(value);
    if (formattedName) {
        return formattedName;
    }
    return value.description;
};

export const extendedOneLineNameWithTitles = (value: Holder | LegalEntity): string => {
    let result = "";

    if (value) {
        if (value.isPerson) {
            result = oneLineNameWithTitles(value);
        } else {
            if (value.companyID) {
                result += `IČ ${value.companyID}`;
                if (value.companyName) {
                    result += ", ";
                }
            }

            if (value.companyName) {
                result += value.companyName;
            }
        }
    }

    return result;
};

export const formatEmployerPayments = (t: TFunction, employerPayments: PaymentsExtended, currency: string, frequencyMap: any): string => {
    if (!employerPayments.enabled) {
        return formatYesNo(t, employerPayments.enabled);
    }

    const employer = extendedOneLineNameWithTitles(employerPayments.payer);
    const paymentAmount = fn.isEmptyOrZero(employerPayments.amount)
        ? `${employerPayments.percentage} %`
        : `${employerPayments.amount} ${currency}`;

    const frequency = formatLocalizedValueMap(employerPayments.frequency, frequencyMap);

    return `${formatYesNo(t, employerPayments.enabled)},${nbsp}${employer}, ${paymentAmount} / ${frequency}`;
};
