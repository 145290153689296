import { useFormContext } from "react-hook-form";
import { ErrorContent } from "ui-library/atoms";
import { ContentBox } from "ui-library/layouts";
import { FormBlock } from "ui-library/modules";

export default function FormErrorBox() {
    const { watch } = useFormContext();
    const errors = watch("errors");

    return (
        errors?.length > 0 && (
            <FormBlock mb={4}>
                <ContentBox>
                    {errors.map((error: string) => (
                        <div key={error} className={"error_component_class"}>
                            <ErrorContent content={error} />
                        </div>
                    ))}
                </ContentBox>
            </FormBlock>
        )
    );
}
