import { call, fork, select } from "redux-saga/effects";

import entity from "core/entity";
import router from "core/router";
import userContracts from "core/userContracts";
import modal from "core/modal";
import electronicCommunicationForm from "form/modal/electronicCommunication";
import contractNameForm from "form/modal/contractName";
import contractOwnerPaymentsForm from "form/modal/contractOwnerPayments";
import policyHolderForm from "form/fullPage/policyHolder";
import { Tabs } from "routeConstants";
import { contractsApi, documentsApi, lifeApi, paymentApi, uniqaLifeApi } from "serverApi";
import { ContractChangeType } from "types";

import { clear, setLifeContractDetail } from "./actions";
import {
    ENTITY_CONTRACT_DOCUMENTS,
    ENTITY_CONTRACT_INCIDENTS,
    ENTITY_CONTRACT_OVERVIEW,
    ENTITY_FINANCIAL_OPERATIONS,
    ENTITY_FINANCIAL_STATEMENT,
    ENTITY_PAYMENT_INFORMATION,
    ENTITY_TAX_CERTIFICATE_AVAILABLE_YEARS,
    FORM_EDIT_CONTRACT_NAME,
    FORM_EDIT_ELECTRONIC_COMMUNICATION,
    FORM_EDIT_INSURANCE_PAYMENTS,
    FORM_EDIT_POLICY_HOLDER,
} from "./constants";
import { FinancialOperationsTableFilter } from "./modules";
import {
    getActualPaymentSchedulerAxa,
    getActualSchedulerRecordUniqa,
    getElectronicCommunication,
    getIdObject,
    getIsUniqaContract,
    getPolicy,
    getPolicyHolder,
} from "./selectors";

export default router.routerWrapper({
    *getDataForPage({ idObject }) {
        const policy = yield select(userContracts.createGetContractById(Number(idObject)));
        const lifeContractDetail = policy.isUniqaContract
            ? yield call(uniqaLifeApi.getLifeContractDetail, idObject)
            : yield call(lifeApi.getLifeContractDetail, idObject);

        return [setLifeContractDetail(lifeContractDetail)];
    },
    *onPageEnter({ idObject }) {
        const policy = yield select(userContracts.createGetContractById(Number(idObject)));
        if (policy.isUniqaContract) {
            yield fork(loadUniqaData, idObject);
        } else {
            yield fork(loadAxaData, idObject);
        }
        yield fork(entity.load, ENTITY_CONTRACT_DOCUMENTS, () => documentsApi.getContractDocuments(idObject));
        yield fork(entity.load, ENTITY_TAX_CERTIFICATE_AVAILABLE_YEARS, () => documentsApi.getAvailableYearsLifeTaxCertificate(idObject));
        const productGroup = yield select(userContracts.createGetProductGroupUrlNameById(idObject));
        yield fork(entity.load, ENTITY_CONTRACT_OVERVIEW, () => contractsApi.getContractOverview(productGroup, idObject));
        yield fork(FinancialOperationsTableFilter.saga);
        yield fork(loadPaymentInformation, idObject);
    },
    *onInnerRouteChange(name, params, query) {
        if (name === Tabs.CHANGES) {
            yield call(handleChangeTabFormsInitialization, query?.type, params?.idObject);
        }
    },
    *onModalOpen(modalName) {
        const idObject = yield select(getIdObject);
        const isUniqaContract = yield select(getIsUniqaContract);

        switch (modalName) {
            case FORM_EDIT_CONTRACT_NAME:
                yield call(contractNameForm.createSaga, FORM_EDIT_CONTRACT_NAME, idObject);
                break;
            case FORM_EDIT_INSURANCE_PAYMENTS:
                yield call(
                    contractOwnerPaymentsForm.createSagaLegacy,
                    FORM_EDIT_INSURANCE_PAYMENTS,
                    idObject,
                    isUniqaContract ? getActualSchedulerRecordUniqa : getActualPaymentSchedulerAxa,
                );
                break;
            default:
                break;
        }
    },
    clearDataForPage() {
        return [
            clear(),
            modal.closeAllModalForms(),
            entity.clearData(
                ENTITY_CONTRACT_DOCUMENTS,
                ENTITY_FINANCIAL_STATEMENT,
                ENTITY_FINANCIAL_OPERATIONS,
                ENTITY_CONTRACT_INCIDENTS,
                ENTITY_PAYMENT_INFORMATION,
                ENTITY_TAX_CERTIFICATE_AVAILABLE_YEARS,
            ),
        ];
    },
});

function* loadAxaData(idObject) {
    yield fork(entity.load, ENTITY_FINANCIAL_STATEMENT, () => lifeApi.getLifeFinancialStatement(idObject));
    yield fork(entity.load, ENTITY_FINANCIAL_OPERATIONS, () => lifeApi.getLifeFinancialOperations(idObject));
    yield fork(entity.load, ENTITY_CONTRACT_INCIDENTS, () => lifeApi.getLifeClaims(idObject));
    yield fork(entity.periodicallyCheckClaims, 60 * 1000, ENTITY_CONTRACT_INCIDENTS, () => lifeApi.getLifeClaims(idObject));
}

function* loadUniqaData(idObject) {
    yield fork(entity.load, ENTITY_FINANCIAL_STATEMENT, () => uniqaLifeApi.getLifeFinancialStatement(idObject));
    yield fork(entity.load, ENTITY_FINANCIAL_OPERATIONS, () => uniqaLifeApi.getLifeFinancialOperations(idObject));
    yield fork(entity.load, ENTITY_CONTRACT_INCIDENTS, () => uniqaLifeApi.getLifeClaims(idObject));
    yield fork(entity.periodicallyCheckClaims, 60 * 1000, ENTITY_CONTRACT_INCIDENTS, () => uniqaLifeApi.getLifeClaims(idObject));
}

function* loadPaymentInformation(idObject) {
    const productGroup = yield select(userContracts.createGetProductGroupUrlNameById(idObject));
    yield call(entity.load, ENTITY_PAYMENT_INFORMATION, () => paymentApi.get(productGroup, idObject));
}

const contractEditHolderFormSaga = policyHolderForm.createSagaLegacy(FORM_EDIT_POLICY_HOLDER, getPolicyHolder, getPolicy);

function* handleChangeTabFormsInitialization(type, idObject) {
    switch (type) {
        case ContractChangeType.POLICY_HOLDER:
            yield call(contractEditHolderFormSaga, idObject);
            break;
        case ContractChangeType.INSURANCE_PAYMENTS:
            const isUniqaContract = yield select(getIsUniqaContract);
            yield call(
                contractOwnerPaymentsForm.createSagaLegacy,
                FORM_EDIT_INSURANCE_PAYMENTS,
                idObject,
                isUniqaContract ? getActualSchedulerRecordUniqa : getActualPaymentSchedulerAxa,
            );
            break;
        case ContractChangeType.CONTRACT_NAME:
            yield call(contractNameForm.createSaga, FORM_EDIT_CONTRACT_NAME, idObject);
            break;
        case ContractChangeType.ELECTRONIC_COMMUNICATION:
            yield call(
                electronicCommunicationForm.createSaga,
                FORM_EDIT_ELECTRONIC_COMMUNICATION,
                idObject,
                getPolicyHolder,
                getElectronicCommunication,
            );
            break;
        default:
            break;
    }
}
