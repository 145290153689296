import { app } from "core/util";
import { NAME } from "./constants";
import { Selector } from "react-redux";
import { State } from "types";
import { CodeValue, Garage, Region } from "types/garages";

const getModel = app.createGetModel(NAME);
const getData: Selector<State, any> = (state) => getModel(state).get("data");
const getAppState: Selector<State, any> = (state) => getModel(state).get("appState");

export const getCarMakes: Selector<State, CodeValue[]> = (state): CodeValue[] => getData(state).get("carMakes");
export const getRegions: Selector<State, Region[]> = (state): Region[] => getData(state).get("regions");
export const getGarages: Selector<State, Garage[]> = (state): Garage[] => getData(state).get("garages");

export const getIsLoading: Selector<State, boolean> = (state): boolean => getAppState(state).get("isLoading");
export const getErrorMessage: Selector<State, string | null> = (state): string | null => getAppState(state).get("errorMessage")