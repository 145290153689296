import React, { useState } from "react";
import PropTypes from "prop-types";
import IPropTypes from "react-immutable-proptypes";
import { useDropzone } from "react-dropzone";
import { v4 as uuid } from "uuid";
import { List, Map } from "immutable";

import i18n from "core/i18n";
import { Button, ErrorContent, Icon, Loading, Typography } from "ui-library/atoms";
import { Box, Flexbox } from "ui-library/layouts";
import { StyledDropzoneContainer } from "ui-library/Styled";

export const Dropzone = ({
    displayAsButton,
    onChange,
    value,
    directUpload,
    acceptedFileSize,
    maxUploadedFiles,
    acceptedTypes,
    disabled,
    meta,
}) => {
    const { t } = i18n.useTranslation();

    const [loading, setLoading] = useState(false);
    const [rejectedFiles, setRejectedFiles] = useState([]);
    const [moreThanOneFile, setMoreThanOneFile] = useState(false);

    const fieldErrorList = meta?.error;

    const onLoad = (file, data) => {
        const defaultFile = Map({
            id: uuid(),
            fileName: file.name,
            size: file.size,
            fileFormat: file.type,
            data,
        });

        const fileToUpload = directUpload ? defaultFile.set("uploadStatus", Dropzone.UploadStatusEnum.PENDING) : defaultFile;
        onChange(value ? value.push(fileToUpload) : List([fileToUpload]));
        setLoading(false);
    };

    // we will do validation in redux-form
    // forEach is no really an issue, since we have multiple: false.
    // IF MULTIPLE FILES AT ONCE ENABLED, THIS WOULD BE A PROBLEM!
    const onDrop = (acceptedFiles, fileRejections) => {
        acceptedFiles.forEach((file) => {
            setLoading(true);
            if (acceptedFileSize && file.size > acceptedFileSize) {
                // when file would exceed file limit, we will NOT read its content and set empty file instead.
                // This way, we can raise error upward, somewhere in redux form - new value will have everything
                // file, aside from file contents.
                onLoad(file, null);
            } else {
                const reader = new FileReader();
                reader.onload = () => onLoad(file, arrayBufferToBase64(reader.result));
                reader.onabort = () => console.error("File reading was aborted.");
                reader.onerror = () => console.error("File reading has failed.");
                // https://stackoverflow.com/questions/31391207/javascript-readasbinarystring-function-on-e11
                reader.readAsArrayBuffer(file);
            }
        });

        if (fileRejections.length > 1) {
            setMoreThanOneFile(true);
        } else {
            setRejectedFiles(fileRejections.map(({ file, errors }) => ({ name: file.name, errorCode: errors[0].code })));
            setMoreThanOneFile(false);
        }
    };

    const onRemoveFile = (id) => onChange(value ? value.filter((file) => file.get("id") !== id) : List());

    const disableUpload = disabled || (value && value.size >= maxUploadedFiles);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        disabled: disableUpload,
        multiple: false,
        noKeyboard: true,
        // accept: acceptedTypes,
        // accept: "image/png, image/jpeg"
    });

    return (
        <Loading loading={loading}>
            <StyledDropzoneContainer>
                {displayAsButton ? (
                    <span {...getRootProps({ className: "" })}>
                        <Button variant="contained" color="green" endIcon={"download"}>
                            {t("common.uploadFile")}
                        </Button>
                    </span>
                ) : (
                    <div {...getRootProps({ className: "dropzone" })}>
                        <Flexbox alignItems={"center"} gap={3} direction={"column"}>
                            <Icon icon="document-upload" color={disableUpload ? "gray" : "blue"} size={32} />
                            <Box textAlign="center">
                                <Typography variant="span">{t("form.uploadDocuments")}</Typography>
                                <br />
                                <Typography variant="span">{t("form.uploadDocumentsFormat")}</Typography>
                                <br />
                                <Typography variant="span">{`(${t("form.maximumNumberOfFiles")}: ${maxUploadedFiles})`}</Typography>
                            </Box>
                        </Flexbox>
                        <input
                            accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            {...getInputProps()}
                        />
                    </div>
                )}

                {/* Error message - only one file per drop */}
                {moreThanOneFile && <ErrorContent content={t("error.moreThanOneFile")} />}

                {/* Error message wrong file format */}
                {!moreThanOneFile &&
                    rejectedFiles.length > 0 &&
                    rejectedFiles.map(({ name }) => <ErrorContent key={name} content={`${name} ${t("error.fileInvalidType")}`} />)}

                {/* Error message - From redux store */}
                {fieldErrorList?.size > 0 && fieldErrorList.map((error) => <ErrorContent key={error} content={error} />)}

                {/* List of uploaded files */}
                {value.size > 0 && <CustomFiles files={value} onRemoveFile={onRemoveFile} />}
            </StyledDropzoneContainer>
        </Loading>
    );
};

const CustomFiles = ({ files, onRemoveFile }) => {
    const { t } = i18n.useTranslation();
    return (
        <>
            {files.size > 0 && (
                <Box mt={3}>
                    <Typography variant="caption" color="gray">
                        {t("common.uploadedFiles")}
                    </Typography>
                </Box>
            )}
            {files.map((file) => (
                <Flexbox key={file.get("id")} gap={1} alignItems={"center"}>
                    <Icon icon="document-check" si color="success" />
                    <Box>
                        <Typography>{file.get("fileName")}</Typography>
                    </Box>
                    <Button onClick={() => onRemoveFile(file.get("id"))} variant="text" color="blue">
                        {t("common.remove")}
                    </Button>
                </Flexbox>
            ))}
        </>
    );
};

// https://gist.github.com/Deliaz/e89e9a014fea1ec47657d1aac3baa83c
function arrayBufferToBase64(buffer) {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i += 1) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

Dropzone.UploadStatusEnum = Object.freeze({
    PENDING: "pending",
    IN_PROGRESS: "inProgress",
    DONE: "done",
    ERROR: "error",
});

Dropzone.propTypes = {
    meta: PropTypes.shape({
        error: IPropTypes.list,
        touched: PropTypes.bool.isRequired,
        asyncValidating: PropTypes.bool,
        dirty: PropTypes.bool,
        valid: PropTypes.bool,
        form: PropTypes.string,
    }).isRequired,
    /** Value with list of uploaded files. */
    value: IPropTypes.listOf(
        IPropTypes.contains({
            id: PropTypes.string.isRequired,
            fileName: PropTypes.string.isRequired,
            size: PropTypes.number,
            fileFormat: PropTypes.string,
            uploadStatus: PropTypes.oneOf(Object.values(Dropzone.UploadStatusEnum)),
            errorMsg: PropTypes.string,
            data: PropTypes.string.isRequired,
        }),
    ),
    /** Will be called with new list of uploaded files. */
    onChange: PropTypes.func.isRequired,
    directUpload: PropTypes.bool,
    disabled: PropTypes.bool,
    maxUploadedFiles: PropTypes.number,
    /** Will be used in file selection dialog. Will still allow to select non-accepted types! */
    acceptedTypes: PropTypes.object.isRequired,
    /**
     * Will be used to actually not even try to parse a big file. Useful in tens of MBs, when browser wil timeout. If set and exceeded
     * empty file contents are retrieved instead.
     */
    acceptedFileSize: PropTypes.number,
};

Dropzone.defaultProps = {
    value: List(),
    directUpload: false,
    disabled: false,
    maxUploadedFiles: 2,
    acceptedFileSize: null,
};
