import React, { FC } from "react";
import { useSelector } from "react-redux";

import { GridLayout } from "ui-library/layouts";

import i18n, { TFunction } from "core/i18n";

import { GenerateStatementGridItem } from "containers/contract";

import { ActualStatements, ExtraStatements, YearStatements } from "../modules";
import { MovementsOverviewGridItem } from "../containers";
import { getIsDipContract } from "../selectors";

const getStatementOptions = (t: TFunction, setStatement, isDipContract: boolean) => {
    const statementOptions = [
        setStatement(t("statements.extraStatement"), ExtraStatements),
        setStatement(t("statements.actualStatement"), ActualStatements),
    ];
    if (!isDipContract) {
        statementOptions.unshift(setStatement(t("statements.yearlyStatement"), YearStatements));
    }
    return statementOptions;
};

export const StatementsAndPaymentsTab: FC = () => {
    const { t } = i18n.useTranslation();
    const { setStatement } = GenerateStatementGridItem;

    const isDipContract = useSelector(getIsDipContract);

    return (
        <GridLayout defaultSpacing>
            <MovementsOverviewGridItem />

            <GenerateStatementGridItem statements={getStatementOptions(t, setStatement, isDipContract)} />
        </GridLayout>
    );
};
