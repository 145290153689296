import { Roles } from "enums";
import { ChangesDefinition, ChangeType } from "types";

import { useHasRole } from "../user/useHasRole";

export function useGetUserChangeTypes(changeDefinition: ChangesDefinition): ChangeType[] {
    const isAuthenticated = useHasRole(Roles.AUTHENTICATED);

    return isAuthenticated ? changeDefinition.authenticatedChangeOptions : changeDefinition.oneTimeChangeOptions;
}
