import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Button } from "ui-library/atoms";
import { InfoPanel, LabelTextItem } from "ui-library/modules";
import { Box, ContentBox, GridItem } from "ui-library/layouts";

import i18n from "core/i18n";
import localized from "core/localized";
import router from "core/router";
import { useHasEditContractPermission } from "core/hooks";
import userContracts from "core/userContracts";

import { Pages, Params, Tabs } from "routeConstants";
import { getPaymentsScheduler } from "../selectors";

export const ContributionsGridItem: FC = () => {
    const { t } = i18n.useTranslation();

    const paymentsScheduler = useSelector(getPaymentsScheduler);
    const holderPayments = useSelector(localized.getLocalizedSchedulerPaymentFull(paymentsScheduler, true));
    const { idObject } = useParams();
    const hasEditPermission = useHasEditContractPermission();
    const { canEdit: isContractEditable } = userContracts.useGetContractById(idObject);

    const dispatch = useDispatch();

    const doTransition = (contractId: number) =>
        router.navigate(
            Pages.ONLINE_PAYMENT,
            Tabs.PAYMENTS_PAY,
            {},
            {
                [Params.PAYMENT_CONTRACT_ID]: contractId,
            },
            true,
        );

    return (
        <GridItem display="flex" xs={12} md={6}>
            <InfoPanel
                icon="circle-dollar"
                title={t("pension.contributions")}
                actionButton={
                    hasEditPermission &&
                    isContractEditable && (
                        <Box>
                            <Button color="green" onClick={() => dispatch(doTransition(Number(idObject)))}>
                                {t("common.pay")}
                            </Button>
                        </Box>
                    )
                }
            >
                <ContentBox>
                    <LabelTextItem label={t("account.holderPayments")} text={holderPayments} />
                </ContentBox>
            </InfoPanel>
        </GridItem>
    );
};
