import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { InfoPanel, LabelTextItem } from "ui-library/modules";
import { Box, GridItem } from "ui-library/layouts";

import i18n from "core/i18n";
import localized from "core/localized";
import { useHasEditContractPermission } from "core/hooks";
import { dateTimeToLocalFullDate } from "core/formatters";
import { date, fn } from "core/util";
import userContracts from "core/userContracts";

import { getActualScheduler } from "../selectors";

// const NO_VINCULATION = 0;

export const ContractDataGridItem: FC = () => {
    const { t } = i18n.useTranslation();
    const { idObject } = useParams();
    const contract = userContracts.useGetContractById(idObject);
    const hasEditPermission = useHasEditContractPermission();

    // TODO Vinculations
    // const {vinculations} = insuredRealEstateProperty;
    // const filteredVinculations = vinculations.filter((item) => !fn.isObjectEmpty(item))
    //     .filter((item) => item.isExists !== NO_VINCULATION);

    const actualScheduler = useSelector(getActualScheduler);
    const formattedSchedulerPayment = useSelector(
        localized.getLocalizedSchedulerPaymentFullLegacy(fn.getItemOrFirstItemWhenIterable(actualScheduler)),
    );

    return (
        <GridItem display={"flex"} xs={12} md={6}>
            <InfoPanel icon={"contract"} title={t("common.contract")}>
                <Box>
                    <LabelTextItem label={t("common.customContractName")} text={contract?.contractName || "-"} />
                    <LabelTextItem label={t("common.contractNumber")} text={contract?.contractNumber} />
                    <LabelTextItem label={t("common.begin")} text={dateTimeToLocalFullDate(contract?.beginDate)} />
                    <LabelTextItem
                        label={t("insurance.insuranceTo")}
                        text={date.formatToDateWithUnlimited(contract?.endDate, t("common.unlimitedDate"))}
                    />
                    <LabelTextItem label={t("insurance.insuranceCost")} text={formattedSchedulerPayment} />
                    {/*<LabelTextItem label={t("home.vinculations")} text={formatYesNo(t, !filteredVinculations.isEmpty())} />*/}
                </Box>
            </InfoPanel>
        </GridItem>
    );
};
