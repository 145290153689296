import React, { FC } from "react";

import { ContentBox } from "ui-library/layouts";

import i18n from "core/i18n";
import { Field, InputText, max50Characters, required, upperLettersNumbers, validateSkIban, validIban } from "core/form";

import { FIELD_IBAN } from "../../constants";

type AccountSectionSkProps = {
    sectionName: string;
    shouldCheckIbanCountry: boolean;
};

export const AccountSectionSk: FC<AccountSectionSkProps> = ({ sectionName, shouldCheckIbanCountry }) => {
    const { t } = i18n.useTranslation();
    return (
        <>
            <ContentBox fullWidth>
                <Field
                    name={`${sectionName}.${FIELD_IBAN}`}
                    label={t("investments.sellSwitch.targetAccountIban")}
                    component={InputText}
                    validateStatic={shouldCheckIbanCountry ? [required, validIban, validateSkIban] : [required, validIban]}
                    normalize={[upperLettersNumbers, max50Characters]}
                    md={6}
                />
            </ContentBox>
        </>
    );
};
