import { NAME } from "./constants";
import reducer from "./reducer";
import { getIsAuthenticated, getIsOneTimeChangeRole, getIsOneTimeRole, getUserAccount, getUserRoles, hasPermission } from "./selectors";
import { loadUserAccount } from "./util";

export default {
    NAME,
    reducer,
    loadUserAccount,
    hasPermission,
    getUserAccount,
    getUserRoles,
    getIsAuthenticated,
    getIsOneTimeRole,
    getIsOneTimeChangeRole,
};
