import React from "react";

import { GridLayout } from "ui-library/layouts";

import { ContractDataGridItem, InsuredTravelPersonGridItem } from "../containers";

export const DetailTab = () => {
    return (
        <GridLayout defaultSpacing>
            <InsuredTravelPersonGridItem />

            <ContractDataGridItem />
        </GridLayout>
    );
};
