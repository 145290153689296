import React, { FC } from "react";

import { Typography } from "ui-library/atoms";
import { Box } from "ui-library/layouts";

export interface InPageFormHeaderProps {
    title: string;
    lead?: string;
}

export const InPageFormHeader: FC<InPageFormHeaderProps> = ({ title, lead }) => (
    <Box mb={3} fullWidth>
        <Typography variant="h3">{title}</Typography>
        {lead && <Typography variant="lead">{lead}</Typography>}
    </Box>
);
