import { ChangeLanguageButton } from "containers/buttons";
import pageContext from "core/pageContext";
import router from "core/router";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Pages } from "routeConstants";
import { pageRoutes } from "routeUrls";
import { Button } from "ui-library/atoms";
import { Flexbox } from "ui-library/layouts";
import useBreakpoints from "ui-library/theme/breakpoints";
import i18n from "core/i18n";

export default function LoggedOutButtons() {
    const { t } = i18n.useTranslation();
    const locale = useSelector(pageContext.getLocale);
    const breakpointMd = useBreakpoints("md");
    let navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <Flexbox gap={1} flexDirection={{ xs: "column", md: "row" }} mt={{ xs: 3, md: 0 }}>
            {breakpointMd && <ChangeLanguageButton />}
            <Button startIcon={"user"} variant="outlined" color="blue" onClick={() => dispatch(router.navigateSsoLogin())}>
                {t("menu.login")}
            </Button>
            <Button
                variant="contained"
                color="blue"
                onClick={() => navigate(router.enhanceUrlWithLangParam(pageRoutes[Pages.REGISTRATION], locale))}
            >
                {t("menu.howToLogIn")}
            </Button>
        </Flexbox>
    );
}
