import React, { FC } from "react";

import { GridLayout } from "ui-library/layouts";

import { ContractDataGridItem, InsuranceCoverageGridItem } from "../containers";

export const DetailTab: FC = () => {
    return (
        <GridLayout defaultSpacing>
            <InsuranceCoverageGridItem />

            <ContractDataGridItem />
        </GridLayout>
    );
};
