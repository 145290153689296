import { createSelector } from "reselect";
import { Set } from "immutable";

import entity from "core/entity";
import { DEFAULT_EMPTY_PARAM } from "app/constants";
import { app, claims } from "core/util";
import pageContext from "core/pageContext";
import { ClaimsEvents } from "model/claim";
import { COVERAGE_TYPE_PROPERTY, COVERAGE_TYPE_VEHICLE } from "app/pages/detailHome/constants";
import { HarmonizedFinancialOperation } from "model/harmonized";
import userContracts from "core/userContracts";
import { ContractOverview } from "model/common";
import { GreenCardHeader } from "model/nonLife";

import {
    ENTITY_CONTRACT_INCIDENTS,
    ENTITY_CONTRACT_OVERVIEW,
    ENTITY_FINANCIAL_OPERATIONS,
    ENTITY_GREEN_CARD_DATA,
    NAME,
} from "./constants";
import { getLifeContractDetail } from "app/pages/detailLife/selectors";

const LOB_MTPL = "MTPL";

/** contract data */
const getModel = app.createGetModel(NAME);
const getData = (state) => getModel(state).get("data");

const getVehicleContract = (state) => getData(state).get("vehicleContractDetail");
export const getContractOverview = (state) => entity.getDataSelector(state, ENTITY_CONTRACT_OVERVIEW, ContractOverview.fromServer());
const getLineOfBusinessClasses = (state) => getContractOverview(state).getIn(["lineOfBusinessClasses"]);
export const createGetCompliantLineOfBusinessClasses = (idObject) =>
    createSelector(
        getLineOfBusinessClasses,
        userContracts.createGetProductGroupLvl2ById(idObject),
        pageContext.getSiteId,
        (lineOfBusinessClasses, productGroupLvl2, siteId) =>
            claims.getCompliantLineOfBusinesses(lineOfBusinessClasses, productGroupLvl2, siteId),
    );
export const getIdObject = (state) => getVehicleContract(state).get("idObject") || DEFAULT_EMPTY_PARAM;
export const getContractDetail = (state) => getVehicleContract(state).get("contractDetail");

export const getPolicyHolder = (state) => getVehicleContract(state).getIn(["contractDetail", "holder"]);
export const getBalanceOnContract = (state) => getContractDetail(state).get("balanceOnContract");

export const getElectronicCommunication = (state) => getContractDetail(state).get("electronicCommunication");

export const getNumberOfInsuredVehicles = (state) => getContractDetail(state).get("vehicles").size;
export const getVehicles = (state) => getContractDetail(state).get("vehicles");
export const getInsuredVehicle = (state) => getContractDetail(state).get("vehicles").first();

export const getAdditionalCoverages = (state) => getVehicleContract(state).getIn(["contractDetail", "additionalCoverages"]);

export const getActualPaymentScheduler = (state) => getContractDetail(state).get("actualPaymentsScheduler");
export const getActualSchedulerRecord = (state) => getActualPaymentScheduler(state).first();
export const getAdvancedPaymentScheduler = (state) => getContractDetail(state).get("advancePaymentsScheduler");

export const getInsuredProperties = (state) => getContractDetail(state).get("insuredRealEstateProperties");
export const getInsuredVehicles = (state) => getContractDetail(state).get("insuredVehicles");

export const getFinancialOperations = (state) =>
    entity.getDataSelector(state, ENTITY_FINANCIAL_OPERATIONS, HarmonizedFinancialOperation.fromServerList());
export const getClaimsEvents = (state) => entity.getDataSelector(state, ENTITY_CONTRACT_INCIDENTS, ClaimsEvents.fromServer());
export const getClaims = (state) => getClaimsEvents(state).get("claims");
export const getGreenCardHeaderDataList = (state) => entity.getDataSelector(state, ENTITY_GREEN_CARD_DATA, []);

/** appState */
const getAppState = (state) => getModel(state).get("appState");
export const getDetailSelectedCoverage = (state) => getAppState(state).get("detailSelectedCoverage");

/** user data */
export const getPolicy = createSelector(userContracts.getContractNormalizedData, getIdObject, (policies, idObject) =>
    idObject ? policies[idObject] : null,
);

/** Changes */
const getChanges = (state) => getAppState(state).get("changes");

/** Vehicle data change */
const getVehicleDataChange = (state) => getChanges(state).get("vehicleDataChange");
export const getStep = (state) => getVehicleDataChange(state).get("step");
export const getIsFormLoading = (state) => getVehicleDataChange(state).get("isFormLoading");
export const getIsSubmitting = (state) => getVehicleDataChange(state).get("isSubmitting");
export const getIsLicencePlateFillInLoading = (state) => getVehicleDataChange(state).get("isLicencePlateFillInLoading");
export const getIsVehicleLicenceFillInLoading = (state) => getVehicleDataChange(state).get("isVehicleLicenceFillInLoading");

/** custom selectors */
export const getInsurancePackagesOptions = createSelector(
    getInsuredVehicles,
    getInsuredProperties,
    (insuredVehicles, insuredProperties) => {
        const vehicleInsurance = insuredVehicles
            .filter((risk) => !risk.coverages.isEmpty())
            .map((risk) => ({
                value: risk.reactKey,
                label: `${risk?.licencePlateNumber} - ${risk?.category?.value}`,
            }));

        const propertyInsurance = insuredProperties
            .filter((risk) => !risk.coverages.isEmpty())
            .map((risk) => ({
                value: risk.reactKey,
                label: `${risk.description} - ${risk.category?.value}`,
            }));

        return vehicleInsurance.concat(propertyInsurance);
    },
);

export const getInsuranceCategoryCodes = createSelector(
    getContractDetail,
    getInsuredVehicles,
    getInsuredProperties,
    (detail, insuredVehicles, insuredProperties) => {
        const codes = [];
        codes.push(detail.getIn(["contractParameters", "lineOfBusinessClasses"]));
        insuredVehicles.map((insuredVehicle) => codes.push(insuredVehicle.getIn(["category", "code"])));
        insuredProperties.map((insuredVehicle) => codes.push(insuredVehicle.getIn(["category", "code"])));
        return Set(codes.flat());
    },
);

export const getSelectedProductPackage = createSelector(
    getInsuredVehicles,
    getInsuredProperties,
    getDetailSelectedCoverage,
    (insuredVehicles, insuredProperties, selectedCoverageReactKey) => {
        const foundVehicle = insuredVehicles.find((vehicle) => vehicle.reactKey === selectedCoverageReactKey);
        if (foundVehicle) {
            return {
                type: COVERAGE_TYPE_VEHICLE,
                entity: foundVehicle,
            };
        }

        const foundProperty = insuredProperties.find((property) => property.reactKey === selectedCoverageReactKey);
        if (foundProperty) {
            return {
                type: COVERAGE_TYPE_PROPERTY,
                entity: foundProperty,
            };
        }
    },
);

export const getInsuredVehicleWithMtpl = createSelector(getVehicles, (vehicleList) =>
    vehicleList.filter((insuredVehicle) => insuredVehicle.get("hasActiveMTPL")),
);

export const getSelectedInsuredVehicle = createSelector(
    getNumberOfInsuredVehicles,
    getInsuredVehicle,
    getSelectedProductPackage,
    (numberOfInsuredVehicles, insuredVehicle, selectedProductPackage) =>
        numberOfInsuredVehicles === 1 ? insuredVehicle : selectedProductPackage?.entity,
);
