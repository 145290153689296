import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { ContentBox } from "ui-library/layouts";
import { Button, Loading, Typography } from "ui-library/atoms";

import i18n from "core/i18n";
import userContracts from "core/userContracts";
import { InPageFormButtonsLayout, InPageFormLayout } from "containers/inPageForm";
import { Field, form, FormWideDisabledProvider, getFormFieldValue, required, SegmentedControl, SingleSelect } from "core/form";
import { getLocalizedStatementFrequencyOptions } from "core/localized";
import { ChangeSuccessTextType } from "types";

import {
    RECEIVE_BUY_CONFIRMATION_FIELD,
    RECEIVE_SELL_CONFIRMATION_FIELD,
    RECEIVE_STATEMENT_FIELD,
    STATEMENT_PERIODICITY_FIELD,
} from "./constants";

const MutualFundsSettingsForm = ({ canSubmit, handleSubmit, submitting, receiveStatements, includeBuySellConfirmation, isElComSet }) => {
    const { t } = i18n.useTranslation();
    const frequencyOptions = useSelector(getLocalizedStatementFrequencyOptions);

    if (!isElComSet) {
        return (
            <ContentBox fullWidth textAlign={"center"}>
                <Typography markDown={t("general.clientStatement.missingElectronicCommunicationForChange")} />
            </ContentBox>
        );
    }

    return (
        <>
            <FormWideDisabledProvider value={submitting}>
                <ContentBox fullWidth>
                    <Field
                        name={`${RECEIVE_STATEMENT_FIELD}`}
                        component={SegmentedControl}
                        label={t("investments.clientStatement.receiveStatement")}
                    />
                </ContentBox>

                {receiveStatements && (
                    <ContentBox fullWidth>
                        <Field
                            name={`${STATEMENT_PERIODICITY_FIELD}`}
                            component={SingleSelect}
                            label={t("form.statementPeriodicity")}
                            options={frequencyOptions}
                            validateStatic={[required]}
                            placeholder={t("common.choose")}
                        />
                    </ContentBox>
                )}

                {includeBuySellConfirmation && (
                    <>
                        <ContentBox fullWidth>
                            <Field
                                name={`${RECEIVE_BUY_CONFIRMATION_FIELD}`}
                                component={SegmentedControl}
                                label={t("investments.clientStatement.receiveBuyConfirmation")}
                            />
                        </ContentBox>

                        <ContentBox fullWidth>
                            <Field
                                name={`${RECEIVE_SELL_CONFIRMATION_FIELD}`}
                                component={SegmentedControl}
                                label={t("investments.clientStatement.receiveSellConfirmation")}
                            />
                        </ContentBox>
                    </>
                )}
            </FormWideDisabledProvider>

            <InPageFormButtonsLayout
                rightButton={
                    <Button color="blue" variant="contained" onClick={handleSubmit} disabled={!canSubmit || submitting}>
                        {submitting ? <Loading loading /> : t("common.submitChange")}
                    </Button>
                }
            />
        </>
    );
};

MutualFundsSettingsForm.propTypes = {
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    receiveStatements: PropTypes.bool,
    includeBuySellConfirmation: PropTypes.bool,
    isElComSet: PropTypes.bool,
};

MutualFundsSettingsForm.defaultProps = {
    receiveStatements: false,
    includeBuySellConfirmation: false,
    isElComSet: false,
};

export const createInPageContainer = (formName, getIsElComSet, includeBuySellConfirmation = false) =>
    form(formName)((props) => {
        const { t } = i18n.useTranslation();
        const receiveStatements = useSelector(getFormFieldValue(formName, RECEIVE_STATEMENT_FIELD));
        const { idObject } = useParams();
        const contract = userContracts.useGetContractById(idObject);
        const isElComSet = useSelector(getIsElComSet);

        return (
            <InPageFormLayout
                formName={formName}
                formTitle={t("change.title.STATEMENTS_SETTINGS", { contractNumber: contract.contractNumber })}
                changeSuccessTextType={ChangeSuccessTextType.CHANGE_SUBMITTED_FOR_PROCESSING}
            >
                <MutualFundsSettingsForm
                    receiveStatements={receiveStatements}
                    includeBuySellConfirmation={includeBuySellConfirmation}
                    isElComSet={isElComSet}
                    {...props}
                />
            </InPageFormLayout>
        );
    });
