export const SECTION_MARGIN = 4;

// Modal
export const MODAL_UPLOAD_DOCUMENT = "CANCELLATION_MODAL_UPLOAD_DOCUMENT";

// Form fields
export const VIRTUAL_FIELD_FORM_STEP = "formStep";
export const VIRTUAL_FIELD_CHANGE_ID = "changeId";
export const VIRTUAL_FIELD_CHANGE_STATUS = "changeStatus";
export const VIRTUAL_FIELD_CANCELLATION_REASONS = "CANCELLATION_REASONS_VALUES";
export const VIRTUAL_FIELD_ATTACHMENTS = "ATTACHMENTS_VALUES";
export const VIRTUAL_FIELD_DOCUMENT_TO_UPLOAD_FIELD_NAME = "DOCUMENT_TO_UPLOAD_FIELD_NAME";

// Verification
export const FIELD_VERIFY_IDENTIFICATION_NUMBER = "identificationNumberVerify";

export const FIELD_CANCELLATION_REASON = "cancellationReason";

export const FIELD_CANCELLATION_NOTE = "cancellationNote";
export const FIELD_VEHICLE_REMOVED_FROM_REGISTRATION_STATEMENT = "vehicleRemovedFromRegistrationStatement";
export const FIELD_CANCELLATION_DATE = "cancellationDate";

// contract person
export const SECTION_CONTACT_PERSON = "contactPerson";
export const FIELD_SUBJECT_TYPE = "subjectType";
export const FIELD_BIRTH_NUMBER = "birthNumber";
export const FIELD_COMPANY_IDENTIFICATION_NUMBER = "companyIdentificationNumber";
export const FIELD_COMPANY_NAME = "companyName";
export const FIELD_FIRST_NAME = "firstName";
export const FIELD_LAST_NAME = "lastName";
export const FIELD_PHONE_NUMBER = "phoneNumber";
export const FIELD_EMAIL = "email";

export const PERMANENT_ADDRESS_AUTOCOMPLETE = "autocomplete.permanentAddress";

export const SECTION_ADDRESS = "address";
export const FIELD_STREET = "street";
export const FIELD_DESCRIPTION_NUMBER = "descriptionNumber";
export const FIELD_ORIENTATION_NUMBER = "orientationNumber";
export const FIELD_CITY = "city";
export const FIELD_POSTAL_CODE = "postalCode";
export const FIELD_COUNTRY_CODE = "countryCode";

// bank account
export const SECTION_BANK_ACCOUNT = "fundsTransferBankAccount";
export const FIELD_ACCOUNT_PREFIX = "prefix";
export const FIELD_ACCOUNT_NUMBER = "accountNumber";
export const FIELD_BANK_CODE = "bankCode";
export const FIELD_IBAN = "iban";

// Signature
export const FIELD_SIGNATURE_TYPE = "signatureType";
export const FIELD_DATE_OF_SIGNING = "dateOfSigning";
export const FIELD_PLACE_OF_SIGNING = "placeOfSigning";
export const FIELD_SIGNATURE_FILE = "signatureFile";
export const FIELD_ATTACHMENTS = "attachments";

// Form step
export const FORM_STEP = {
    ZERO_INTRODUCTION: 0,
    FIRST_EDIT: 1,
    SECOND_REVIEW_SUBMIT: 2,
    THIRD_CONFIRMATION: 3,
};
